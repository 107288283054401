import { Component, EventEmitter, Input, OnInit, Output }      from '@angular/core';
import { BaseModalComponent }                                  from '../base-modal/base-modal.component';
import { getErrorMessageFromData, getFixedSmallModalSettings } from '../utils/helpers';
import { ApprovableApproveGQL, ApprovableTypeEnum }            from '../../../generated/graphql';
import { TranslatePipe }                                       from '@ngx-translate/core';
import { MatDialog }                                           from '@angular/material/dialog';
import { MatSnackBar }                                         from '@angular/material/snack-bar';

@Component({
  selector: 'et-admin-unapprove-field',
  templateUrl: './admin-unapprove-field.component.html',
  styleUrls: ['./admin-unapprove-field.component.scss'],
  providers: [TranslatePipe]
})
export class AdminUnapproveFieldComponent implements OnInit {
  overlayToShow = '';

  @Input() fieldData!: { id: string, approvableType: ApprovableTypeEnum , key?: string};
  @Output() unapproveFieldSucceeded = new EventEmitter<any>();


  constructor(private approvableApproveGQL: ApprovableApproveGQL,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  onUnapproveFieldClick(): void {
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('admin-unapprove-field.modal-confirm-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('admin-unapprove-field.modal-confirm-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.approvableApproveGQL.mutate({
          input: {
            approvableId: this.fieldData.id,
            approvableType: this.fieldData.approvableType,
            approved: false
          }
        }).subscribe(res => {
          this.unapproveFieldSucceeded.emit(res.data?.approvableApprove);
          this.snackBar.open(this.translatePipe.transform('admin-unapprove-field.operation-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }
}
