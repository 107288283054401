import { Component, Inject, OnInit }     from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe }                 from '@ngx-translate/core';
import {
  AdherableStructureTypeEnum,
  AdherentStructureCreateGQL,
  AdherentStructureDeleteGQL,
  AdherentStructureSearchGQL,
  AdherentStructureUpdateGQL,
  OperationReturnTypeEnum,
  PersonSearchGQL
}                                        from '../../../generated/graphql';
import { FormControl, Validators }       from '@angular/forms';
import { forkJoin, of }                  from 'rxjs';

@Component({
  selector: 'et-adherent-structures-modal',
  templateUrl: './adherent-structures-modal.component.html',
  styleUrls: ['./adherent-structures-modal.component.scss'],
  providers: [TranslatePipe]
})
export class AdherentStructuresModalComponent implements OnInit {

  private readonly adherentStructuresRepeaterData: any[];
  private adherentStructuresToRemove: string[] = [];

  // adherent repeater
  // tslint:disable-next-line:max-line-length
  adherentStructuresRepeaterList: { index: number, isAddNew?: boolean, isUpdate?: boolean, repeaterItem: { adherentId?: string, adherableType?: AdherableStructureTypeEnum, adherableId: FormControl, adherableOptions: { id: any; text: any; }[] | [], personId: FormControl, personOptions: { id: any; text: any; }[] | [] } }[] = [];
  fieldsModel: { [key: string]: any }[] = [];

  constructor(public dialogRef: MatDialogRef<AdherentStructuresModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { fieldKey: string, adherentStructures: any[], institutionId: string },
              private personSearchGQL: PersonSearchGQL,
              private adherentStructureCreateGQL: AdherentStructureCreateGQL,
              private adherentStructureUpdateGQL: AdherentStructureUpdateGQL,
              private adherentStructureDeleteGQL: AdherentStructureDeleteGQL,
              private adherentStructureSearchGQL: AdherentStructureSearchGQL) {
    this.adherentStructuresRepeaterData = this.data.adherentStructures ? [...this.data.adherentStructures] : [];
    this.adherentStructuresRepeaterList = this.adherentStructuresRepeaterData.map((item, index) => {

      const adherableValue = item.adherable.vName?.attributeValue || item.adherable.name;
      const adherableOptions = adherableValue ? [{id: item.adherable.id, text: adherableValue}] : [];
      const personOptions = item.person ? [{id: item.person.id, text: item.person.name}] : [];

      return {
        index,
        isUpdate: true,
        repeaterItem: {
          adherentId: item.id,
          // tslint:disable-next-line:max-line-length
          adherableType: item.adherable.__typename === 'Department' ? AdherableStructureTypeEnum.Department : (item.adherable.__typename === 'Spinoff' ? AdherableStructureTypeEnum.Spinoff : AdherableStructureTypeEnum.Institution),
          adherableId: new FormControl({id: item.adherable.id, text: adherableValue}, {
            validators: [Validators.required]
          }),
          adherableOptions,
          personId: new FormControl(item.person ? {id: item.person.id, text: item.person.name} : undefined, {}),
          personOptions
        }
      };
    });
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  isConfirmDisabled(): boolean {
    return this.adherentStructuresRepeaterList.some(ri => {
      return !ri.repeaterItem.adherableId.value;
    });
  }

  onConfirmClick(): void {
    forkJoin(
      this.adherentStructuresToRemove.length > 0 ? this.adherentStructuresToRemove.map(id => {
        return this.adherentStructureDeleteGQL.mutate({adherentStructureId: id});
      }) : of([])
    ).subscribe(doneDeletion => {
      forkJoin(
        this.adherentStructuresRepeaterList.length > 0 ? this.adherentStructuresRepeaterList.map(item => {
          if (item.isAddNew && item.repeaterItem.adherableType) {
            return this.adherentStructureCreateGQL.mutate({
              input: {
                adherableType: item.repeaterItem.adherableType,
                institutionId: this.data.institutionId,
                adherableId: item.repeaterItem.adherableId.value.id,
                personId: item.repeaterItem.personId.value ? item.repeaterItem.personId.value.id : undefined
              }
            });
          } else if (item.repeaterItem.adherableType && item.repeaterItem.adherentId) {
            // isUpdate - default
            return this.adherentStructureUpdateGQL.mutate({
              adherentStructureId: item.repeaterItem.adherentId,
              input: {
                adherableType: item.repeaterItem.adherableType,
                adherableId: item.repeaterItem.adherableId.value.id,
                institutionId: this.data.institutionId,
                personId: item.repeaterItem.personId.value ? item.repeaterItem.personId.value.id : undefined
              }
            });
          }
          return of([]);
        }) : of([])
      ).subscribe((doneCreateUpdate) => {
        this.dialogRef.close(OperationReturnTypeEnum.Ok);
      }, error => {
        this.dialogRef.close(OperationReturnTypeEnum.Ko);
      });
    }, error => {
      this.dialogRef.close(OperationReturnTypeEnum.Ko);
    });
  }

  autocompleteDisplayFn(option: any): string {
    return option && option.hasOwnProperty('text') ? option.text : '';
  }

  // @ts-ignore
  onAutocompleteOptionSelected({option}, itemIndex: number, fieldKey: 'adherableId' | 'personId'): void {
    const {value} = option;
    if (fieldKey === 'adherableId') {
      this.adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableType = value.adherableType;
    }
  }

  // @ts-ignore
  onAutocompleteTextChange({target}, itemIndex: number, fieldKey: 'adherableId' | 'personId'): void {
    const {value} = target;
    if (value) {
      // perform search
      if (fieldKey === 'adherableId') {
        this.adherentStructureSearchGQL.fetch({keyword: value, active: true}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableOptions = res.data.adherentStructureSearch ? res.data.adherentStructureSearch.map((a) => {
            // @ts-ignore
            const textName = a.vName?.attributeValue || a.name;
            return {
              id: a.id,
              text: textName,
              // tslint:disable-next-line:max-line-length
              adherableType: a.__typename === 'Department' ? AdherableStructureTypeEnum.Department : (a.__typename === 'Spinoff' ? AdherableStructureTypeEnum.Spinoff : AdherableStructureTypeEnum.Institution)
            };
          }) : [];
        });
      } else if (fieldKey === 'personId') {
        this.personSearchGQL.fetch({
          first: undefined,
          last: undefined,
          after: undefined,
          before: undefined,
          keyword: value
        }).subscribe(res => {
          this.adherentStructuresRepeaterList[itemIndex].repeaterItem.personOptions = res.data.personSearch?.edges ? res.data.personSearch.edges.map((e) => {
            return {
              id: e?.node?.id,
              text: e?.node?.name
            };
          }) : [];
        });
      }
    } else {
      if (fieldKey === 'adherableId') {
        this.adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableOptions = [];
      } else if (fieldKey === 'personId') {
        this.adherentStructuresRepeaterList[itemIndex].repeaterItem.personOptions = [];
      }
    }
  }

  addNewAdherentRepeaterItem(): void {
    const newIndex = this.adherentStructuresRepeaterList.length;
    this.adherentStructuresRepeaterList = [...this.adherentStructuresRepeaterList, {
      index: newIndex,
      isAddNew: true,
      repeaterItem: {
        adherableId: new FormControl(undefined, {
          validators: [Validators.required]
        }),
        adherableOptions: [],
        personId: new FormControl(undefined, {}),
        personOptions: []
      }
    }];
  }

  markItemForRemoval(itemIndex: number): void {
    const foundIndexToDelete = this.adherentStructuresRepeaterList.findIndex(o => o.index === itemIndex);
    if (foundIndexToDelete !== -1 && this.adherentStructuresRepeaterList[itemIndex].isUpdate) {
      // @ts-ignore
      this.adherentStructuresToRemove.push(this.adherentStructuresRepeaterList[itemIndex].repeaterItem.adherentId);
    }
    if (foundIndexToDelete !== -1) {
      this.adherentStructuresRepeaterList.splice(foundIndexToDelete, 1);
    }
  }
}
