import { NgModule }                    from '@angular/core';
import { RouterModule, Routes }        from '@angular/router';
import { NotFoundComponent }           from './core/not-found/not-found.component';
import { SignInComponent }             from './sign-in/sign-in.component';
import { UserService }                 from './core/user/user.service';
import { DashboardComponent }          from './dashboard/dashboard.component';
import { InstitutionsComponent }       from './institutions/institutions.component';
import { PersonsComponent }            from './persons/persons.component';
import { DepartmentsComponent }        from './departments/departments.component';
import { InstitutionDetailsComponent } from './institution-details/institution-details.component';
import { DepartmentDetailsComponent }  from './department-details/department-details.component';
import { PersonDetailsComponent }      from './person-details/person-details.component';
import { ExpirationsAllComponent }     from './expirations-all/expirations-all.component';
import { LogoutComponent }             from './core/logout/logout.component';
import { UserRoleEnum }                from '../generated/graphql';
import { StatisticsComponent }         from './statistics/statistics.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: `/dashboard`,
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'enti-spinoff',
    component: InstitutionsComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'dipartimenti',
    component: DepartmentsComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'persone',
    component: PersonsComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'scadenze',
    component: ExpirationsAllComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'enti-spinoff/:id',
    component: InstitutionDetailsComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'dipartimenti/:id',
    component: DepartmentDetailsComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'persone/:id',
    component: PersonDetailsComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: 'grafici',
    component: StatisticsComponent,
    canActivate: [UserService],
    data: {
      requiredStates: ['signedIn'],
      allowedRoles: [UserRoleEnum.SpinoffOffice, UserRoleEnum.SpinoffAdmin, UserRoleEnum.InstitutionOffice, UserRoleEnum.InstitutionAdmin]
    }
  },
  {
    path: 'accedi',
    component: SignInComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedOut']}
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [UserService],
    data: {requiredStates: ['signedIn']}
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
