<ng-template #fileDropContainer>
	<div [class.file-picker__drop-container]="!withInputAndLabel || withInputAndLabel.length <= 0"
       [class.file-picker__drop-container--smaller-drop]="(!withInputAndLabel || withInputAndLabel.length <= 0) && smallerDrop"
			 [class.file-picker__drop-container-with-input-label]="withInputAndLabel && withInputAndLabel.length > 0"
			 [class.file-picker__drop-container--dragging]="dragging"
			 [class.no-cursor-pointer]="!multiple && file"
			 *ngIf="!readOnly"
			 ngFileDrop
			 (click)="labelRef.click()"
			 (uploadOutput)="onUploadOutput($event)">
		<ng-container *ngIf="!withInputAndLabel">
			<div *ngIf="!file || (file && !fileChanged)" class="file-picker__upload-clickable-area cursor-pointer">
				<div class="circle-icon">
					<mat-icon aria-hidden="false" aria-label="carica file">cloud_upload</mat-icon>
				</div>
				<p class="file-picker__upload-instructions">{{'file-picker.upload-instructions' | translate}}</p>
			</div>
			<div *ngIf="file && fileChanged" class="file-picker__upload-clickable-area cursor-pointer">
				<mat-icon class="circle-icon" svgIcon="file-uploaded-check"></mat-icon>
				<p class="file-picker__upload-instructions">{{customUploadedLabel || ('file-picker.file-uploaded' | translate)}}</p>
			</div>
		</ng-container>
		<label #labelRef (click)="$event.stopPropagation()" class="cursor-pointer">
			<input hidden
						 type="file" [accept]="mode === 'all' ? 'application/pdf,image/*' : (mode === 'file'? 'application/pdf' : 'image/*')"
						 ngFileSelect
						 (uploadOutput)="onUploadOutput($event)"
						 [multiple]="multiple" #inputRef>
			{{ withInputAndLabel && withInputAndLabel.length > 0 ? withInputAndLabel : undefined }}
		</label>
	</div>
</ng-template>

<div class="file-picker file-picker--{{mode}}"
			[class.file-picker--with-input-label]="withInputAndLabel">
	<!-- Single image file -->
	<ng-container *ngIf="mode === 'image'">
		<ng-template [ngTemplateOutlet]="fileDropContainer"></ng-template>
		<div class="file-picker__image">
			<img *ngIf="!files?.length && file?.url" [src]="file?.url"/>
			<span *ngIf="file?.name">{{file?.name}}&nbsp;</span>
		</div>
		<div class="file-picker__images" *ngFor="let file of files">
			<img *ngIf="file?.url" [src]="file?.url"/>
			<span *ngIf="file?.name">{{file?.name}}&nbsp;</span>
		</div>
	</ng-container>
	<!-- Document file -->
	<div class="file-picker__documents" *ngIf="mode === 'file' || mode === 'all'">
		<div class="file-picker__documents__tile">
			<ng-template [ngTemplateOutlet]="fileDropContainer"></ng-template>
		</div>
		<ng-container *ngIf="files && files.length > 0">
			<small>{{'file-picker.uploaded-file' | translate}} ({{files.length}})</small>
			<div class="file-picker__documents__tile file-picker__documents__preview-name-container"
					 *ngFor="let document of files; index as i">
				<div class="file-picker__documents__preview">
					<!--<img *ngIf="document.mimeType === 'image/jpeg' || document.mimeType === 'image/jpg'" alt="file jpg caricato" src="../../../assets/images/ic-jpg.svg"/>
					<img *ngIf="document.mimeType === 'image/png'" alt="file png caricato" src="../../../assets/images/ic-png.svg"/>
					<img *ngIf="document.mimeType === 'application/pdf'" alt="file pdf caricato" src="../../../assets/images/ic-pdf.svg"/>-->
					<div class="circle-grey">
						<mat-icon aria-hidden="false" aria-label="allegato">attachment</mat-icon>
					</div>
				</div>
				<div class="file-picker__documents__filename">
					<!--<div>
						<label class="input-label">{{'file-picker.label' | translate}}</label>
					</div>-->
					<!--<mat-form-field appearance="outline">
						<input matInput [(ngModel)]="document.name" [disabled]="readOnly"/>
					</mat-form-field>-->
					<p>{{document?.name}}</p>
				</div>
				<div class="file-picker__documents__delete">
					<button mat-icon-button (click)="onDelete(i)">
						<mat-icon aria-hidden="false" aria-label="elimina">delete_outline</mat-icon>
					</button>
				</div>

			</div>
		</ng-container>
		<ng-container *ngIf="file">
			<div class="file-picker__documents__tile file-picker__documents__preview-name-container">
				<div class="file-picker__documents__preview">
					<!--img *ngIf="file.mimeType === 'application/pdf'" alt="file pdf caricato" src="../../../assets/images/ic-pdf.svg"/>-->
					<div class="circle-grey">
            <mat-icon aria-hidden="false" aria-label="allegato">attachment</mat-icon>
          </div>
				</div>
				<div class="file-picker__documents__filename">
					<p *ngIf="file.name">{{file.name}}</p>
					<a *ngIf="file?.url" (click)="openFileUrl(file.url)">{{'file-picker.view-uploaded-file' | translate}}</a>
				</div>
				<div class="file-picker__documents__delete">
					<button mat-icon-button (click)="onDelete(null)">
            <mat-icon aria-hidden="false" aria-label="elimina">delete_outline</mat-icon>
          </button>
				</div>
			</div>
		</ng-container>
	</div>
</div>
