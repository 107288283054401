<!-- Requirement side panel: a) edit inline > with GENERAL form type. b) edit which closes side panel and open related edit modal >  QUOTE, PARTNERSHIP, DOCUMENT_ATTACHMENT, DOCUMENT_DELIBERATION, ROLE. c) edit of side panel internal lists are handled by inner components > case Quote Payments, Role Compensations.-->
<!-- DETAILS SIDE PANEL -->
<ng-template #detailsSidePanel let-formType="formType" let-formConfiguration="formConfiguration" let-formData="formData"
             let-showPanel="showPanel" let-editMode="editMode">
  <div class="details-side-panel"
       [class.details-side-panel--visible]="showPanel">
    <div class="details-side-panel__header">
      <button mat-icon-button color="primary" aria-label="chiudi" (click)="closeSidePanel(formType)">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="details-side-panel__content" *ngIf="formData && formType">
      <h2>
        {{formType === detailsFormTypes.GENERAL ? ('institution-details.side-panel-details-title--general' | translate) : ''}}
        {{formType === detailsFormTypes.QUOTE ? ('institution-details.side-panel-details-title--quote' | translate) : ''}}
        {{formType === detailsFormTypes.PARTNERSHIP ? ('institution-details.side-panel-details-title--partnership' | translate) : ''}}
        {{formType === detailsFormTypes.DOCUMENT_ATTACHMENT ? ('institution-details.side-panel-details-title--documents-attachment' | translate) : ''}}
        {{formType === detailsFormTypes.DOCUMENT_DELIBERATION ? ('institution-details.side-panel-details-title--documents-deliberation' | translate) : ''}}
        {{formType === detailsFormTypes.ROLE ? ('role-details.side-panel-details-title' | translate) : ''}}
        <span>
           <ng-container *ngIf="formType === detailsFormTypes.GENERAL">
             <ng-template [ngTemplateOutlet]="editCancelSaveButtons"
                          [ngTemplateOutletContext]="{editContext: 'generalDetails', editFlag: formsEditabilityMap.generalDetails}"></ng-template>
            <ng-template [ngTemplateOutlet]="validateAllButton"
                         [ngTemplateOutletContext]="{editContext: 'generalDetails', unapprovedCount: detailsUnapprovedArr.length}"></ng-template>
           </ng-container>
          <ng-container *ngIf="!isAdmin && !isViewer && formType !== detailsFormTypes.GENERAL">
            <button mat-stroked-button
                    color="primary"
                    (click)="onSidePanelOpenModal(formType, formData.id)"
                    class="mat-elevation-z1">
              <mat-icon aria-hidden="false" aria-label="modifica">edit</mat-icon>
              {{'institution-details.edit-button' | translate}}
            </button>
          </ng-container>
        </span>
      </h2>
      <div class="form-box"
           [class.form-box--is-admin]="isAdmin">
        <ng-container *ngFor="let detailsField of formConfiguration">
          <div *ngIf="!detailsField.onlySpinoff || (detailsField.onlySpinoff && formData.__typename === 'Spinoff')"
               [class.form-box__admin-field]="isAdmin"
               [class.form-box__vfield-not-valid]="detailsField.versioned && formData[detailsField.key]?.id && isNotApproved(formData[detailsField.key].id)">
            <label>{{detailsField.label}}{{detailsField.required ? '*' : ''}}</label>
            <!-- view mode -->
            <ng-container *ngIf="!editMode">
              <p
                [class.text-area-view]="detailsField.type === fieldTypesEnum.TEXTAREA"
                *ngIf="!detailsField.type || detailsField.type === fieldTypesEnum.NUMBER || detailsField.type === fieldTypesEnum.TEXTAREA">
                {{detailsField.versioned ? (formData[detailsField.key]?.attributeValue || '—') : (formData[detailsField.key] || '—')}}
              </p>
              <p
                *ngIf="detailsField.type === fieldTypesEnum.BOOLEAN">
                {{formData[detailsField.key] ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}
              </p>
              <p *ngIf="detailsField.type === fieldTypesEnum.DATE">
                {{ formData[detailsField.key] ? (formData[detailsField.key] | date: 'dd/MM/yyyy') : '—'}}
              </p>
              <p *ngIf="detailsField.type === fieldTypesEnum.CURRENCY">
                {{formData[detailsField.key] ? (formData[detailsField.key] | enhancedCurrency: 'EUR') : '—'}}
              </p>
              <!--              <p *ngIf="detailsField.type === fieldTypesEnum.UNI_PARTECIPATION">-->
              <!--                {{ formData[detailsField.key] ? (('institution-details.uniparticipation&#45;&#45;' + formData[detailsField.key]?.attributeValue) | translate) : '—'}}-->
              <!--              </p>-->
              <p *ngIf="detailsField.type === fieldTypesEnum.SELECT">
                {{ formData[detailsField.key] ? (('institution-details.' + detailsField.key + '--' + formData[detailsField.key]) | translate) : '—'}}
              </p>
              <ng-container *ngIf="detailsField.type === fieldTypesEnum.ATTACHMENT">
                <ng-template [ngTemplateOutlet]="viewAttachment"
                             [ngTemplateOutletContext]="{fieldKey: detailsField.key, attachments: formData.attachments }"></ng-template>
              </ng-container>
              <ng-container *ngIf="detailsField.type === fieldTypesEnum.DOCUMENT">
                <p
                  style="margin-right: 10px">{{getDocumentUrlByKeyAndData(detailsField.key, formData) ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}</p>
                <div class="file-view"
                     *ngIf="getDocumentUrlByKeyAndData(detailsField.key, formData)">
                  <span (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(detailsField.key, formData))">PDF</span>
                  <a
                    (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(detailsField.key, formData))">{{'form-fields.document-download' | translate}}</a>
                </div>
              </ng-container>
              <ng-container *ngIf="detailsField.type === fieldTypesEnum.ATECO_ACTIVITIES">
                <ng-template [ngTemplateOutlet]="viewAtecoActivities"
                             [ngTemplateOutletContext]="{fieldKey: detailsField.key }"></ng-template>
              </ng-container>
              <div class="validate-single-field"
                   *ngIf="isAdmin && detailsField.versioned && formData[detailsField.key]?.id && isNotApproved(formData[detailsField.key].id)">
                <button mat-stroked-button
                        color="primary"
                        (click)="validateApprovableField({id : institutionData[detailsField.key].id, approvableType: approvableTypes.VersionedRecord}, detailsField.key)"
                        class="mat-elevation-z1 only-icon">
                  <mat-icon aria-hidden="false" [attr.aria-label]="'validate action'">done_all</mat-icon>
                </button>
              </div>
              <et-admin-unapprove-field
                *ngIf="isAdmin && detailsField.versioned && formData[detailsField.key]?.id && !isNotApproved(formData[detailsField.key].id)"
                [fieldData]="{id: formData[detailsField.key].id, key: detailsField.key, approvableType: approvableTypes.VersionedRecord}"
                (unapproveFieldSucceeded)="refreshApprovableField($event, approvableTypes.VersionedRecord)"></et-admin-unapprove-field>
            </ng-container>
            <!-- edit mode -->
            <ng-container *ngIf="editMode">
              <ng-template [ngTemplateOutlet]="baseEditField"
                           [ngTemplateOutletContext]="{fieldConfiguration: detailsField }"></ng-template>
              <ng-template [ngTemplateOutlet]="versionedField"
                           [ngTemplateOutletContext]="{fieldConfiguration: detailsField }"></ng-template>
              <div class="field-with-actions-container full-width-field"
                   *ngIf="detailsField.type === fieldTypesEnum.ATTACHMENT">
                <ng-template [ngTemplateOutlet]="viewAttachment"
                             [ngTemplateOutletContext]="{fieldKey: detailsField.key, attachments: formData.attachments }"></ng-template>
                <button mat-stroked-button
                        color="primary"
                        class="mat-elevation-z1 tooltip-button"
                        [matTooltip]="('institution-details.attachment-field-edit-info-tooltip' | translate)"
                        matTooltipClass="attachment-tooltip"
                        [attr.aria-label]="('institution-details.attachment-field-edit-info-tooltip' | translate)">
                  i
                </button>
              </div>
              <!-- Complex field of general details (ateco codes) -->
              <div class="field-with-actions-container full-width-field"
                   *ngIf="detailsField.type === fieldTypesEnum.ATECO_ACTIVITIES">
                <ng-template [ngTemplateOutlet]="viewAtecoActivities"
                             [ngTemplateOutletContext]="{fieldKey: detailsField.key }"></ng-template>
                <button mat-stroked-button
                        color="primary"
                        (click)="openAtecoActivitiesModal(detailsField.key)"
                        class="only-icon mat-elevation-z1">
                  <mat-icon aria-hidden="false" aria-label="modifica">edit</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="formType === detailsFormTypes.QUOTE && formData">
        <et-quote-payments [quoteId]="formData.id" [hasActions]="!isAdmin && !isViewer"></et-quote-payments>
      </ng-container>
      <ng-container *ngIf="formType === detailsFormTypes.ROLE && formData">
        <et-role-compensations [roleId]="formData.id" [hasActions]="!isAdmin && !isViewer"></et-role-compensations>
      </ng-container>
    </div>
  </div>
</ng-template>

<!-- VIEW ATTACHMENT FIELD -->
<ng-template #viewAttachment let-fieldKey="fieldKey" let-attachments="attachments">
  <div class="file-view"
       *ngIf="attachments && getAttachmentValueByFieldKeyAndProp(fieldKey, 'url')">
    <span (click)="onOpenDocumentClick(getAttachmentValueByFieldKeyAndProp(fieldKey, 'url'))">PDF</span>
    <a
      (click)="onOpenDocumentClick(getAttachmentValueByFieldKeyAndProp(fieldKey, 'url'))">{{getAttachmentValueByFieldKeyAndProp(fieldKey, 'description')}}</a>
  </div>
  <p *ngIf="!attachments?.length || !getAttachmentValueByFieldKeyAndProp(fieldKey, 'url')">—</p>
</ng-template>

<!-- VIEW ADHERENT STRUCTURES FIELD -->
<ng-template #viewAdherentStructures let-fieldKey="fieldKey">
  <div class="text-list-view">
    <div *ngFor="let adherentStructure of institutionData[fieldKey]">
      <p>{{adherentStructure.adherable?.vName?.attributeValue || adherentStructure.adherable?.name}}</p>
      <p *ngIf="adherentStructure.person">Referente: <a
        [routerLink]="['/persone', adherentStructure.person?.id]">{{adherentStructure.person?.name}}</a>
      </p>
    </div>
    {{!institutionData[fieldKey]?.length ? '—' : ''}}
  </div>
</ng-template>

<ng-template #viewAtecoActivities let-fieldKey="fieldKey">
  <div class="text-list-view">
    <div *ngFor="let activity of institutionData[fieldKey]">
      <p>{{activity.atecoSector?.atecoCode}}</p>
      <p>Percentuale: {{activity.percentage}}%</p>
    </div>
    {{!institutionData[fieldKey]?.length ? '—' : ''}}
  </div>
</ng-template>

<!-- EDIT CANCEL SAVE BUTTONS -->
<ng-template #editCancelSaveButtons let-editContext="editContext" let-editFlag="editFlag">
  <ng-container *ngIf="!isAdmin && !isViewer">
    <button *ngIf="!editFlag"
            mat-stroked-button
            color="primary"
            (click)="onEnableEditing(editContext)"
            class="mat-elevation-z1">
      <mat-icon aria-hidden="false" aria-label="modifica">edit</mat-icon>
      {{'institution-details.edit-button' | translate}}
    </button>
    <button *ngIf="editFlag"
            mat-button
            color="primary"
            (click)="onCancelChanges(editContext)">
      {{'institution-details.cancel-button' | translate}}
    </button>
    <button *ngIf="editFlag"
            mat-stroked-button
            color="primary"
            [disabled]="isSaveDisabled(editContext)"
            (click)="onSaveChanges(editContext)"
            class="mat-elevation-z1">
      <mat-icon aria-hidden="false" aria-label="dettagli">done</mat-icon>
      {{'institution-details.save-button' | translate}}
    </button>
  </ng-container>
</ng-template>

<ng-template #validateAllButton let-editContext="editContext" let-unapprovedCount="unapprovedCount">
  <ng-container *ngIf="isAdmin">
    <button mat-stroked-button
            color="primary"
            [disabled]="!unapprovedCount"
            (click)="onValidateAllClicked(editContext)"
            class="mat-elevation-z1">
      <mat-icon *ngIf="unapprovedCount > 0" aria-hidden="false"
                [attr.aria-label]="('institution-details.validate--'+ editContext | translate)">visibility
      </mat-icon>
      <mat-icon *ngIf="!unapprovedCount" aria-hidden="false"
                [attr.aria-label]="('institution-details.validated--'+ editContext | translate)">done_all
      </mat-icon>
      <ng-container *ngIf="editContext === 'accountReportCurrent'">
        <ng-container
          *ngIf="unapprovedCount > 0">{{'institution-details.account-report-unapproved' | translate:{year: (this.selectedVersionedDate ? this.selectedVersionedDate.getFullYear() : todayDate.getFullYear())} }}</ng-container>
        <ng-container
          *ngIf="!unapprovedCount">
          {{'institution-details.account-report-validated' | translate}}
        </ng-container>
      </ng-container>
      <ng-container *ngIf="editContext !== 'accountReportCurrent'">
        <ng-container
          *ngIf="unapprovedCount === 1">{{'institution-details.fields-to-validate-singular' | translate:{unapprovedCount: unapprovedCount} }}</ng-container>
        <ng-container
          *ngIf="unapprovedCount > 1">{{'institution-details.fields-to-validate-plural' | translate:{unapprovedCount: unapprovedCount} }}</ng-container>
        <ng-container
          *ngIf="!unapprovedCount">
          {{'institution-details.fields-all-validated' | translate}}
        </ng-container>
      </ng-container>
    </button>
  </ng-container>
</ng-template>

<!-- EDIT MODE BASE FORM FIELDS -->
<ng-template #baseEditField let-fieldConfiguration="fieldConfiguration">
  <ng-container *ngIf="!fieldConfiguration.versioned">
    <!-- INPUT TEXT -->
    <mat-form-field *ngIf="!fieldConfiguration.type || fieldConfiguration.type === fieldTypesEnum.TEXT"
                    class="full-width-field"
                    appearance="outline">
      <input matInput
             type="text"
             [name]="fieldConfiguration.key"
             [maxLength]="1000"
             placeholder=""
             [formControl]="fieldConfiguration.fControl"
             [errorStateMatcher]="errorStateMatcher"
             (ngModelChange)="onFormFieldChange($event, fieldConfiguration.key)"/>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
    </mat-form-field>
    <!-- TEXTAREA -->
    <mat-form-field *ngIf="fieldConfiguration.type === fieldTypesEnum.TEXTAREA"
                    appearance="outline"
                    class="full-width-field">
					<textarea matInput
                    [name]="fieldConfiguration.key"
                    placeholder=""
                    rows="5"
                    [formControl]="fieldConfiguration.fControl"
                    [errorStateMatcher]="errorStateMatcher"
                    (ngModelChange)="onFormFieldChange($event, fieldConfiguration.key)"></textarea>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
    </mat-form-field>
    <!-- NUMBER -->
    <mat-form-field *ngIf="fieldConfiguration.type === fieldTypesEnum.NUMBER"
                    class="full-width-field"
                    appearance="outline">
      <input matInput
             type="number"
             [name]="fieldConfiguration.key"
             placeholder=""
             [formControl]="fieldConfiguration.fControl"
             [errorStateMatcher]="errorStateMatcher"
             (ngModelChange)="onFormFieldChange($event, fieldConfiguration.key)"/>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
    </mat-form-field>
    <!-- DATE -->
    <mat-form-field *ngIf="fieldConfiguration.type === 'date'"
                    appearance="outline"
                    class="full-width-field mat-form-field--datepicker-dark">
      <input matInput
             [name]="fieldConfiguration.key"
             placeholder=""
             [matDatepicker]="pickerDate"
             [errorStateMatcher]="errorStateMatcher"
             [formControl]="fieldConfiguration.fControl"
             (ngModelChange)="onFormFieldChange($event, fieldConfiguration.key)">
      <mat-datepicker-toggle [disableRipple]="true" matPrefix [for]="pickerDate">
        <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="apri calendario">calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #pickerDate></mat-datepicker>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
    </mat-form-field>
    <!-- CURRENCY -->
    <mat-form-field *ngIf="fieldConfiguration.type === fieldTypesEnum.CURRENCY"
                    class="full-width-field"
                    appearance="outline">
      <mat-icon aria-hidden="false" aria-label="euro" matPrefix>euro</mat-icon>
      <input matInput
             type="number"
             step="0.01"
             [name]="fieldConfiguration.key"
             [errorStateMatcher]="errorStateMatcher"
             [formControl]="fieldConfiguration.fControl"
             (ngModelChange)="onFormFieldChange($event, fieldConfiguration.key)"
             (blur)="formatCurrencyNumber(fieldConfiguration)">
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('currencyValueInvalid')">{{'form-validation.currency-value-invalid' | translate}}</mat-error>
    </mat-form-field>
    <!-- BOOLEAN -->
    <mat-form-field *ngIf="fieldConfiguration.type === fieldTypesEnum.BOOLEAN"
                    appearance="outline">
      <mat-select placeholder=""
                  [value]="fieldConfiguration.fControl.value"
                  [errorStateMatcher]="errorStateMatcher"
                  (selectionChange)="onSelectFieldChange(fieldConfiguration, $event.value)">
        <mat-option [value]="true">{{ 'form-fields.boolean-yes' | translate}}</mat-option>
        <mat-option [value]="false">{{'form-fields.boolean-no' | translate}}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
    </mat-form-field>
    <!-- SELECT AND SECTOR -->
    <mat-form-field *ngIf="fieldConfiguration.type === fieldTypesEnum.SELECT"
                    class="full-width-field"
                    appearance="outline">
      <mat-select placeholder=""
                  [value]="fieldConfiguration.fControl.value"
                  [errorStateMatcher]="errorStateMatcher"
                  (selectionChange)="onSelectFieldChange(fieldConfiguration, $event.value)">
        <mat-option *ngFor="let item of fieldConfiguration?.selectOptions"
                    [value]="item.id">{{item.text}}</mat-option>
      </mat-select>
      <mat-error
        *ngIf="fieldConfiguration.fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
    </mat-form-field>
    <!-- DOCUMENT - PDF FILE PICKER -->
    <et-file-picker *ngIf="fieldConfiguration.type === fieldTypesEnum.DOCUMENT"
                    [mode]="'file'"
                    [multiple]="false"
                    [smallerDrop]="true"
                    [file]="fieldsModel[fieldConfiguration.key]"
                    (upload)="onUploadOutput($event, fieldConfiguration.key)"
                    (delete)="onDocumentRemove($event, fieldConfiguration.key)"></et-file-picker>
  </ng-container>
</ng-template>

<!-- EDIT MODE VERSIONED FIELD -->
<ng-template #versionedField let-fieldConfiguration="fieldConfiguration">
  <div class="versioned-field full-width-field"
       *ngIf="fieldConfiguration.versioned"
       [class.form-box__vfield-not-valid]="institutionData[fieldConfiguration.key]?.id && isNotApproved(institutionData[fieldConfiguration.key].id)">
    <p
      [class.text-area-view]="fieldConfiguration.type === fieldTypesEnum.TEXTAREA"
      *ngIf="!fieldConfiguration.type || fieldConfiguration.type === fieldTypesEnum.NUMBER || fieldConfiguration.type === fieldTypesEnum.TEXTAREA">
      {{institutionData[fieldConfiguration.key]?.attributeValue || '—'}}
    </p>
    <p *ngIf="fieldConfiguration.type === fieldTypesEnum.UNI_PARTECIPATION">
      {{ institutionData[fieldConfiguration.key] ? (('institution-details.uniparticipation--' + institutionData[fieldConfiguration.key]?.attributeValue) | translate) : '—'}}
    </p>
    <a *ngIf="fieldConfiguration.type === fieldTypesEnum.LINK"
       [class.no-cursor-pointer]="!institutionData[fieldConfiguration.key]?.attributeValue"
       (click)="openUrlOfLinkField(fieldConfiguration)">
      {{institutionData[fieldConfiguration.key]?.attributeValue || '—'}}
    </a>
    <div class="info-overlay-parent">
      <button mat-stroked-button
              color="primary"
              (click)="versionedFieldOverlayToShow = fieldConfiguration.key"
              class="mat-elevation-z1 only-icon">
        <mat-icon aria-hidden="false" [attr.aria-label]="'azioni'">more_horiz</mat-icon>
      </button>
      <div class="info-backdrop-overlay" *ngIf="versionedFieldOverlayToShow !== ''"
           (click)="versionedFieldOverlayToShow = ''"></div>
      <div class="info-overlay" *ngIf="versionedFieldOverlayToShow === fieldConfiguration.key">
        <p
          (click)="openVersionedFieldModal('add-new', fieldConfiguration.key, institutionData[fieldConfiguration.key])">{{'versioned-field.action-add-new' | translate}}</p>
        <p *ngIf="institutionData[fieldConfiguration.key]?.attributeValue"
           (click)="openVersionedFieldModal('edit', fieldConfiguration.key, institutionData[fieldConfiguration.key])">
          {{'versioned-field.action-edit' | translate}}
        </p>
        <p *ngIf="institutionData[fieldConfiguration.key]?.attributeValue"
           class="delete-text"
           (click)="openVersionedFieldModal('delete', fieldConfiguration.key, institutionData[fieldConfiguration.key])">
          {{'versioned-field.action-delete' | translate}}
        </p>
      </div>
    </div>
  </div>
</ng-template>


<!-- INSTITUTION DETAILS -->
<div class="institution-details">
  <div class="institution-details__header">
    <div class="title" *ngIf="!busy">
      <!-- TITLE AND STATUS -->
      <h1>{{institutionData?.vName?.attributeValue || ('no-name.institution' | translate)}}</h1>
      <div class="status-label status-label--valid" *ngIf="institutionData?.allApproved">
        <mat-icon aria-hidden="false" [attr.aria-label]="('institution-details.status-validated' | translate)">
          done_all
        </mat-icon>
        {{'institution-details.status-validated' | translate}}
      </div>
      <div class="status-label status-label--not-valid" *ngIf="!institutionData?.allApproved">
        <mat-icon aria-hidden="false" [attr.aria-label]="('institution-details.status-not-validated' | translate)">
          visibility
        </mat-icon>
        {{'institution-details.status-not-validated' | translate}}
      </div>
      <button mat-stroked-button
              color="primary"
              class="mat-elevation-z1 tooltip-button"
              [matTooltip]="(institutionData?.allApproved ? ('institution-details.status-label-tooltip' | translate) : ('institution-details.status-label-tooltip--to-validate' | translate))"
              [attr.aria-label]="(institutionData?.allApproved ? ('institution-details.status-label-tooltip' | translate) : ('institution-details.status-label-tooltip--to-validate' | translate))">
        ?
      </button>
      <div class="right-actions">
        <button *ngIf="!isAdmin && !isViewer"
                mat-stroked-button
                style="margin-right: 10px"
                (click)="handleInstitutionStatus()"
                color="primary"
                class="mat-elevation-z1">{{(institutionData.active ? 'institution-details.deactivate-institution' : 'institution-details.activate-institution') | translate}}</button>
        <!-- VALIDATION INFO -->
        <ng-container *ngIf="!institutionData?.allApproved">
          <span *ngIf="isAdmin"
                style="margin-right: 16px">{{'institution-details.validation-info-prefix--is-admin' | translate}}</span>
          <button *ngIf="!isAdmin"
                  mat-stroked-button
                  (click)="showValidationInfoOverlay = !showValidationInfoOverlay"
                  color="primary"
                  class="mat-elevation-z1">{{'institution-details.validation-info-button' | translate}}</button>
          <button *ngIf="isAdmin"
                  mat-flat-button
                  (click)="showValidationInfoOverlay = !showValidationInfoOverlay"
                  color="primary"
                  class="mat-elevation-z1">{{ 'institution-details.validation-info-button--is-admin' | translate}}</button>
          <div class="validation-info-backdrop" *ngIf="showValidationInfoOverlay"
               (click)="showValidationInfoOverlay = !showValidationInfoOverlay"></div>
          <div class="validation-info-overlay" *ngIf="showValidationInfoOverlay">
            <p *ngIf="selectedSnapshotValidated">
              {{'institution-details.validation-info-overlay-all-approved' | translate}}
            </p>
            <ng-container *ngFor="let snapshot of snapshotsToValidate">
              <div class="validation-info-overlay__current-snapshot" *ngIf="snapshot.containsCurrent">
                <p class="alert-text">
                  {{ isAdmin ? ('institution-details.validation-info-overlay-unapproved--is-admin' | translate:{count: snapshot.totalCount}) : ('institution-details.validation-info-overlay-unapproved' | translate:{count: snapshot.totalCount}) }}
                </p>
                <div *ngIf="snapshot.categoriesMap.GeneralInfo"
                     (click)="openTabSection(sectionNames.GENERAL_INFO)">
                  <mat-icon aria-hidden="false" aria-label="apri">keyboard_arrow_right</mat-icon>
                  <p>{{'institution-details.validation-info-general' | translate}}</p>
                  <small>{{snapshot.categoriesMap.GeneralInfo}}&nbsp;{{isAdmin ? (snapshot.categoriesMap.GeneralInfo > 1 ? ('institution-details.validation-info-overlay-fields-to-approve' | translate) : ('institution-details.validation-info-overlay-field-to-approve' | translate)) : (snapshot.categoriesMap.GeneralInfo > 1 ? ('institution-details.validation-info-overlay-fields-unapproved' | translate) : ('institution-details.validation-info-overlay-field-unapproved' | translate)) }}</small>
                </div>
                <div *ngIf="snapshot.categoriesMap.AccountReport"
                     (click)="openTabSection(sectionNames.ACCOUNT_REPORT)">
                  <mat-icon aria-hidden="false" aria-label="apri">keyboard_arrow_right</mat-icon>
                  <p>{{'institution-details.validation-info-account-report' | translate}}</p>
                  <small>{{isAdmin ? ('institution-details.validation-info-overlay-account-report-to-approve' | translate) : ('institution-details.validation-info-overlay-account-report-unapproved' | translate) }}</small>
                </div>
                <div *ngIf="snapshot.categoriesMap.ReferentsAndRoles"
                     (click)="openTabSection(sectionNames.REFERENTS_AND_ROLES)">
                  <mat-icon aria-hidden="false" aria-label="apri">keyboard_arrow_right</mat-icon>
                  <p>{{'institution-details.validation-info-referents-roles' | translate}}</p>
                  <small>{{snapshot.categoriesMap.ReferentsAndRoles}}&nbsp;{{isAdmin ? (snapshot.categoriesMap.ReferentsAndRoles > 1 ? ('institution-details.validation-info-overlay-rows-to-approve' | translate) : ('institution-details.validation-info-overlay-row-to-approve' | translate)) : (snapshot.categoriesMap.ReferentsAndRoles > 1 ? ('institution-details.validation-info-overlay-rows-unapproved' | translate) : ('institution-details.validation-info-overlay-row-unapproved' | translate)) }}</small>
                </div>
                <div *ngIf="snapshot.categoriesMap.Partnerships"
                     (click)="openTabSection(sectionNames.PARTNERSHIPS)">
                  <mat-icon aria-hidden="false" aria-label="apri">keyboard_arrow_right</mat-icon>
                  <p>{{'institution-details.validation-info-partnerships' | translate}}</p>
                  <small>{{snapshot.categoriesMap.Partnerships}}&nbsp;{{isAdmin ? (snapshot.categoriesMap.Partnerships > 1 ? ('institution-details.validation-info-overlay-rows-to-approve' | translate) : ('institution-details.validation-info-overlay-row-to-approve' | translate)) : (snapshot.categoriesMap.Partnerships > 1 ? ('institution-details.validation-info-overlay-rows-unapproved' | translate) : ('institution-details.validation-info-overlay-row-unapproved' | translate)) }}</small>
                </div>
              </div>
              <div class="validation-info-overlay__prev-snapshot" *ngIf="!snapshot.containsCurrent"
                   (click)="openPreviousSnapshot(snapshot.endDate)">
                <mat-icon aria-hidden="false" aria-label="apri">keyboard_arrow_right</mat-icon>
                <p>{{'institution-details.validation-info-overlay-prev-snapshot' | translate}} {{snapshot.displayableEndDate | date:'dd/MM/yyyy'}}</p>
                <small>{{isAdmin ? ('institution-details.validation-info-overlay-link-prev-snapshot--is-admin' | translate:{count: snapshot.totalCount}) : ('institution-details.validation-info-overlay-link-prev-snapshot' | translate:{count: snapshot.totalCount})}}</small>
              </div>
            </ng-container>
          </div>
          <!-- non si applica in questo caso quando ci sono operazioni condizionali, chel dio!! -->
          <!-- This template displays the overlay content and is connected to the button -->
          <!--        <ng-template-->
          <!--          cdkConnectedOverlay-->
          <!--          [cdkConnectedOverlayOrigin]="validationInfoOverlay"-->
          <!--          [cdkConnectedOverlayOpen]="showValidationInfoOverlay">-->
          <!--          <div class="validation-info-overlay">-->
          <!--            <p>{{ institutionData?.allApproved ? ('institution-details.validation-info-overlay-all-approved' | translate) : ('institution-details.validation-info-overlay-unapproved' | translate:{count: institutionData?.unapproved?.length})}}</p>-->
          <!--          </div>-->
          <!--        </ng-template>-->
          <button mat-stroked-button
                  color="primary"
                  class="mat-elevation-z1 tooltip-button"
                  [matTooltip]="('institution-details.validation-info-tooltip' | translate)"
                  [attr.aria-label]="('institution-details.validation-info-tooltip' | translate)">
            ?
          </button>
        </ng-container>
      </div>
    </div>
    <!-- TIME MACHINE CONTROLS STRIPE -->
    <div class="institution-details__time-machine-stripe"
         [class.institution-details__time-machine-stripe--is-current]="!selectedVersionedDate || selectedVersionedDate.getTime() === todayDate.getTime()"
         [class.institution-details__time-machine-stripe--is-snapshot]="selectedVersionedDate && selectedVersionedDate.getTime() !== todayDate.getTime()">
      <p>
        {{!selectedVersionedDate ? ('institution-details.is-current' | translate) : (('institution-details.is-snapshot' | translate) + ' ' + (selectedVersionedDate | date:'dd/MM/yyyy'))}}
        <button mat-stroked-button
                color="primary"
                class="mat-elevation-z1 tooltip-button"
                [matTooltip]="('institution-details.time-machine-stripe-tooltip' | translate)"
                [attr.aria-label]="('institution-details.time-machine-stripe-tooltip' | translate)">
          ?
        </button>
      </p>
      <div>
        <button *ngIf="selectedVersionedDate"
                mat-stroked-button
                color="primary"
                (click)="onGoBackToCurrentVersion()"
                class="mat-elevation-z1"
                style="margin-right: 16px">
          <mat-icon aria-hidden="false" aria-label="torna ad oggi">calendar_today</mat-icon>
          {{'institution-details.current-version-date' | translate}}
        </button>
        <button mat-stroked-button
                color="primary"
                (click)="picker.open()"
                class="mat-elevation-z1">
          <mat-datepicker-toggle [for]="picker"></mat-datepicker-toggle>
          {{'institution-details.change-versioned-date' | translate}}
        </button>
        <input matInput
               name="changeVersionedDate"
               [max]="todayDate"
               [(ngModel)]="pickerDate"
               (ngModelChange)="onSelectVersionedDate($event)"
               [matDatepicker]="picker">
        <mat-datepicker #picker disabled="false"></mat-datepicker>
      </div>
    </div>
  </div>
  <et-loading *ngIf="busy" class="spacing-top-40"></et-loading>
  <mat-tab-group dynamicHeight
                 *ngIf="institutionData && !busy"
                 [selectedIndex]="selectedTab.value"
                 (selectedIndexChange)="onTabSectionSelected($event)">
    <!-- section GENERAL_INFO -->
    <mat-tab [label]="(('institution-details.tab-label-section--'+sectionNames.GENERAL_INFO) | translate)">
      <div class="institution-details__content two-column-grid">
        <div>
          <h2>
            {{'institution-details.section-titles-general' | translate}}
            <span>
              <button *ngIf="!formsEditabilityMap.generalMain"
                      mat-stroked-button
                      [color]="detailsUnapprovedArr.length > 0 ? 'warn' : 'primary'"
                      (click)="showGeneralDetailsSidePanel = true"
                      class="mat-elevation-z1">
                <mat-icon aria-hidden="false" aria-label="dettagli">list</mat-icon>
                {{'institution-details.view-details-button' | translate}}
                <ng-container
                  *ngIf="detailsUnapprovedArr.length > 0">{{'institution-details.details-unapproved' | translate:{detailsUnapproved: detailsUnapprovedArr.length} }}</ng-container>
              </button>
              <ng-template [ngTemplateOutlet]="editCancelSaveButtons"
                           [ngTemplateOutletContext]="{editContext: 'generalMain', editFlag: formsEditabilityMap.generalMain}"></ng-template>
              <ng-template [ngTemplateOutlet]="validateAllButton"
                           [ngTemplateOutletContext]="{editContext: 'generalMain', unapprovedCount: mainUnapprovedArr.length}"></ng-template>
            </span>
          </h2>
          <div class="form-box" [class.form-box--is-admin]="isAdmin">
            <div *ngFor="let mainField of formsFieldsMap.generalMain"
                 [class.form-box__admin-field]="isAdmin"
                 [class.form-box__vfield-not-valid]="mainField.versioned && institutionData[mainField.key]?.id && isNotApproved(institutionData[mainField.key].id)">
              <label>{{mainField.label}}{{mainField.required ? '*' : ''}}</label>
              <!-- view mode -->
              <ng-container *ngIf="!formsEditabilityMap.generalMain">
                <p
                  [class.text-area-view]="mainField.type === fieldTypesEnum.TEXTAREA"
                  *ngIf="!mainField.type || mainField.type === fieldTypesEnum.NUMBER || mainField.type === fieldTypesEnum.TEXTAREA">
                  {{mainField.versioned ? (institutionData[mainField.key]?.attributeValue || '—') : (institutionData[mainField.key] || '—')}}
                </p>
                <a *ngIf="mainField.type === fieldTypesEnum.LINK"
                   [class.no-cursor-pointer]="!institutionData[mainField.key]?.attributeValue"
                   (click)="openUrlOfLinkField(mainField)">
                  {{mainField.versioned ? (institutionData[mainField.key]?.attributeValue || '—') : (institutionData[mainField.key] || '—')}}
                </a>
                <p *ngIf="mainField.type === fieldTypesEnum.UNI_PARTECIPATION">
                  {{ institutionData[mainField.key] ? (('institution-details.uniparticipation--' + institutionData[mainField.key]?.attributeValue) | translate) : '—'}}
                </p>
                <p *ngIf="mainField.type === fieldTypesEnum.SELECT && mainField.key !== 'sector'">
                  {{ institutionData[mainField.key] ? (('institution-details.' + mainField.key + '--' + institutionData[mainField.key]) | translate) : '—'}}
                </p>
                <p *ngIf="mainField.type === fieldTypesEnum.SELECT && mainField.key === 'sector'">
                  {{institutionData[mainField.key]?.name || '—'}}
                </p>
                <ng-container *ngIf="mainField.type === fieldTypesEnum.ADHERENT_STRUCTURES">
                  <ng-template [ngTemplateOutlet]="viewAdherentStructures"
                               [ngTemplateOutletContext]="{fieldKey: mainField.key }"></ng-template>
                </ng-container>
                <div class="validate-single-field"
                     *ngIf="isAdmin && mainField.versioned && institutionData[mainField.key]?.id && isNotApproved(institutionData[mainField.key].id)">
                  <button mat-stroked-button
                          color="primary"
                          (click)="validateApprovableField({id : institutionData[mainField.key].id, approvableType: approvableTypes.VersionedRecord}, mainField.key)"
                          class="mat-elevation-z1 only-icon">
                    <mat-icon aria-hidden="false" [attr.aria-label]="'validate action'">done_all</mat-icon>
                  </button>
                </div>
                <et-admin-unapprove-field
                  *ngIf="isAdmin && mainField.versioned && institutionData[mainField.key]?.id && !isNotApproved(institutionData[mainField.key].id)"
                  [fieldData]="{id: institutionData[mainField.key].id, key: mainField.key, approvableType: approvableTypes.VersionedRecord}"
                  (unapproveFieldSucceeded)="refreshApprovableField($event, approvableTypes.VersionedRecord)"></et-admin-unapprove-field>
              </ng-container>
              <!-- edit mode -->
              <ng-container *ngIf="formsEditabilityMap.generalMain">
                <ng-template [ngTemplateOutlet]="baseEditField"
                             [ngTemplateOutletContext]="{fieldConfiguration: mainField }"></ng-template>
                <ng-template [ngTemplateOutlet]="versionedField"
                             [ngTemplateOutletContext]="{fieldConfiguration: mainField }"></ng-template>
                <!-- Complex fields of general main (adherent structures) -->
                <div class="field-with-actions-container full-width-field"
                     *ngIf="mainField.type === fieldTypesEnum.ADHERENT_STRUCTURES">
                  <ng-template [ngTemplateOutlet]="viewAdherentStructures"
                               [ngTemplateOutletContext]="{fieldKey: mainField.key }"></ng-template>
                  <button mat-stroked-button
                          color="primary"
                          (click)="openAdherentStructuresModal(mainField.key)"
                          class="only-icon mat-elevation-z1">
                    <mat-icon aria-hidden="false" aria-label="modifica">edit</mat-icon>
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div>
          <!-- separated fields (description, contact purpose) -->
          <ng-container *ngIf="institutionData.__typename === 'Spinoff' && formsFieldsMap.description">
            <h2>
              {{'institution-details.section-titles-spinoff-description' | translate}}
              <span>
                <ng-template [ngTemplateOutlet]="editCancelSaveButtons"
                             [ngTemplateOutletContext]="{editContext: 'description', editFlag: formsEditabilityMap.description}"></ng-template>
              </span>
            </h2>
            <div class="form-box" style="margin-bottom: 32px">
              <!-- view mode -->
              <p *ngIf="!formsEditabilityMap.description"
                 class="text-area-view">{{institutionData.description || '—'}}</p>
              <!-- edit mode -->
              <ng-container *ngIf="formsEditabilityMap.description">
                <ng-template [ngTemplateOutlet]="baseEditField"
                             [ngTemplateOutletContext]="{fieldConfiguration: formsFieldsMap.description }"></ng-template>
              </ng-container>
            </div>
          </ng-container>
          <h2>
            {{'institution-details.section-titles-corporate-purpose' | translate}}
            <span>
              <ng-template [ngTemplateOutlet]="editCancelSaveButtons"
                           [ngTemplateOutletContext]="{editContext: 'corporatePurpose', editFlag: formsEditabilityMap.corporatePurpose}"></ng-template>
            </span>
          </h2>
          <div class="form-box">
            <!-- view mode -->
            <p *ngIf="!formsEditabilityMap.corporatePurpose"
               class="text-area-view">{{institutionData.corporatePurpose || '—'}}</p>
            <!-- edit mode -->
            <ng-container *ngIf="formsEditabilityMap.corporatePurpose">
              <ng-template [ngTemplateOutlet]="baseEditField"
                           [ngTemplateOutletContext]="{fieldConfiguration: formsFieldsMap.corporatePurpose }"></ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- section ACCOUNT_REPORT -->
    <mat-tab [label]="(('institution-details.tab-label-section--'+sectionNames.ACCOUNT_REPORT) | translate)">
      <div class="institution-details__content two-column-grid">
        <div class="grid-column-span-2 annual-account-actions">
          <ng-container *ngIf="processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]">
            <ng-template [ngTemplateOutlet]="validateAllButton"
                         [ngTemplateOutletContext]="{editContext: 'accountReportCurrent', unapprovedCount: !processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].approved ? 1 : 0}"></ng-template>
          </ng-container>
          <button mat-stroked-button
                  *ngIf="!isAdmin && !isViewer && hasPreviousYearAnnualAccountReport()"
                  color="primary"
                  (click)="onEditPreviousAnnualAccountReportClick()"
                  class="mat-elevation-z1">
            <mat-icon aria-hidden="false" aria-label="modifica">edit</mat-icon>
            {{'institution-details.edit-previous-account-report' | translate}}
          </button>
          <button mat-stroked-button
                  *ngIf="!isAdmin && !isViewer && !hasPreviousYearAnnualAccountReport()"
                  color="primary"
                  (click)="onCreatePreviousAnnualAccountReportClick()"
                  class="mat-elevation-z1">
            {{'institution-details.create-previous-account-report' | translate }}
          </button>
          <button mat-stroked-button
                  *ngIf="!isAdmin && !isViewer && !processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]"
                  color="primary"
                  (click)="onCreateCurrentAnnualAccountReportClick()"
                  class="mat-elevation-z1">
            {{'institution-details.create-current-account-report' | translate:{year: (this.selectedVersionedDate ? this.selectedVersionedDate.getFullYear() : todayDate.getFullYear())} }}
          </button>
        </div>
        <ng-container *ngIf="processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]">
          <div>
            <h2>
              {{'institution-details.section-titles-account-report-current' | translate}}
              <span>
                <ng-template [ngTemplateOutlet]="editCancelSaveButtons"
                             [ngTemplateOutletContext]="{editContext: 'accountReportCurrent', editFlag: formsEditabilityMap.accountReportCurrent}"></ng-template>
              </span>
            </h2>
            <div class="form-box">
              <div *ngFor="let field of formsFieldsMap.accountReportCurrent">
                <label>{{field.label}}{{field.required ? '*' : ''}}</label>
                <!-- view mode -->
                <ng-container
                  *ngIf="!formsEditabilityMap.accountReportCurrent">
                  <p
                    *ngIf="field.type === fieldTypesEnum.BOOLEAN">{{processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}</p>
                  <p
                    *ngIf="field.type === fieldTypesEnum.DATE">{{ processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] ? (processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] | date: 'dd/MM/yyyy') : '—'}}</p>
                  <ng-container *ngIf="field.type === fieldTypesEnum.DOCUMENT">
                    <p
                      style="margin-right: 10px">{{getDocumentUrlByKeyAndData(field.key, processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]) ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}</p>
                    <div class="file-view"
                         *ngIf="getDocumentUrlByKeyAndData(field.key, processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT])">
                    <span
                      (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(field.key, processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]))">PDF</span>
                      <a
                        (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(field.key, processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]))">{{'form-fields.document-download' | translate}}</a>
                    </div>
                  </ng-container>
                </ng-container>
                <!-- edit mode -->
                <ng-container *ngIf="formsEditabilityMap.accountReportCurrent">
                  <ng-template [ngTemplateOutlet]="baseEditField"
                               [ngTemplateOutletContext]="{fieldConfiguration: field }"></ng-template>
                </ng-container>
              </div>
            </div>
          </div>
          <div>
            <h2>
              {{'institution-details.section-titles-account-report-extra-info' | translate}}
              <span>
                <ng-template [ngTemplateOutlet]="editCancelSaveButtons"
                             [ngTemplateOutletContext]="{editContext: 'accountReportExtraInfo', editFlag: formsEditabilityMap.accountReportExtraInfo}"></ng-template>
              </span>
            </h2>
            <div class="form-box">
              <div *ngFor="let field of formsFieldsMap.accountReportExtraInfo">
                <label>{{field.label}}{{field.required ? '*' : ''}}</label>
                <!-- view mode -->
                <ng-container
                  *ngIf="!formsEditabilityMap.accountReportExtraInfo">
                  <p
                    *ngIf="!field.type">{{(processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] === undefined || processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] === null) ? '—' : processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key]}}</p>
                  <p
                    *ngIf="field.type === fieldTypesEnum.NUMBER">{{processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] || '—'}}</p>
                  <p
                    *ngIf="field.type === fieldTypesEnum.CURRENCY">{{ (processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] === undefined || processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] === null) ? '—' : (processedData[processedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key] | enhancedCurrency: 'EUR')}}</p>
                </ng-container>
                <!-- edit mode -->
                <ng-container *ngIf="formsEditabilityMap.accountReportExtraInfo">
                  <ng-template [ngTemplateOutlet]="baseEditField"
                               [ngTemplateOutletContext]="{fieldConfiguration: field }"></ng-template>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="grid-column-span-2">
          <h2>{{'institution-details.section-titles-account-report-history' | translate}}</h2>
          <div class="list-box category-table"
               *ngIf="processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS]">
            <div class="category-axis">
              <p>&nbsp;</p>
              <p>{{'institution-details.revenue' | translate}}</p>
              <p>{{'institution-details.productionValue' | translate}}</p>
              <p>{{'institution-details.exerciseResult' | translate}}</p>
              <p>{{'institution-details.socialCapital' | translate}}</p>
              <p>{{'institution-details.netAssets' | translate}}</p>
              <p>{{'institution-details.employeeNumber' | translate}}</p>
            </div>
            <div class="data-axis">
              <div>
                <p
                  *ngFor="let hItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].header">{{hItem}}</p>
              </div>
              <div>
                <p
                  *ngFor="let cItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data?.revenue">{{cItem || '—'}}</p>
              </div>
              <div>
                <p
                  *ngFor="let cItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data?.productionValue">{{cItem || '—'}}</p>
              </div>
              <div>
                <p
                  *ngFor="let cItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data?.exerciseResult">{{cItem || '—'}}</p>
              </div>
              <div>
                <p
                  *ngFor="let cItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data?.socialCapital">{{cItem || '—'}}</p>
              </div>
              <div>
                <p
                  *ngFor="let cItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data?.netAssets">{{cItem || '—'}}</p>
              </div>
              <div>
                <p
                  *ngFor="let cItem of processedData[processedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data?.employeeNumber">{{cItem || '—'}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-column-span-2">
          <div class="filterable-table-title-wrapper">
            <h2>{{'institution-details.section-titles-account-report-quotes' | translate}}</h2>
            <et-filterable-table [tableData]="quotesTableConfig"
                                 [hasAddNewButton]="!isAdmin && !isViewer"
                                 [addNewButtonText]="('institution-details.modal-add-new-item' | translate)"
                                 [addNewButtonIcon]="'add_circle_outline'"
                                 (addNewClicked)="openAddNewQuoteModal()"
                                 (actionButtonClicked)="filterableTableItemClickedAction($event)"></et-filterable-table>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- section REFERENTS_AND_ROLES -->
    <mat-tab [label]="(('institution-details.tab-label-section--'+sectionNames.REFERENTS_AND_ROLES) | translate)">
      <div class="institution-details__content">
        <et-referents-and-roles
          *ngIf="processedData[processedDataKinds.REFERENTS_AND_ROLES]"
          [institutionId]="institutionData.id"
          [listData]="processedData[processedDataKinds.REFERENTS_AND_ROLES]"
          (listDataUpdated)="onReferentsAndRolesListUpdated($event)"
          (validateRoleItemClicked)="validateApprovableField($event)"
          (refreshRoleApprovable)="refreshApprovableField($event, approvableTypes.Role)"
          (viewDetailsClicked)="onRoleDetailsClicked($event)"></et-referents-and-roles>
      </div>
    </mat-tab>
    <!-- section PARTNERSHIPS -->
    <mat-tab [label]="(('institution-details.tab-label-section--'+sectionNames.PARTNERSHIPS) | translate)">
      <div class="institution-details__content">
        <div class="filterable-table-title-wrapper">
          <et-filterable-table [tableData]="partnershipsTableConfig"
                               [hasRowMarking]="isAdmin"
                               [enableCellMark]="true"
                               [hasAddNewButton]="!isAdmin && !isViewer"
                               [addNewButtonText]="('institution-details.modal-add-new-item' | translate)"
                               [addNewButtonIcon]="'add_circle_outline'"
                               (addNewClicked)="openAddNewPartnershipModal()"
                               (linkCellClicked)="onPersonLinkClick($event)"
                               (validateItemClicked)="validateApprovableField($event, approvableTypes.Partnership)"
                               (unapproveItemSucceeded)="refreshApprovableField($event, approvableTypes.Partnership)"
                               (actionButtonClicked)="filterableTableItemClickedAction($event)"></et-filterable-table>
        </div>
      </div>
    </mat-tab>
    <!-- section EXPIRATIONS -->
    <mat-tab [label]="(('institution-details.tab-label-section--'+sectionNames.EXPIRATIONS) | translate)">
      <div class="institution-details__content">
        <et-expiration-reminds-table [reminds]="processedData[processedDataKinds.EXPIRING_REMINDS]"
                                     [showArchiveCheckbox]="!isAdmin && !isViewer"
                                     (expirationRemindArchived)="updateArchivedRemindsTable($event)"
                                     [title]="('institution-details.next-expiration-reminds' | translate)"
                                     [hasActions]="!isAdmin && !isViewer"
                                     (createActionClicked)="openAddNewExpirationRemindModal()"
                                     (editActionClicked)="openExpirationRemindEditModal($event)"></et-expiration-reminds-table>
        <div class="spacing-top-40">&nbsp;</div>
        <et-expiration-reminds-table [reminds]="processedData[processedDataKinds.ARCHIVED_REMINDS]"
                                     [showArchiveCheckbox]="!isAdmin && !isViewer"
                                     (expirationRemindArchived)="updateArchivedRemindsTable($event)"
                                     [title]="('institution-details.archived-reminds' | translate)"></et-expiration-reminds-table>
      </div>
    </mat-tab>
    <!-- section DOCUMENTS -->
    <mat-tab [label]="(('institution-details.tab-label-section--'+sectionNames.DOCUMENTS) | translate)">
      <div class="institution-details__content">
        <div class="filterable-table-title-wrapper">
          <h2>{{'institution-details.section-titles-documents' | translate}}</h2>
          <et-filterable-table [tableData]="documentsAttachmentsTableConfig"
                               [hasAddNewButton]="!isAdmin && !isViewer"
                               [addNewButtonText]="('institution-details.modal-add-new-item' | translate)"
                               [addNewButtonIcon]="'add_circle_outline'"
                               (addNewClicked)="openAddNewAttachmentModal()"
                               (linkCellClicked)="onDocumentsLinkClick($event)"
                               (actionButtonClicked)="filterableTableItemClickedAction($event)"></et-filterable-table>
        </div>
        <div class="filterable-table-title-wrapper spacing-top-40">
          <h2>{{'institution-details.section-titles-deliberations' | translate}}</h2>
          <et-filterable-table [tableData]="documentsDeliberationsTableConfig"
                               [hasAddNewButton]="!isAdmin && !isViewer"
                               [addNewButtonText]="('institution-details.modal-add-new-item' | translate)"
                               [addNewButtonIcon]="'add_circle_outline'"
                               (addNewClicked)="openAddNewDeliberationModal()"
                               (linkCellClicked)="onDocumentsLinkClick($event)"
                               (actionButtonClicked)="filterableTableItemClickedAction($event)"></et-filterable-table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- Attach details side panel with a backdrop overlay -->
<div class="app-backdrop-overlay" *ngIf="showGeneralDetailsSidePanel"
     (click)="closeSidePanel(detailsFormTypes.GENERAL)"></div>
<ng-template [ngTemplateOutlet]="detailsSidePanel"
             [ngTemplateOutletContext]="{formType: detailsFormTypes.GENERAL , formConfiguration: formsFieldsMap.generalDetails ,
             formData: institutionData, showPanel: showGeneralDetailsSidePanel, editMode: formsEditabilityMap.generalDetails }"></ng-template>

<div class="app-backdrop-overlay" *ngIf="showPartnershipDetailsSidePanel"
     (click)="closeSidePanel(detailsFormTypes.PARTNERSHIP)"></div>
<ng-template [ngTemplateOutlet]="detailsSidePanel"
             [ngTemplateOutletContext]="{formType: detailsFormTypes.PARTNERSHIP , formConfiguration: formsFieldsMap.partnershipDetails,
             formData: processedData[processedDataKinds.SELECTED_PARTNERSHIP_DETAILS], showPanel: showPartnershipDetailsSidePanel, editMode: formsEditabilityMap.partnershipDetails }"></ng-template>

<div class="app-backdrop-overlay" *ngIf="showDocumentAttachmentDetailsSidePanel"
     (click)="closeSidePanel(detailsFormTypes.DOCUMENT_ATTACHMENT)"></div>
<ng-template [ngTemplateOutlet]="detailsSidePanel"
             [ngTemplateOutletContext]="{formType: detailsFormTypes.DOCUMENT_ATTACHMENT , formConfiguration: formsFieldsMap.documentsAttachmentDetails,
             formData: processedData[processedDataKinds.SELECTED_DOCUMENT_ATTACHMENT_DETAILS], showPanel: showDocumentAttachmentDetailsSidePanel, editMode: formsEditabilityMap.documentsAttachmentDetails }"></ng-template>

<div class="app-backdrop-overlay" *ngIf="showDocumentDeliberationDetailsSidePanel"
     (click)="closeSidePanel(detailsFormTypes.DOCUMENT_DELIBERATION)"></div>
<ng-template [ngTemplateOutlet]="detailsSidePanel"
             [ngTemplateOutletContext]="{formType: detailsFormTypes.DOCUMENT_DELIBERATION , formConfiguration: formsFieldsMap.documentsDeliberationDetails,
             formData: processedData[processedDataKinds.SELECTED_DOCUMENT_DELIBERATION_DETAILS], showPanel: showDocumentDeliberationDetailsSidePanel, editMode: formsEditabilityMap.documentsDeliberationDetails }"></ng-template>
<!-- custom component quote-payments inside so we need to unload processedData[processedDataKinds.SELECTED_QUOTE_DETAILS] by putting undefined -->
<div class="app-backdrop-overlay" *ngIf="showQuoteDetailsSidePanel"
     (click)="closeSidePanel(detailsFormTypes.QUOTE)"></div>
<ng-template [ngTemplateOutlet]="detailsSidePanel"
             [ngTemplateOutletContext]="{formType: detailsFormTypes.QUOTE , formConfiguration: formsFieldsMap.quoteDetails,
             formData: processedData[processedDataKinds.SELECTED_QUOTE_DETAILS], showPanel: showQuoteDetailsSidePanel, editMode: formsEditabilityMap.quoteDetails }"></ng-template>
<!-- custom component role-compensations inside so we need to unload processedData[processedDataKinds.SELECTED_ROLE_DETAILS] by putting undefined -->
<div class="app-backdrop-overlay" *ngIf="showRoleDetailsSidePanel"
     (click)="closeSidePanel(detailsFormTypes.ROLE)"></div>
<ng-template [ngTemplateOutlet]="detailsSidePanel"
             [ngTemplateOutletContext]="{formType: detailsFormTypes.ROLE , formConfiguration: formsFieldsMap.roleDetails,
             formData: processedData[processedDataKinds.SELECTED_ROLE_DETAILS], showPanel: showRoleDetailsSidePanel, editMode: formsEditabilityMap.roleDetails }"></ng-template>
