import { NgModule }                        from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { TranslateModule }                 from '@ngx-translate/core';
import { MatCheckboxModule }               from '@angular/material/checkbox';
import { FormsModule }                     from '@angular/forms';
import { ExpirationRemindsTableComponent } from './expiration-reminds-table.component';
import { MatIconModule }                   from '@angular/material/icon';
import { MatButtonModule }                 from '@angular/material/button';



@NgModule({
  declarations: [ExpirationRemindsTableComponent],
  exports: [
    ExpirationRemindsTableComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatCheckboxModule,
    FormsModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class ExpirationRemindsTableModule { }
