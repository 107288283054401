import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatisticsComponent } from './statistics.component';
import { ExpirationRemindsTableModule } from '../shared/expiration-reminds-table/expiration-reminds-table.module';
import { LoadingModule } from '../shared/loading/loading.module';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FiltersModule } from '../shared/filters/filters.module';
import { ChartsModule } from 'ng2-charts';



@NgModule({
  declarations: [
    StatisticsComponent
  ],
  imports: [
    CommonModule,
    ExpirationRemindsTableModule,
    LoadingModule,
    MatTabsModule,
    TranslateModule,
    FiltersModule,
    ChartsModule
  ]
})
export class StatisticsModule { }
