<ng-template #repeaterAdherableItem let-itemIndex="itemIndex">
  <div class="form-field-container">
    <label>{{'adherent-structures-modal.label--adherableId' | translate}}*</label>
    <mat-form-field appearance="outline">
      <input matInput
             [attr.name]="'adherableId'"
             [placeholder]="('base-modal.autocomplete-placeholder' | translate)"
             [matAutocomplete]="adherableAuto"
             [formControl]="adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableId"
             (input)="onAutocompleteTextChange($event, itemIndex, 'adherableId')">
      <mat-autocomplete #adherableAuto="matAutocomplete"
                        [displayWith]="autocompleteDisplayFn"
                        (optionSelected)="onAutocompleteOptionSelected($event, itemIndex, 'adherableId')">
        <mat-option *ngFor="let a of adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableOptions" [value]="a">
          <span>{{a.text}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableId.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
      <mat-error
        *ngIf="adherentStructuresRepeaterList[itemIndex].repeaterItem.adherableId.hasError('invalidAutocompleteObject')">{{'form-validation.invalid-option' | translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-field-container">
    <label>{{'adherent-structures-modal.label--personId' | translate}}</label>
    <mat-form-field appearance="outline">
      <input matInput
             [attr.name]="'personId'"
             [placeholder]="('base-modal.autocomplete-placeholder' | translate)"
             [matAutocomplete]="personAuto"
             [formControl]="adherentStructuresRepeaterList[itemIndex].repeaterItem.personId"
             (input)="onAutocompleteTextChange($event, itemIndex, 'personId')">
      <mat-autocomplete #personAuto="matAutocomplete"
                        [displayWith]="autocompleteDisplayFn"
                        (optionSelected)="onAutocompleteOptionSelected($event, itemIndex, 'personId')">
        <mat-option *ngFor="let a of adherentStructuresRepeaterList[itemIndex].repeaterItem.personOptions" [value]="a">
          <span>{{a.text}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="adherentStructuresRepeaterList[itemIndex].repeaterItem.personId.hasError('invalidAutocompleteObject')">{{'form-validation.invalid-option' | translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="adherent-structures-modal__remove-item-area">
    <button mat-button
            color="warn"
            (click)="markItemForRemoval(itemIndex)">{{('adherent-structures-modal.button-remove-item' | translate)}}</button>
  </div>
</ng-template>


<div class="base-modal adherent-structures-modal">
  <div class="base-modal__header base-modal__header--sticky">
    <h3 class="base-modal__header__title">{{ 'adherent-structures-modal.title' | translate}}</h3>
    <button [mat-dialog-close]="false" mat-icon-button class="mat-icon-button--small">
      <mat-icon aria-hidden="false" aria-label="chiudi">close</mat-icon>
    </button>
  </div>
  <!-- base-modal__body--with-sticky-footer -->
  <div class="base-modal__body base-modal__body--with-sticky-header">
    <div class="base-modal__fields-container" *ngIf="adherentStructuresRepeaterList.length > 0">
      <div class="adherent-structures-modal__repeater-item"
           [ngStyle]="{gridColumn: 'span 12'}"
           *ngFor="let item of adherentStructuresRepeaterList; index as aIndex">
        <ng-template [ngTemplateOutlet]="repeaterAdherableItem"
                     [ngTemplateOutletContext]="{itemIndex: aIndex }"></ng-template>
      </div>
    </div>
    <div class="adherent-structures-modal__add-new-area">
      <button mat-stroked-button
              color="primary"
              (click)="addNewAdherentRepeaterItem()"
              class="mat-elevation-z1">
        <mat-icon aria-hidden="false" aria-label="modifica">add_circle_outline</mat-icon>
        {{'adherent-structures-modal.add-new-item' | translate}}
      </button>
    </div>
  </div>
  <!-- base-modal__footer--sticky -->
  <div class="base-modal__footer">
    <button mat-button
            color="primary"
            (click)="onCancelClick()">{{('base-modal.cancel' | translate)}}</button>
    <button mat-stroked-button
            color="primary"
            class="mat-elevation-z1"
            [disabled]="isConfirmDisabled()"
            (click)="onConfirmClick()">
      <mat-icon aria-hidden="false" aria-label="conferma">done</mat-icon>
      {{('base-modal.confirm' | translate)}}
    </button>
  </div>
</div>
