import { NgModule }             from '@angular/core';
import { EnhancedCurrencyPipe } from './enhanced-currency.pipe';
import { CurrencyPipe }         from '@angular/common';


@NgModule({
  declarations: [EnhancedCurrencyPipe],
  exports: [EnhancedCurrencyPipe],
  providers: [CurrencyPipe]
})
export class EnhancedCurrencyModule {
}
