import { Component, HostListener, OnInit }            from '@angular/core';
import { FilterInternal, FilterSetting, FilterTypes } from '../shared/filters/filters.component';
import { TranslatePipe }                              from '@ngx-translate/core';
import { UserService }                                from '../core/user/user.service';
import {
  ChartScopedExerciseResultsPerYearGQL,
  ChartScopedInstitutionsPerConstitutionYearGQL,
  ChartScopedInstitutionsPerDepartmentGQL,
  ChartScopedInstitutionsPerRecognitionYearGQL,
  ChartScopedInstitutionsPerSectorGQL,
  ChartScopedProductionValuesPerYearGQL,
  UserRoleEnum
}                                                     from '../../generated/graphql';
import ChartDataLabels                                from 'chartjs-plugin-datalabels';
import { getBarChartBaseOptions, splitSentence }      from '../shared/utils/helpers';
import { Label }                                      from 'ng2-charts';
import Chart, { ChartDataSets }                       from 'chart.js';
import { EnhancedCurrencyPipe }                       from '../shared/enhanced-currency/enhanced-currency.pipe';

@Component({
  selector: 'et-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
  providers: [TranslatePipe, EnhancedCurrencyPipe]
})
export class StatisticsComponent implements OnInit {
  tabs = ['institutionsPerSector', 'institutionsPerDepartment', 'institutionsPerRecognitionYear', 'institutionsPerConstitutionYear', 'balanceByYear'];
  currentTab: string = this.tabs[0];
  filtersConfiguration: {
    institutionsPerSector: FilterSetting[],
    institutionsPerDepartment: FilterSetting[],
    institutionsPerRecognitionYear: FilterSetting[],
    institutionsPerConstitutionYear: FilterSetting[],
    productionValuesPerYear: FilterSetting[],
    exerciseResultsPerYear: FilterSetting[],
  };
  filtersCurrentData: { [propName: string]: FilterInternal[] } = {};
  barColorsCfg = {
    backgroundColor: 'rgb(247,189,41)',
    hoverBackgroundColor: 'rgb(188,147,31)',
    borderColor: 'rgb(188,147,31)',
  };
  charts: {
    institutionsPerSector: { labels: Label[], datasets: ChartDataSets[] },
    institutionsPerDepartment: { labels: Label[], datasets: ChartDataSets[] },
    institutionsPerRecognitionYear: { labels: Label[], datasets: ChartDataSets[] },
    institutionsPerConstitutionYear: { labels: Label[], datasets: ChartDataSets[] },
    productionValuesPerYear: { labels: Label[], datasets: ChartDataSets[] },
    exerciseResultsPerYear: { labels: Label[], datasets: ChartDataSets[] },
  } = {
    institutionsPerSector: {
      labels: [],
      datasets: [{
        ...this.barColorsCfg,
        data: []
      }]
    },
    institutionsPerDepartment: {
      labels: [],
      datasets: [{
        ...this.barColorsCfg,
        data: []
      }]
    },
    institutionsPerRecognitionYear: {
      labels: [],
      datasets: [{
        ...this.barColorsCfg,
        data: []
      }]
    },
    institutionsPerConstitutionYear: {
      labels: [],
      datasets: [{
        ...this.barColorsCfg,
        data: []
      }]
    },
    productionValuesPerYear: {
      labels: [],
      datasets: [{
        ...this.barColorsCfg,
        data: []
      }]
    },
    exerciseResultsPerYear: {
      labels: [],
      datasets: [{
        ...this.barColorsCfg,
        data: []
      }]
    }
  };
  barChartOptions = getBarChartBaseOptions();
  barChartInstitutionsPerDepartmentsOptions = getBarChartBaseOptions({
    scales: {
      xAxes: [{
        ticks: {
          // fontSize: 10,
          maxRotation: 0
        }
      }]
    }
  });
  barChartWithCurrenciesOptions;
  barChartPlugins = [ChartDataLabels];

  private updateFontsForResponsiveness() {
    if (window.matchMedia('(max-width: 900px)').matches) {
      Chart.defaults.global.defaultFontSize = this.currentTab === 'institutionsPerDepartment' ? 3 : 4;
    } else if (window.matchMedia('(max-width: 1150px)').matches) {
      Chart.defaults.global.defaultFontSize = this.currentTab === 'institutionsPerDepartment' ? 4 : 6;
    } else if (window.matchMedia('(max-width: 1400px)').matches) {
      Chart.defaults.global.defaultFontSize = this.currentTab === 'institutionsPerDepartment' ? 5 : 8;
    } else {
      Chart.defaults.global.defaultFontSize = this.currentTab === 'institutionsPerDepartment' ? 7 : 10;
    }
  }

  @HostListener('window:resize', ['$event']) onWindowResize(event: any) {
    this.updateFontsForResponsiveness();
  }

  constructor(private translatePipe: TranslatePipe,
              private enhancedCurrencyPipe: EnhancedCurrencyPipe,
              private userService: UserService,
              private chartScopedinstitutionsPerSectorGQL: ChartScopedInstitutionsPerSectorGQL,
              private chartScopedinstitutionsPerDepartmentGQL: ChartScopedInstitutionsPerDepartmentGQL,
              private chartScopedinstitutionsPerRecognitionYearGQL: ChartScopedInstitutionsPerRecognitionYearGQL,
              private chartScopedinstitutionsPerConstitutionYearGQL: ChartScopedInstitutionsPerConstitutionYearGQL,
              private chartScopedproductionValuesPerYearGQL: ChartScopedProductionValuesPerYearGQL,
              private chartScopedexerciseResultsPerYearGQL: ChartScopedExerciseResultsPerYearGQL) {
    // old datalabels option for currencies charts
    // {
    //   anchor: 'end',
    //     align: 'end',
    //   rotation: -45,
    //   formatter: function(value, context) {
    //   return enhancedCurrencyPipe.transform(value, 'EUR');
    // }
    // }
    this.barChartWithCurrenciesOptions = getBarChartBaseOptions({
      tooltips: {
        enabled: true,
        callbacks: {
          label: function(tooltipItem, data) {
            return enhancedCurrencyPipe.transform(tooltipItem.yLabel, 'EUR');
          }
        }
      }
    }, {
      anchor: 'end',
      align: 'end',
      rotation: -45,
      formatter: function(value, context) {
        return (value > -2000 && value < 2000) ? enhancedCurrencyPipe.transform(value, 'EUR') : '';
      }
    });
    const activeFilter: FilterSetting = {
      fieldKey: 'active',
      type: FilterTypes.DROPDOWN,
      values: [
        {id: 'yes', text: this.translatePipe.transform('statistics.filter-active-yes')},
        {id: '', text: this.translatePipe.transform('statistics.filter-active-all')},
        {id: 'no', text: this.translatePipe.transform('statistics.filter-active-no')}
      ],
      defaultValue: 'yes'
    };
    const spinoffParticipation = {
      fieldKey: 'spinoffParticipation',
      type: FilterTypes.DROPDOWN,
      values: [
        {id: '', text: this.translatePipe.transform('statistics.filter-spinoff-participation-all')},
        {id: 'participate', text: this.translatePipe.transform('statistics.filter-spinoff-participation-yes')},
        {id: 'not_participate', text: this.translatePipe.transform('statistics.filter-spinoff-participation-no')}
      ],
      defaultValue: ''
    };
    const spinoffParticipationStartup = {
      fieldKey: 'spinoffParticipation',
      type: FilterTypes.DROPDOWN,
      values: [
        {id: '', text: this.translatePipe.transform('statistics.filter-spinoff-participation-all')},
        {id: 'participate', text: this.translatePipe.transform('statistics.filter-spinoff-participation-yes')},
        {id: 'not_participate', text: this.translatePipe.transform('statistics.filter-spinoff-participation-no')},
        {id: 'innovative_startup', text: this.translatePipe.transform('statistics.filter-spinoff-startup')}
      ],
      defaultValue: ''
    };
    const filters = {
      institutionsPerSector: [
        {...activeFilter}
      ],
      institutionsPerDepartment: [
        {...activeFilter}
      ],
      institutionsPerRecognitionYear: [
        {...activeFilter}
      ],
      institutionsPerConstitutionYear: [
        {...activeFilter}
      ],
      productionValuesPerYear: [
        {...activeFilter}
      ],
      exerciseResultsPerYear: [
        {...activeFilter}
      ]
    };
    if (this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.SpinoffOffice) {
      Object.keys(filters).forEach(key => {
        if (key === 'institutionsPerSector' || key === 'institutionsPerDepartment') {
          filters[key].push({...spinoffParticipation});
        } else if (key === 'institutionsPerRecognitionYear' || key === 'institutionsPerConstitutionYear') {
          filters[key].push({...spinoffParticipationStartup});
        }
      });
    }
    this.filtersConfiguration = filters;
  }

  private postProcessScopedChart(chartData: any, labelsKey: 'names' | 'years'): ({ label: string, value: number }[] | undefined) {
    let countOnes = 0;
    let auxMap = chartData[labelsKey] ? chartData[labelsKey].map((s: any, index: string | number) => {
      if (chartData.values[index] === 1) {
        countOnes++;
      }
      return {
        label: labelsKey === 'names' ? splitSentence(s, 14) : s,
        value: chartData?.values[index]
      };
    }) : undefined;

    if (auxMap) {
      if (labelsKey === 'names') {
        if (countOnes > 1) {
          auxMap = auxMap.filter((el: { value: number; }) => el.value > 1);
          auxMap = [...auxMap, {
            label: this.translatePipe.transform('common.chart-value--other'),
            value: 1
          }];
        }
        auxMap.sort((a: { value: number; }, b: { value: number; }) => {
          if (a.value === b.value) {
            return 0;
          }
          return a.value < b.value ? 1 : -1;
        });
      }

    }
    return auxMap;
  }

  private updateCurrentTabData() {
    if (this.currentTab) {
      if (this.currentTab === 'balanceByYear') {
        const activeFilter1Value = this.filtersCurrentData['productionValuesPerYear']?.find(filter => filter.fieldKey === 'active')?.newValue;
        const filters1: any = {
          active: activeFilter1Value === 'yes' ? true : (activeFilter1Value === 'no' ? false : null)
        };
        const activeFilter2Value = this.filtersCurrentData['exerciseResultsPerYear']?.find(filter => filter.fieldKey === 'active')?.newValue;
        const filters2: any = {
          active: activeFilter2Value === 'yes' ? true : (activeFilter2Value === 'no' ? false : null)
        };
        this.fetchDataByKind('productionValuesPerYear', filters1);
        this.fetchDataByKind('exerciseResultsPerYear', filters2);
      } else {
        const activeFilterValue = this.filtersCurrentData[this.currentTab].find(f => f.fieldKey === 'active')?.newValue;
        const filters: any = {
          active: activeFilterValue === 'yes' ? true : (activeFilterValue === 'no' ? false : null)
        };
        if (['institutionsPerSector', 'institutionsPerDepartment', 'institutionsPerRecognitionYear', 'institutionsPerConstitutionYear'].includes(this.currentTab)) {
          const spinoffParticipationFilterValue = this.filtersCurrentData[this.currentTab].find(f => f.fieldKey === 'spinoffParticipation')?.newValue;
          filters.spinoffKind = spinoffParticipationFilterValue === '' ? null : spinoffParticipationFilterValue;
        }
        this.fetchDataByKind(this.currentTab, filters);
      }
    }
  }

  private fetchDataByKind(kind: any, filters: any) {
    // @ts-ignore
    this[`chartScoped${kind}GQL`].fetch({
      ...filters
    }, {fetchPolicy: 'no-cache'}).subscribe((res: any) => {
      const auxMap = this.postProcessScopedChart(res.data[`chartScoped${kind.charAt(0).toUpperCase()}${kind.slice(1)}`], this.currentTab.includes('Year') ? 'years' : 'names');
      if (auxMap) {
        // @ts-ignore
        this.charts[kind].labels = (auxMap.map(el => el.label)) as Label[];
        // @ts-ignore
        this.charts[kind].datasets[0].data = auxMap.map((el: { value: number; }) => el.value);
        this.updateFontsForResponsiveness();
      }
    }, (err: any) => {
    });

  }

  ngOnInit(): void {
  }

  onTabChange(index: number) {
    console.log(index);
    this.currentTab = this.tabs[index];
    console.log(this.filtersConfiguration);
    this.updateCurrentTabData();
  }

  onFiltersChanged(newFilters: FilterInternal[], filterKey: 'institutionsPerSector' | 'institutionsPerDepartment' | 'institutionsPerRecognitionYear' | 'institutionsPerConstitutionYear' | 'productionValuesPerYear' | 'exerciseResultsPerYear') {
    this.filtersCurrentData[filterKey] = [...newFilters];
    this.updateCurrentTabData();
  }
}
