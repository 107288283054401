import { Injectable }                                                       from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Apollo }                                                           from 'apollo-angular';
import { BehaviorSubject, from, Observable, of }                            from 'rxjs';
import { AuthHeaderData, Maybe, MeGQL, User, UserFragment, UserSignOutGQL } from '../../../generated/graphql';
import { catchError, map, mapTo, tap }                                      from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpParams }                        from '@angular/common/http';
import { environment }                                                      from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService implements CanActivate {

  private localUserAlreadyFetched = false;
  // private subscriptions: Subscription[] = [];
  user: Maybe<{ __typename?: 'User' } & UserFragment> | undefined; // add the others supported types when available
  /* setUserSubject is a subject to watch set of user data (ex. on dashboard we subscribe on that because of we need to
  reload dashboard data if something on the logged user change. If this need is required please let dashboard component
  extends BaseComponent and use the following code in the ngOnInit:
    this.subscriptions.push(
      this.userService.setUserSubject.subscribe(_ => { */
  setUserSubject = new BehaviorSubject(null);
  showOverlay = false;

  private static getSignOutHeaders(): AuthHeaderData {
    const headers: AuthHeaderData = {};
    headers.accessToken = localStorage.getItem('access-token') || '';
    headers.client = localStorage.getItem('client') || '';
    headers.uid = localStorage.getItem('uid') || '';
    return headers;
  }

  static setHeaders(headers: AuthHeaderData | undefined): void {
    localStorage.setItem('access-token', headers?.accessToken || '');
    localStorage.setItem('client', headers?.client || '');
    localStorage.setItem('token-type', headers?.tokenType || '');
    localStorage.setItem('expiry', headers?.expiry || '');
    localStorage.setItem('uid', headers?.uid || '');
  }

  static getHeadersAsQS(): string {
   return `access-token=${localStorage.getItem('access-token')}&client=${localStorage.getItem('client')}&token-type=${localStorage.getItem('token-type')}&expiry=${localStorage.getItem('expiry')}&uid=${localStorage.getItem('uid')}`;
  }

  private static handleError(error: HttpErrorResponse): void {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
  }

  // unreadNotifications = 0;

  constructor(private router: Router,
              private meGQL: MeGQL,
              private http: HttpClient,
              private userSignOutGQL: UserSignOutGQL,
              private apollo: Apollo) {
  }

  fetchUser(): Observable<any> {
    return this.meGQL.fetch({}, {fetchPolicy: 'network-only'}).pipe(
      tap(res => {
        this.setUser(res.data.me);
        this.localUserAlreadyFetched = true;
      }),
      catchError(_ => of({data: {me: null}}))
    );
  }

  private checkUserPermissions(data: any): boolean {
    if (data.requiredStates.includes('signedOut')) {
      if (this.user) {
        this.router.navigate(['/']);
        return false;
      }
      return true;
    } else if (data.requiredStates.includes('signedIn')) {
      if (!this.user) {
        this.router.navigate(['/accedi']);
        return false;
      } else if (this.user) {
        if (!this.user.confirmedAt && !data.requiredStates.includes('unconfirmed')) {
          return true;
        } else if (this.user.confirmedAt && data.requiredStates.includes('unconfirmed')) {
          this.router.navigate(['/']);
          return false;
        }
        if (data.allowedRoles) {
          const isAllowed = this.checkUserRole(data.allowedRoles);
          if(!isAllowed){
            this.router.navigate(['/']);
          }
          return isAllowed;
        } else {
          return true;
        }
      }
      return true;
    }
    return false;
  }

  private checkUserRole(allowedRoles: any): boolean {
    if (!allowedRoles || allowedRoles.length <= 0) {
      return true;
    }
    if (allowedRoles && allowedRoles.length > 0 && this.user) {
      return allowedRoles.includes(this.user.role);
    }
    return false;
  }

  setUser(user: Maybe<{ __typename?: 'User' } & UserFragment> | undefined): void {
    this.user = user;
    from(this.apollo.client.cache.reset()).subscribe(_ => {
      this.setUserSubject.next(null);
    });
  }

  doSignOut(): void {
    this.requestOverlay();
    this.userSignOutGQL.mutate({}).subscribe(res => {
      this.router.navigate(['logout']);
      this.releaseOverlay();
    }, error => {
      // todo fix this brute force?
      this.router.navigate(['logout']);
      this.releaseOverlay();
    });
  }

  doOuterSignOut(preventReload?: boolean): void {
    this.requestOverlay();
    const options = {
      params: new HttpParams({
        fromObject: (UserService.getSignOutHeaders()) as {
          [param: string]: string | ReadonlyArray<string>;
        }
      })
    };

    window.location.href = `${environment.graphqlEndpoint.split('/graphql')[0]}/saml/logout_init?${options.params.toString()}`;
    // this.http.get(`${environment.graphqlEndpoint.split('/graphql')[0]}/saml/logout_init`, options).subscribe(res => {
    //   alert('LOGOUT TEST: successo logout');
    //   this.user = undefined;
    //   UserService.setHeaders({});
    //   // this.subscriptions.forEach(subscription => subscription.unsubscribe());
    //   from(this.apollo.client.cache.reset()).subscribe(_ => this.setUserSubject.next(null));
    //   if (preventReload) {
    //     return;
    //   }
    //   window.location.reload();
    // }, error => {
    //   alert('LOGOUT TEST: errore logout. see errors from dev tools.');
    //   UserService.setHeaders({});
    //   UserService.handleError(error);
    //   this.releaseOverlay();
    // });
  }

  requestOverlay(): void {
    this.showOverlay = true;
  }

  releaseOverlay(): void {
    this.showOverlay = false;
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!route.data.requiredStates || !route.data.requiredStates.length) { // no requiredStates = public route
      if (!this.localUserAlreadyFetched) { // fetch the user to avoid the header to change (which is ugly!)
        return this.fetchUser().pipe(mapTo(true));
      } else {
        return of(true);
      }
    } else {
      if (state.url.includes('logout')) {
        this.user = undefined;
        UserService.setHeaders({});
        from(this.apollo.client.cache.reset()).subscribe(_ => this.setUserSubject.next(null));
        this.router.navigate(['/accedi']);
        return of(false);
      }
      if (!this.localUserAlreadyFetched) {
        // used map instead of mapTo because mapTo always emits a value without 'waiting' for a response of the fetch user
        // must return an observable of(this.checkUserPermissions(route.data.requiredStates))?
        return this.fetchUser().pipe(map(_ => this.checkUserPermissions(route.data)));
      } else {
        return of(this.checkUserPermissions(route.data));
      }
    }
  }
}
