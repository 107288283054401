import { NgModule }              from '@angular/core';
import { CommonModule }          from '@angular/common';
import { PersonsComponent }      from './persons.component';
import { FilterableTableModule } from '../shared/filterable-table/filterable-table.module';
import { TranslateModule }       from '@ngx-translate/core';
import { MatButtonModule }       from '@angular/material/button';
import { MatIconModule }         from '@angular/material/icon';
import { BaseModalModule }       from '../shared/base-modal/base-modal.module';



@NgModule({
  declarations: [PersonsComponent],
  imports: [
    CommonModule,
    FilterableTableModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    BaseModalModule
  ]
})
export class PersonsModule { }
