<ng-template #repeaterAtecoActivityItem let-itemIndex="itemIndex">
  <div class="form-field-container">
    <label>{{'ateco-activities-modal.label--sectorId' | translate}}*</label>
    <mat-form-field appearance="outline">
      <input matInput
             [attr.name]="'sectorId'"
             [placeholder]="('base-modal.autocomplete-placeholder' | translate)"
             [matAutocomplete]="adherableAuto"
             [formControl]="atecoActivitiesRepeaterList[itemIndex].repeaterItem.sectorId"
             (input)="onAutocompleteTextChange($event, itemIndex, 'sectorId')">
      <mat-autocomplete #adherableAuto="matAutocomplete"
                        [displayWith]="autocompleteDisplayFn"
                        (optionSelected)="onAutocompleteOptionSelected($event, itemIndex, 'sectorId')">
        <mat-option *ngFor="let a of atecoActivitiesRepeaterList[itemIndex].repeaterItem.sectorOptions" [value]="a">
          <span>{{a.text}}</span>
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="atecoActivitiesRepeaterList[itemIndex].repeaterItem.sectorId.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
      <mat-error
        *ngIf="atecoActivitiesRepeaterList[itemIndex].repeaterItem.sectorId.hasError('invalidAutocompleteObject')">{{'form-validation.invalid-option' | translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="form-field-container">
    <label>{{'ateco-activities-modal.label--percentage' | translate}}</label>
    <mat-form-field appearance="outline">
      <input matInput
             type="number"
             min="0"
             max="100"
             [name]="'percentage'"
             placeholder=""
             (ngModelChange)="onPercentageChange($event, itemIndex)"
             [formControl]="atecoActivitiesRepeaterList[itemIndex].repeaterItem.percentage"/>
    </mat-form-field>
  </div>
  <div class="ateco-activities-modal__remove-item-area">
    <button mat-button
            color="warn"
            (click)="markItemForRemoval(itemIndex)">{{('ateco-activities-modal.button-remove-item' | translate)}}</button>
  </div>
</ng-template>


<div class="base-modal ateco-activities-modal">
  <div class="base-modal__header base-modal__header--sticky">
    <h3 class="base-modal__header__title">{{ 'ateco-activities-modal.title' | translate}}</h3>
    <button [mat-dialog-close]="false" mat-icon-button class="mat-icon-button--small">
      <mat-icon aria-hidden="false" aria-label="chiudi">close</mat-icon>
    </button>
  </div>
  <!-- base-modal__body--with-sticky-footer -->
  <div class="base-modal__body base-modal__body--with-sticky-header">
    <div class="base-modal__fields-container" *ngIf="atecoActivitiesRepeaterList.length > 0">
      <div class="ateco-activities-modal__repeater-item"
           [ngStyle]="{gridColumn: 'span 12'}"
           *ngFor="let item of atecoActivitiesRepeaterList; index as aIndex">
        <ng-template [ngTemplateOutlet]="repeaterAtecoActivityItem"
                     [ngTemplateOutletContext]="{itemIndex: aIndex }"></ng-template>
      </div>
    </div>
    <div class="ateco-activities-modal__add-new-area">
      <button mat-stroked-button
              color="primary"
              (click)="addNewAtecoActivityRepeaterItem()"
              class="mat-elevation-z1">
        <mat-icon aria-hidden="false" aria-label="modifica">add_circle_outline</mat-icon>
        {{'ateco-activities-modal.add-new-item' | translate}}
      </button>
    </div>
  </div>
  <!-- base-modal__footer--sticky -->
  <div class="base-modal__footer">
    <button mat-button
            color="primary"
            (click)="onCancelClick()">{{('base-modal.cancel' | translate)}}</button>
    <button mat-stroked-button
            color="primary"
            class="mat-elevation-z1"
            [disabled]="isConfirmDisabled()"
            (click)="onConfirmClick()">
      <mat-icon aria-hidden="false" aria-label="conferma">done</mat-icon>
      {{('base-modal.confirm' | translate)}}
    </button>
  </div>
</div>
