<div class="expirations-all">
  <div class="expirations-all__header">
    <h1>{{'expirations-all.title' | translate}}</h1>
  </div>
  <mat-tab-group dynamicHeight
                 (selectedIndexChange)="onTabChange($event)">
    <mat-tab [label]="('expirations-all.tab-label-expiring' | translate)">
      <et-loading *ngIf="busy" class="spacing-top-40"></et-loading>
      <div class="expirations-all__content" *ngIf="!busy">
        <et-expiration-reminds-table [reminds]="expiringRemindsData"
                                     [hasActions]="!isAdmin && !isViewer"
                                     [showInstitution]="true"
                                     [showArchiveCheckbox]="!isAdmin && !isViewer"
                                     (editActionClicked)="openExpirationEditModal($event)"
                                     (createActionClicked)="openAddNewExpirationModal()"></et-expiration-reminds-table>
      </div>
    </mat-tab>
    <mat-tab [label]="('expirations-all.tab-label-all' | translate)">
      <et-loading *ngIf="busy" class="spacing-top-40"></et-loading>
      <div class="expirations-all__content" *ngIf="!busy">
        <et-expiration-reminds-table [reminds]="allRemindsData"
                                     [hasActions]="!isAdmin && !isViewer"
                                     [showInstitution]="true"
                                     [showArchiveCheckbox]="!isAdmin && !isViewer"
                                     (editActionClicked)="openExpirationEditModal($event)"
                                     (createActionClicked)="openAddNewExpirationModal()"></et-expiration-reminds-table>
      </div>
    </mat-tab>
    <mat-tab [label]="('expirations-all.tab-label-archived' | translate)">
      <et-loading *ngIf="busy" class="spacing-top-40"></et-loading>
      <div class="expirations-all__content" *ngIf="!busy">
        <et-expiration-reminds-table [reminds]="archivedRemindsData"
                                     [showInstitution]="true"
                                     [showArchiveCheckbox]="!isAdmin && !isViewer"></et-expiration-reminds-table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
