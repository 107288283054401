import { NgModule }              from '@angular/core';
import { CommonModule }          from '@angular/common';
import { InstitutionsComponent } from './institutions.component';
import { FilterableTableModule } from '../shared/filterable-table/filterable-table.module';
import {TranslateModule} from "@ngx-translate/core";


@NgModule({
  declarations: [InstitutionsComponent],
	imports: [
		CommonModule,
		FilterableTableModule,
		TranslateModule
	]
})
export class InstitutionsModule {
}
