import { Component, OnInit }                                                                            from '@angular/core';
import { ActivatedRoute, Router }                                                                       from '@angular/router';
import {
  AllSectorsGQL,
  AnnualAccountingReportCreateGQL,
  AnnualAccountingReportUpdateGQL,
  ApprovableApproveGQL,
  ApprovableApproveManyGQL,
  ApprovableInput,
  ApprovableTypeEnum,
  Attachment,
  AttachmentCreateGQL,
  AttachmentDeleteGQL,
  AttachmentGQL,
  AttachmentTypeEnum,
  AttachmentUpdateGQL,
  CheckApprovablesGQL,
  CheckComplexDataGQL,
  Deliberation,
  DeliberationCreateGQL,
  DeliberationDeleteGQL,
  DeliberationDescriptionEnum,
  DeliberationGQL,
  DeliberationTypeEnum,
  DeliberationUpdateGQL,
  InstitutionGQL,
  InstitutionSearchGQL,
  InstitutionSetStatusGQL,
  InstitutionStatusEnum,
  InstitutionTypeEnum,
  InstitutionUpdateGQL,
  LegalBody,
  OperationReturnTypeEnum,
  Partnership,
  PartnershipCreateGQL,
  PartnershipDeleteGQL,
  PartnershipGQL, PartnershipPersonKind,
  PartnershipUpdateGQL,
  PaymentFrequencyEnum,
  Quote,
  QuoteCreateGQL,
  QuoteDeleteGQL,
  QuoteGQL,
  QuoteUpdateGQL,
  Remind,
  RemindCreateGQL,
  RemindUpdateGQL,
  Role,
  UniParticipationEnum,
  UserRoleEnum,
  VersionedFloat,
  VersionedInteger,
  VersionedString,
  VersionedUniParticipation
}                                                                                                       from '../../generated/graphql';
import { UserService }                                                                                  from '../core/user/user.service';
import { ABErrorStateMatcher, getErrorMessageFromData, getFixedSmallModalSettings, roundCurrencyValue } from '../shared/utils/helpers';
import { TranslatePipe }                                                                                from '@ngx-translate/core';
import { FormControl, ValidatorFn, Validators }                                                         from '@angular/forms';
import moment, { Moment }                                                                               from 'moment';
import {
  TableColumnTypes,
  TableData,
  TableRowActions
}                                                                                                       from '../shared/filterable-table/filterable-table.component';
import {
  EnhancedCurrencyPipe
}                                                                                                       from '../shared/enhanced-currency/enhanced-currency.pipe';
import { MatSnackBar }                                                                                  from '@angular/material/snack-bar';
import {
  BaseModalComponent,
  ModalField
}                                                                                                       from '../shared/base-modal/base-modal.component';
import { MatDialog }                                                                                    from '@angular/material/dialog';
import { UploadOutput }                                                                                 from 'ngx-uploader';
import {
  ReferentsAndRolesService
}                                                                                                       from './referents-and-roles/referents-and-roles.service';
import {
  VersionedFieldModalComponent
}                                                                                                       from './versioned-field-modal/versioned-field-modal.component';
import {
  AdherentStructuresModalComponent
}                                                                                                       from './adherent-structures-modal/adherent-structures-modal.component';
import {
  AtecoActivitiesModalComponent
}                                                                                                       from './ateco-activities-modal/ateco-activities-modal.component';
import {
  FilterTypes
}                                                                                                       from '../shared/filters/filters.component';

// *handled like versioned field > show modal with validity fields and related attributeValue field
// Versioned records edit mode: institutionUpdate quando aggiungo un attributo versionato. Ad esempio il numero di telefono. L'attributo non c'è prima.
// Invece Quando modifichi QUALSIASI attributo versionato, sia nel passato che nel presente, o anche nel futuro, usi VersionedRecordUpdate.

// no type is displayed as default and edit mode is handled with input as form field
export enum FieldTypes {
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DATE = 'date', // view mode: date pipe - edit mode: date picker (as form field)
  BOOLEAN = 'boolean', // view mode: yes/no - edit mode: select yes:true/no:false
  NUMBER = 'number', // view mode: number text - edit mode: input number
  CURRENCY = 'currency', // view mode: currency piped - edit mode: input currency
  ATTACHMENT = 'attachment', // view mode: pdf - edit mode: handled like versioned field* with file picker inside, BUT use mutation attachmentCreate/Update/Delete
  DOCUMENT = 'document', // view mode: pdf - edit mode: file picker (as form field)
  ATECO_ACTIVITIES = 'ateco_activity', // text-list-view - edit mode: proper modal, use mutation atecoSectorCreate/Update/Delete
  LINK = 'link', //
  ADHERENT_STRUCTURES = 'adherent_structure', // text-list-view - edit mode: proper modal, use adherentStructureCreate/Update/Delete
  UNI_PARTECIPATION = 'uni_partecipation', // view mode: enum translation , edit mode: handled like versioned* field with select inside
  SELECT = 'select', // view mode: enum translation, edit mode: handled with select
  ATTACHMENT_TYPES = 'attachment_types', // view mode: enum translation, edit mode: handled with select
  DELIBERATION_TYPES = 'deliberation_types' // view mode: enum translation, edit mode: handled with select
}

export enum DetailsFormTypes {
  GENERAL = 'GeneralDetails',
  QUOTE = 'QuoteDetails',
  ROLE = 'RoleDetails',
  PARTNERSHIP = 'PartnershipDetails',
  DOCUMENT_ATTACHMENT = 'DocumentAttachmentDetails',
  DOCUMENT_DELIBERATION = 'DocumentDeliberationDetails'
}

export enum ProcessedDataKinds {
  CURRENT_ANNUAL_ACCOUNTING_REPORT = 'currentAnnualAccountingReport',
  TABLE_ANNUAL_ACCOUNTING_REPORTS = 'tableAnnualAccountingReports',
  SELECTED_QUOTE_DETAILS = 'selectedQuoteDetails',
  SELECTED_PARTNERSHIP_DETAILS = 'selectedPartnershipDetails',
  SELECTED_DOCUMENT_ATTACHMENT_DETAILS = 'selectedDocumentAttachmentDetails',
  SELECTED_DOCUMENT_DELIBERATION_DETAILS = 'selectedDocumentDeliberationDetails',
  SELECTED_ROLE_DETAILS = 'selectedRoleDetails',
  EXPIRING_REMINDS = 'expiringReminds',
  ARCHIVED_REMINDS = 'archivedReminds',
  REFERENTS_AND_ROLES = 'referentsAndRoles'
}

export enum SectionNames {
  GENERAL_INFO = 'GeneralInfo',
  ACCOUNT_REPORT = 'AccountReport',
  REFERENTS_AND_ROLES = 'ReferentsAndRoles',
  PARTNERSHIPS = 'Partnerships',
  EXPIRATIONS = 'Expirations',
  DOCUMENTS = 'Documents'
}

export interface FieldConfiguration {
  key: any;
  required?: boolean;
  fControl?: FormControl;
  label?: string;
  versioned?: boolean;
  type?: FieldTypes;
  onlySpinoff?: boolean;
  selectOptions?: { id: string, text: string }[];
}

export interface SnapshotInfo {
  startDate: Moment;
  endDate: Moment;
  categoriesMap: { [propName: string]: number };
  approvableIds: any[];
  containsCurrent?: boolean;
  displayableEndDate?: Date;
  totalCount?: number;
}

@Component({
  selector: 'et-institution-details',
  templateUrl: './institution-details.component.html',
  styleUrls: ['./institution-details.component.scss'],
  providers: [TranslatePipe, EnhancedCurrencyPipe]
})
export class InstitutionDetailsComponent implements OnInit {

  private institutionId: string | undefined;

  institutionData: any = {};
  processedData: any = {};
  detailsUnapprovedArr = [];
  mainUnapprovedArr = [];
  busy = true;
  isAdmin = false;
  isViewer = false;
  todayDate;
  pickerDate;
  tomorrowMinDateConstraint: Date;
  // tabs control
  selectedTab = new FormControl(0);
  // snapshot controls
  selectedVersionedDate: Date | undefined;
  showValidationInfoOverlay = false;
  snapshotsToValidate: SnapshotInfo[] = [];
  selectedSnapshotValidated = false;
  // side panel displaying logic
  showGeneralDetailsSidePanel = false;
  showQuoteDetailsSidePanel = false;
  showPartnershipDetailsSidePanel = false;
  showDocumentAttachmentDetailsSidePanel = false;
  showDocumentDeliberationDetailsSidePanel = false;
  showRoleDetailsSidePanel = false;
  versionedFieldOverlayToShow = '';
  // form controls logic
  errorStateMatcher = new ABErrorStateMatcher();
  fieldsModel: { [key: string]: any } = {};
  formsFieldsMap: {
    generalMain: FieldConfiguration[],
    description?: FieldConfiguration, // spinoff description
    corporatePurpose: FieldConfiguration,
    generalDetails: FieldConfiguration[],
    accountReportCurrent: FieldConfiguration[],
    accountReportExtraInfo: FieldConfiguration[]
    quoteDetails: FieldConfiguration[]
    partnershipDetails: FieldConfiguration[]
    documentsAttachmentDetails: FieldConfiguration[]
    documentsDeliberationDetails: FieldConfiguration[]
    roleDetails: FieldConfiguration[]
  };
  formsEditabilityMap = {
    generalMain: false,
    corporatePurpose: false,
    description: false, // spinoff description
    generalDetails: false,
    accountReportCurrent: false,
    accountReportExtraInfo: false,
    quoteDetails: false,
    partnershipDetails: false,
    documentsAttachmentDetails: false,
    documentsDeliberationDetails: false,
    roleDetails: false
  };
  fieldTypesEnum = FieldTypes;
  sectionNames = SectionNames;
  processedDataKinds = ProcessedDataKinds;
  detailsFormTypes = DetailsFormTypes;
  approvableTypes = ApprovableTypeEnum;
  // standard tables logic
  quotesTableConfig: TableData;
  partnershipsTableConfig: TableData;
  documentsAttachmentsTableConfig: TableData;
  documentsDeliberationsTableConfig: TableData;

  private static addCategoryCountToSnapshot(snapshot: SnapshotInfo, item: any): SnapshotInfo {
    snapshot.approvableIds.push(item.id);
    let categoryKey = '';
    // only Approvable fields here, so it's safe to not consider other sections.
    if (item.__typename === 'AnnualAccountingReport') {
      categoryKey = SectionNames.ACCOUNT_REPORT;
    } else if (item.__typename === 'Partnership') {
      categoryKey = SectionNames.PARTNERSHIPS;
    } else if (item.__typename === 'Role') {
      categoryKey = SectionNames.REFERENTS_AND_ROLES;
    } else {
      categoryKey = SectionNames.GENERAL_INFO;
    }
    if (!snapshot.categoriesMap[categoryKey]) {
      snapshot.categoriesMap[categoryKey] = 1;
    } else {
      snapshot.categoriesMap[categoryKey] += 1;
    }
    return snapshot;
  }

  constructor(private activatedRoute: ActivatedRoute,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar,
              private router: Router,
              private dialog: MatDialog,
              private enhancedCurrencyPipe: EnhancedCurrencyPipe,
              private userService: UserService,
              private referentsAndRolesService: ReferentsAndRolesService,
              private institutionSetStatusGQL: InstitutionSetStatusGQL,
              private institutionGQL: InstitutionGQL,
              private institutionsSearchGQL: InstitutionSearchGQL,
              private checkApprovablesGQL: CheckApprovablesGQL,
              private checkComplexDataGQL: CheckComplexDataGQL,
              private allSectorsGQL: AllSectorsGQL,
              private remindCreateGQL: RemindCreateGQL,
              private remindUpdateGQL: RemindUpdateGQL,
              private attachmentGQL: AttachmentGQL,
              private attachmentCreateGQL: AttachmentCreateGQL,
              private attachmentUpdateGQL: AttachmentUpdateGQL,
              private attachmentDeleteGQL: AttachmentDeleteGQL,
              private deliberationGQL: DeliberationGQL,
              private deliberationCreateGQL: DeliberationCreateGQL,
              private deliberationUpdateGQL: DeliberationUpdateGQL,
              private deliberationDeleteGQL: DeliberationDeleteGQL,
              private partnershipGQL: PartnershipGQL,
              private partnershipCreateGQL: PartnershipCreateGQL,
              private partnershipUpdateGQL: PartnershipUpdateGQL,
              private partnershipDeleteGQL: PartnershipDeleteGQL,
              private quoteGQL: QuoteGQL,
              private quoteCreateGQL: QuoteCreateGQL,
              private quoteUpdateGQL: QuoteUpdateGQL,
              private quoteDeleteGQL: QuoteDeleteGQL,
              private annualAccountingReportCreateGQL: AnnualAccountingReportCreateGQL,
              private annualAccountingReportUpdateGQL: AnnualAccountingReportUpdateGQL,
              private institutionUpdateGQL: InstitutionUpdateGQL,
              private approvableApproveGQL: ApprovableApproveGQL,
              private approvableApproveManyGQL: ApprovableApproveManyGQL) {
    const todayMidnight = new Date().setHours(0, 0, 0, 0);
    this.todayDate = new Date(todayMidnight);
    this.pickerDate = new Date(todayMidnight);
    this.tomorrowMinDateConstraint = moment(todayMidnight).add(1, 'day').toDate();
    this.isAdmin = this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.InstitutionAdmin;
    this.isViewer = this.userService.user?.role === UserRoleEnum.Viewer;
    // configure fields of all the tab sections
    this.formsFieldsMap = {
      corporatePurpose: {
        key: 'corporatePurpose',
        type: FieldTypes.TEXTAREA,
        fControl: new FormControl(this.fieldsModel.corporatePurpose, {validators: []})
      },
      generalMain: ['vName', 'fiscalCode', 'vatNumber', 'vUniParticipation', 'institutionType', 'sector', 'vWebsite', 'vEmail', 'vPhoneNumber', 'vLegalResidence', 'adherentStructuresInfo'].map(key => {

        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          versioned: /v([A-Z])+/.test(key),
          key
        };

        let type;
        if (key === 'adherentStructuresInfo') {
          type = FieldTypes.ADHERENT_STRUCTURES;
        } else if (key === 'sector') {
          type = FieldTypes.SELECT;
          this.allSectorsGQL.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(res => {
            conf.selectOptions = res.data?.allSectors ? res.data.allSectors.map(s => {
              return {
                id: s.id,
                text: s.name
              };
            }) : [];
          });
        } else if (key === 'vWebsite') {
          type = FieldTypes.LINK;
        } else if (key === 'institutionType') {
          type = FieldTypes.SELECT;
          conf.selectOptions = Object.values(InstitutionTypeEnum).map(value => ({
            id: value,
            text: this.translatePipe.transform('institution-details.institutionType--' + value)
          }));
        } else if (key === 'vUniParticipation') {
          type = FieldTypes.UNI_PARTECIPATION;
        }
        conf.type = type;

        // general main needs form fields as editing is inline only for the expression below (versioned fields and the complex others are excluded).
        if (this.isFormFieldType(conf)) {
          conf.fControl = new FormControl(this.fieldsModel[key], {validators: []});
        }
        return conf;
      }),
      // tslint:disable-next-line:max-line-length
      generalDetails: ['vAcronym', 'vAdministrativeHeadquarter', 'vOperationalHeadquarter', 'constitutionDate', 'attachments:' + AttachmentTypeEnum.ConstitutiveAct, 'attachments:' + AttachmentTypeEnum.CurrentStatute, 'recognitionDate', 'attachments:' + AttachmentTypeEnum.RecognitionDocument, 'attachments:' + AttachmentTypeEnum.CompanyRegistrationReport, 'atecoActivities', 'attachments:' + AttachmentTypeEnum.AccesionAct, 'idUGov', 'adhesionDate', 'nominalValue', 'mef', 'innovativeStartup', 'startCupParticipant', 'incubation', 'patents', 'duration', 'expiryDate', 'contacts', 'note'].map(key => {
        let type;
        if (key.includes('attachments')) {
          type = FieldTypes.ATTACHMENT;
        } else if (key === 'atecoActivities') {
          type = FieldTypes.ATECO_ACTIVITIES;
        } else if (key === 'constitutionDate' || key === 'recognitionDate' || key === 'adhesionDate' || key === 'expiryDate' || key === 'duration') {
          type = FieldTypes.DATE;
        } else if (key === 'mef' || key === 'innovativeStartup' || key === 'startCupParticipant' || key === 'incubation') {
          type = FieldTypes.BOOLEAN;
        } else if (key === 'contacts' || key === 'note') {
          type = FieldTypes.TEXTAREA;
        } else if (key === 'nominalValue') {
          type = FieldTypes.CURRENCY;
        }

        let onlySpinoff;
        if (key === 'innovativeStartup' || key === 'startCupParticipant' || key === 'incubation' || key === 'patents') {
          onlySpinoff = true;
        }

        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          versioned: /v([A-Z])+/.test(key),
          key,
          type,
          onlySpinoff
        };
        if (this.isFormFieldType(conf)) {
          const validators: ValidatorFn[] = [];
          conf.fControl = new FormControl(this.fieldsModel[key], {validators});
        }
        return conf;
      }),
      accountReportCurrent: ['dateOfApproval', 'document:approvalDocument', 'document:supplementaryNoteDocument', 'document:controlBodyReportDocument', 'consolidatedFinancialReport', 'consolidatedUniversityFinancialReport', 'universityFinancialReport'].map(key => {
        let type = FieldTypes.BOOLEAN;
        if (key.includes('document')) {
          type = FieldTypes.DOCUMENT;
        } else if (key === 'dateOfApproval') {
          type = FieldTypes.DATE;
        }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          key,
          type
        };
        if (this.isFormFieldType(conf)) {
          const validators: ValidatorFn[] = [];
          conf.fControl = new FormControl(this.fieldsModel[key], {validators});
        }
        return conf;
      }),
      accountReportExtraInfo: ['revenue', 'productionValue', 'exerciseResult', 'socialCapital', 'netAssets', 'employeeNumber'].map(key => {
        let type;
        if (key === 'revenue' || key === 'productionValue' || key === 'exerciseResult' || key === 'netAssets') {
          type = FieldTypes.CURRENCY;
        } else if (key === 'employeeNumber') {
          type = FieldTypes.NUMBER;
        }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          key,
          type
        };
        if (this.isFormFieldType(conf)) {
          const validators: ValidatorFn[] = [];
          conf.fControl = new FormControl(this.fieldsModel[key], {validators});
        }
        return conf;
      }),
      quoteDetails: ['membershipQuote', 'uniIsReceiver', 'note'].map(key => {
        let type;
        if (key === 'membershipQuote' || key === 'uniIsReceiver') {
          type = FieldTypes.BOOLEAN;
        } else if (key === 'note') {
          type = FieldTypes.TEXTAREA;
        }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          key,
          type
        };
        return conf;
      }),
      roleDetails: ['roleType', 'document:roleDocument', 'note'].map(key => {
        let type;
        if (key === 'roleType') {
          type = FieldTypes.SELECT;
        } else if (key.includes('document')) {
          type = FieldTypes.DOCUMENT;
        } else if (key === 'note') {
          type = FieldTypes.TEXTAREA;
        }
        // else if (key === 'endDate') {
        //   type = FieldTypes.DATE;
        // } else if (key === 'uniNominee') {
        //   type = FieldTypes.BOOLEAN;
        // }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('role-details.' + key),
          key,
          type
        };
        return conf;
      }),
      partnershipDetails: ['proposer', 'personKind', 'nominalValue', 'note'].map(key => {
        let type;
        if (key === 'proposer') {
          type = FieldTypes.BOOLEAN;
        } else if (key === 'nominalValue') {
          type = FieldTypes.CURRENCY;
        } else if (key === 'note') {
          type = FieldTypes.TEXTAREA;
        } else if(key === 'personKind') {
          type = FieldTypes.SELECT;
        }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          key,
          type
        };
        return conf;
      }),
      documentsAttachmentDetails: ['note'].map(key => {
        let type;
        // if (key === 'attachmentType') {
        //   type = FieldTypes.ATTACHMENT_TYPES;
        // }
        if (key === 'note') {
          type = FieldTypes.TEXTAREA;
        }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          key,
          type
        };
        return conf;
      }),
      documentsDeliberationDetails: ['note'].map(key => {
        let type;
        // if (key === 'deliberationType') {
        //   type = FieldTypes.DELIBERATION_TYPES;
        // }
        if (key === 'note') {
          type = FieldTypes.TEXTAREA;
        }
        const conf: FieldConfiguration = {
          label: this.translatePipe.transform('institution-details.' + key),
          key,
          type
        };
        return conf;
      })
    };
    this.quotesTableConfig = {
      list: [],
      columnsConfig: [
        {dataToShowKey: 'amount', headerTitleKey: 'institution-details.quotes-total', type: TableColumnTypes.CURRENCY},
        {dataToShowKey: 'coanAccount', headerTitleKey: 'institution-details.quotes-coan'},
        {dataToShowKey: 'ua', headerTitleKey: 'institution-details.quotes-ua'},
        {dataToShowKey: 'paymentFrequency', headerTitleKey: 'institution-details.quotes-payment-frequency'},
        {dataToShowKey: 'actions', headerTitleKey: '', type: TableColumnTypes.ACTIONS}
      ]
    };
    this.partnershipsTableConfig = {
      list: [],
      columnsConfig: [
        {
          dataToShowKey: 'nominative',
          headerTitleKey: 'institution-details.partnerships-nominative',
          hasCellMarking: true
          // type: TableColumnTypes.LINK,
          // linkTarget: 'Person'
        },
        {dataToShowKey: 'quote', headerTitleKey: 'institution-details.partnerships-quote', hasCellMarking: true},
        {
          dataToShowKey: 'startDate',
          headerTitleKey: 'institution-details.partnerships-start-date',
          type: TableColumnTypes.DATE,
          hasCellMarking: true
        },
        {
          dataToShowKey: 'endDate',
          headerTitleKey: 'institution-details.partnerships-end-date',
          type: TableColumnTypes.DATE,
          hasCellMarking: true
        },
        {dataToShowKey: 'actions', headerTitleKey: '', type: TableColumnTypes.ACTIONS}
      ]
    };
    this.documentsAttachmentsTableConfig = {
      list: [],
      columnsConfig: [
        {
          dataToShowKey: 'description',
          headerTitleKey: 'institution-details.documents-name',
          type: TableColumnTypes.LINK,
          linkIcon: 'pdf'
        },
        {dataToShowKey: 'type', headerTitleKey: 'institution-details.attachmentType'},
        {dataToShowKey: 'validFrom', headerTitleKey: 'institution-details.documents-valid-from', type: TableColumnTypes.DATE},
        {dataToShowKey: 'expiryDate', headerTitleKey: 'institution-details.documents-expiry-date', type: TableColumnTypes.DATE},
        {dataToShowKey: 'actions', headerTitleKey: '', type: TableColumnTypes.ACTIONS}
      ]
    };
    this.documentsDeliberationsTableConfig = {
      list: [],
      columnsConfig: [
        {
          dataToShowKey: 'description',
          headerTitleKey: 'institution-details.documents-name',
          type: TableColumnTypes.LINK,
          linkIcon: 'pdf'
        },
        {dataToShowKey: 'type', headerTitleKey: 'institution-details.deliberationType'},
        {dataToShowKey: 'date', headerTitleKey: 'institution-details.documents-date', type: TableColumnTypes.DATE},
        {dataToShowKey: 'actions', headerTitleKey: '', type: TableColumnTypes.ACTIONS}
      ]
    };
    // console.log('formsFieldsMap: ', this.formsFieldsMap);
  }

  // Complex view mode data auxiliaries

  private parseSocialCapital(scStr: string): number {
    const processed = scStr.toLowerCase().trim().replace('€', '');
    return processed.length > 0 ? (processed === 'zero' ? 0 : parseFloat(processed)) : 0;
  }

  private buildProcessedData(): void {
    this.processedData = {};
    const theDate = this.selectedVersionedDate || this.todayDate;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT] = this.institutionData.vAnnualAccountingReports.find((r: any) => {
      return theDate.getFullYear() === r.year;
    });

    const headerData = [4, 3, 2, 1, 0].map(yd => theDate.getFullYear() - yd);

    const orderedByYearDesc = this.institutionData.vAnnualAccountingReports.sort((a: any, b: any) => {
      if (a.year < b.year) {
        return 1;
      }
      if (a.year > b.year) {
        return -1;
      }
      return 0;
    });
    const tableData = {
      revenue: [] as any,
      productionValue: [] as any,
      exerciseResult: [] as any,
      socialCapital: [] as any,
      netAssets: [] as any,
      employeeNumber: [] as any
    };
    headerData.forEach(year => {
      const found = orderedByYearDesc.find((item: any) => item.year === year);
      if (!found) {
        tableData.revenue.push(null);
        tableData.productionValue.push(null);
        tableData.exerciseResult.push(null);
        tableData.socialCapital.push(null);
        tableData.netAssets.push(null);
        tableData.employeeNumber.push(null);
      } else {
        tableData.revenue.push((found.revenue !== null && found.revenue !== undefined) ? this.enhancedCurrencyPipe.transform(found.revenue, 'EUR') : '—');
        tableData.productionValue.push((found.productionValue !== null && found.productionValue !== undefined) ? this.enhancedCurrencyPipe.transform(found.productionValue, 'EUR') : '—');
        // tslint:disable-next-line:max-line-length
        tableData.exerciseResult.push((found.exerciseResult !== null && found.exerciseResult !== undefined) ? this.enhancedCurrencyPipe.transform(found.exerciseResult, 'EUR') : '—');
        // tslint:disable-next-line:radix max-line-length
        tableData.socialCapital.push((found.socialCapital !== null && found.socialCapital !== undefined) ? this.enhancedCurrencyPipe.transform(this.parseSocialCapital(found.socialCapital), 'EUR') : '—');
        tableData.netAssets.push((found.netAssets !== null && found.netAssets !== undefined) ? this.enhancedCurrencyPipe.transform(found.netAssets, 'EUR') : '—');
        tableData.employeeNumber.push(found.employeeNumber);
      }
    });
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS] = {
      header: headerData,
      data: tableData
    };
    const todayMidnightDatetime = new Date().setHours(0, 0, 0, 0);
    this.processedData[ProcessedDataKinds.EXPIRING_REMINDS] = this.institutionData.reminds?.filter((r: Remind) => {
      // not archived
      return !r.archived;
    });
    this.processedData[ProcessedDataKinds.ARCHIVED_REMINDS] = this.institutionData.reminds?.filter((r: Remind) => r.archived);

    this.processedData[ProcessedDataKinds.REFERENTS_AND_ROLES] = this.institutionData.legalBodies.map((l: LegalBody) => {
      const foundRelatedRoles = this.institutionData.vRoles.filter((r: Role) => r.legalBody.id === l.id);
      if (foundRelatedRoles && foundRelatedRoles.length > 0) {
        return {
          ...l,
          groupedRoles: foundRelatedRoles.map((role: Role) => {
            role.approved = !this.isNotApproved(role.id);
            return role;
          })
        };
      }
      return {
        ...l,
        groupedRoles: []
      };
    });
    // const resultObj = this.institutionData.vRoles.reduce((acc: any, r: Role) => {
    //   if (!acc[r.legalBody.bodyType]) {
    //     acc[r.legalBody.bodyType] = {
    //       ...r.legalBody,
    //       groupedRoles: [{...r}]
    //     };
    //   } else {
    //     acc[r.legalBody.bodyType].groupedRoles.push({...r});
    //   }
    //   return acc;
    // }, Object.create(null));
    // this.processedData[ProcessedDataKinds.REFERENTS_AND_ROLES] = Object.keys(resultObj).map(key => resultObj[key]);
  }

  private getAttachmentDataForTableItem(a: Attachment): any {
    return {
      id: a.id,
      detailsType: DetailsFormTypes.DOCUMENT_ATTACHMENT,
      description: a.description,
      type: this.translatePipe.transform('institution-details.attachments:' + a.attachmentType),
      validFrom: a.validFrom,
      expiryDate: a.expiryDate,
      documentUrl: a.attachedDocument.url,
      note: a.note,
      actions: (this.isAdmin || this.isViewer) ? [TableRowActions.VIEW_DETAILS] : [TableRowActions.VIEW_DETAILS, TableRowActions.UPDATE, TableRowActions.DELETE]
    };
  }

  private getDeliberationDataForTableItem(d: Deliberation): any {
    return {
      id: d.id,
      detailsType: DetailsFormTypes.DOCUMENT_DELIBERATION,
      description: this.translatePipe.transform('institution-details.deliberation-description--' + d.description),
      type: this.translatePipe.transform('institution-details.deliberations:' + d.deliberationType),
      date: d.date,
      note: d.note,
      documentUrl: d.deliberationDocument.url,
      actions: (this.isAdmin || this.isViewer) ? [TableRowActions.VIEW_DETAILS] : [TableRowActions.VIEW_DETAILS, TableRowActions.UPDATE, TableRowActions.DELETE]
    };
  }

  private getPartnershipDataForTableItem(p: Partnership): any {
    return {
      id: p.id,
      markCell: this.isNotApproved(p.id),
      detailsType: DetailsFormTypes.PARTNERSHIP,
      approvableType: ApprovableTypeEnum.Partnership,
      nominative: p.partnerName,
      quote: p.quote?.toFixed(2),
      nominalValue: p.nominalValue,
      note: p.note,
      proposer: p.proposer,
      personKind: p.personKind,
      startDate: p.startDate,
      endDate: p.endDate,
      actions: (this.isAdmin || this.isViewer) ? [TableRowActions.VIEW_DETAILS] : [TableRowActions.VIEW_DETAILS, TableRowActions.UPDATE, TableRowActions.DELETE]
    };
  }

  private getQuoteDataForTableItem(q: Quote): any {
    return {
      id: q.id,
      detailsType: DetailsFormTypes.QUOTE,
      amount: q.amount,
      coanAccount: q.coanAccount,
      ua: q.ua,
      membershipQuote: q.membershipQuote,
      uniIsReceiver: q.uniIsReceiver,
      note: q.note,
      paymentFrequency: this.translatePipe.transform('institution-details.quotes-payment-frequency--' + q.paymentFrequency),
      actions: (this.isAdmin || this.isViewer) ? [TableRowActions.VIEW_DETAILS] : [TableRowActions.VIEW_DETAILS, TableRowActions.UPDATE, TableRowActions.DELETE]
    };
  }

  private buildTablesData(): void {
    this.quotesTableConfig.list = this.institutionData.quotes.map((q: Quote) => {
      return this.getQuoteDataForTableItem(q);
    });
    this.partnershipsTableConfig.list = this.institutionData.vPartnerships.map((p: Partnership) => {
      return this.getPartnershipDataForTableItem(p);
    });
    this.partnershipsTableConfig.list.sort((a, b) => {
      return this.isNotApproved(a.id) ? -1 : 1;
    });
    this.documentsAttachmentsTableConfig.list = this.institutionData.attachments.map((a: Attachment) => {
      return this.getAttachmentDataForTableItem(a);
    });
    this.documentsAttachmentsTableConfig.list.sort((a, b) => {
      if (moment(a.validFrom).isSame(moment(b.validFrom))) {
        return 0;
      }
      return moment(a.validFrom).isAfter(moment(b.validFrom)) ? -1 : 1;
    });
    this.documentsDeliberationsTableConfig.list = this.institutionData.deliberations.map((d: Deliberation) => {
      return this.getDeliberationDataForTableItem(d);
    });
  }

  // Fetch institution handler

  private fetchInstitutionById(id?: string, silentReload?: boolean, postExecutionFn?: () => void): void {
    if (!silentReload) {
      this.busy = true;
    }
    let versionableOptions;
    if (this.selectedVersionedDate) {
      versionableOptions = {onDate: moment(this.selectedVersionedDate).toISOString(true)};
      id = this.institutionId;
    }
    if (!id) {
      console.error('cannot fetch institution! Id needed.');
      return;
    }
    this.resetEditModel();
    this.institutionGQL.fetch({id, versionableOptions}, {fetchPolicy: 'no-cache'}).subscribe(res => {
      this.institutionData = {...res.data.institution};
      if (this.institutionData.__typename === 'Spinoff') {
        this.formsFieldsMap.description = {
          key: 'description',
          type: FieldTypes.TEXTAREA,
          fControl: new FormControl(this.fieldsModel.description, {validators: []})
        };
      }
      this.institutionId = id;
      this.calculateSnapshotsToValidate();
      this.calculateUnapprovedFields();
      this.buildProcessedData();
      // console.log('processedData: ', this.processedData);
      this.buildTablesData();
      this.busy = false;
      if (this.activatedRoute.snapshot.queryParams.sectionToOpen) {
        this.openTabSection(this.activatedRoute.snapshot.queryParams.sectionToOpen);
      }
      if (postExecutionFn && typeof postExecutionFn === 'function') {
        postExecutionFn();
      }
    }, error => {
      this.busy = false;
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }

  // Form fields auxiliaries

  private resetEditModel(): void {
    this.formsEditabilityMap = {
      generalMain: false,
      corporatePurpose: false,
      description: false,
      generalDetails: false,
      accountReportCurrent: false,
      accountReportExtraInfo: false,
      quoteDetails: false,
      partnershipDetails: false,
      documentsAttachmentDetails: false,
      documentsDeliberationDetails: false,
      roleDetails: false
    };
    this.fieldsModel = {};
  }

  private getAttachmentTypesForOptions(): { id: string, text: string }[] {
    return Object.values(AttachmentTypeEnum).map(value => ({
      id: value,
      text: this.translatePipe.transform('institution-details.attachments:' + value)
    }));
  }

  private getDeliberationTypesForOptions(): { id: string, text: string }[] {
    return Object.values(DeliberationTypeEnum).map(value => ({
      id: value,
      text: this.translatePipe.transform('institution-details.deliberations:' + value)
    }));
  }

  private getDeliberationDescriptionsForOptions(): { id: string, text: string }[] {
    return Object.values(DeliberationDescriptionEnum).map(value => ({
      id: value,
      text: this.translatePipe.transform('institution-details.deliberation-description--' + value)
    }));
  }

  private createPaylaodForGeneralInfoFormFields(editContext: string): { institutionInput: undefined | any, spinoffInput: undefined | any } {
    const payload: { institutionInput: undefined | any, spinoffInput: undefined | any } = {
      institutionInput: undefined,
      spinoffInput: undefined
    };
    if (this.institutionData.__typename === 'Spinoff') {
      payload.spinoffInput = {};
      // @ts-ignore
      this.formsFieldsMap[editContext].forEach((field: FieldConfiguration) => {
        // todo refactor this code duplication when you will have time
        if (this.isFormFieldType(field)) {
          if (field.type === FieldTypes.DATE && this.fieldsModel[field.key]) {
            payload.spinoffInput[field.key] = moment(this.fieldsModel[field.key]).toISOString(true);
          } else if (field.type === FieldTypes.CURRENCY) {
            payload.spinoffInput[field.key] = parseFloat(this.fieldsModel[field.key]);
          } else if (field.key === 'sector') {
            payload.spinoffInput.sectorId = this.fieldsModel[field.key];
          } else {
            payload.spinoffInput[field.key] = this.fieldsModel[field.key];
          }
        }
      });
    } else {
      payload.institutionInput = {};
      // @ts-ignore
      this.formsFieldsMap[editContext].forEach((field: FieldConfiguration) => {
        if (this.isFormFieldType(field)) {
          if (field.type === 'date' && this.fieldsModel[field.key]) {
            payload.institutionInput[field.key] = moment(this.fieldsModel[field.key]).toISOString(true);
          } else if (field.type === FieldTypes.CURRENCY) {
            payload.institutionInput[field.key] = parseFloat(this.fieldsModel[field.key]);
          } else if (field.key === 'sector') {
            payload.institutionInput.sectorId = this.fieldsModel[field.key];
          } else {
            payload.institutionInput[field.key] = this.fieldsModel[field.key];
          }
        }
      });
    }
    return payload;
  }

  // Approvable items auxiliaries

  private calculateSnapshotsToValidate(): void {
    this.snapshotsToValidate = [];
    if (this.institutionData.unapproved.length > 0) {
      this.institutionData.unapproved.forEach((uitem: any) => {
        const startDate = moment(new Date(uitem.validFrom || uitem.startDate || (uitem.year ? (uitem.year + '-1-1') : '1700-1-1')));
        const endDate = moment(new Date(uitem.validTo || (uitem.year ? (uitem.year + '-12-31') : '2500-1-1')));
        if (this.snapshotsToValidate.length === 0) {
          let snapshot: SnapshotInfo = {
            startDate,
            endDate,
            categoriesMap: {},
            approvableIds: []
          };
          snapshot = InstitutionDetailsComponent.addCategoryCountToSnapshot(snapshot, uitem);
          this.snapshotsToValidate.push(snapshot);
        } else {
          let foundSomething = false;
          // There are some snapshots available
          for (let i = 0; i < this.snapshotsToValidate.length && !foundSomething; i++) {
            // check if this unapproved item has a range in existing added snapshots
            if (endDate.isAfter(this.snapshotsToValidate[i].startDate) && startDate.isBefore(this.snapshotsToValidate[i].endDate)) {
              foundSomething = true;
              this.snapshotsToValidate[i] = InstitutionDetailsComponent.addCategoryCountToSnapshot(this.snapshotsToValidate[i], uitem);
              if (startDate.isAfter(this.snapshotsToValidate[i].startDate)) {
                this.snapshotsToValidate[i].startDate = startDate;
              }
              if (endDate.isBefore(this.snapshotsToValidate[i].endDate)) {
                this.snapshotsToValidate[i].endDate = endDate;
              }
            }
          }

          if (!foundSomething) {
            let snapshot: SnapshotInfo = {
              startDate,
              endDate,
              categoriesMap: {},
              approvableIds: []
            };
            snapshot = InstitutionDetailsComponent.addCategoryCountToSnapshot(snapshot, uitem);
            this.snapshotsToValidate.push(snapshot);
          }
        }
      });
      // this.snapshotsToValidate.sort((a, b) => {
      //   if (a.endDate.isSame(b.endDate)) {
      //     return 0;
      //   }
      //   return a.endDate.isAfter(b.endDate) ? -1 : 1;
      // });
      let haveAlreadyFoundCurrent = false;
      this.snapshotsToValidate = this.snapshotsToValidate.map(s => {
        if (moment(this.selectedVersionedDate).isBetween(s.startDate, s.endDate, 'day', '[]')) {
          if (!haveAlreadyFoundCurrent) {
            s.containsCurrent = true;
            haveAlreadyFoundCurrent = true;
          }
        } else {
          s.displayableEndDate = s.endDate.isAfter(moment(), 'day') ? moment().toDate() : s.endDate.toDate();
        }
        s.totalCount = (s.categoriesMap.AccountReport || 0) + (s.categoriesMap.Partnerships || 0) + (s.categoriesMap.ReferentsAndRoles || 0) + (s.categoriesMap.GeneralInfo || 0);
        return s;
      });
      this.snapshotsToValidate.sort((a, b) => {
        if (a.endDate.isSame(b.endDate)) {
          return 0;
        }
        return a.endDate.isAfter(b.endDate) ? -1 : 1;
      });
      // contains current on top please
      this.snapshotsToValidate.sort((a, b) => {
        return a.containsCurrent && !b.containsCurrent ? -1 : 1;
      });
      this.selectedSnapshotValidated = !this.snapshotsToValidate.find(s => s.containsCurrent);
    }
    // console.log('snapshotsToValidate: ', this.snapshotsToValidate);
  }

  private calculateUnapprovedFields(): void {
    this.detailsUnapprovedArr = this.institutionData.unapproved.filter((u: any) => {
      // tslint:disable-next-line:max-line-length
      return u.attributeName && (u.attributeName === 'vAcronym' || u.attributeName === 'vAdministrativeHeadquarter' || u.attributeName === 'vOperationalHeadquarter')
        && this.isNotApproved(u.id);
    });
    this.mainUnapprovedArr = this.institutionData.unapproved.filter((u: any) => {
      // tslint:disable-next-line:max-line-length
      return u.attributeName && (u.attributeName === 'vName' || u.attributeName === 'vWebsite' || u.attributeName === 'vEmail' || u.attributeName === 'vPhoneNumber' || u.attributeName === 'vLegalResidence' || u.attributeName === 'vUniParticipation')
        && this.isNotApproved(u.id);
    });
  }

  private updateApprovables(): Promise<void> {
    return new Promise((resolveP, rejectP) => {
      let versionableOptions;
      if (this.selectedVersionedDate) {
        versionableOptions = {onDate: moment(this.selectedVersionedDate).toISOString(true)};
      }
      if (this.institutionId) {
        this.checkApprovablesGQL.fetch({id: this.institutionId, versionableOptions}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data.institution) {
            this.institutionData.allApproved = res.data.institution.allApproved;
            this.institutionData.unapproved = [...res.data.institution.unapproved];
            this.calculateSnapshotsToValidate();
            this.calculateUnapprovedFields();
            resolveP();
          }
          rejectP();
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
          rejectP();
        });
      }
    });
  }

  // Annual account info refresh

  private refreshAnnualAccountExtraInfo(data: any): void {
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT] = {...data};
    // such a nasty hot way to update but view template had to be done in this way.
    // tslint:disable-next-line:max-line-length
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data.revenue[4] = (data.revenue === null || data.revenue === undefined) ? '—' : this.enhancedCurrencyPipe.transform(data.revenue, 'EUR');
    // tslint:disable-next-line:max-line-length
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data.productionValue[4] = (data.productionValue === null || data.productionValue === undefined) ? '—' : this.enhancedCurrencyPipe.transform(data.productionValue, 'EUR');
    // tslint:disable-next-line:max-line-length
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data.exerciseResult[4] = (data.exerciseResult === null || data.exerciseResult === undefined) ? '—' : this.enhancedCurrencyPipe.transform(data.exerciseResult, 'EUR');
    // tslint:disable-next-line:max-line-length
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data.socialCapital[4] = (data.socialCapital !== null && data.socialCapital !== undefined) ? this.enhancedCurrencyPipe.transform(this.parseSocialCapital(data.socialCapital), 'EUR') : '—';
    // tslint:disable-next-line:max-line-length
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data.netAssets[4] = (data.netAssets === null || data.netAssets === undefined) ? '—' : this.enhancedCurrencyPipe.transform(data.netAssets, 'EUR');
    this.processedData[ProcessedDataKinds.TABLE_ANNUAL_ACCOUNTING_REPORTS].data.employeeNumber[4] = data.employeeNumber;
  }

  private refreshAnnualAccountCurrent(data: any): void {
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].dateOfApproval = data.dateOfApproval;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].approvalDocument = data.approvalDocument;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].supplementaryNoteDocument = data.supplementaryNoteDocument;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].controlBodyReportDocument = data.controlBodyReportDocument;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].consolidatedFinancialReport = data.consolidatedFinancialReport;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].consolidatedUniversityFinancialReport = data.consolidatedUniversityFinancialReport;
    this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].universityFinancialReport = data.universityFinancialReport;
  }

  // view functions

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.fetchInstitutionById(routeParams.id);
    });
  }

  isFormFieldType(conf: FieldConfiguration): boolean {
    // tslint:disable-next-line:max-line-length
    return !conf.versioned && (!conf.type || conf.type === FieldTypes.TEXT || conf.type === FieldTypes.BOOLEAN || conf.type === FieldTypes.CURRENCY || conf.type === FieldTypes.DATE || conf.type === FieldTypes.DOCUMENT || conf.type === FieldTypes.NUMBER || conf.type === FieldTypes.TEXTAREA || conf.type === FieldTypes.SELECT);
  }

  // snapshot things

  onSelectVersionedDate(event: any): void {
    this.selectedVersionedDate = event._d;
    this.fetchInstitutionById();
  }

  onGoBackToCurrentVersion(): void {
    this.selectedVersionedDate = undefined;
    this.pickerDate = new Date(this.todayDate);
    this.fetchInstitutionById(this.institutionId);
  }

  openPreviousSnapshot(snapshotDate: moment.Moment): void {
    let selectedSnapshotDate = snapshotDate;
    const todayMidnight = new Date().setHours(0, 0, 0, 0);
    if (selectedSnapshotDate.isSameOrAfter(moment(todayMidnight), 'day')) {
      selectedSnapshotDate = moment(todayMidnight);
      this.selectedVersionedDate = undefined;
      this.fetchInstitutionById(this.institutionId);
    } else {
      this.selectedVersionedDate = selectedSnapshotDate.toDate();
      this.fetchInstitutionById();
    }
    this.pickerDate = selectedSnapshotDate.toDate();
    this.showValidationInfoOverlay = false;
  }

  isNotApproved(id: string): boolean {
    const c = this.snapshotsToValidate.find(s => s.containsCurrent);
    if (c) {
      return c.approvableIds.includes(id);
    }
    return false;
  }

  // validation things

  onValidateAllClicked(editContext: string): void {
    let payloadInput: ApprovableInput[];
    switch (editContext) {
      case 'accountReportCurrent':
        payloadInput = [{
          approvableId: this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].id,
          approvableType: ApprovableTypeEnum.AnnualAccountingReport,
          approved: true
        }];
        break;
      case 'generalMain':
        if (this.mainUnapprovedArr.length > 0) {
          payloadInput = this.mainUnapprovedArr.map(a => {
            return {
              // @ts-ignore
              approvableId: a.id,
              approvableType: ApprovableTypeEnum.VersionedRecord,
              approved: true
            };
          });
        }
        break;
      case 'generalDetails':
        if (this.detailsUnapprovedArr.length > 0) {
          payloadInput = this.detailsUnapprovedArr.map(a => {
            return {
              // @ts-ignore
              approvableId: a.id,
              approvableType: ApprovableTypeEnum.VersionedRecord,
              approved: true
            };
          });
        }
        break;
    }
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: editContext === 'accountReportCurrent' ? this.translatePipe.transform('validate-account-report-confirmation-modal.confirm-title') : this.translatePipe.transform('validate-all-confirmation-modal.confirm-title'),
        // tslint:disable-next-line:max-line-length
        bodyHtml: '<p>' + (editContext === 'accountReportCurrent' ? this.translatePipe.transform('validate-account-report-confirmation-modal.confirm-text') : this.translatePipe.transform('validate-all-confirmation-modal.confirm-text')) + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed && payloadInput) {
        this.approvableApproveManyGQL.mutate({
          input: payloadInput
        }).subscribe(res => {
          this.fetchInstitutionById(this.institutionId, true);
          this.snackBar.open(this.translatePipe.transform('validate-all-confirmation-modal.operation-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  validateApprovableField(data: { id: string, approvableType: ApprovableTypeEnum }, fieldKey?: string): void {

    if (!data.approvableType) {
      throw new Error('invalid approvable type');
    }

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('validate-item-confirmation-modal.confirm-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('validate-item-confirmation-modal.confirm-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.approvableApproveGQL.mutate({
          input: {
            approvableId: data.id,
            approvableType: data.approvableType,
            approved: true
          }
        }).subscribe(res => {
          if (data.approvableType === ApprovableTypeEnum.VersionedRecord && fieldKey) {
            this.institutionData[fieldKey].approved = res.data?.approvableApprove.approved;
            this.updateApprovables();
          } else {
            this.fetchInstitutionById(this.institutionId, true);
          }
          this.snackBar.open(this.translatePipe.transform('validate-item-confirmation-modal.operation-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  refreshApprovableField(data: any, approvableType: ApprovableTypeEnum): void {
    if (approvableType === ApprovableTypeEnum.VersionedRecord) {
      this.institutionData[data.attributeName].approved = data.approved;
      this.updateApprovables();
    } else {
      this.fetchInstitutionById(this.institutionId, true);
    }
  }

  // form things

  onEnableEditing(editContext: string): void {
    if (editContext) {
      if ((editContext === 'corporatePurpose' || editContext === 'description') && this.formsFieldsMap[editContext]?.fControl) {
        // @ts-ignore
        this.formsFieldsMap[editContext].fControl.setValue(this.institutionData[editContext]);
        // @ts-ignore
        this.formsFieldsMap[editContext].fControl.markAsUntouched();
      } else if (editContext === 'accountReportCurrent' || editContext === 'accountReportExtraInfo') {
        this.formsFieldsMap[editContext].forEach((field: FieldConfiguration) => {
          if (field.fControl) {
            if (field.type === FieldTypes.CURRENCY) {
              field.fControl.setValue(roundCurrencyValue(this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key]));
              this.fieldsModel[field.key] = this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key];
            } else if (field.type === FieldTypes.DOCUMENT) {
              const foundUrl = this.getDocumentUrlByKeyAndData(field.key, this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT]);
              this.fieldsModel[field.key] = foundUrl ? {url: foundUrl} : undefined;
            } else {
              field.fControl.setValue(this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key]);
              this.fieldsModel[field.key] = this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT][field.key];
            }
            field.fControl.markAsUntouched();
          }
        });
      } else if (editContext === 'generalMain' || editContext === 'generalDetails') {
        this.formsFieldsMap[editContext].forEach((field: FieldConfiguration) => {
          if (field.fControl) {
            if (field.type === FieldTypes.CURRENCY) {
              field.fControl.setValue(roundCurrencyValue(this.institutionData[field.key]));
            } else if (field.key === 'sector' && this.institutionData[field.key]) {
              // triggered hehe
              field.fControl.setValue(this.institutionData[field.key].id);
            } else {
              field.fControl.setValue(this.institutionData[field.key]);
            }
            field.fControl.markAsUntouched();
          }
        });
      }
      // @ts-ignore
      this.formsEditabilityMap[editContext] = true;
    }
  }

  onCancelChanges(editContext: string): void {
    if (editContext) {
      // @ts-ignore
      this.formsEditabilityMap[editContext] = false;
    }
  }

  isSaveDisabled(editContext: string): boolean {
    if (editContext === 'generalMain' || editContext === 'generalDetails') {
      // @ts-ignore
      return this.formsFieldsMap[editContext] && this.formsFieldsMap[editContext].some((field: FieldConfiguration) => {
        if (field.type === FieldTypes.DOCUMENT) {
          return field.required && !this.fieldsModel[field.key];
        }
        return field.required && field.fControl && !field.fControl.valid && !field.fControl.disabled;
      });
    }
    return false;
  }

  onSaveChanges(editContext: string): void {
    // console.log('save: editContext', editContext);
    if (this.institutionId) {
      if (editContext === 'corporatePurpose' || editContext === 'description') {
        // CASE SEPARATED FIELDS: corporatePurpose, description

        // tslint:disable-next-line:one-variable-per-declaration
        let institutionInput, spinoffInput;
        if (this.institutionData.__typename === 'Spinoff') {
          spinoffInput = {};
          // @ts-ignore
          spinoffInput[editContext] = this.fieldsModel[editContext];
        } else {
          institutionInput = {
            corporatePurpose: this.fieldsModel[editContext]
          };
        }
        this.institutionUpdateGQL.mutate({
          institutionId: this.institutionId,
          institutionInput,
          spinoffInput
        }).subscribe(res => {
          // @ts-ignore
          this.institutionData[editContext] = res.data?.institutionUpdate[editContext];
          this.formsEditabilityMap[editContext] = false;
          this.snackBar.open(this.translatePipe.transform('institution-details.save-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      } else if (editContext === 'accountReportCurrent') {
        // CASE ANNUAL ACCOUNT REPORT: current info
        this.annualAccountingReportUpdateGQL.mutate({
          id: this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].id,
          input: {
            year: this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].year,
            institutionId: this.institutionId,
            dateOfApproval: moment(this.fieldsModel.dateOfApproval).toISOString(true),
            approvalDocument: this.fieldsModel['document:approvalDocument'] ? this.fieldsModel['document:approvalDocument'].file : null,
            supplementaryNoteDocument: this.fieldsModel['document:supplementaryNoteDocument'] ? this.fieldsModel['document:supplementaryNoteDocument'].file : null,
            controlBodyReportDocument: this.fieldsModel['document:controlBodyReportDocument'] ? this.fieldsModel['document:controlBodyReportDocument'].file : null,
            consolidatedFinancialReport: this.fieldsModel.consolidatedFinancialReport,
            consolidatedUniversityFinancialReport: this.fieldsModel.consolidatedUniversityFinancialReport,
            universityFinancialReport: this.fieldsModel.universityFinancialReport
          }
        }).subscribe(res => {
          this.refreshAnnualAccountCurrent(res.data?.annualAccountingReportUpdate);
          this.snackBar.open(this.translatePipe.transform('institution-details.save-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
          this.formsEditabilityMap[editContext] = false;
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      } else if (editContext === 'accountReportExtraInfo') {
        // CASE ANNUAL ACCOUNT REPORT: current extra info
        this.annualAccountingReportUpdateGQL.mutate({
          id: this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].id,
          input: {
            year: this.processedData[ProcessedDataKinds.CURRENT_ANNUAL_ACCOUNTING_REPORT].year,
            institutionId: this.institutionId,
            revenue: this.fieldsModel.revenue !== 0 ? parseFloat(this.fieldsModel.revenue) : 0,
            productionValue: this.fieldsModel.productionValue !== 0 ? parseFloat(this.fieldsModel.productionValue) : 0,
            exerciseResult: this.fieldsModel.exerciseResult !== 0 ? parseFloat(this.fieldsModel.exerciseResult) : 0,
            socialCapital: this.fieldsModel.socialCapital || '0',
            netAssets: this.fieldsModel.netAssets !== 0 ? parseFloat(this.fieldsModel.netAssets) : 0,
            employeeNumber: this.fieldsModel.employeeNumber
          }
        }).subscribe(res => {
          this.refreshAnnualAccountExtraInfo(res.data?.annualAccountingReportUpdate);
          this.snackBar.open(this.translatePipe.transform('institution-details.save-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
          this.formsEditabilityMap[editContext] = false;
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      } else if (editContext === 'generalMain' || editContext === 'generalDetails') {
        // CASE GENERAL INFO: main and details info
        const payload = this.createPaylaodForGeneralInfoFormFields(editContext);
        this.institutionUpdateGQL.mutate({
          institutionId: this.institutionId,
          ...payload
        }).subscribe(res => {
          this.institutionData = {...res.data?.institutionUpdate};
          this.formsEditabilityMap[editContext] = false;
          this.snackBar.open(this.translatePipe.transform('institution-details.save-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    }
  }

  onFormFieldChange(value: any, key: string): void {
    this.fieldsModel[key] = value;
  }

  formatCurrencyNumber(fieldConfiguration: FieldConfiguration): void {
    if (fieldConfiguration.fControl && this.fieldsModel[fieldConfiguration.key]) {
      fieldConfiguration.fControl.setValue(roundCurrencyValue(this.fieldsModel[fieldConfiguration.key]));
    }
  }

  onSelectFieldChange(fieldConfiguration: FieldConfiguration, value: string): void {
    if (fieldConfiguration.fControl) {
      fieldConfiguration.fControl.setValue(value);
      this.fieldsModel[fieldConfiguration.key] = value;
    }
  }

  onUploadOutput(event: UploadOutput, fieldName: string): void {
    if (event.type === 'addedToQueue' && event.file) {
      this.fieldsModel[fieldName] = {
        file: event.file.nativeFile,
        name: event.file.name,
        mimeType: event.file.type
      };
    }
  }

  onDocumentRemove(e: any, fieldName: string): void {
    delete this.fieldsModel[fieldName];
  }

  // Link and urls things

  getAttachmentValueByFieldKeyAndProp(key: string, prop: string): string | undefined {
    const split = key.split(':');
    // tslint:disable-next-line:max-line-length
    if (split.length === 2 && (split[1] === AttachmentTypeEnum.RecognitionDocument || split[1] === AttachmentTypeEnum.CompanyRegistrationReport || split[1] === AttachmentTypeEnum.ConstitutiveAct || split[1] === AttachmentTypeEnum.AccesionAct || split[1] === AttachmentTypeEnum.CurrentStatute || split[1] === AttachmentTypeEnum.OtherType)) {
      const founds = this.institutionData.attachments.filter((a: Attachment) => {
        const dateToCompare = this.selectedVersionedDate ? moment(this.selectedVersionedDate) : moment();
        const dateFrom = a.validFrom ? a.validFrom : '1800-1-1';
        const dateTo = a.expiryDate ? a.expiryDate : '2500-1-1';
        return a.attachmentType === split[1] && (dateToCompare.isBetween(dateFrom, dateTo, undefined, '[]'));
      });
      if (founds.length > 0) {
        return prop === 'url' ? (founds[0]?.attachedDocument[prop]) : (founds[0][prop]);
      }
    }
    return undefined;
  }

  getDocumentUrlByKeyAndData(key: string, data: any): string | undefined {
    const split = key.split(':');
    if (split.length === 2) {
      return data[split[1]]?.url;
    }
    return undefined;
  }

  onOpenDocumentClick(urlToOpen?: string | undefined): void {
    window.open(urlToOpen || 'no-url', '_blank');
  }

  onPersonLinkClick(rowDataAndTarget: { data: any; linkTarget: string | undefined }): void {
    this.router.navigate(['/persone/' + rowDataAndTarget.data.id], {queryParams: {sectionToOpen: rowDataAndTarget.linkTarget}});
  }

  onDocumentsLinkClick(event: { data: any; linkTarget: string | undefined }): void {
    window.open(event.data.documentUrl, '_blank');
  }

  openUrlOfLinkField(mainField: FieldConfiguration): void {
    if (mainField.versioned) {
      window.open(`//${this.institutionData[mainField.key]?.attributeValue}`, 'blank');
    } else {
      window.open(`//${this.institutionData[mainField.key]}`, 'blank');
    }
    // `
  }

  // Complex data updated handlers

  onReferentsAndRolesListUpdated(data: { listData: any, updateUnapprovables?: boolean }): void {

    if (data.updateUnapprovables) {
      // update only Role because LegalBody is not an approvable type
      this.updateApprovables().then(() => {
        this.processedData[ProcessedDataKinds.REFERENTS_AND_ROLES] = data.listData.map((rr: { groupedRoles: Role[]; }) => {
          rr.groupedRoles = rr.groupedRoles.map((role: Role) => {
            role.approved = !this.isNotApproved(role.id);
            return role;
          });
          return rr;
        });
      });
    }
  }

  onPartnershipsListUpdated(): void {
    // update always
    this.updateApprovables().then(() => {
      this.partnershipsTableConfig.list = this.partnershipsTableConfig.list.map((p: any) => {
        p.markCell = this.isNotApproved(p.id);
        return p;
      });
    });
  }

  // Referent and Roles custom component - role details clicked handler
  onRoleDetailsClicked(data: { referentId: string, roleId: string }): void {
    const foundReferentIndex = this.processedData[ProcessedDataKinds.REFERENTS_AND_ROLES].findIndex((o: LegalBody) => o.id === data.referentId);
    if (foundReferentIndex !== -1) {
      const foundRoleIndex = this.processedData[ProcessedDataKinds.REFERENTS_AND_ROLES][foundReferentIndex].groupedRoles.findIndex((r: Role) => r.id === data.roleId);
      if (foundRoleIndex !== -1) {
        this.processedData[ProcessedDataKinds.SELECTED_ROLE_DETAILS] = this.processedData[ProcessedDataKinds.REFERENTS_AND_ROLES][foundReferentIndex].groupedRoles[foundRoleIndex];
        this.showRoleDetailsSidePanel = true;
      }
    }
    if (!this.showRoleDetailsSidePanel) {
      throw new Error('Error. Sorry but it was unable to fetch Role Details by referentId: [' + data.referentId + '] and roleId: [' + data.roleId + ']');
    }
  }

  // Tab section handler
  openTabSection(section: SectionNames): void {
    this.showValidationInfoOverlay = false;
    switch (section) {
      case SectionNames.GENERAL_INFO:
        this.selectedTab.setValue(0);
        break;
      case SectionNames.ACCOUNT_REPORT:
        this.selectedTab.setValue(1);
        break;
      case SectionNames.REFERENTS_AND_ROLES:
        this.selectedTab.setValue(2);
        break;
      case SectionNames.PARTNERSHIPS:
        this.selectedTab.setValue(3);
        break;
      case SectionNames.EXPIRATIONS:
        this.selectedTab.setValue(4);
        break;
      case SectionNames.DOCUMENTS:
        this.selectedTab.setValue(5);
        break;
    }
  }

  // filterable-table component - actions handler of a clicked item
  filterableTableItemClickedAction(event: { actionType: string; object: any; valueKey?: string; newValue?: any }): void {

    // Example of event.actionType
    // 'view-details' (["view-details", "update", "delete"])
    // Example of event.object
    // id : 2 (quote id)
    // detailsType: "QuoteDetails" (DetailsFormTypes)
    // ...Quote data prop

    if (event.actionType === 'view-details') {
      this.formsEditabilityMap.generalMain = false;
      this.fieldsModel = {};
      this.versionedFieldOverlayToShow = '';
      if (event.object.detailsType === DetailsFormTypes.QUOTE) {
        this.processedData[ProcessedDataKinds.SELECTED_QUOTE_DETAILS] = this.quotesTableConfig.list.find((q: Quote) => q.id === event.object.id);
        this.showQuoteDetailsSidePanel = true;
      } else if (event.object.detailsType === DetailsFormTypes.PARTNERSHIP) {
        this.processedData[ProcessedDataKinds.SELECTED_PARTNERSHIP_DETAILS] = this.partnershipsTableConfig.list.find((p: Partnership) => p.id === event.object.id);
        this.showPartnershipDetailsSidePanel = true;
      } else if (event.object.detailsType === DetailsFormTypes.DOCUMENT_ATTACHMENT) {
        this.processedData[ProcessedDataKinds.SELECTED_DOCUMENT_ATTACHMENT_DETAILS] = this.documentsAttachmentsTableConfig.list.find((a: Attachment) => a.id === event.object.id);
        this.showDocumentAttachmentDetailsSidePanel = true;
      } else if (event.object.detailsType === DetailsFormTypes.DOCUMENT_DELIBERATION) {
        // tslint:disable-next-line:max-line-length
        this.processedData[ProcessedDataKinds.SELECTED_DOCUMENT_DELIBERATION_DETAILS] = this.documentsDeliberationsTableConfig.list.find((d: Deliberation) => d.id === event.object.id);
        this.showDocumentDeliberationDetailsSidePanel = true;
      }
    } else if (event.actionType === 'update') {
      if (event.object.detailsType === DetailsFormTypes.DOCUMENT_ATTACHMENT) {
        this.openEditAttachmentModal(event.object.id);
      } else if (event.object.detailsType === DetailsFormTypes.DOCUMENT_DELIBERATION) {
        this.openEditDeliberationModal(event.object.id);
      } else if (event.object.detailsType === DetailsFormTypes.PARTNERSHIP) {
        this.openEditPartnershipModal(event.object.id);
      } else if (event.object.detailsType === DetailsFormTypes.QUOTE) {
        this.openEditQuoteModal(event.object.id);
      }
    } else if (event.actionType === 'delete') {
      // if (event.object.detailsType === DetailsFormTypes.PARTNERSHIP && !event.object.markCell) {
      //   this.snackBar.open(this.translatePipe.transform('partnership-modal.cannot-delete-approved-item'), this.translatePipe.transform('notification-box.close'), {
      //     duration: 4000
      //   });
      //   return;
      // }
      this.openDeleteItemModalByType(event.object.id, event.object.detailsType);
    }
  }

  // side panel closing handler
  closeSidePanel(formType: DetailsFormTypes): void {
    switch (formType) {
      case DetailsFormTypes.GENERAL:
        this.showGeneralDetailsSidePanel = false;
        this.formsEditabilityMap.generalDetails = false;
        break;
      case DetailsFormTypes.QUOTE:
        this.processedData[ProcessedDataKinds.SELECTED_QUOTE_DETAILS] = undefined;
        this.showQuoteDetailsSidePanel = false;
        break;
      case DetailsFormTypes.PARTNERSHIP:
        this.processedData[ProcessedDataKinds.SELECTED_PARTNERSHIP_DETAILS] = undefined;
        this.showPartnershipDetailsSidePanel = false;
        break;
      case DetailsFormTypes.DOCUMENT_ATTACHMENT:
        this.processedData[ProcessedDataKinds.SELECTED_DOCUMENT_ATTACHMENT_DETAILS] = undefined;
        this.showDocumentAttachmentDetailsSidePanel = false;
        break;
      case DetailsFormTypes.DOCUMENT_DELIBERATION:
        this.processedData[ProcessedDataKinds.SELECTED_DOCUMENT_DELIBERATION_DETAILS] = undefined;
        this.showDocumentDeliberationDetailsSidePanel = false;
        break;
      case DetailsFormTypes.ROLE:
        this.processedData[ProcessedDataKinds.SELECTED_ROLE_DETAILS] = undefined;
        this.showRoleDetailsSidePanel = false;
        break;
    }
    this.fieldsModel = {};
    this.versionedFieldOverlayToShow = '';
  }

  // section selected handler of tab panel
  onTabSectionSelected(sectionNumber: number): void {
    this.selectedTab.setValue(sectionNumber);
    let sectionToOpen: string | null = null;
    switch (sectionNumber) {
      case 1:
        sectionToOpen = SectionNames.ACCOUNT_REPORT;
        break;
      case 2:
        sectionToOpen = SectionNames.REFERENTS_AND_ROLES;
        break;
      case 3:
        sectionToOpen = SectionNames.PARTNERSHIPS;
        break;
      case 4:
        sectionToOpen = SectionNames.EXPIRATIONS;
        break;
      case 5:
        sectionToOpen = SectionNames.DOCUMENTS;
        break;
    }
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {sectionToOpen},
        queryParamsHandling: 'merge'
      });
  }

  // expiration reminds things

  updateArchivedRemindsTable(item: any): void {
    if (item.archived) {
      this.processedData[ProcessedDataKinds.ARCHIVED_REMINDS] = [{...item}, ...this.processedData[ProcessedDataKinds.ARCHIVED_REMINDS]];
      this.processedData[ProcessedDataKinds.EXPIRING_REMINDS] = this.processedData[ProcessedDataKinds.EXPIRING_REMINDS].filter((r: Remind) => !r.archived);
    } else {
      this.processedData[ProcessedDataKinds.EXPIRING_REMINDS] = [{...item}, ...this.processedData[ProcessedDataKinds.EXPIRING_REMINDS]];
      this.processedData[ProcessedDataKinds.ARCHIVED_REMINDS] = this.processedData[ProcessedDataKinds.ARCHIVED_REMINDS].filter((r: Remind) => r.archived);
    }
  }

  openExpirationRemindEditModal(item: Remind): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'description',
        type: 'text',
        label: this.translatePipe.transform('expiration-modal.field--description'),
        required: true,
        value: item.description
      },
      {
        fieldKey: 'date',
        type: 'date',
        label: this.translatePipe.transform('expiration-modal.field--date'),
        required: true,
        value: item.date
      },
      {
        fieldKey: 'notificationDate',
        type: 'date',
        label: this.translatePipe.transform('expiration-modal.field--notificationDate'),
        required: true,
        value: item.notificationDate
      },
      {
        fieldKey: 'institutionId',
        type: 'autocomplete',
        label: this.translatePipe.transform('expiration-modal.field--institutionId'),
        required: true,
        searchDisabled: true,
        options: [
          {id: item.institution?.id, text: item.institution?.vName?.genericValue}
        ],
        value: item.institution?.id
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('expirations-all.edit-expiration'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.remindUpdateGQL.mutate({
          remindId: item.id,
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          const foundIndex = this.processedData[ProcessedDataKinds.EXPIRING_REMINDS].findIndex((o: Remind) => o.id === item.id);
          if (foundIndex !== -1) {
            this.processedData[ProcessedDataKinds.EXPIRING_REMINDS][foundIndex] = {...res.data?.remindUpdate};
          }
          this.snackBar.open(this.translatePipe.transform('expiration-all.edit-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  openAddNewExpirationRemindModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'description',
        type: 'text',
        label: this.translatePipe.transform('expiration-modal.field--description'),
        required: true
      },
      {
        fieldKey: 'date',
        type: 'date',
        label: this.translatePipe.transform('expiration-modal.field--date'),
        required: true
      },
      // {
      //   type: 'currency',
      //   label: 'test currency 1',
      //   fieldKey: 'amount'
      // },
      // {
      //   type: 'currency',
      //   label: 'test currency 2',
      //   fieldKey: 'amount2',
      //   value: 2312,
      //   disabled: true
      // },
      {
        fieldKey: 'institutionId',
        type: 'autocomplete',
        label: this.translatePipe.transform('expiration-modal.field--institutionId'),
        required: true,
        searchDisabled: true, // prefill value but not disable field, just disable autocomplete search.
        options: [
          {id: this.institutionId, text: this.institutionData.vName?.attributeValue}
        ],
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('expirations-all.add-expiration'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.remindCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.remindCreate) {
            this.processedData[ProcessedDataKinds.EXPIRING_REMINDS] = [res.data.remindCreate, ...this.processedData[ProcessedDataKinds.EXPIRING_REMINDS]];
            this.snackBar.open(this.translatePipe.transform('expiration-all.create-success'), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          }
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  // Create/Edit/Delete modal things handled by a list item action (filterable-table)

  private openDeleteItemModalByType(idToDelete: number, detailsType: DetailsFormTypes): void {

    let deleteQueryName = '';
    // Deletables
    switch (detailsType) {
      case DetailsFormTypes.QUOTE:
        deleteQueryName = 'quoteDelete';
        break;
      case DetailsFormTypes.PARTNERSHIP:
        deleteQueryName = 'partnershipDelete';
        break;
      case DetailsFormTypes.DOCUMENT_ATTACHMENT:
        deleteQueryName = 'attachmentDelete';
        break;
      case DetailsFormTypes.DOCUMENT_DELIBERATION:
        deleteQueryName = 'deliberationDelete';
        break;
    }

    if (!deleteQueryName) {
      throw new Error('Cannot delete this item of type: ' + detailsType);
    }

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        // @ts-ignore I know what I am doing so shut up
        this[`${deleteQueryName}GQL`].mutate({
          id: idToDelete,
        }, {fetchPolicy: 'no-cache'}).subscribe((res: any) => {
          if (res.data?.[deleteQueryName].success === OperationReturnTypeEnum.Ok) {
            switch (detailsType) {
              case DetailsFormTypes.QUOTE:
                this.quotesTableConfig.list = this.quotesTableConfig.list.filter(d => d.id !== idToDelete);
                break;
              case DetailsFormTypes.PARTNERSHIP:
                this.partnershipsTableConfig.list = this.partnershipsTableConfig.list.filter(d => d.id !== idToDelete);
                this.onPartnershipsListUpdated();
                break;
              case DetailsFormTypes.DOCUMENT_ATTACHMENT:
                // this.documentsAttachmentsTableConfig.list = this.documentsAttachmentsTableConfig.list.filter(d => d.id !== idToDelete);
                // todo unfortunately, needed to do that in order to refresh attachments displayed in general info section. find a better alternative tho
                this.fetchInstitutionById(this.institutionId, true);
                break;
              case DetailsFormTypes.DOCUMENT_DELIBERATION:
                // this.documentsDeliberationsTableConfig.list = this.documentsDeliberationsTableConfig.list.filter(d => d.id !== idToDelete);
                // todo unfortunately, needed to do that in order to refresh attachments displayed in general info section. find a better alternative tho
                this.fetchInstitutionById(this.institutionId, true);
                break;
            }
          }
          this.snackBar.open(this.translatePipe.transform('delete-item-confirmation-modal.delete-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, (error: any) => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  openAddNewAttachmentModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'attachmentType',
        type: 'select',
        label: this.translatePipe.transform('attachment-modal.field--attachmentType'),
        width: 6,
        options: this.getAttachmentTypesForOptions(),
        required: true
      },
      // {
      //   fieldKey: 'name',
      //   type: 'text',
      //   label: this.translatePipe.transform('attachment-modal.field--name'),
      //   required: false
      // },
      {
        fieldKey: 'description',
        type: 'text',
        label: this.translatePipe.transform('attachment-modal.field--description'),
        required: true
      },
      {
        fieldKey: 'validFrom',
        width: 6,
        type: 'date',
        label: this.translatePipe.transform('attachment-modal.field--validFrom')
      },
      {
        fieldKey: 'expiryDate',
        width: 6,
        type: 'date',
        label: this.translatePipe.transform('attachment-modal.field--expiryDate')
      },
      {
        fieldKey: 'attachedDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('attachment-modal.field--attachedDocument'),
        required: true
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('attachment-modal.field--note'),
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        stickyFooter: true,
        hasClose: true,
        title: this.translatePipe.transform('attachment-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.attachmentCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          // if (res.data?.attachmentCreate) {
          // tslint:disable-next-line:max-line-length
          //   this.documentsAttachmentsTableConfig.list = [this.getAttachmentDataForTableItem((res.data.attachmentCreate) as Attachment), ...this.documentsAttachmentsTableConfig.list];
          // }
          // todo unfortunately, needed to do that in order to refresh attachments displayed in general info section. find a better alternative tho
          this.fetchInstitutionById(this.institutionId, true);

          this.snackBar.open(this.translatePipe.transform('attachment-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openEditAttachmentModal(itemId: string): void {
    this.attachmentGQL.fetch({id: itemId}, {fetchPolicy: 'no-cache'}).subscribe(attachmentRes => {
      const fieldsConf: ModalField[] = [
        {
          fieldKey: 'attachmentType',
          type: 'select',
          label: this.translatePipe.transform('attachment-modal.field--attachmentType'),
          width: 6,
          options: this.getAttachmentTypesForOptions(),
          required: true,
          value: attachmentRes.data?.attachment?.attachmentType
        },
        // {
        //   fieldKey: 'name',
        //   type: 'text',
        //   label: this.translatePipe.transform('attachment-modal.field--name'),
        //   required: false,
        //   value: attachmentRes.data?.attachment?.name
        // },
        {
          fieldKey: 'description',
          type: 'text',
          label: this.translatePipe.transform('attachment-modal.field--description'),
          required: true,
          value: attachmentRes.data?.attachment?.description
        },
        {
          fieldKey: 'validFrom',
          width: 6,
          type: 'date',
          label: this.translatePipe.transform('attachment-modal.field--validFrom'),
          value: attachmentRes.data?.attachment?.validFrom
        },
        {
          fieldKey: 'expiryDate',
          width: 6,
          type: 'date',
          label: this.translatePipe.transform('attachment-modal.field--expiryDate'),
          value: attachmentRes.data?.attachment?.expiryDate
        },
        {
          fieldKey: 'attachedDocument',
          type: 'file-picker-pdf',
          label: this.translatePipe.transform('attachment-modal.field--attachedDocument'),
          required: true,
          value: attachmentRes.data?.attachment?.attachedDocument?.url ? {url: attachmentRes.data.attachment.attachedDocument.url} : undefined,
        },
        {
          fieldKey: 'note',
          type: 'textarea',
          label: this.translatePipe.transform('attachment-modal.field--note'),
          value: attachmentRes.data?.attachment?.note
        },
        {
          fieldKey: 'institutionId',
          type: 'text-readonly',
          hidden: true,
          value: this.institutionId
        }
      ];

      const matDialogRef = this.dialog.open(BaseModalComponent, {
        ...getFixedSmallModalSettings(),
        disableClose: false,
        outputOnCancel: true,
        data: {
          stickyHeader: true,
          stickyFooter: true,
          hasClose: true,
          title: this.translatePipe.transform('attachment-modal.edit-title'),
          fields: fieldsConf
        }
      });

      matDialogRef.afterClosed().subscribe(fieldsModel => {
        if (fieldsModel) {
          fieldsModel.validFrom = !fieldsModel.validFrom ? null : fieldsModel.validFrom;
          fieldsModel.expiryDate = !fieldsModel.expiryDate ? null : fieldsModel.expiryDate;
          this.attachmentUpdateGQL.mutate({
            attachmentId: itemId,
            input: fieldsModel
          }, {fetchPolicy: 'no-cache'}).subscribe(res => {
            // if (res.data?.attachmentUpdate) {
            //   const foundIndex = this.documentsAttachmentsTableConfig.list.findIndex((d: any) => d.id === itemId);
            //   if (foundIndex !== -1) {
            //     this.documentsAttachmentsTableConfig.list[foundIndex] = this.getAttachmentDataForTableItem((res.data.attachmentUpdate) as Attachment);
            //     this.documentsAttachmentsTableConfig.list = [...this.documentsAttachmentsTableConfig.list]; // needed that to trigger angular update.
            //   }
            // }
            // todo unfortunately, needed to do that in order to refresh attachments displayed in general info section. find a better alternative tho
            this.fetchInstitutionById(this.institutionId, true);
            this.snackBar.open(this.translatePipe.transform('attachment-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          }, error => {
            this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          });
        }
      });
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }

  openAddNewDeliberationModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'deliberationType',
        type: 'select',
        label: this.translatePipe.transform('deliberation-modal.field--deliberationType'),
        width: 6,
        options: this.getDeliberationTypesForOptions(),
        required: true
      },
      {
        fieldKey: 'description',
        type: 'select',
        label: this.translatePipe.transform('deliberation-modal.field--description'),
        width: 6,
        options: this.getDeliberationDescriptionsForOptions(),
        required: true
      },
      {
        fieldKey: 'date',
        type: 'date',
        width: 6,
        required: true,
        label: this.translatePipe.transform('deliberation-modal.field--date')
      },
      {
        fieldKey: 'deliberationDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('deliberation-modal.field--deliberationDocument'),
        required: true
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('deliberation-modal.field--note'),
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('deliberation-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.deliberationCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          // if (res.data?.deliberationCreate) {
          //   tslint:disable-next-line:max-line-length
          //   this.documentsDeliberationsTableConfig.list = [this.getDeliberationDataForTableItem((res.data.deliberationCreate) as Deliberation), ...this.documentsDeliberationsTableConfig.list];
          // }
          // todo unfortunately, needed to do that in order to refresh attachments displayed in general info section. find a better alternative tho
          this.fetchInstitutionById(this.institutionId, true);
          this.snackBar.open(this.translatePipe.transform('deliberation-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openEditDeliberationModal(itemId: string): void {
    this.deliberationGQL.fetch({id: itemId}, {fetchPolicy: 'no-cache'}).subscribe(deliberationRes => {
      const fieldsConf: ModalField[] = [
        {
          fieldKey: 'deliberationType',
          type: 'select',
          label: this.translatePipe.transform('deliberation-modal.field--deliberationType'),
          width: 6,
          options: this.getDeliberationTypesForOptions(),
          required: true,
          value: deliberationRes.data?.deliberation?.deliberationType
        },
        {
          fieldKey: 'description',
          type: 'select',
          label: this.translatePipe.transform('deliberation-modal.field--description'),
          width: 6,
          options: this.getDeliberationDescriptionsForOptions(),
          required: true,
          value: deliberationRes.data?.deliberation?.description
        },
        {
          fieldKey: 'date',
          type: 'date',
          width: 6,
          required: true,
          label: this.translatePipe.transform('deliberation-modal.field--date'),
          value: deliberationRes.data?.deliberation?.date
        },
        {
          fieldKey: 'deliberationDocument',
          type: 'file-picker-pdf',
          label: this.translatePipe.transform('deliberation-modal.field--deliberationDocument'),
          required: true,
          value: deliberationRes.data?.deliberation?.deliberationDocument?.url ? {url: deliberationRes.data.deliberation.deliberationDocument.url} : undefined,
        },
        {
          fieldKey: 'note',
          type: 'textarea',
          label: this.translatePipe.transform('deliberation-modal.field--note'),
          value: deliberationRes.data?.deliberation?.note
        },
        {
          fieldKey: 'institutionId',
          type: 'text-readonly',
          hidden: true,
          value: this.institutionId
        }
      ];

      const matDialogRef = this.dialog.open(BaseModalComponent, {
        ...getFixedSmallModalSettings(),
        disableClose: false,
        outputOnCancel: true,
        data: {
          stickyHeader: true,
          stickyFooter: true,
          hasClose: true,
          title: this.translatePipe.transform('deliberation-modal.edit-title'),
          fields: fieldsConf
        }
      });

      matDialogRef.afterClosed().subscribe(fieldsModel => {
        if (fieldsModel) {
          this.deliberationUpdateGQL.mutate({
            deliberationId: itemId,
            input: fieldsModel
          }, {fetchPolicy: 'no-cache'}).subscribe(res => {
            // if (res.data?.deliberationUpdate) {
            //   const foundIndex = this.documentsDeliberationsTableConfig.list.findIndex((d: any) => d.id === itemId);
            //   if (foundIndex !== -1) {
            //     this.documentsDeliberationsTableConfig.list[foundIndex] = this.getDeliberationDataForTableItem((res.data.deliberationUpdate) as Deliberation);
            //     this.documentsDeliberationsTableConfig.list = [...this.documentsDeliberationsTableConfig.list]; // needed that to trigger angular update.
            //   }
            // }
            // todo unfortunately, needed to do that in order to refresh attachments displayed in general info section. find a better alternative tho
            this.fetchInstitutionById(this.institutionId, true);

            this.snackBar.open(this.translatePipe.transform('deliberation-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          }, error => {
            this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          });
        }
      });
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }

  private getPartnershipPersonKindsForOptions(): { id: string, text: string }[] {
    return Object.values(PartnershipPersonKind).map(value => ({
      id: value,
      text: this.translatePipe.transform('institution-details.personKind--' + value)
    }));
  }

  openAddNewPartnershipModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'partnerName',
        type: 'text',
        label: this.translatePipe.transform('partnership-modal.field--partnerName'),
        required: true
      },
      {
        fieldKey: 'quote',
        type: 'number',
        minNumber: 0,
        maxNumber: 100,
        width: 6,
        label: this.translatePipe.transform('partnership-modal.field--quote'),
        required: true
      },
      {
        fieldKey: 'nominalValue',
        type: 'currency',
        width: 6,
        label: this.translatePipe.transform('partnership-modal.field--nominalValue')
      },
      {
        fieldKey: 'proposer',
        type: 'select',
        width: 6,
        required: true,
        label: this.translatePipe.transform('partnership-modal.field--proposer'),
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ]
      },
      {
        fieldKey: 'personKind',
        type: 'select',
        width: 6,
        label: this.translatePipe.transform('partnership-modal.field--personKind'),
        options: [...this.getPartnershipPersonKindsForOptions()],
        required: true
      },
      {
        fieldKey: 'startDate',
        type: 'date',
        maxDateConstraint: new Date(),
        width: 6,
        label: this.translatePipe.transform('partnership-modal.field--startDate')
      },
      {
        fieldKey: 'endDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('partnership-modal.field--endDate')
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('partnership-modal.field--note'),
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('partnership-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.partnershipCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.partnershipCreate) {
            // tslint:disable-next-line:max-line-length
            this.partnershipsTableConfig.list = [this.getPartnershipDataForTableItem((res.data.partnershipCreate) as Partnership), ...this.partnershipsTableConfig.list];
            this.onPartnershipsListUpdated();
          }
          this.snackBar.open(this.translatePipe.transform('partnership-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openEditPartnershipModal(itemId: string): void {
    this.partnershipGQL.fetch({id: itemId}, {fetchPolicy: 'no-cache'}).subscribe(partnershipRes => {
      const fieldsConf: ModalField[] = [
        {
          fieldKey: 'partnerName',
          type: 'text',
          label: this.translatePipe.transform('partnership-modal.field--partnerName'),
          required: true,
          value: partnershipRes.data?.partnership?.partnerName
        },
        {
          fieldKey: 'quote',
          type: 'number',
          width: 6,
          minNumber: 0,
          maxNumber: 100,
          label: this.translatePipe.transform('partnership-modal.field--quote'),
          required: true,
          value: partnershipRes.data?.partnership?.quote
        },
        {
          fieldKey: 'nominalValue',
          type: 'currency',
          width: 6,
          label: this.translatePipe.transform('partnership-modal.field--nominalValue'),
          value: partnershipRes.data?.partnership?.nominalValue
        },
        {
          fieldKey: 'proposer',
          type: 'select',
          required: true,
          width: 6,
          label: this.translatePipe.transform('partnership-modal.field--proposer'),
          options: [
            {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
            {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
          ],
          value: partnershipRes.data?.partnership?.proposer
        },
        {
          fieldKey: 'personKind',
          type: 'select',
          label: this.translatePipe.transform('partnership-modal.field--personKind'),
          options: [...this.getPartnershipPersonKindsForOptions()],
          required: true,
          width: 6,
          value: partnershipRes.data?.partnership?.personKind
        },
        {
          fieldKey: 'startDate',
          type: 'date',
          maxDateConstraint: new Date(),
          width: 6,
          label: this.translatePipe.transform('partnership-modal.field--startDate'),
          value: partnershipRes.data?.partnership?.startDate
        },
        {
          fieldKey: 'endDate',
          type: 'date',
          width: 6,
          label: this.translatePipe.transform('partnership-modal.field--endDate'),
          value: partnershipRes.data?.partnership?.endDate
        },
        {
          fieldKey: 'note',
          type: 'textarea',
          label: this.translatePipe.transform('partnership-modal.field--note'),
          value: partnershipRes.data?.partnership?.note
        },
        {
          fieldKey: 'institutionId',
          type: 'text-readonly',
          hidden: true,
          value: this.institutionId
        }
      ];

      const matDialogRef = this.dialog.open(BaseModalComponent, {
        ...getFixedSmallModalSettings(),
        disableClose: false,
        outputOnCancel: true,
        data: {
          stickyHeader: true,
          stickyFooter: true,
          hasClose: true,
          title: this.translatePipe.transform('partnership-modal.edit-title'),
          fields: fieldsConf
        }
      });

      matDialogRef.afterClosed().subscribe(fieldsModel => {
        if (fieldsModel) {
          fieldsModel.startDate = !fieldsModel.startDate ? null : fieldsModel.startDate;
          fieldsModel.endDate = !fieldsModel.endDate ? null : fieldsModel.endDate;
          this.partnershipUpdateGQL.mutate({
            partnershipId: itemId,
            input: fieldsModel
          }, {fetchPolicy: 'no-cache'}).subscribe(res => {
            if (res.data?.partnershipUpdate) {
              const foundIndex = this.partnershipsTableConfig.list.findIndex((d: any) => d.id === itemId);
              if (foundIndex !== -1) {
                this.partnershipsTableConfig.list[foundIndex] = this.getPartnershipDataForTableItem((res.data.partnershipUpdate) as Partnership);
                this.partnershipsTableConfig.list = [...this.partnershipsTableConfig.list]; // needed that to trigger angular update.
                this.onPartnershipsListUpdated();
              }
            }
            this.snackBar.open(this.translatePipe.transform('partnership-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          }, error => {
            this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          });
        }
      });
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }

  openAddNewQuoteModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'amount',
        type: 'currency',
        width: 6,
        label: this.translatePipe.transform('quote-modal.field--amount'),
        required: true
      },
      {
        fieldKey: 'membershipQuote',
        type: 'select',
        label: this.translatePipe.transform('quote-modal.field--membershipQuote'),
        required: true,
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ],
        width: 6
      },
      {
        fieldKey: 'paymentFrequency',
        type: 'select',
        label: this.translatePipe.transform('quote-modal.field--paymentFrequency'),
        required: true,
        options: [
          {
            id: PaymentFrequencyEnum.Annual,
            text: this.translatePipe.transform('institution-details.quotes-payment-frequency--' + PaymentFrequencyEnum.Annual)
          },
          {
            id: PaymentFrequencyEnum.UnaTantum,
            text: this.translatePipe.transform('institution-details.quotes-payment-frequency--' + PaymentFrequencyEnum.UnaTantum)
          }
        ],
        width: 6
      },
      {
        fieldKey: 'uniIsReceiver',
        type: 'select',
        label: this.translatePipe.transform('quote-modal.field--uniIsReceiver'),
        required: true,
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ],
        width: 6
      },
      {
        fieldKey: 'ua',
        type: 'text',
        label: this.translatePipe.transform('quote-modal.field--ua')
      },
      {
        fieldKey: 'coanAccount',
        type: 'text',
        label: this.translatePipe.transform('quote-modal.field--coanAccount')
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('quote-modal.field--note'),
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('quote-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.quoteCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.quoteCreate) {
            // tslint:disable-next-line:max-line-length
            this.quotesTableConfig.list = [this.getQuoteDataForTableItem((res.data.quoteCreate) as Quote), ...this.quotesTableConfig.list];
          }
          this.snackBar.open(this.translatePipe.transform('quote-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openEditQuoteModal(itemId: string): void {
    this.quoteGQL.fetch({id: itemId}, {fetchPolicy: 'no-cache'}).subscribe(quoteRes => {
      const fieldsConf: ModalField[] = [
        {
          fieldKey: 'amount',
          type: 'currency',
          width: 6,
          label: this.translatePipe.transform('quote-modal.field--amount'),
          required: true,
          value: quoteRes.data?.quote?.amount
        },
        {
          fieldKey: 'membershipQuote',
          type: 'select',
          label: this.translatePipe.transform('quote-modal.field--membershipQuote'),
          required: true,
          options: [
            {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
            {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
          ],
          width: 6,
          value: quoteRes.data?.quote?.membershipQuote
        },
        {
          fieldKey: 'paymentFrequency',
          type: 'select',
          label: this.translatePipe.transform('quote-modal.field--paymentFrequency'),
          required: true,
          options: [
            {
              id: PaymentFrequencyEnum.Annual,
              text: this.translatePipe.transform('institution-details.quotes-payment-frequency--' + PaymentFrequencyEnum.Annual)
            },
            {
              id: PaymentFrequencyEnum.UnaTantum,
              text: this.translatePipe.transform('institution-details.quotes-payment-frequency--' + PaymentFrequencyEnum.UnaTantum)
            }
          ],
          width: 6,
          value: quoteRes.data?.quote?.paymentFrequency
        },
        {
          fieldKey: 'uniIsReceiver',
          type: 'select',
          label: this.translatePipe.transform('quote-modal.field--uniIsReceiver'),
          required: true,
          options: [
            {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
            {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
          ],
          width: 6,
          value: quoteRes.data?.quote?.uniIsReceiver
        },
        {
          fieldKey: 'ua',
          type: 'text',
          label: this.translatePipe.transform('quote-modal.field--ua'),
          value: quoteRes.data?.quote?.ua
        },
        {
          fieldKey: 'coanAccount',
          type: 'text',
          label: this.translatePipe.transform('quote-modal.field--coanAccount'),
          value: quoteRes.data?.quote?.coanAccount
        },
        {
          fieldKey: 'note',
          type: 'textarea',
          label: this.translatePipe.transform('quote-modal.field--note'),
          value: quoteRes.data?.quote?.note
        },
        {
          fieldKey: 'institutionId',
          type: 'text-readonly',
          hidden: true,
          value: this.institutionId
        }
      ];

      const matDialogRef = this.dialog.open(BaseModalComponent, {
        ...getFixedSmallModalSettings(),
        disableClose: false,
        outputOnCancel: true,
        data: {
          stickyHeader: true,
          stickyFooter: true,
          hasClose: true,
          title: this.translatePipe.transform('quote-modal.edit-title'),
          fields: fieldsConf
        }
      });

      matDialogRef.afterClosed().subscribe(fieldsModel => {
        if (fieldsModel) {
          this.quoteUpdateGQL.mutate({
            quoteId: itemId,
            input: fieldsModel
          }, {fetchPolicy: 'no-cache'}).subscribe(res => {
            if (res.data?.quoteUpdate) {
              const foundIndex = this.quotesTableConfig.list.findIndex((d: any) => d.id === itemId);
              if (foundIndex !== -1) {
                this.quotesTableConfig.list[foundIndex] = this.getQuoteDataForTableItem((res.data.quoteUpdate) as Quote);
                this.quotesTableConfig.list = [...this.quotesTableConfig.list]; // needed that to trigger angular update.
              }
            }
            this.snackBar.open(this.translatePipe.transform('quote-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          }, error => {
            this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          });
        }
      });
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }

  onCreateCurrentAnnualAccountReportClick(): void {
    const year = this.selectedVersionedDate ? this.selectedVersionedDate.getFullYear() : this.todayDate.getFullYear();
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'year',
        type: 'text-readonly',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--year'),
        value: year
      },
      {
        fieldKey: 'dateOfApproval',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--dateOfApproval')
      },
      {
        fieldKey: 'approvalDocument',
        width: 6,
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('annual-account-modal.field--approvalDocument')
      },
      {
        fieldKey: 'supplementaryNoteDocument',
        width: 6,
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('annual-account-modal.field--supplementaryNoteDocument')
      },
      {
        fieldKey: 'controlBodyReportDocument',
        width: 6,
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('annual-account-modal.field--controlBodyReportDocument')
      },
      {
        fieldKey: 'consolidatedFinancialReport',
        type: 'select',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--consolidatedFinancialReport'),
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ]
      },
      {
        fieldKey: 'consolidatedUniversityFinancialReport',
        type: 'select',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--consolidatedUniversityFinancialReport'),
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ]
      },
      {
        fieldKey: 'universityFinancialReport',
        type: 'select',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--universityFinancialReport'),
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ]
      },
      {
        fieldKey: 'revenue',
        type: 'currency',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--revenue')
      },
      {
        fieldKey: 'productionValue',
        type: 'currency',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--productionValue')
      },
      {
        fieldKey: 'exerciseResult',
        type: 'currency',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--exerciseResult')
      },
      {
        fieldKey: 'socialCapital',
        type: 'text',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--socialCapital')
      },
      {
        fieldKey: 'netAssets',
        type: 'currency',
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--netAssets')
      },
      {
        fieldKey: 'employeeNumber',
        type: 'number',
        minNumber: 0,
        width: 6,
        label: this.translatePipe.transform('annual-account-modal.field--employeeNumber')
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        stickyFooter: true,
        hasClose: true,
        title: this.translatePipe.transform('annual-account-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.annualAccountingReportCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.refreshAnnualAccountExtraInfo(res.data?.annualAccountingReportCreate);
          this.updateApprovables();
          this.snackBar.open(this.translatePipe.transform('annual-account-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  onSidePanelOpenModal(formType: DetailsFormTypes, id: string): void {
    switch (formType) {
      case DetailsFormTypes.QUOTE:
        this.showQuoteDetailsSidePanel = false;
        this.openEditQuoteModal(id);
        break;
      case DetailsFormTypes.ROLE:
        const referentId = this.processedData[ProcessedDataKinds.SELECTED_ROLE_DETAILS].legalBody.id;
        this.referentsAndRolesService.updateRoleDetails(referentId, this.processedData[ProcessedDataKinds.SELECTED_ROLE_DETAILS]);
        this.showRoleDetailsSidePanel = false;
        break;
      case DetailsFormTypes.PARTNERSHIP:
        this.showPartnershipDetailsSidePanel = false;
        this.openEditPartnershipModal(id);
        break;
      case DetailsFormTypes.DOCUMENT_ATTACHMENT:
        this.showDocumentAttachmentDetailsSidePanel = false;
        this.openEditAttachmentModal(id);
        break;
      case DetailsFormTypes.DOCUMENT_DELIBERATION:
        this.showDocumentDeliberationDetailsSidePanel = false;
        this.openEditDeliberationModal(id);
        break;
    }
  }

  // Versioned fields modal

  // tslint:disable-next-line:max-line-length
  openVersionedFieldModal(operationType: 'add-new' | 'edit' | 'delete', attributeName: string, versionedData?: VersionedString | VersionedFloat | VersionedInteger | VersionedUniParticipation): void {
    const matDialogRef = this.dialog.open(VersionedFieldModalComponent, {
      ...getFixedSmallModalSettings(),
      data: {
        operationType,
        attributeName,
        versionedData,
        attibuteValueSelectOptions: attributeName === 'vUniParticipation' ? Object.values(UniParticipationEnum).map(value => ({
          id: value,
          text: this.translatePipe.transform('institution-details.uniparticipation--' + value)
        })) : undefined,
        institutionData: {
          id: this.institutionId,
          isSpinoff: this.institutionData.__typename === 'Spinoff',
          versionableOptions: {onDate: moment(this.selectedVersionedDate).toISOString(true)}
        },
        // tslint:disable-next-line:max-line-length
        previousVersionedRecord: operationType !== 'delete' ? {
          ...this.institutionData[attributeName]?.previousVersionedRecords[0],
          _versionedType: this.institutionData[attributeName].__typename
        } : undefined
      }
    });

    matDialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (operationType === 'delete') {
          this.institutionData[attributeName] = null;
        } else {
          if (data.hasOwnProperty('attributeValue')) {
            // institutionUpdate case - data is already derived to specific versioned record
            this.institutionData[attributeName] = {...data};
          } else {
            // versionedRecordUpdate case - data must be derived first
            const derivedVersionedRecordData = {
              ...data,
              attributeValue: data.string || data.integer || data.float || data.uniPartecipation
            };
            this.institutionData[attributeName] = {...derivedVersionedRecordData};
          }
        }
      }
      this.updateApprovables();
      this.versionedFieldOverlayToShow = '';
    });
  }

  private updateComplexData(): void {
    let versionableOptions;
    if (this.selectedVersionedDate) {
      versionableOptions = {onDate: moment(this.selectedVersionedDate).toISOString(true)};
    }
    // @ts-ignore
    this.checkComplexDataGQL.fetch({id: this.institutionId, versionableOptions}, {fetchPolicy: 'no-cache'}).subscribe(res => {
      this.institutionData.adherentStructuresInfo = res.data.institution?.adherentStructuresInfo ? [...res.data.institution.adherentStructuresInfo] : [];
      this.institutionData.atecoActivities = res.data.institution?.atecoActivities ? [...res.data.institution.atecoActivities] : [];
    });
  }

  openAdherentStructuresModal(fieldKey: string): void {
    const matDialogRef = this.dialog.open(AdherentStructuresModalComponent, {
      ...getFixedSmallModalSettings(),
      data: {
        fieldKey,
        adherentStructures: this.institutionData.adherentStructuresInfo,
        institutionId: this.institutionId
      }
    });

    matDialogRef.afterClosed().subscribe(operationResult => {
      if (operationResult === OperationReturnTypeEnum.Ok) {
        this.updateComplexData();
        this.snackBar.open(this.translatePipe.transform('institution-details.adherent-structures-edit-succeeded'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      } else if (operationResult === OperationReturnTypeEnum.Ko) {
        this.snackBar.open(this.translatePipe.transform('institution-details.adherent-structures-edit-failed'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      }
    });
  }

  openAtecoActivitiesModal(fieldKey: string): void {
    const matDialogRef = this.dialog.open(AtecoActivitiesModalComponent, {
      ...getFixedSmallModalSettings(),
      data: {
        fieldKey,
        atecoActivities: this.institutionData.atecoActivities,
        institutionId: this.institutionId
      }
    });

    matDialogRef.afterClosed().subscribe(operationResult => {
      if (operationResult === OperationReturnTypeEnum.Ok) {
        this.updateComplexData();
        this.snackBar.open(this.translatePipe.transform('institution-details.ateco-activities-edit-succeeded'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      } else if (operationResult === OperationReturnTypeEnum.Ko) {
        this.snackBar.open(this.translatePipe.transform('institution-details.ateco-activities-edit-failed'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      }
    });
  }

  handleInstitutionStatus(): void {
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('institution-details.confirm-operation-title'),
        // tslint:disable-next-line:max-line-length
        bodyHtml: '<p>' + (this.institutionData.active ? this.translatePipe.transform('institution-details.confirm-deactivation-text') : this.translatePipe.transform('institution-details.confirm-activation-text')) + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed && this.institutionId) {
        this.institutionSetStatusGQL.mutate({
          institutionId: this.institutionId,
          status: this.institutionData.active ? InstitutionStatusEnum.Inactive : InstitutionStatusEnum.Active
        }).subscribe(res => {
          this.fetchInstitutionById(this.institutionId, true);
          this.snackBar.open(this.translatePipe.transform('institution-details.confirm-operation-succeeded'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private getPreviousEndOfYearDate(): Date {
    const currentSelectedVersionedDate = moment(this.selectedVersionedDate ? this.selectedVersionedDate : new Date()).subtract(1, 'year');
    currentSelectedVersionedDate.set('month', 11);  // December
    currentSelectedVersionedDate.set('date', 31);
    currentSelectedVersionedDate.set('hour', 0);
    currentSelectedVersionedDate.set('minute', 0);
    return currentSelectedVersionedDate.toDate();
  }

  onCreatePreviousAnnualAccountReportClick(): void {
    this.selectedVersionedDate = this.getPreviousEndOfYearDate();
    this.fetchInstitutionById(this.institutionId, true, () => {
      this.onCreateCurrentAnnualAccountReportClick();
    });
  }

  hasPreviousYearAnnualAccountReport(): boolean {
    const currentSelectedVersionedDate = moment(this.selectedVersionedDate ? this.selectedVersionedDate : new Date());
    return this.institutionData.vAnnualAccountingReports.some((a: { year: number; }) => {
      return a.year === (currentSelectedVersionedDate.year() - 1);
    });
  }

  onEditPreviousAnnualAccountReportClick(): void {
    this.selectedVersionedDate = this.getPreviousEndOfYearDate();
    this.fetchInstitutionById(this.institutionId, true, () => {
      this.onEnableEditing('accountReportExtraInfo');
    });
  }
}
