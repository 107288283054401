<div class="referents-and-roles"
     [class.referents-and-roles--is-admin]="isAdmin">
  <div class="referents-and-roles__add-new" *ngIf="!isAdmin && !isViewer">
    <button mat-stroked-button
            color="primary"
            (click)="onAddNewReferentClick()"
            class="mat-elevation-z1">
      <mat-icon aria-hidden="false" [attr.aria-label]="('referents-and-roles.add-new-referent' | translate)">add_circle_outline</mat-icon>
      {{'referents-and-roles.add-new-referent' | translate}}
    </button>
  </div>
  <div class="referents-and-roles__container-item" *ngFor="let item of listData; index as i">
    <div class="referents-and-roles__referent-title">
      <div>
        <h2>{{item.bodyType !== legalBodyTypes.OtherBody ? ('referents-and-roles.body-type--' + item.bodyType | translate) : item.name}}</h2>
        <div *ngIf="item.bodyType !== legalBodyTypes.OtherBody && item.bodyType !== legalBodyTypes.LegalRepresentative"
             class="referent-subtype">
          {{ ('referents-and-roles.body-subtype--' + (item.administrativeBodyType || item.advisoryBodyType || item.controlBodyType || item.steeringBodyType)) | translate }}
        </div>
      </div>
      <div *ngIf="!isAdmin && !isViewer">
        <button mat-stroked-button
                color="primary"
                (click)="onAddNewRoleClick(item.id)"
                class="mat-elevation-z1 button-spacing-right-8">
          <mat-icon aria-hidden="false" [attr.aria-label]="('referents-and-roles.add-new-role' | translate)">person_add</mat-icon>
          {{'referents-and-roles.add-new-role' | translate}}
        </button>
        <div class="referents-and-roles__actions">
          <button mat-stroked-button
                  color="primary"
                  (click)="referentOverlayIndexToShow = i"
                  class="mat-elevation-z1 only-icon">
            <mat-icon aria-hidden="false" [attr.aria-label]="'azioni'">more_horiz</mat-icon>
          </button>
          <div class="info-backdrop-overlay" *ngIf="referentOverlayIndexToShow !== -1"
               (click)="referentOverlayIndexToShow = -1"></div>
          <div class="info-overlay" *ngIf="referentOverlayIndexToShow === i">
            <p (click)="openEditReferentModal(item)">{{'info-overlay.action-edit' | translate}}</p>
            <p (click)="openDeleteReferentModal(item.id)" class="delete-text">{{'info-overlay.action-delete' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="referents-and-roles__referent-info">
      <p>{{'referents-and-roles.referent-expected-employee-number' | translate}}&nbsp;{{item.expectedEmployeeNumber || '—'}}</p>
      <p>{{'referents-and-roles.referent-expiry-date' | translate}}&nbsp;{{item.expiryDate ? (item.expiryDate | date:'dd/MM/yyyy') : '—'}}</p>
      <p>{{'referents-and-roles.referent-duration' | translate:{duration: item.duration || '—'} }}</p>
    </div>
    <div class="referents-and-roles__role-item"
         *ngFor="let role of item.groupedRoles; index as j"
         [class.referents-and-roles__role-item--not-valid]="!role.approved">
      <div class="person-column">
        <div>
          <a
            [routerLink]="['/persone', role.person?.id]">{{role.person?.name || '—'}}</a>
          <p>C.F. {{role.person?.fiscalCode}}</p>
        </div>
      </div>
      <div>
        <h4>{{'referents-and-roles.role' | translate}}</h4>
        <p>{{('institution-details.roleType--' + role.roleType) | translate}}</p>
      </div>
      <div>
        <h4>{{'referents-and-roles.uni-nominee' | translate}}</h4>
        <p>{{role.uniNominee ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}</p>
      </div>
      <div>
        <h4>{{'referents-and-roles.start-date' | translate}}</h4>
        <p>{{role.startDate ? (role.startDate | date: 'dd/MM/yyyy') : '—'}}</p>
      </div>
      <div>
        <h4>{{'referents-and-roles.expected-end-date' | translate}}</h4>
        <p>{{role.expectedEndDate ? (role.expectedEndDate | date: 'dd/MM/yyyy') : '—'}}</p>
      </div>
      <div>
        <h4>{{'referents-and-roles.end-date' | translate}}</h4>
        <p>{{role.endDate ? (role.endDate | date: 'dd/MM/yyyy') : '—'}}</p>
      </div>
      <!-- azioni -->
      <div class="referents-and-roles__actions">
        <button mat-stroked-button
                *ngIf="isAdmin && !role.approved"
                color="primary"
                (click)="validateRoleItem(role.id)"
                class="mat-elevation-z1 only-icon validate-single-field">
          <mat-icon aria-hidden="false" [attr.aria-label]="'validate action'">done_all</mat-icon>
        </button>
        <et-admin-unapprove-field
          *ngIf="isAdmin && role.approved"
          [fieldData]="{id: role.id, approvableType: roleApprovableType}"
          (unapproveFieldSucceeded)="refreshRoleApprovableField($event)"></et-admin-unapprove-field>
        <button mat-icon-button color="primary" aria-label="azioni" (click)="roleOverlayIndexToShow = (+item.id + j)">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <div class="info-backdrop-overlay" *ngIf="roleOverlayIndexToShow !== -1"
             (click)="roleOverlayIndexToShow = -1"></div>
        <div class="info-overlay" *ngIf="roleOverlayIndexToShow === (+item.id+j)">
          <p (click)="onViewDetailsClick(item.id, role.id)">{{'info-overlay.action-view-details' | translate}}</p>
          <ng-container *ngIf="!isAdmin && !isViewer">
            <p (click)="openEditRoleModal(item.id, role)">{{'info-overlay.action-edit' | translate}}</p>
            <p (click)="openDeleteRoleModal(item.id, role.id)" class="delete-text">{{'info-overlay.action-delete' | translate}}</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <p *ngIf="!listData?.length">{{'referents-and-roles.no-data' | translate}}</p>
</div>
