import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  CompensationCreateGQL,
  CompensationDeleteGQL,
  CompensationUpdateGQL,
  OperationReturnTypeEnum,
  RoleCompensationsGQL
}                                                             from '../../../generated/graphql';
import { MatDialog }                                          from '@angular/material/dialog';
import { TranslatePipe }                                      from '@ngx-translate/core';
import { MatSnackBar }                                        from '@angular/material/snack-bar';
import {
  BaseModalComponent,
  ModalField
}                                                             from '../../shared/base-modal/base-modal.component';
import {
  getErrorMessageFromData,
  getFixedSmallModalSettings
}                                                             from '../../shared/utils/helpers';

@Component({
  selector: 'et-role-compensations',
  templateUrl: './role-compensations.component.html',
  styleUrls: ['./role-compensations.component.scss']
})
export class RoleCompensationsComponent implements OnInit, OnChanges {

  @Input() roleId!: string;
  @Input() hasActions?: boolean;

  roleCompensations: any[];
  infoOverlayIndexToShow = -1;

  constructor(private dialog: MatDialog,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar,
              private roleCompensationsGQL: RoleCompensationsGQL,
              private compensationCreateGQL: CompensationCreateGQL,
              private compensationUpdateGQL: CompensationUpdateGQL,
              private compensationDeleteGQL: CompensationDeleteGQL) {
    this.roleCompensations = [];
  }

  ngOnInit(): void {
  }

  private fetchRoleCompensations(): void {
    this.roleCompensationsGQL.fetch({id: this.roleId}, {fetchPolicy: 'no-cache'}).subscribe(res => {
      this.roleCompensations = res.data?.role?.compensations ? res.data.role.compensations : [];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.roleId) {
      this.fetchRoleCompensations();
    }
  }

  onAddNewCompensationClick(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'year',
        type: 'number',
        minNumber: 1800,
        width: 6,
        label: this.translatePipe.transform('compensation-modal.field--year'),
        required: true
      },
      {
        fieldKey: 'compensation',
        type: 'currency',
        label: this.translatePipe.transform('compensation-modal.field--compensation'),
        required: true
      },
      {
        fieldKey: 'compensationNature',
        type: 'text',
        label: this.translatePipe.transform('compensation-modal.field--compensationNature'),
        required: true
      },
      // {
      //   fieldKey: 'note',
      //   type: 'textarea',
      //   label: this.translatePipe.transform('compensation-modal.field--note'),
      // },
      {
        fieldKey: 'roleId',
        type: 'text-readonly',
        hidden: true,
        value: this.roleId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('compensation-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.compensationCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.compensationCreate) {
            this.roleCompensations = [...this.roleCompensations, res.data?.compensationCreate];
          }
          this.snackBar.open(this.translatePipe.transform('compensation-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openEditCompensationModal(item: any): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'year',
        type: 'number',
        minNumber: 1800,
        width: 6,
        label: this.translatePipe.transform('compensation-modal.field--year'),
        required: true,
        value: item.year
      },
      {
        fieldKey: 'compensation',
        type: 'currency',
        label: this.translatePipe.transform('compensation-modal.field--compensation'),
        required: true,
        value: item.compensation
      },
      {
        fieldKey: 'compensationNature',
        type: 'text',
        label: this.translatePipe.transform('compensation-modal.field--compensationNature'),
        required: true,
        value: item.compensationNature
      },
      // {
      //   fieldKey: 'note',
      //   type: 'textarea',
      //   label: this.translatePipe.transform('compensation-modal.field--note'),
      //   value: item.note
      // },
      {
        fieldKey: 'roleId',
        type: 'text-readonly',
        hidden: true,
        value: this.roleId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('compensation-modal.edit-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.compensationUpdateGQL.mutate({
          compensationId: item.id,
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.compensationUpdate) {
            const foundIndex = this.roleCompensations.findIndex((d: any) => d.id === item.id);
            if (foundIndex !== -1) {
              this.roleCompensations[foundIndex] = {...res.data.compensationUpdate};
              this.roleCompensations = [...this.roleCompensations]; // needed that to trigger angular update.
            }
          }
          this.snackBar.open(this.translatePipe.transform('compensation-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openDeleteCompensationModal(compensationId: string): void {
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.compensationDeleteGQL.mutate({
          id: compensationId,
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.compensationDelete.success === OperationReturnTypeEnum.Ok) {
            this.roleCompensations = this.roleCompensations.filter(p => p.id !== compensationId);
          }
          this.snackBar.open(this.translatePipe.transform('delete-item-confirmation-modal.delete-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, (error: any) => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  onActionButtonClick(actionName: 'update' | 'delete', item: any): void {
    if (actionName === 'update') {
      this.openEditCompensationModal(item);
    } else if (actionName === 'delete') {
      this.openDeleteCompensationModal(item.id);
    }
    this.infoOverlayIndexToShow = -1;
  }

}
