<div class="app-wrapper"
     [class.app-wrapper--authenticated]="userService.user && userService.user.confirmedAt"
     [class.app-wrapper--show-on-lowres]="mobileForced"
     cdkScrollable>
  <ng-container
    *ngIf="userService.user && userService.user.confirmedAt">
    <et-header></et-header>
  </ng-container>
  <div class="router-wrapper">
    <router-outlet style="display: none"></router-outlet>
  </div>
</div>

<div *ngIf="!mobileForced" class="mobile-brokenpoint-warning">
  <img src="../../assets/images/entity-logo.svg"/>
  <p>{{ 'app.mobile-brokenpoint-warning-text' | translate}}</p>
  <a (click)="mobileForced = true">{{ 'app.mobile-brokenpoint-warning-proceed' | translate}}</a>
</div>

<div class="logout-overlay" [class.logout-overlay--activated]="userService.showOverlay">
  <img src="../../assets/images/entity-logo.svg"/>
</div>
