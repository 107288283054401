<div class="admin-unapprove-field">
  <button mat-stroked-button
          color="primary"
          (click)="overlayToShow = fieldData.id"
          class="mat-elevation-z1 only-icon">
    <mat-icon aria-hidden="false" [attr.aria-label]="'annulla validato'">done_all</mat-icon>
    <mat-icon aria-hidden="false" [attr.aria-label]="'annulla validato'">arrow_drop_down</mat-icon>
  </button>
  <div class="info-backdrop-overlay" *ngIf="overlayToShow !== ''"
       (click)="overlayToShow = ''"></div>
  <div class="info-overlay" *ngIf="overlayToShow === fieldData.id">
      <p (click)="onUnapproveFieldClick()">{{'admin-unapprove-field.action-unapprove' | translate}}</p>
  </div>
</div>
