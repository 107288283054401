import { NgModule }        from '@angular/core';
import { CommonModule }    from '@angular/common';
import { SignInComponent } from './sign-in.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingModule }   from '../shared/loading/loading.module';
import { TranslateModule }                  from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule }                   from '@angular/material/input';
import { MatIconModule }   from '@angular/material/icon';


@NgModule({
  declarations: [SignInComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    LoadingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class SignInModule {
}
