import { NgModule }                   from '@angular/core';
import { CommonModule }               from '@angular/common';
import { DepartmentDetailsComponent } from './department-details.component';
import { TranslateModule }            from '@ngx-translate/core';
import { RouterModule }               from '@angular/router';
import { LoadingModule }              from '../shared/loading/loading.module';



@NgModule({
  declarations: [DepartmentDetailsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    LoadingModule
  ]
})
export class DepartmentDetailsModule { }
