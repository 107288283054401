<div class="institutions">
  <et-filterable-table [tableData]="institutionsTableConfig"
                       [tableTitle]="tableTitle"
                       [filters]="filtersConfig"
                       [paginationData]="paginationData"
                       [hasPagination]="true"
                       [isRowClickable]="true"
                       [dataLoading]="tableLoading"
                       [hasStickyHeader]="true"
                       [filtersQueryParamMode]="true"
                       [hasAddNewButton]="showAddNewButton"
                       [addNewButtonText]="addNewButtonText"
                       [addNewButtonIcon]="'person_add'"
                       (addNewClicked)="openAddNewInstitutionModal()"
                       (filtersChanged)="onNewFilters($event)"
                       (sortChanged)="onSortChanged($event)"
                       (rowClicked)="openInstitutionDetails($event)"
                       (linkCellClicked)="openInstitutionDetailsWithTarget($event)"
                       (pageChange)="onPageChange($event)"></et-filterable-table>
</div>
