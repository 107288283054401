import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringClamp'
})
export class StringClampPipe implements PipeTransform {

  constructor() {
  }

  public transform(value: any, limit: number, atIndex: boolean = false): string {
    if (!value) {
      return '';
    }
    if (atIndex) {
      return `${value.substring(0, limit)}…`;
    } else {
      const split = value.split(' ');
      let clampStr = '';
      let stop = false;
      for (let i = 0; i < split.length && !stop; i++) {
        if (clampStr.length < limit) {
          if (clampStr.length + split[i].length > limit) {
            clampStr += split[i].substr(0, split[i].length - (limit - clampStr.length)) + '…';
            stop = true;
          } else {
            clampStr += `${split[i]} `;
          }
        } else {
          clampStr += '…';
          stop = true;
        }
      }
      return clampStr;
    }
  }
}
