<div class="dashboard">
  <div class="dashboard__expirations-and-downloads" *ngIf="!isAdmin">
    <!-- only institution office and spinoff office -->
    <div *ngIf="!isViewer">
      <h2>{{'dashboard.downloads-title' | translate}}</h2>
      <div class="download-buttons">
        <ng-container *ngIf="isInstitutionOffice">
          <button mat-stroked-button
                  (click)="doExport1(true)"
                  color="primary"
                  class="mat-elevation-z1">
            <mat-icon svgIcon="download"></mat-icon>
            {{'dashboard.export-1-download--for-institutions' | translate}}
          </button>
          <button mat-stroked-button
                  color="primary"
                  (click)="doExport2(true)"
                  class="mat-elevation-z1">
            <mat-icon svgIcon="download"></mat-icon>
            {{'dashboard.export-2-download--for-institutions' | translate}}
          </button>
        </ng-container>
        <button mat-stroked-button
                (click)="doExport1(false)"
                color="primary"
                class="mat-elevation-z1">
          <mat-icon svgIcon="download"></mat-icon>
          {{ isInstitutionOffice ? ('dashboard.export-1-download--for-spinoff' | translate) : ('dashboard.export-1-download' | translate)}}
        </button>
        <button mat-stroked-button
                color="primary"
                (click)="doExport2(false)"
                class="mat-elevation-z1">
          <mat-icon svgIcon="download"></mat-icon>
          {{ isInstitutionOffice ? ('dashboard.export-2-download--for-spinoff' | translate) : ('dashboard.export-2-download' | translate)}}
        </button>
        <button mat-stroked-button
                color="primary"
                (click)="openCSVExportModal()"
                class="mat-elevation-z1 span-button">
          <mat-icon svgIcon="download"></mat-icon>
          {{ 'dashboard.export-csv' | translate }}
        </button>
        <button mat-stroked-button
                color="primary"
                (click)="doActiveSpinoffPdfExport()"
                class="mat-elevation-z1 span-button">
          <mat-icon svgIcon="download"></mat-icon>
          {{ 'dashboard.export-pdf--active-spinoff' | translate }}
        </button>
      </div>
    </div>
    <!-- only institution office, spinoff office and viewer -->
    <div>
      <h2>{{'dashboard.next-expirations-title' | translate}}
        <button mat-stroked-button
                color="primary"
                (click)="onNextExpirationsButtonClick()"
                class="mat-elevation-z1">{{'dashboard.view-all-f-button' | translate}}</button>
      </h2>
      <et-loading class="spacing-top-40 spacing-bottom-40" *ngIf="tableLoading"></et-loading>
      <et-filterable-table *ngIf="!tableLoading"
                           [tableData]="nextExpirationsTableConfig"
                           [hasStickyHeader]="true"
                           (linkCellClicked)="onExpirationCountClick($event)"></et-filterable-table>
    </div>
  </div>
  <!-- only institution admin and spinoff admin -->
  <div *ngIf="isAdmin">
    <h2>{{isSpinoff ? ('dashboard.institutions-to-validate-title--spinoff' | translate) : ('dashboard.institutions-to-validate-title' | translate)}}
      <button mat-stroked-button
              color="primary"
              (click)="onInstitutionsToValidateButtonClick()"
              class="mat-elevation-z1">{{'dashboard.view-all-m-button' | translate}}</button>
    </h2>
    <et-loading class="spacing-top-40 spacing-bottom-40" *ngIf="tableLoading"></et-loading>
    <div class="list-box" *ngIf="!tableLoading">
      <div class="list-box__header">
        <h3>{{'dashboard.institutions-to-validate-name' | translate}}</h3>
      </div>
      <div class="list-box__row" *ngFor="let item of adminInstitutionsToValidateTableData">
        <a (click)="$event.stopPropagation(); $event.preventDefault();"
           [routerLink]="['/enti-spinoff/', item?.id]">{{item?.vName?.attributeValue || '—'}}</a>
      </div>
      <p class="list-box__no-data" *ngIf="adminInstitutionsToValidateTableData.length === 0">
        {{ isSpinoff ? ('dashboard.institutions-to-validate-no-data--spinoff' | translate) : ('dashboard.institutions-to-validate-no-data' | translate)}}
      </p>
    </div>
  </div>
  <!-- always -->
  <div class="dashboard__charts-container">
    <!-- only institution office and institution admin -->
    <ng-container *ngIf="!isSpinoff && !isViewer">
      <h2>{{ isInstitutionOffice ? ('dashboard.institutions-per-department' | translate) : ('dashboard.institutions-per-department--only-institutions' | translate)}}</h2>
      <et-loading class="spacing-top-40 spacing-bottom-40" *ngIf="institutionsPerDepartmentLoading"></et-loading>
      <div class="horizontal-chart-container"
           *ngIf="!institutionsPerDepartmentLoading && institutionsPerDepartmentHasData">
        <canvas baseChart
                [datasets]="institutionsPerDepartmentData"
                [labels]="institutionsPerDepartmentLabels"
                [options]="institutionsPerDepartmentOptions"
                [plugins]="barChartPlugins"
                [legend]="false"
                [chartType]="'horizontalBar'">
        </canvas>
      </div>
      <p class="dashboard__no-chart-data"
         *ngIf="!institutionsPerDepartmentHasData">{{'dashboard.chart-no-data' | translate}}</p>
    </ng-container>
    <ng-container *ngIf="isSpinoffOffice">
      <h2>{{'dashboard.spinoff-per-year' | translate}}</h2>
      <et-loading class="spacing-top-40 spacing-bottom-40" *ngIf="spinoffPerYearLoading"></et-loading>
      <div *ngIf="!spinoffPerYearLoading && spinoffPerYearHasData">
        <canvas baseChart
                [datasets]="spinoffPerYearData"
                [labels]="spinoffPerYearLabels"
                [options]="spinoffPerYearOptions"
                [plugins]="barChartPlugins"
                [legend]="false"
                [chartType]="'bar'">
        </canvas>
      </div>
      <p class="dashboard__no-chart-data"
         *ngIf="!spinoffPerYearHasData">{{'dashboard.chart-no-data' | translate}}</p>
    </ng-container>
    <!-- onlu spinoff office, spinoff admin and viewer -->
    <ng-container *ngIf="isSpinoff || isViewer">
      <h2>{{'dashboard.spinoff-per-department' | translate}}</h2>
      <et-loading class="spacing-top-40 spacing-bottom-40" *ngIf="spinoffPerDepartmentLoading"></et-loading>
      <div class="horizontal-chart-container horizontal-chart-container--spinoff"
           *ngIf="!spinoffPerDepartmentLoading && spinoffPerDepartmentHasData">
        <canvas baseChart
                [datasets]="spinoffPerDepartmentData"
                [labels]="spinoffPerDepartmentLabels"
                [options]="spinoffPerDepartmentOptions"
                [plugins]="barChartPlugins"
                [legend]="false"
                [chartType]="'horizontalBar'">
        </canvas>
      </div>
      <p class="dashboard__no-chart-data"
         *ngIf="!spinoffPerDepartmentHasData && spinoffPerDepartmentLoading">{{'dashboard.chart-no-data' | translate}}</p>
    </ng-container>
    <!-- only viewer -->
    <ng-container *ngIf="isViewer">
      <h2>{{'dashboard.institutions-per-department' | translate}}</h2>
      <et-loading class="spacing-top-40 spacing-bottom-40" *ngIf="viewerInstitutionsPerDepartmentLoading"></et-loading>
      <div class="horizontal-chart-container horizontal-chart-container--spinoff"
           *ngIf="!viewerInstitutionsPerDepartmentLoading && viewerInstitutionsPerDepartmentHasData">
        <canvas baseChart
                [datasets]="viewerInstitutionsPerDepartmentData"
                [labels]="viewerInstitutionsPerDepartmentLabels"
                [options]="viewerInstitutionsPerDepartmentOptions"
                [plugins]="barChartPlugins"
                [legend]="false"
                [chartType]="'horizontalBar'">
        </canvas>
      </div>
      <p class="dashboard__no-chart-data"
         *ngIf="!viewerInstitutionsPerDepartmentHasData && viewerInstitutionsPerDepartmentLoading">{{'dashboard.chart-no-data' | translate}}</p>
    </ng-container>
  </div>
</div>
