import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { GraphQLModule }                from './graphql/graphql.module';
import { HeaderModule }                 from './header/header.module';
import { UserModule }                   from './user/user.module';
import { MatSnackBarModule }            from '@angular/material/snack-bar';
import { NotFoundModule }               from './not-found/not-found.module';
import { LogoutModule }                 from './logout/logout.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GraphQLModule,
    MatSnackBarModule
  ],
  exports: [
    GraphQLModule,
    HeaderModule,
    UserModule,
    NotFoundModule,
    LogoutModule
  ]
})
export class CoreModule {
  /* Core Module Purpose: stuff that you import once when the app starts and never import anywhere else */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
