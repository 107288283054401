<div class="temporal-list-section">
  <div class="temporal-list-section__add-new">
    <h2>{{'role-details.side-panel-details-compensations-title' | translate}}</h2>
    <button mat-stroked-button
            *ngIf="hasActions"
            color="primary"
            (click)="onAddNewCompensationClick()"
            class="mat-elevation-z1">
      <mat-icon aria-hidden="false" [attr.aria-label]="('institution-details.modal-add-new-item' | translate)">add_circle_outline</mat-icon>
      {{'institution-details.modal-add-new-item' | translate}}
    </button>
  </div>
  <div class="list-box" *ngIf="roleCompensations && roleCompensations.length > 0">
    <div class="list-box__row" *ngFor="let item of roleCompensations; let i = index">
      <p>{{item.year}}</p>
      <p>{{item.compensation | enhancedCurrency: 'EUR'}}</p>
      <p>{{item.compensationNature || '—'}}</p>
      <div *ngIf="hasActions"
           class="actions-container-cell"
           [class.info-overlay-parent]="i > 1">
        <button mat-icon-button color="primary" aria-label="azioni" (click)="infoOverlayIndexToShow = i">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <div class="info-backdrop-overlay" *ngIf="infoOverlayIndexToShow !== -1"
             (click)="infoOverlayIndexToShow = -1"></div>
        <div class="info-overlay" *ngIf="infoOverlayIndexToShow === i">
          <p (click)="onActionButtonClick('update', item)">
            {{'info-overlay.action-edit' | translate}}
          </p>
          <p class="delete-text" (click)="onActionButtonClick('delete', item)">
            {{'info-overlay.action-delete' | translate}}
          </p>
        </div>
      </div>
    </div>
  </div>
  <p
    *ngIf="!roleCompensations?.length">{{'role-details.side-panel-details-compensations-no-data' | translate}}</p>
</div>
