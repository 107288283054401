import { Component, HostListener, OnInit }                     from '@angular/core';
import { UserService }                                         from '../core/user/user.service';
import { TableColumnTypes, TableData }                         from '../shared/filterable-table/filterable-table.component';
import { SectionNames }                                        from '../institution-details/institution-details.component';
import { Router }                                              from '@angular/router';
import {
  AdminInstitutionsWithUnapprovedGQL,
  ChartActiveInstitutionsAndSpinoffsPerDepartmentGQL,
  ChartActiveInstitutionsPerDepartmentGQL,
  ChartActiveSpinoffsPerDepartmentGQL,
  ChartActiveSpinoffsPerYearGQL,
  DepartmentValue,
  InstitutionInterface,
  InstitutionsWithExpiringRemindsGQL,
  Maybe,
  UserRoleEnum
}                                                              from '../../generated/graphql';
import { InstitutionRow }                                      from '../institutions/institutions.component';
import { TranslatePipe }                                                                         from '@ngx-translate/core';
import {
  getBarChartBaseOptions,
  getErrorMessageFromData,
  getFixedSmallModalSettings,
  getHorizontalBarChartBaseOptions
} from '../shared/utils/helpers';
import { MatSnackBar }                                                                           from '@angular/material/snack-bar';
import { ChartDataSets, ChartOptions }                         from 'chart.js';
import { Label }                                               from 'ng2-charts';
import ChartDataLabels                                         from 'chartjs-plugin-datalabels';
import moment                                                  from 'moment';
import { environment }                                         from '../../environments/environment';
import { BaseModalComponent, ModalField }                      from '../shared/base-modal/base-modal.component';
import { MatDialog }                                           from '@angular/material/dialog';

@Component({
  selector: 'et-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [TranslatePipe]
})
export class DashboardComponent implements OnInit {

  nextExpirationsTableConfig: TableData;
  adminInstitutionsToValidateTableData: any[] = [];
  tableLoading = true;
  spinoffPerDepartmentLoading = true;
  institutionsPerDepartmentLoading = true;
  viewerInstitutionsPerDepartmentLoading = true;
  spinoffPerYearLoading = true;
  isSpinoff: boolean;
  isSpinoffOffice: boolean;
  isInstitutionOffice: boolean;
  isAdmin: boolean;
  isViewer: boolean;
  barChartPlugins = [ChartDataLabels];
  spinoffPerDepartmentHasData = false;
  spinoffPerDepartmentOptions: ChartOptions;
  spinoffPerDepartmentLabels: Label[] = [];
  spinoffPerDepartmentData: ChartDataSets[] = [
    {
      backgroundColor: 'rgb(155,204,54)',
      hoverBackgroundColor: 'rgb(125,171,25)',
      borderColor: 'rgb(125,171,25)',
      data: []
    }
  ];
  viewerInstitutionsPerDepartmentHasData = false;
  viewerInstitutionsPerDepartmentOptions: ChartOptions;
  viewerInstitutionsPerDepartmentLabels: Label[] = [];
  viewerInstitutionsPerDepartmentData: ChartDataSets[] = [
    {
      backgroundColor: 'rgb(155,204,54)',
      hoverBackgroundColor: 'rgb(125,171,25)',
      borderColor: 'rgb(125,171,25)',
      data: []
    }
  ];
  institutionsPerDepartmentHasData = false;
  institutionsPerDepartmentOptions: ChartOptions;
  institutionsPerDepartmentLabels: Label[] = [];
  institutionsPerDepartmentData: ChartDataSets[] = [
    {
      backgroundColor: 'rgb(155,204,54)',
      hoverBackgroundColor: 'rgb(125,171,25)',
      borderColor: 'rgb(125,171,25)',
      data: []
    }
  ];
  spinoffPerYearHasData = false;
  spinoffPerYearOptions: ChartOptions;
  spinoffPerYearLabels: Label[] = [];
  spinoffPerYearData: ChartDataSets[] = [
    {
      backgroundColor: 'rgb(247,189,41)',
      hoverBackgroundColor: 'rgb(188,147,31)',
      borderColor: 'rgb(188,147,31)',
      data: []
    }
  ];

  constructor(private userService: UserService,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar,
              private router: Router,
              private dialog: MatDialog,
              private institutionsWithExpiringRemindsGQL: InstitutionsWithExpiringRemindsGQL,
              private adminInstitutionsWithUnapprovedGQL: AdminInstitutionsWithUnapprovedGQL,
              private chartActiveInstitutionsAndSpinoffsPerDepartmentGQL: ChartActiveInstitutionsAndSpinoffsPerDepartmentGQL,
              private chartActiveSpinoffsPerDepartmentGQL: ChartActiveSpinoffsPerDepartmentGQL,
              private chartActiveInstitutionsPerDepartmentGQL: ChartActiveInstitutionsPerDepartmentGQL,
              private chartActiveSpinoffsPerYearGQL: ChartActiveSpinoffsPerYearGQL) {
    this.isSpinoff = this.userService.user?.role === UserRoleEnum.SpinoffOffice || this.userService.user?.role === UserRoleEnum.SpinoffAdmin;
    this.isSpinoffOffice = this.userService.user?.role === UserRoleEnum.SpinoffOffice;
    this.isInstitutionOffice = this.userService.user?.role === UserRoleEnum.InstitutionOffice;
    this.isAdmin = this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.InstitutionAdmin;
    this.isViewer = this.userService.user?.role === UserRoleEnum.Viewer;
    this.spinoffPerDepartmentOptions = getHorizontalBarChartBaseOptions();
    this.spinoffPerYearOptions = getBarChartBaseOptions();
    this.institutionsPerDepartmentOptions = getHorizontalBarChartBaseOptions();
    this.viewerInstitutionsPerDepartmentOptions = getHorizontalBarChartBaseOptions();
    this.nextExpirationsTableConfig = {
      list: [],
      columnsConfig: [
        {
          dataToShowKey: 'name',
          type: TableColumnTypes.LINK,
          headerTitleKey: 'dashboard.next-expirations-institution',
          linkTarget: SectionNames.GENERAL_INFO
        },
        {
          dataToShowKey: 'expirationsCount',
          type: TableColumnTypes.LINK,
          linkIcon: 'notifications_active',
          headerTitleKey: 'dashboard.next-expirations-count',
          linkTarget: SectionNames.EXPIRATIONS
        }
      ]
    };
  }

  private formatArrayToMatchTableData(arrayToFormat: any): Array<InstitutionRow> {
    return arrayToFormat.map((el: InstitutionInterface) => {
      return {
        id: el.id,
        name: el.vName?.attributeValue,
        hideLink: !el.isVisible, // aux property for link cell
        expirationsCount: el.expiringReminds?.length
      };
    }, []);
  }

  private fetchNextExpirations(): void {
    this.institutionsWithExpiringRemindsGQL.fetch({
        expiringWithinDays: 30
      },
      {fetchPolicy: 'no-cache'}
    ).subscribe(res => {
      this.nextExpirationsTableConfig.list = this.formatArrayToMatchTableData(res.data.institutionsWithExpiringReminds);
      this.tableLoading = false;
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
      this.tableLoading = false;
    });
  }

  private fetchInstitutionsToValidate(): void {
    this.adminInstitutionsWithUnapprovedGQL.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(res => {
      this.adminInstitutionsToValidateTableData = (res.data.adminInstitutionsWithUnapproved) as any[];
      this.tableLoading = false;
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
      this.tableLoading = false;
    });
  }

  private postProcessChartPerDepartments(chartData: Maybe<{ __typename?: 'DepartmentValue' } & Pick<DepartmentValue, 'departmentNames' | 'values'>> | undefined): any {
    let countOnes = 0;
    let auxMap = chartData?.departmentNames.map((d, index) => {
      if (chartData.values[index] === 1) {
        countOnes++;
      }
      return {
        department: d,
        value: chartData?.values[index]
      };
    });
    if (auxMap) {
      if (countOnes > 1) {
        auxMap = auxMap.filter(el => el.value > 1);
        auxMap = [...auxMap, {
          department: this.translatePipe.transform('common.chart-value--other'),
          value: 1
        }];
      }
      auxMap.sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        }
        return a.value < b.value ? 1 : -1;
      });
    }
    return auxMap;
  }

  private postProcessScopedChartPerDepartments(chartData: {
    __typename?: 'NameValue';
    names: Array<string>;
    values: Array<number>
  } | null | undefined): any {
    let countOnes = 0;
    let auxMap = chartData?.names.map((d, index) => {
      if (chartData.values[index] === 1) {
        countOnes++;
      }
      return {
        department: d,
        value: chartData?.values[index]
      };
    });
    if (auxMap) {
      if (countOnes > 1) {
        auxMap = auxMap.filter(el => el.value > 1);
        auxMap = [...auxMap, {
          department: this.translatePipe.transform('common.chart-value--other'),
          value: 1
        }];
      }
      auxMap.sort((a, b) => {
        if (a.value === b.value) {
          return 0;
        }
        return a.value < b.value ? 1 : -1;
      });
    }
    return auxMap;
  }

  private loadCharts(): void {
    if (this.isSpinoff || this.isViewer) {
      this.spinoffPerDepartmentLoading = true;
      this.chartActiveSpinoffsPerDepartmentGQL.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(res => {
        const auxMap = this.postProcessChartPerDepartments(res.data.chartActiveSpinoffsPerDepartment);
        this.spinoffPerDepartmentHasData = auxMap.length > 0;
        if (auxMap) {
          this.spinoffPerDepartmentLabels = (auxMap.map((el: { department: string; }) => el.department)) as Label[];
          this.spinoffPerDepartmentData[0].data = auxMap.map((el: { value: number; }) => el.value);
        }
        this.spinoffPerDepartmentLoading = false;
      }, error => {
        this.spinoffPerDepartmentLoading = false;
      });

      if (this.isSpinoffOffice) {
        this.spinoffPerYearLoading = true;
        const fromYear = moment().subtract(12, 'year').year();
        const toYear = moment().year();
        this.chartActiveSpinoffsPerYearGQL.fetch({fromYear, toYear}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          // @ts-ignore
          // this.spinoffPerYearOptions.scales.yAxes[0].ticks.suggestedMax = Math.max.apply(null, res.data.chartActiveSpinoffsPerYear?.values || [0]) + 5;
          this.spinoffPerYearHasData = res.data.chartActiveSpinoffsPerYear?.values?.length > 0;
          this.spinoffPerYearLabels = (res.data.chartActiveSpinoffsPerYear?.years) as Label[];
          this.spinoffPerYearData[0].data = res.data.chartActiveSpinoffsPerYear?.values;
          this.spinoffPerYearLoading = false;
        }, error => {
          this.spinoffPerYearLoading = false;
        });
      }

      if (this.isViewer) {
        this.viewerInstitutionsPerDepartmentLoading = true;
        this.chartActiveInstitutionsPerDepartmentGQL.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          const auxMap = this.postProcessScopedChartPerDepartments(res.data.chartScopedInstitutionsPerDepartment);
          this.viewerInstitutionsPerDepartmentHasData = auxMap.length > 0;
          if (auxMap) {
            this.viewerInstitutionsPerDepartmentLabels = (auxMap.map((el: { department: string; }) => el.department)) as Label[];
            this.viewerInstitutionsPerDepartmentData[0].data = auxMap.map((el: { value: number; }) => el.value);
          }
          this.viewerInstitutionsPerDepartmentLoading = false;
        }, error => {
          this.viewerInstitutionsPerDepartmentLoading = false;
        });
      }
    } else {
      this.institutionsPerDepartmentLoading = true;
      this.chartActiveInstitutionsAndSpinoffsPerDepartmentGQL.fetch({}, {fetchPolicy: 'no-cache'}).subscribe(res => {
        const auxMap = this.postProcessChartPerDepartments(res.data.chartActiveInstitutionsAndSpinoffsPerDepartment);
        this.institutionsPerDepartmentHasData = auxMap.length > 0;
        if (auxMap) {
          this.institutionsPerDepartmentLabels = (auxMap.map((el: { department: string; }) => el.department)) as Label[];
          this.institutionsPerDepartmentData[0].data = auxMap.map((el: { value: number; }) => el.value);
        }
        this.institutionsPerDepartmentLoading = false;
      }, error => {
        this.institutionsPerDepartmentLoading = false;
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // console.log(event.target.innerWidth);
    if (this.isSpinoff) {
      this.spinoffPerDepartmentOptions = {...this.spinoffPerDepartmentOptions}; // This is a hack to force refresh
      this.spinoffPerYearOptions = {...this.spinoffPerYearOptions};
    } else {
      this.institutionsPerDepartmentOptions = {...this.institutionsPerDepartmentOptions};
    }
  }

  ngOnInit(): void {
    if (this.isAdmin) {
      this.fetchInstitutionsToValidate();
    } else {
      this.fetchNextExpirations();
    }
    this.loadCharts();
  }

  onExpirationCountClick(rowDataAndTarget: { data: any; linkTarget: string | undefined }): void {
    this.router.navigate(['/enti-spinoff/' + rowDataAndTarget.data.id], {queryParams: {sectionToOpen: rowDataAndTarget.linkTarget}});
  }

  onNextExpirationsButtonClick(): void {
    this.router.navigate(['/scadenze']);
  }

  onInstitutionsToValidateButtonClick(): void {
    this.router.navigate(['/enti-spinoff']);
  }

  doExport1(isForInstitutionOffice: boolean): void {
    if (isForInstitutionOffice) {
      window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/csv_export/institution_summary?${UserService.getHeadersAsQS()}`);
    } else {
      window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/csv_export/spinoff_summary?${UserService.getHeadersAsQS()}`);
    }
  }

  doExport2(isForInstitutionOffice: boolean): void {
    // const baseUrl = environment.production ? window.location.origin : environment.graphqlEndpoint.split('/graphql')[0];
    if (isForInstitutionOffice) {
      window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/csv_export/institution_accounting_reports?${UserService.getHeadersAsQS()}`);
    } else {
      window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/csv_export/spinoff_accounting_reports?${UserService.getHeadersAsQS()}`);
    }
  }

  openCSVExportModal(): void {

    let exportOptions = [
      {
        id: 'spinoff_roles_with_date_range',
        text: this.translatePipe.transform('export-csv-modal.field-exportType-1')
      },
      {
        id: 'spinoff_summary_with_date_range',
        text: this.translatePipe.transform('export-csv-modal.field-exportType-3')
      }
    ];
    if (!this.isSpinoff) {
      exportOptions = [
        ...exportOptions,
        {
          id: 'institution_roles_with_date_range',
          text: this.translatePipe.transform('export-csv-modal.field-exportType-2')
        },
        {
          id: 'institution_summary_with_date_range',
          text: this.translatePipe.transform('export-csv-modal.field-exportType-4')
        }
      ];
    }

    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'dateRange',
        type: 'date-from-to',
        label: this.translatePipe.transform('export-csv-modal.field--dateRange'),
        required: true,
        closedRangeRequired: true
      },
      {
        fieldKey: 'exportType',
        type: 'select',
        label: this.translatePipe.transform('export-csv-modal.field--exportType'),
        required: true,
        options: exportOptions
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        hasClose: true,
        title: this.translatePipe.transform('dashboard.export-csv'),
        fields: fieldsConf
      }
    });

    // matDialogRef.componentInstance.onFieldsChange.subscribe(data => {
    //   console.log(data);
    // });

    // https://github.com/angular/components/issues/14292 when they will implement this todo add check before close
    // matDialogRef.beforeClosed().subscribe(fieldsModel => {
    //   console.log(fieldsModel);
    // });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        const dateRangeQS = `from=${fieldsModel.dateRange?.beginDate}&to=${fieldsModel.dateRange?.endDate}`;
        window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/csv_export/${fieldsModel.exportType}?${UserService.getHeadersAsQS()}&${dateRangeQS}`);
      }
    });
  }

  doActiveSpinoffPdfExport(): void {
    window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/pdf_reports/spinoffs.pdf?${UserService.getHeadersAsQS()}`);
  }
}
