import { Component, Inject, OnInit }                                              from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                                          from '@angular/material/dialog';
import { UploadOutput }                                                           from 'ngx-uploader';
import { ABErrorStateMatcher, autocompleteRequiredValidator, roundCurrencyValue } from '../utils/helpers';
import { FormControl, ValidatorFn, Validators }                                   from '@angular/forms';
import { Subject }                                                                from 'rxjs';
import moment, { Moment }                                                         from 'moment';
import { debounceTime }                                                           from 'rxjs/operators';

export interface ModalField {
  type: 'text' | 'text-readonly' | 'textarea' | 'email' | 'select' | 'number' | 'date' | 'currency' | 'file-picker-image' | 'file-picker-pdf' | 'date-from-to' | 'autocomplete' | 'toggle';
  fieldKey: string; // backend/model key
  required?: boolean;
  label?: string; // translatedString
  hint?: string; // translatedString
  value?: any;
  disabled?: boolean;
  options?: any[];
  width?: number; // grid column span: 1-12, default is 12
  fileCustomUploadedLabel?: string; // translatedString
  hidden?: boolean;
  hasRemoteSearch?: boolean;
  searchDisabled?: boolean;
  minDateConstraint?: Date;
  maxDateConstraint?: Date;
  minNumber?: number;
  maxNumber?: number;
  closedRangeRequired?: boolean;
}

// Template of the Modal 'data' object
/*data: {
    stickyHeader: false,
    stickyFooter: false,
    title: 'the title string',
    bodyHtml: 'html string',
    fields: ModalField[]
    hasClose: true  //if has close button
    isConfirmModal: true // if is a confirmation/disruptive modal
    buttonsTitle: { 'cancel': string, 'confirm': string },
    noFooter: boolean
  }*/


@Component({
  selector: 'et-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: []
})
export class BaseModalComponent implements OnInit {

  private autocompleteFieldsOptionsOrig: { [key: string]: any } = {};
  private autocompleteChangesSubject = new Subject();

  fieldsModel: { [key: string]: any } = {};
  fieldsControls: { [key: string]: FormControl } = {};
  errorStateMatcher = new ABErrorStateMatcher();
  autocompleteFieldsOptions: { [key: string]: any } = {};
  onFieldsChange: Subject<any> = new Subject<any>();
  onAutocompleteChangedRemoteSearch: Subject<any> = new Subject<any>();
  updating = false;

  private static hasFormControl(fieldType: string): boolean {
    return fieldType !== 'date-from-to' && fieldType !== 'file-picker-image' && fieldType !== 'file-picker-pdf';
  }

  constructor(public dialogRef: MatDialogRef<BaseModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    const today = new Date();
    this.autocompleteChangesSubject.pipe(debounceTime(300)).subscribe((listenedData) => {
      // tslint:disable-next-line:max-line-length
      const relatedData: { fieldKey: string, searchedText: string, hasRemoteSearch: boolean } = listenedData as ({ fieldKey: string, searchedText: string, hasRemoteSearch: boolean });
      if (relatedData?.hasRemoteSearch) {
        this.onAutocompleteChangedRemoteSearch.next(relatedData);
      } else {
        // tslint:disable-next-line:max-line-length
        this.autocompleteFieldsOptions[relatedData.fieldKey] = this.autocompleteFieldsOptionsOrig[relatedData.fieldKey].filter((o: { text: string; }) => o.text.toLowerCase().includes(relatedData.searchedText.toLowerCase()));
      }

    });
  }

  static filterConfirmedModelByDisabledAndExcludedKeys(confirmedModel: any, excludedKeys: string[] = []): any {
    const filtered: any = {};
    Object.keys(confirmedModel).forEach(key => {
      if (!(confirmedModel[key]?.disabled) && !excludedKeys.includes(key)) {
        filtered[key] = confirmedModel[key];
      }
    });
    return filtered;
  }

  private configureFields(): void {
    if (this.data.fields && this.data.fields.length > 0) {
      this.fieldsControls = {};
      this.data.fields.forEach((field: ModalField) => {
        if (!field.width) {
          field.width = 12;
        }

        // handle value conf
        if (field.type === 'currency') {
          field.value = roundCurrencyValue(field.value);
        } else if (field.type === 'autocomplete') {
          this.autocompleteFieldsOptionsOrig[field.fieldKey] = field.options || [];
          if (field.value) {
            this.autocompleteFieldsOptions[field.fieldKey] = field.options || [];
          } else {
            this.autocompleteFieldsOptions[field.fieldKey] = [];
          }
        }

        // handle disabled conf
        if (field.disabled || field.searchDisabled) {
          this.fieldsModel[field.fieldKey] = {value: field.value, disabled: true};
        } else {
          this.fieldsModel[field.fieldKey] = field.value;
        }

        if (BaseModalComponent.hasFormControl(field.type)) {
          // handle required conf and default validations
          let validators: ValidatorFn[] = [];
          if (field.required) {
            validators = [Validators.required];
            if (field.type === 'autocomplete') {
              validators.push(autocompleteRequiredValidator());
            }
          }
          if (field.type === 'email') {
            validators.push(Validators.email);
          }

          if (!this.fieldsControls[field.fieldKey]) {
            this.fieldsControls[field.fieldKey] = new FormControl(this.fieldsModel[field.fieldKey], {
              validators
            });
            if (field.type === 'autocomplete' && field.value && this.autocompleteFieldsOptionsOrig[field.fieldKey].length > 0) {
              // better, the sanfermo way
              const optionId = field.value?.id ? field.value.id : field.value;
              const found = this.autocompleteFieldsOptionsOrig[field.fieldKey].find((o: { id: any; }) => o.id === optionId);
              if (found) {
                this.fieldsControls[field.fieldKey].setValue(found);
                this.fieldsControls[field.fieldKey].markAsUntouched();
              }
            }
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.configureFields();
  }

  onConfirmClick(): void {
    if (this.data.fields) {
      const confirmedModel: { [key: string]: any } = this.data.fields.filter((f: ModalField) => !f.disabled).reduce((acc: any, f: ModalField) => {
        if (f.type === 'currency') {
          if (!!this.fieldsModel[f.fieldKey].value && this.fieldsModel[f.fieldKey].value === 'NaN') {
            delete this.fieldsModel[f.fieldKey];
          } else {
            acc[f.fieldKey] = parseFloat(this.fieldsModel[f.fieldKey]);
          }
        } else if (f.type === 'file-picker-image' || f.type === 'file-picker-pdf' && this.fieldsModel[f.fieldKey]) {
          acc[f.fieldKey] = this.fieldsModel[f.fieldKey].file;
        } else if (f.type === 'autocomplete') {
          acc[f.fieldKey] = f.searchDisabled ? this.fieldsModel[f.fieldKey].value : this.fieldsModel[f.fieldKey];
        } else if (f.type === 'date' && this.fieldsModel[f.fieldKey]) {
          const value = this.fieldsModel[f.fieldKey].hasOwnProperty('value') ? this.fieldsModel[f.fieldKey].value : this.fieldsModel[f.fieldKey];
          acc[f.fieldKey] = value ? moment(value).toISOString(true) : null;
        } else {
          acc[f.fieldKey] = this.fieldsModel[f.fieldKey];
        }

        return acc;
      }, {});
      this.dialogRef.close(confirmedModel);
    } else {
      this.dialogRef.close(true);
    }
  }

  isConfirmDisabled(): boolean {
    return this.data.fields && this.data.fields.some((field: ModalField) => {
      if (field.type === 'file-picker-image' || field.type === 'file-picker-pdf' || field.type === 'date-from-to') {
        // tslint:disable-next-line:max-line-length
        const valueConstraintCheck = (field.type === 'date-from-to' && field.closedRangeRequired) ? (this.fieldsModel[field.fieldKey]?.beginDate && this.fieldsModel[field.fieldKey]?.endDate) : this.fieldsModel[field.fieldKey];
        return field.required && !this.fieldsModel[field.fieldKey]?.disabled && !valueConstraintCheck;
      }
      return field.required && !this.fieldsControls[field.fieldKey].valid && !this.fieldsControls[field.fieldKey].disabled;
    });
  }

  onFieldChange(value: any, fieldName: string): void {
    this.data.fields.find((el: ModalField) => el.fieldKey === fieldName).value = value;
    this.fieldsModel[fieldName] = value;
    if (!this.updating) {
      this.onFieldsChange.next({fieldName, currentFieldsModel: this.fieldsModel});
    }
  }

  checkMaxMin(fieldName: string): void {
    const found = this.data.fields.find((el: ModalField) => el.fieldKey === fieldName);
    if (typeof found.maxNumber !== undefined || typeof found.minNumber !== undefined) {
      if (found.value < found.minNumber) {
        this.fieldsControls[fieldName].setValue(found.minNumber);
      }
      if (found.value > found.maxNumber) {
        this.fieldsControls[fieldName].setValue(found.maxNumber);
      }
    }
  }

  onUploadOutput(event: UploadOutput, fieldName: string): void {
    if (event.type === 'addedToQueue' && event.file) {
      this.fieldsModel[fieldName] = {
        file: event.file.nativeFile,
        name: event.file.name,
        mimeType: event.file.type
      };
    }
  }

  onAttachedDocumentDelete(e: any, fieldName: string): void {
    delete this.fieldsModel[fieldName];
  }

  formatCurrencyNumber(fieldKey: string): void {
    if (this.fieldsModel[fieldKey]) {
      this.fieldsControls[fieldKey].setValue(roundCurrencyValue(this.fieldsModel[fieldKey]));
    }
  }

  autocompleteDisplayFn(option: any): string {
    return option && option.hasOwnProperty('text') ? option.text : '';
  }

  // @ts-ignore
  onAutocompleteOptionSelected({option}, fieldKey: string): void {
    const {value} = option;
    this.onFieldChange(value.id, fieldKey);
  }

  // @ts-ignore
  onAutocompleteTextChange({target}, fieldKey, hasRemoteSearch?): void {
    const {value} = target;
    if (value) {
      this.autocompleteChangesSubject.next({fieldKey, searchedText: value, hasRemoteSearch});
    } else {
      this.autocompleteFieldsOptions[fieldKey] = [...this.autocompleteFieldsOptionsOrig[fieldKey]];
      this.onFieldChange(null, fieldKey);
    }
  }

  updateAutocompleteOptionsWithRemoteData(fieldkey: string, remoteData: any[]): void {
    this.autocompleteFieldsOptionsOrig[fieldkey] = remoteData;
    this.autocompleteFieldsOptions[fieldkey] = remoteData;
  }

  setFieldVisibility(fieldName: string, visible: boolean): void {
    this.data.fields.find((el: ModalField) => el.fieldKey === fieldName).hidden = !visible;
  }

  setFieldRequired(fieldName: string, isRequired: boolean): void {
    this.data.fields.find((el: ModalField) => el.fieldKey === fieldName).required = isRequired;
    this.configureFields();
  }

  setSelectFieldOptions(fieldName: string, options: any[], value?: string | null): void {
    this.data.fields.find((el: ModalField) => el.fieldKey === fieldName).options = [...options];
    if (value || value === null) {
      this.data.fields.find((el: ModalField) => el.fieldKey === fieldName).value = value;
    }
    this.configureFields();
  }

  onDateChange(dateObj: Moment, type: 'beginDate' | 'endDate', fieldKey: string): void {
    const index = this.data.fields.findIndex((field: { fieldKey: string; }) => field.fieldKey === fieldKey);
    if (!this.fieldsModel[fieldKey]) {
      this.fieldsModel[fieldKey] = {};
    }
    if (type === 'beginDate') {
      this.fieldsModel[fieldKey].beginDate = dateObj ? dateObj.toISOString(true) : undefined;
      this.data.fields[index].startDate = dateObj ? dateObj.toISOString(true) : undefined;
    } else if (type === 'endDate') {
      this.fieldsModel[fieldKey].endDate = dateObj ? moment(dateObj.set({hours: 23, minutes: 59})).toISOString(true) : undefined;
      this.data.fields[index].endDate = dateObj ? moment(dateObj.set({hours: 23, minutes: 59})).toISOString(true) : undefined;
    }
    if (!this.updating) {
      this.onFieldsChange.next({fieldKey, currentFieldsModel: this.fieldsModel});
    }
  }

  onSelectionChange(fieldKey: string, value: string): void {
    this.fieldsControls[fieldKey].setValue(value);
    this.onFieldChange(value, fieldKey);
  }
}
