<div class="temporal-list-section">
  <div class="temporal-list-section__add-new">
    <h2>{{'institution-details.side-panel-details-quote-payments-title' | translate}}</h2>
    <button mat-stroked-button
            *ngIf="hasActions"
            color="primary"
            (click)="onAddNewPaymentClick()"
            class="mat-elevation-z1">
      <mat-icon aria-hidden="false" [attr.aria-label]="('institution-details.modal-add-new-item' | translate)">add_circle_outline</mat-icon>
      {{'institution-details.modal-add-new-item' | translate}}
    </button>
  </div>
  <div class="list-box" *ngIf="quotePayments && quotePayments.length > 0">
    <div class="list-box__row" *ngFor="let item of quotePayments; let i = index">
      <p>{{item.paymentDate | date:'YYYY'}}</p>
      <p>{{item.note}}</p>
      <div class="file-view"
           *ngIf="item.paymentDocument">
        <span (click)="onOpenDocumentClick(item.paymentDocument.url)">PDF</span>
        <a
          (click)="onOpenDocumentClick(item.paymentDocument.url)">{{'form-fields.document-download' | translate}}</a>
      </div>
      <p *ngIf="!item?.paymentDocument">—</p>
      <div *ngIf="hasActions"
           class="actions-container-cell"
           [class.info-overlay-parent]="i > 1">
        <button mat-icon-button color="primary" aria-label="azioni" (click)="infoOverlayIndexToShow = i">
          <mat-icon>more_horiz</mat-icon>
        </button>
        <div class="info-backdrop-overlay" *ngIf="infoOverlayIndexToShow !== -1"
             (click)="infoOverlayIndexToShow = -1"></div>
        <div class="info-overlay" *ngIf="infoOverlayIndexToShow === i">
            <p (click)="onActionButtonClick('update', item)">
              {{'info-overlay.action-edit' | translate}}
            </p>
            <p class="delete-text" (click)="onActionButtonClick('delete', item)">
              {{'info-overlay.action-delete' | translate}}
            </p>
        </div>
      </div>
    </div>
  </div>
  <p
    *ngIf="!quotePayments?.length">{{'institution-details.side-panel-details-quote-payments-no-data' | translate}}</p>
</div>
