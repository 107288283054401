<div class="base-modal">
  <div class="base-modal__header" [class.base-modal__header--sticky]="data?.stickyHeader !== false">
    <h3 class="base-modal__header__title" *ngIf="data.title">{{data.title}}</h3>
    <button *ngIf="data?.hasClose !== false" [mat-dialog-close]="false" mat-icon-button class="mat-icon-button--small">
      <mat-icon aria-hidden="false" aria-label="chiudi">close</mat-icon>
    </button>
  </div>
  <div class="base-modal__body"
       [class.base-modal__body--with-sticky-footer]="!data?.noFooter && data?.stickyFooter"
       [class.base-modal__body--with-sticky-header]="data?.stickyHeader !== false">
    <p class="base-modal__body-hint" *ngIf="data.hintHtml" [innerHTML]="data.hintHtml"></p>
    <div class="base-modal__body-html" *ngIf="data.bodyHtml" [innerHTML]="data.bodyHtml"></div>
    <div class="base-modal__fields-container">
      <div class="form-field-container form-field-container--{{field.type}}"
           *ngFor="let field of data.fields"
           [ngStyle]="{gridColumn: 'span ' + field.width, display: field.hidden ? 'none' : undefined}">
        <label *ngIf="field.label">{{field.label}}{{field.required ? '*' : ''}}</label>
        <!-- INPUT TEXT -->
        <mat-form-field *ngIf="field.type === 'text'" appearance="outline">
          <input matInput
                 type="text"
                 [name]="field.fieldKey"
                 [maxLength]="(field.maxLength > 0 ? field.maxLength : 1000)"
                 placeholder=""
                 [formControl]="fieldsControls[field.fieldKey]"
                 [errorStateMatcher]="errorStateMatcher"
                 (ngModelChange)="onFieldChange($event, field.fieldKey)"/>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        </mat-form-field>
        <!-- TEXTAREA -->
        <mat-form-field *ngIf="field.type === 'textarea'" appearance="outline">
					<textarea matInput
                    [name]="field.fieldKey"
                    placeholder=""
                    rows="5"
                    [formControl]="fieldsControls[field.fieldKey]"
                    [errorStateMatcher]="errorStateMatcher"
                    (ngModelChange)="onFieldChange($event, field.fieldKey)"></textarea>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        </mat-form-field>
        <!-- READONLY TEXT -->
        <p class="form-field-locked" *ngIf="field.type === 'text-readonly'">{{field.value}}</p>
        <!-- EMAIL -->
        <mat-form-field *ngIf="field.type === 'email'" appearance="outline">
          <input matInput
                 type="email"
                 [name]="field.fieldKey"
                 placeholder=""
                 [formControl]="fieldsControls[field.fieldKey]"
                 [errorStateMatcher]="errorStateMatcher"
                 (ngModelChange)="onFieldChange($event, field.fieldKey)"/>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('email')">{{'form-validation.email-invalid' | translate}}</mat-error>
        </mat-form-field>
        <!-- NUMBER -->
        <mat-form-field *ngIf="field.type === 'number'" appearance="outline">
          <input matInput
                 type="number"
                 [name]="field.fieldKey"
                 [min]="field.minNumber ? field.minNumber : undefined"
                 [max]="field.maxNumber ? field.maxNumber : undefined"
                 placeholder=""
                 [formControl]="fieldsControls[field.fieldKey]"
                 [errorStateMatcher]="errorStateMatcher"
                 (ngModelChange)="onFieldChange($event, field.fieldKey)"
                 (blur)="checkMaxMin(field.fieldKey)"/>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        </mat-form-field>
        <!-- DATE -->
        <mat-form-field *ngIf="field.type === 'date'" appearance="outline" class="mat-form-field--datepicker-dark">
          <input matInput
                 [name]="field.fieldKey"
                 placeholder=""
                 [min]="field.minDateConstraint ? field.minDateConstraint : undefined"
                 [max]="field.maxDateConstraint ? field.maxDateConstraint : undefined"
                 [matDatepicker]="pickerDate"
                 [errorStateMatcher]="errorStateMatcher"
                 [formControl]="fieldsControls[field.fieldKey]"
                 (ngModelChange)="onFieldChange($event, field.fieldKey)">
          <mat-datepicker-toggle [disableRipple]="true" matPrefix [for]="pickerDate">
            <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="apri calendario">calendar_today</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #pickerDate></mat-datepicker>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        </mat-form-field>
        <!-- CURRENCY -->
        <mat-form-field *ngIf="field.type === 'currency'" appearance="outline">
          <mat-icon aria-hidden="false" aria-label="euro" matPrefix>euro</mat-icon>
          <input matInput
                 type="number"
                 step="0.01"
                 [name]="field.fieldKey"
                 [errorStateMatcher]="errorStateMatcher"
                 [formControl]="fieldsControls[field.fieldKey]"
                 (ngModelChange)="onFieldChange($event, field.fieldKey)"
                 (blur)="formatCurrencyNumber(field.fieldKey)">
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('currencyValueInvalid')">{{'form-validation.currency-value-invalid' | translate}}</mat-error>
        </mat-form-field>
        <!-- DROPDOWN SELECT -->
        <mat-form-field *ngIf="field.type === 'select'" appearance="outline">
          <mat-select placeholder=""
                      [value]="fieldsControls[field.fieldKey].value"
                      [errorStateMatcher]="errorStateMatcher"
                      (selectionChange)="onSelectionChange(field.fieldKey, $event.value)">
            <mat-option *ngFor="let item of field?.options" [value]="item.id">{{item.text}}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
        </mat-form-field>
        <!-- TOGGLE -->
        <mat-slide-toggle *ngIf="field.type === 'toggle'"
                          [formControl]="fieldsControls[field.fieldKey]"
                          (ngModelChange)="onFieldChange($event, field.fieldKey)">{{fieldsModel[field.fieldKey] ? ('base-modal.toggle-enabled' | translate) : ('base-modal.toggle-disabled' | translate)}}</mat-slide-toggle>
        <!-- AUTOCOMPLETE -->
        <mat-form-field *ngIf="field.type === 'autocomplete'" appearance="outline">
          <input matInput
                 [attr.name]="field.fieldKey"
                 [placeholder]="('base-modal.autocomplete-placeholder' | translate)"
                 [matAutocomplete]="auto"
                 [formControl]="fieldsControls[field.fieldKey]"
                 (input)="onAutocompleteTextChange($event, field.fieldKey, field.hasRemoteSearch)">
          <mat-autocomplete #auto="matAutocomplete"
                            [displayWith]="autocompleteDisplayFn"
                            (optionSelected)="onAutocompleteOptionSelected($event, field.fieldKey)">
            <mat-option *ngFor="let a of autocompleteFieldsOptions[field.fieldKey]" [value]="a">
              <span>{{a.text}}</span>
            </mat-option>
          </mat-autocomplete>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('required')">{{'form-validation.required' | translate}}</mat-error>
          <mat-error
            *ngIf="fieldsControls[field.fieldKey].hasError('invalidAutocompleteObject')">{{'form-validation.invalid-option' | translate}}</mat-error>
          <!--mat-hint class="custom-validation-error"
                    *ngIf="autocompleteHasError">{{'form-validation.address-autocomplete-missing-info' | translate}}</mat-hint-->
        </mat-form-field>
        <!-- DATE RANGE -->
        <ng-container *ngIf="field.type === 'date-from-to'">
          <et-datetime-range-picker
            [hideTime]="true"
            [disabled]="field.disabled"
            [beginsAt]="field.startDate"
            [endsAt]="field.endDate"
            (endsAtChange)="onDateChange($event,'endDate', field.fieldKey)"
            (beginsAtChange)="onDateChange($event,'beginDate', field.fieldKey)"></et-datetime-range-picker>
        </ng-container>
        <!-- IMAGE FILE PICKER -->
        <et-file-picker *ngIf="field.type === 'file-picker-image'"
                        [mode]="'image'"
                        [customUploadedLabel]="field.fileCustomUploadedLabel"
                        [multiple]="false"
                        [smallerDrop]="field.width <= 6"
                        [readOnly]="field.disabled"
                        [file]="fieldsModel[field.fieldKey]"
                        (upload)="onUploadOutput($event, field.fieldKey)"
                        (delete)="onAttachedDocumentDelete($event, field.fieldKey)"></et-file-picker>
        <!-- PDF FILE PICKER -->
        <et-file-picker *ngIf="field.type === 'file-picker-pdf'"
                        [mode]="'file'"
                        [customUploadedLabel]="field.fileCustomUploadedLabel"
                        [multiple]="false"
                        [smallerDrop]="field.width <= 6"
                        [readOnly]="field.disabled"
                        [file]="fieldsModel[field.fieldKey]"
                        (upload)="onUploadOutput($event, field.fieldKey)"
                        (delete)="onAttachedDocumentDelete($event, field.fieldKey)"></et-file-picker>
      </div>
    </div>
  </div>
  <div class="base-modal__footer"
       *ngIf="!data?.noFooter"
       [class.base-base-modal__footer--sticky]="data.stickyFooter">
    <button mat-button
            color="primary"
            [mat-dialog-close]="false">{{data?.buttonsTitle?.cancel || ('base-modal.cancel' | translate)}}</button>
    <!-- ACTION MODAL > NORMAL BUTTON -->
    <button *ngIf="!data.isConfirmModal"
            mat-stroked-button
            color="primary"
            class="mat-elevation-z1"
            [disabled]="isConfirmDisabled()"
            (click)="onConfirmClick()">
      <mat-icon aria-hidden="false" aria-label="conferma">done</mat-icon>
      {{data?.buttonsTitle?.confirm || ('base-modal.confirm' | translate)}}
    </button>
    <!-- CONFIRM MODAL > RED BUTTON -->
    <button *ngIf="!!data.isConfirmModal"
            mat-stroked-button
            color="warn"
            class="mat-elevation-z1"
            (click)="onConfirmClick()">{{data?.buttonsTitle?.confirm || ('base-modal.confirm' | translate)}}</button>
  </div>
</div>
