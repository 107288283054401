import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject }                                        from 'rxjs';
import { debounceTime }                                   from 'rxjs/operators';
import { ActivatedRoute, Params, Router }                 from '@angular/router';


export enum FilterTypes {
  HIDDEN = 'hidden',
  TEXT = 'text',
  KEYWORD = 'keyword',
  DROPDOWN = 'dropdown',
  DATE = 'date'
}

export interface FilterSetting {
  fieldKey: string;
  placeholder?: string;
  type: FilterTypes;
  values?: Array<any>;
  defaultValue?: any;
}

export interface FilterInternal extends FilterSetting {
  newValue?: any;
}

@Component({
  selector: 'et-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  internalFilters: Array<FilterInternal> = [];
  FilterTypes = FilterTypes;

  @Input() filters?: Array<FilterSetting>;
  @Input() queryParamMode?: boolean;
  @Output() filtersChanged = new EventEmitter();
  // to avoid doing a query every time user types a word
  private changesSubject = new Subject<FilterInternal[]>();

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.changesSubject.pipe(debounceTime(300)).subscribe(newFilters => this.filtersChanged.emit(newFilters));
  }

  ngOnInit(): void {
    if (this.filters && this.filters.length > 0) {
      // boolean per triggerare i changes
      let thereAreDefaultValues = false;

      this.internalFilters = this.filters.map((el) => {
        const obj: any = {...el};
        if (el.defaultValue) {
          thereAreDefaultValues = true;
        }
        obj.newValue = el.defaultValue;
        return obj;
      }, []);

      let thereAreQueryParams = false;
      // query params things
      if (this.queryParamMode) {
        this.activatedRoute.queryParams.subscribe(params => {
          thereAreQueryParams = true;
          this.internalFilters.forEach(el => {
            Object.keys(params).forEach(key => {
              if (el.fieldKey === key) {
                // route params values are always string
                el.newValue = params[key];
              }
            });
          });
        });
      }

      if (thereAreDefaultValues || thereAreQueryParams) {
        this.onSubjectChange();
      }
    }
  }

  onSubjectChange(): void {
    this.changesSubject.next(this.internalFilters);

    if (this.queryParamMode) {
      this.onQueryParamsChange();
    }
  }

  onKeywordSearchClicked(event: any, filter?: FilterInternal): void {
    // if (filter && filter.newValue?.length <= 2) {
    //   return;
    // }
    this.filtersChanged.emit(this.internalFilters);
    if (this.queryParamMode) {
      this.onQueryParamsChange();
    }
  }

  onQueryParamsChange(): void {
    const filtersThatChanged = this.internalFilters.filter(filter => {
      return filter.newValue;
    });

    if (filtersThatChanged && filtersThatChanged.length > 0) {
      const queryParams: Params = {};
      filtersThatChanged.forEach(el => {
        queryParams[el.fieldKey] = el.newValue._isAMomentObject ? el.newValue.toISOString(true) : el.newValue;
      });
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams,
          queryParamsHandling: null, // remove to replace all query params by provided
        });
    }
  }

}
