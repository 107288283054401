import { NgModule }              from '@angular/core';
import { CommonModule }          from '@angular/common';
import { DashboardComponent }    from './dashboard.component';
import { MatButtonModule }       from '@angular/material/button';
import { FilterableTableModule } from '../shared/filterable-table/filterable-table.module';
import { LoadingModule }         from '../shared/loading/loading.module';
import { TranslateModule }       from '@ngx-translate/core';
import { ChartsModule }          from 'ng2-charts';
import { MatIconModule }         from '@angular/material/icon';
import { RouterModule }          from '@angular/router';



@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    FilterableTableModule,
    LoadingModule,
    TranslateModule,
    ChartsModule,
    MatIconModule,
    RouterModule
  ]
})
export class DashboardModule { }
