<div class="datetime-range-picker" [class.datetime-range-picker--is-disabled]="disabled">
  <mat-form-field appearance="outline" class="mat-form-field--datepicker-dark">
    <mat-datepicker-toggle matPrefix [for]="pickerFrom">
      <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="data dal">date_range</mat-icon>
    </mat-datepicker-toggle>
    <small class="mat-form-field__placeholder">dal</small>
    <input matInput [matDatepicker]="pickerFrom"
           [(ngModel)]="startDate"
           [disabled]="disabled"
           placeholder="{{'datetime-range-picker.choose-a-date' | translate}}"
           (dateChange)="onDateTimeChange()">
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>
  <mat-form-field appearance="outline" class="mat-form-field--datepicker-dark">
    <mat-datepicker-toggle matPrefix [for]="pickerTo">
      <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="data dal">date_range</mat-icon>
    </mat-datepicker-toggle>
    <small class="mat-form-field__placeholder">al</small>
    <input matInput [matDatepicker]="pickerTo"
           [(ngModel)]="endDate"
           [disabled]="disabled"
           placeholder="{{'datetime-range-picker.choose-a-date' | translate}}"
           (dateChange)="onDateTimeChange()">
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>
</div>
