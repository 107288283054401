import { NgModule }                     from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { AdminUnapproveFieldComponent } from './admin-unapprove-field.component';
import { MatIconModule }                from '@angular/material/icon';
import { TranslateModule }              from '@ngx-translate/core';
import { MatButtonModule }              from '@angular/material/button';


@NgModule({
  declarations: [AdminUnapproveFieldComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule
  ],
  exports: [
    AdminUnapproveFieldComponent
  ]
})
export class AdminUnapproveFieldModule {
}
