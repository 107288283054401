import { NgModule }                                       from '@angular/core';
import { APOLLO_OPTIONS }                                 from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink }                                       from 'apollo-angular/http';
import { environment }                                    from '../../../environments/environment';
import { HttpClientModule }                               from '@angular/common/http';
import graphqlSchemaData                                  from '../../../../graphql.schema.json';
import { onError }                                        from '@apollo/client/link/error';
import { setContext }                                     from '@apollo/client/link/context';
import { createUploadLink }                               from 'apollo-upload-client';


const possibleTypes: { [keyprop: string]: any[] } = {};
graphqlSchemaData.__schema.types.forEach((supertype: any) => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map((subtype: any) => subtype.name);
  }
});

const getHeaders = () => {
  const headers: any = {};
  const accessToken = localStorage.getItem('access-token');
  if (accessToken) {
    headers['access-token'] = accessToken;
  }
  const client = localStorage.getItem('client');
  if (client) {
    headers.client = client;
  }
  const tokenType = localStorage.getItem('token-type');
  if (tokenType) {
    headers['token-type'] = tokenType;
  }
  const expiry = localStorage.getItem('expiry');
  if (expiry) {
    headers.expiry = expiry;
  }
  const uid = localStorage.getItem('uid');
  if (uid) {
    headers.uid = uid;
  }
  return {headers};
};

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.map(({message, locations, path}) =>
        console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const authLink = setContext(getHeaders);

  const appendTotalLink = new ApolloLink((operation, forward) => {
    return forward(operation).map(response => {
      const context = operation.getContext();
      const {response: {headers}} = context;
      if (headers) {
        const total = headers.get('total');
        if (total && response.data) {
          response.data.total = total;
        }
      }
      return response;
    });
  });

  // by default use this directly with Apollo.from in link prop:
  // httpLink.create({uri: environment.graphqlEndpoint});
  // if you have to upload File, Blob, etc please do this and do that crazy conversion above in order to succeed compilation:
  const httpWithFile = createUploadLink({uri: environment.graphqlEndpoint});

  return {
    cache: new InMemoryCache({
      addTypename: true,
      possibleTypes
    }),
    link: authLink.concat(errorLink).concat(appendTotalLink).concat((httpWithFile) as unknown as ApolloLink)
  };
}

@NgModule({
  imports: [
    HttpClientModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ]
})
export class GraphQLModule {
}



