import { NgModule }                     from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { ExpirationsAllComponent }      from './expirations-all.component';
import { LoadingModule }                from '../shared/loading/loading.module';
import { MatTabsModule }                from '@angular/material/tabs';
import { TranslateModule }              from '@ngx-translate/core';
import { ExpirationRemindsTableModule } from '../shared/expiration-reminds-table/expiration-reminds-table.module';
import { MatButtonModule }              from '@angular/material/button';
import { MatIconModule }                from '@angular/material/icon';



@NgModule({
  declarations: [ExpirationsAllComponent],
  imports: [
    CommonModule,
    LoadingModule,
    MatTabsModule,
    TranslateModule,
    ExpirationRemindsTableModule,
    MatButtonModule,
    MatIconModule
  ]
})
export class ExpirationsAllModule { }
