import { NgModule }              from '@angular/core';
import { CommonModule }          from '@angular/common';
import { DepartmentsComponent }  from './departments.component';
import { FilterableTableModule } from '../shared/filterable-table/filterable-table.module';
import { TranslateModule }       from '@ngx-translate/core';


@NgModule({
  declarations: [DepartmentsComponent],
  imports: [
    CommonModule,
    FilterableTableModule,
    TranslateModule
  ]
})
export class DepartmentsModule {
}
