import { Component, OnInit }       from '@angular/core';
import { DepartmentGQL }           from '../../generated/graphql';
import { ActivatedRoute }          from '@angular/router';
import { MatSnackBar }             from '@angular/material/snack-bar';
import { TranslatePipe }           from '@ngx-translate/core';
import { getErrorMessageFromData } from '../shared/utils/helpers';

@Component({
  selector: 'et-department-details',
  templateUrl: './department-details.component.html',
  styleUrls: ['./department-details.component.scss'],
  providers: [TranslatePipe]
})
export class DepartmentDetailsComponent implements OnInit {
  departmentData: any = {};
  busy = true;

  constructor(private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private activatedRoute: ActivatedRoute,
              private departmentGQL: DepartmentGQL) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.busy = true;
      this.departmentGQL.fetch({id: routeParams.id}, {fetchPolicy: 'no-cache'}).subscribe(res => {
        this.departmentData = res.data.department;
        this.busy = false;
      }, error => {
        this.busy = false;
        this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      });
    });
  }

}
