import { NgModule }         from '@angular/core';
import { LoadingComponent } from './loading.component';


@NgModule({
  declarations: [LoadingComponent],
  exports: [LoadingComponent]
})
export class LoadingModule {
}
