import { AbstractControl, FormArray, FormControl, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher }                                                                            from '@angular/material/core';
import { ChartOptions }                                                                                 from 'chart.js';
import {
  LabelOptions
}                                                                                                       from 'chartjs-plugin-datalabels/types/options';

export function getTimeOptions(): string[] {
  const timeOptions = [];
  const minutes = ['00', '15', '30', '45'];
  for (let h = 0; h < 24; h++) {
    // tslint:disable-next-line:prefer-for-of
    for (let m = 0; m < minutes.length; m++) {
      timeOptions.push(`${h < 10 ? '0' : ''}${h}:${minutes[m]}`);
    }
  }
  return timeOptions;
}

export function addHttp(url: string): string {
  if (!url) {
    return '';
  }
  if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
    url = 'http://' + url;
  }
  return url;
}

export function removeHttp(url: string): string {
  return url.replace(/^https?\:\/\//i, '');
}

export enum ScreenSizes {
  SM = 768,
  MD = 1024,
  LG = 1450,
  XL = 1600
}

export enum ErrorMessageFieldnames {
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'passwordConfirmation',
  CURRENT_PASSWORD = 'currentPassword'
}

export function getErrorMessageFromData(err: any, fieldName?: ErrorMessageFieldnames): string {
  let errorMessage = '';
  let errors;
  if (err.graphQLErrors && err.graphQLErrors.length > 0) {
    errors = err.graphQLErrors;
  } else if (err.errors && err.errors.length > 0) {
    errors = err.errors;
  }
  if (errors) {
    let stop = false;
    for (let i = 0; i < errors.length && !stop; i++) {
      const field = errors[i].extensions ? errors[i].extensions.field : null;
      if (fieldName === field) {
        errorMessage = errors[i].message;
        stop = true;
      } else if (field === null || !fieldName) {
        errorMessage = errorMessage.concat(`${errors[i].message}\n`);
      }
    }
  } else {
    errorMessage = err.message;
  }
  if (errorMessage.length > 200) {
    errorMessage = `${errorMessage.substr(0, 200)}...`;
  }
  return errorMessage;
}

export function getDefaultModalSettings(): any {
  return {
    panelClass: 'base-modal-container',
    autoFocus: false,
    minWidth: '90vw',
    width: '90vw',
    maxWidth: '90vw',
    minHeight: '70vh',
    height: '70vh',
    maxHeight: '70vh'
  };
}

export function getFixedSmallModalSettings(): any {
  return {
    panelClass: 'base-modal-container-fixed-small',
    autoFocus: false,
    minWidth: '640px',
    width: '640px',
    maxWidth: '640px',
    maxHeight: '90vh'
  };
}

export function isLowerThan1450(): boolean {
  return window.matchMedia('(max-width: ' + (ScreenSizes.LG - 1) + 'px)').matches;
}

export function getMinDate(daysToAdd?: number, isForPicker?: boolean): Date {
  // if we are on validation (!isForPicker) gets almost today midnight: 23.59.59.999 of yesterday ;)
  const theMillisecond = isForPicker ? 0 : 1;
  return new Date(new Date().setTime(new Date().setHours(0, 0, 0, 0) + ((1 + (daysToAdd || 0)) * 24 * 60 * 60 * 1000) - theMillisecond));
}

export function _arrayBufferToBase64(buffer: Iterable<number>): any {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function copyToClipboard(stringVal: string): void {
  const el = document.createElement('textarea');
  el.value = stringVal;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}


export function minSelectedCheckboxes(min = 1): ValidatorFn {
  // @ts-ignore
  return (formArray: FormArray) => {
    const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
      .map(control => control.value)
      // total up the number of checked checkboxes
      .reduce((prev, next) => next ? prev + next : prev, 0);

    // if the total is not greater than the minimum, return the error message
    return totalSelected >= min ? null : {required: true};
  };
}

export function autocompleteRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (typeof control.value === 'string') {
      return {invalidAutocompleteObject: {value: control.value}};
    }
    return null;
  };
}


export class ABErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.dirty && control.invalid) as boolean;
  }
}

export function createRequiredFieldFormControlFromModel(model: any): any {
  return Object.keys(model).map(key => ({
    [key]: new FormControl(model[key], {
      validators: [Validators.required],
      // updateOn: 'blur'
    })
  })).reduce((acc, obj) => ({...acc, ...obj}), {});
}

// ex. obj = {'a':{'11': 'value1', '22': 'value2'}, b: 'value3', c: [{'x': 'value4', y: 'value5'} ,{'x': 'value6', y: 'value7'}], d:[2,2]}
// usage getObjectValueByPath(obj, 'a.22') > 'value2'
// usage getObjectValueByPath(obj, 'c.0.x') > 'value4'
// usage getObjectValueByPath(obj, 'b') > 'value3'
export function getObjectValueByPath(obj: any, path: string): any {
  for (let i = 0, pathArr: Array<string> = path.split('.'); i < pathArr.length; i++) {
    if (Array.isArray(obj[pathArr[i]])) {
      obj = obj[pathArr[i]][pathArr[i + 1]];
      i++;
    } else {
      obj = obj[pathArr[i]];
    }
  }
  return obj;
}

// usage setObjectValueByPath(obj, 'a.22', 'newValue') > 'value2' changed with newValue
// usage setObjectValueByPath(obj, 'c.0.x', 'newValue') > 'value4' changed with newValue
// This sets a value for only an object. Cannot do getObjectValueByPath(obj, 'd.0', 10) to expect d:[10,2].
// You might need to extend the function purpose mate.
export function setObjectValueByPath(obj: any, path: string, value: any): any {
  let stop = false;
  for (let i = 0, pathArr: Array<string> = path.split('.'); i < pathArr.length && !stop; i++) {
    if (Array.isArray(obj[pathArr[i]])) {
      obj = obj[pathArr[i]][pathArr[i + 1]];
      i++;
    } else {
      if (i === pathArr.length - 1) {
        obj[pathArr[i]] = value;
        stop = true;
      } else {
        obj = obj[pathArr[i]];
      }
    }
  }
}


export function roundCurrencyValue(value: number): string {
  return (Math.round(value * 100) / 100).toFixed(2);
}


export function getBarChartBaseOptions(chartOptions?: ChartOptions, datalabelsOptions?: LabelOptions): ChartOptions {
  return {
    tooltips: chartOptions?.tooltips ? chartOptions.tooltips : {
      enabled: false
    },
    title: {
      text: '',
      display: true
    },
    responsive: true,
    showLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: chartOptions?.scales?.xAxes ? chartOptions?.scales?.xAxes[0].ticks : {
          // fontSize: 12,
          maxRotation: 0
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: false,
          beginAtZero: true,
          // stepSize: 1
        }
      }]
    },
    layout: {
      padding: {
        top: 0,
      }
    },
    plugins: {
      datalabels: datalabelsOptions ? datalabelsOptions : {
        anchor: 'end',
        align: 'end'
      }
    }
  };
}

export function getHorizontalBarChartBaseOptions(): ChartOptions {
  return {
    tooltips: {
      enabled: false
    },
    maintainAspectRatio: false, // this saves your day if you set it here and if you put a div container of your canvas with a preset height.
    responsive: true,
    showLines: false,
    scales: {
      xAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          display: false,
          suggestedMin: 0
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        },
        ticks: {
          lineHeight: 1.2,
          backdropPaddingY: 10,
          backdropPaddingX: 5,
          fontSize: 10
        }
      }]
    },
    layout: {
      padding: 0
    },
    plugins: {
      datalabels: {
        color: 'white',
        anchor: 'end',
        align: 'start',
        font: {
          weight: 'bold'
        }
      }
    }
  };
}


export function splitSentence(sentence: string, desiredCount: number = 20) : string[] {
  // Split the sentence into words
  const words = sentence.split(' ');
  // Prepare the list of rows
  let rows: string[] = [];
  let rowIndex = 0;
  for (let i = 0; i < words.length; i++) {
    // Retrive the current word
    let currentWord = words[i];
    // Retrieve the current row or an empty string
    var currentRow = (rows.length > rowIndex) ? rows[rowIndex] : "";
    if (!currentRow.length) {
      // If the row is empty, we fill it with the current word
      currentRow = currentWord;
    } else if ((currentRow.length + currentWord.length) < desiredCount) {
      // If the row is not empty but it can still contain the current word,
      // let's add it to the end of the current row
      currentRow = `${currentRow} ${currentWord}`;
  } else {
      // Otherwise, let's add a new row
      currentRow = currentWord;
      rowIndex += 1;
    }
    if (rows.length > rowIndex) {
      // Update the current row
      rows[rowIndex] = currentRow;
    } else {
      // Add a new row
      rows.push(currentRow);
    }
  }
  return rows;
}
