<div class="department-details">
  <div class="department-details__header">
    <h1>{{departmentData.name}}</h1>
  </div>
  <et-loading class="spacing-top-40" *ngIf="busy"></et-loading>
  <div class="department-details__content" *ngIf="!busy">
    <ng-container *ngIf="departmentData.adherentInstitutionsInfo?.length > 0">
      <h2>{{'department-details.adherent-institutions-title' | translate}}</h2>
      <div class="adherent-institution-item"
           *ngFor="let item of departmentData.adherentInstitutionsInfo; index as i">
        <div class="institution-column">
          <div>
            <a *ngIf="item.institution?.isVisible"
               [routerLink]="['/enti-spinoff/', item.institution?.id]">{{ item.institution?.vName?.genericValue || ('no-name.institution' | translate)}}</a>
            <p *ngIf="!item.institution?.isVisible">{{ item.institution?.vName?.genericValue || ('no-name.institution' | translate)}}</p>
          </div>
        </div>
        <div>
          <h4>{{'department-details.adherent-institution-person' | translate}}</h4>
          <p>{{item.person?.name || '-'}}</p>
        </div>
        <span></span>
      </div>
    </ng-container>
    <p
      *ngIf="!departmentData.adherentInstitutionsInfo?.length">{{'department-details.adherent-institutions-no-data' | translate}}</p>
  </div>
</div>
