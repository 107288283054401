import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReferentsAndRolesService {

  private roleDetailsSubject: Subject<{ referentId: string, item: any }> = new Subject();
  public roleDetailsObservable = this.roleDetailsSubject.asObservable();

  constructor() {
  }

  updateRoleDetails(referentId: string, item: any): void {
    this.roleDetailsSubject.next({referentId, item});
  }
}
