import { Component, OnInit }                            from '@angular/core';
import { UserService }                                  from '../core/user/user.service';
import { Router }                                       from '@angular/router';
import { FormBuilder, FormGroup, Validators }           from '@angular/forms';
import { ABErrorStateMatcher, getErrorMessageFromData } from '../shared/utils/helpers';
import { MeGQL, UserSignInGQL }                         from '../../generated/graphql';
import { MatSnackBar }                                  from '@angular/material/snack-bar';
import { TranslatePipe }                                from '@ngx-translate/core';
import { environment }                                  from '../../environments/environment';

@Component({
  selector: 'et-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [TranslatePipe]
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;
  busy = false;
  hidePassword = true;
  errorMessage: any;
  errorStateMatcher = new ABErrorStateMatcher();

  constructor(private userSignInGQL: UserSignInGQL,
              private meGQL: MeGQL,
              private userService: UserService,
              private router: Router,
              private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private formBuilder: FormBuilder) {
    this.signInForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.required]
    }, {updateOn: 'change'});

  }

  ngOnInit(): void {
    localStorage.removeItem('shibo-login');
  }

  // Classic login

  onSubmit(userData: any): void {
    this.busy = true;
    this.errorMessage = null;
    this.userSignInGQL.mutate({
      input: {
        email: {
          email: userData.email,
          password: userData.password
        }
      }
    }, {fetchPolicy: 'no-cache'}).subscribe(res => {
      localStorage.setItem('shibo-login', 'no');
      this.userService.requestOverlay();
      UserService.setHeaders(res.data?.userSignIn.authHeaders);
      this.userService.setUser(res.data?.userSignIn.user);
      window.location.reload();
    }, err => {
      this.snackBar.open(getErrorMessageFromData(err), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
      this.busy = false;
    });
  }

  // Outer login

  openOuterLogin(): void {
    this.userService.requestOverlay();
    const popup = window.open(`${environment.graphqlEndpoint.split('/graphql')[0]}/saml/login_init`, 'Entity', 'width=700,height=700,left=200,top=200');
    window.addEventListener('message', (event) => {
      if (event.origin !== environment.graphqlEndpoint.split('/graphql')[0]) {
        return;
      }
      if (event && event.data) {
        const response = JSON.parse(event.data);
        if (response.errors) {
          this.snackBar.open(this.translatePipe.transform('sign-in.error-unauthorised'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
          console.error(response.errors);
        } else {
          localStorage.setItem('shibo-login', 'yes');
          UserService.setHeaders(response.headers);
          this.meGQL.fetch({}, {fetchPolicy: 'network-only'}).subscribe(res => {
            this.userService.setUser(res.data.me);
            window.location.reload();
          });
        }
      }
      if (popup) {
        popup.close();
      }
    }, false);
  }
}
