import { Component, OnInit }                          from '@angular/core';
import { PaginationData, TableData }                  from '../shared/filterable-table/filterable-table.component';
import { FilterInternal, FilterSetting, FilterTypes } from '../shared/filters/filters.component';
import { TranslatePipe }                              from '@ngx-translate/core';
import { Router }                                     from '@angular/router';
import { Department, DepartmentSearchGQL }            from '../../generated/graphql';
import { getErrorMessageFromData }                    from '../shared/utils/helpers';
import { MatSnackBar }                                from '@angular/material/snack-bar';

export interface DepartmentRow {
  id: string;
  name: string | undefined;
}

export interface DepartmentFilters {
  keyword?: string | undefined;
}

@Component({
  selector: 'et-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.scss'],
  providers: [TranslatePipe]
})
export class DepartmentsComponent implements OnInit {

  tableLoading = true;
  departmentsTableConfig: TableData;
  paginationData: PaginationData = {
    pageSize: 10
  };
  filtersData: DepartmentFilters = {};
  filtersConfig: FilterSetting[] | undefined;

  private static formatArrayToMatchTableData(arrayToFormat: any): Array<DepartmentRow> {
    return arrayToFormat.filter((d: Department) => !d.fake).map((el: Department) => {
      return {
        id: el.id,
        name: el.name
      };
    }, []);
  }

  constructor(private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private router: Router,
              private departmentSearchGQL: DepartmentSearchGQL) {
    this.departmentsTableConfig = {
      list: [],
      columnsConfig: [
        {dataToShowKey: 'name', headerTitleKey: 'departments.name'}
      ]
    };
  }

  ngOnInit(): void {

    this.filtersConfig = [
      {
        fieldKey: 'keyword',
        placeholder: this.translatePipe.transform('departments.filter-keyword-placeholder'),
        type: FilterTypes.KEYWORD,
        defaultValue: ''
      }
    ];
  }

  private fetchDepartments(first?: number, last?: number, after?: string, before?: string, keyword?: string): void {
    this.tableLoading = true;
    this.departmentSearchGQL.fetch({
        first,
        last,
        after,
        before,
        keyword: keyword || ''
      },
      {fetchPolicy: 'no-cache'}
    ).subscribe(res => {
      this.departmentsTableConfig.list = DepartmentsComponent.formatArrayToMatchTableData(res.data.departmentSearch?.edges?.map((e) => e?.node));
      this.paginationData.totalCount = res.data.departmentSearch?.totalCount;
      this.paginationData.pageInfo = res.data.departmentSearch?.pageInfo;
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }


  openDepartmentDetail(rowData: any): void {
    this.router.navigate([`/dipartimenti/${rowData.id}`]);
  }

  onNewFilters(newFilters: FilterInternal[]): void {
    const keywordFilter = newFilters.find(filter => filter.fieldKey === 'keyword');
    let keywordValue;
    if (keywordFilter) {
      keywordValue = keywordFilter.newValue;
    }
    if (keywordValue !== this.filtersData.keyword) {
      if (this.departmentsTableConfig.list?.length > 0) {
        this.departmentsTableConfig.list = [];
      }
      this.filtersData.keyword = keywordValue;
      this.fetchDepartments(this.paginationData.pageSize, undefined, undefined, undefined, this.filtersData.keyword);
    }
  }

  onPageChange(event: { pageSize: number; after: any; before?: any }): void {
    if (event.after && !event.before) {
      // go forward
      this.fetchDepartments(this.paginationData.pageSize, undefined, event.after, undefined,
        this.filtersData.keyword);
    } else if (event.before) {
      // go backward
      this.fetchDepartments(undefined, this.paginationData.pageSize, undefined,
        event.before, this.filtersData.keyword);
    }
  }
}
