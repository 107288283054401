import { NgModule }                     from '@angular/core';
import { CommonModule }                 from '@angular/common';
import { DatetimeRangePickerComponent } from './datetime-range-picker.component';
import { TranslateModule }              from '@ngx-translate/core';
import { MatInputModule }               from '@angular/material/input';
import { MatDatepickerModule }          from '@angular/material/datepicker';
import { MatIconModule }                from '@angular/material/icon';
import { FormsModule }                  from '@angular/forms';


@NgModule({
  declarations: [DatetimeRangePickerComponent],
  exports: [DatetimeRangePickerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatInputModule,
    MatDatepickerModule,
    MatIconModule,
    FormsModule
  ]
})
export class DatetimeRangePickerModule {
}
