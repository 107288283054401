<div class="expiration-reminds-table">
  <div class="expiration-reminds-table__title-ct">
    <h2>{{title}}</h2>
    <button mat-stroked-button
            color="primary"
            *ngIf="hasActions"
            (click)="onAddNewExpirationRemindClick()"
            class="mat-elevation-z1">
      <mat-icon aria-hidden="false" [attr.aria-label]="('expirations-all.add-expiration' | translate)">
        add_circle_outline
      </mat-icon>
      {{'expirations-all.add-expiration' | translate}}
    </button>
  </div>
  <div class="list-box" *ngIf="reminds">
    <div class="list-box__header">
      <h3>{{'expiration-reminds-table.description' | translate}}</h3>
      <h3 *ngIf="showInstitution" [class.sort-selected]="sortDirection.institution">
        <mat-icon *ngIf="sortDirection.institution === 'desc'"
                  (click)="sortListBy('institution', 'asc')" aria-hidden="false" aria-label="ordina">arrow_drop_down
        </mat-icon>
        <mat-icon *ngIf="!sortDirection.institution || sortDirection.institution === 'asc'"
                  (click)="sortListBy('institution', 'desc')" aria-hidden="false" aria-label="ordina">arrow_drop_up
        </mat-icon>
        {{'expiration-reminds-table.institution-name' | translate}}</h3>
      <h3 [class.sort-selected]="sortDirection.date">
        <mat-icon *ngIf="sortDirection.date === 'desc'"
                  (click)="sortListBy('date', 'asc')" aria-hidden="false" aria-label="ordina">arrow_drop_down
        </mat-icon>
        <mat-icon *ngIf="!sortDirection.date || sortDirection.date === 'asc'"
                  (click)="sortListBy('date', 'desc')" aria-hidden="false" aria-label="ordina">arrow_drop_up
        </mat-icon>
        {{'expiration-reminds-table.expiry-date' | translate}}</h3>
      <h3>{{'expiration-modal.field--notificationDate' | translate}}</h3>
    </div>
    <ng-container *ngFor="let item of reminds; index as i">
      <div class="list-box__row expiration-reminds-table__item"
           *ngIf="archiveCheckables[i] !== true"
           [class.expiration-reminds-table__item--date-expired]="isExpiredItem(item.date)"
           [class.expiration-reminds-table__item--created-automatically]="item.createdAutomatically"
           [class.expiration-reminds-table__item--archived]="item.archived">
        <div>
          <ng-container *ngIf="showArchiveCheckbox">
            <mat-checkbox [(ngModel)]="archiveCheckables[i]"
                          color="primary"
                          (ngModelChange)="onArchiveChecked(item)">&nbsp;
            </mat-checkbox>
            <!--            <mat-checkbox *ngIf="item.archived"-->
            <!--                          [checked]="true"-->
            <!--                          [disabled]="item.archived"-->
            <!--                          color="primary">&nbsp;-->
            <!--            </mat-checkbox>-->
          </ng-container>
          <p>{{item.description}}
            <mat-icon *ngIf="item.createdAutomatically" aria-hidden="false" aria-label="creata automaticamente"
                      [title]="('expiration-reminds-table.created-automatically'  | translate)">auto_mode
            </mat-icon>
          </p>
        </div>
        <p *ngIf="showInstitution">{{item.institution?.vName?.genericValue}}</p>
        <p>{{item.date | date: 'dd/MM/yyyy'}}</p>
        <p>{{item.notificationDate | date: 'dd/MM/yyyy'}}</p>
        <button *ngIf="hasActions"
                mat-stroked-button
                color="primary"
                (click)="onEditExpirationRemindClick(item)"
                class="mat-elevation-z1 edit-expiration-remind">
          {{'expiration-reminds-table.edit-button' | translate}}
        </button>
      </div>
    </ng-container>
    <p class="list-box__no-data" *ngIf="hasNoData()">{{'expiration-reminds-table.no-data' | translate}}</p>
  </div>
</div>
