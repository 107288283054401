import { Component }                                          from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE }     from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Overlay, ScrollStrategy }                            from '@angular/cdk/overlay';
import { MAT_SELECT_SCROLL_STRATEGY }                         from '@angular/material/select';
import { MatPaginatorIntl }                                   from '@angular/material/paginator';
import { MatPaginatorI18nService }                            from './shared/utils/mat-paginator-i18n.service';
import { DomSanitizer }                                       from '@angular/platform-browser';
import { UserService }                                        from './core/user/user.service';
import { TranslateService }                                   from '@ngx-translate/core';
import { MatIconRegistry }                                    from '@angular/material/icon';

export function scrollFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

export const SF_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'et-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'it'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: SF_DATE_FORMATS},
    {provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay]},
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    }
  ]
})
export class AppComponent {

  mobileForced = false;

  constructor(private translate: TranslateService,
              public userService: UserService,
              private matIconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private dateAdapter: DateAdapter<any>) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('it');
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('it');
    // @ts-ignore
    document.querySelector('html').setAttribute('lang', translate.currentLang);

    this.dateAdapter.setLocale('it');

    window.addEventListener('dragover', (e) => {
      // @ts-ignore
      e = e || event;
      e.preventDefault();
    }, false);

    matIconRegistry.addSvgIcon('pdf', sanitizer.bypassSecurityTrustResourceUrl('assets/images/pdf.svg'))
      .addSvgIcon('file-uploaded-check', sanitizer.bypassSecurityTrustResourceUrl('assets/images/file-uploaded-check.svg'))
      .addSvgIcon('download', sanitizer.bypassSecurityTrustResourceUrl('assets/images/download.svg'));
  }
}
