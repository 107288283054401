<div class="persons">
  <et-filterable-table [tableData]="personsTableConfig"
                       [tableTitle]="('persons.title' | translate)"
                       [filters]="filtersConfig"
                       [paginationData]="paginationData"
                       [showDoSearchHint] = "filtersData.keyword === undefined"
                       [hasPagination]="true"
                       [isRowClickable]="true"
                       [dataLoading]="tableLoading"
                       [hasStickyHeader]="true"
                       [filtersQueryParamMode]="true"
                       [hasAddNewButton] = "!isAdmin && !isViewer"
                       [addNewButtonText]="('persons.add-person' | translate)"
                       [addNewButtonIcon]="'person_add'"
                       (addNewClicked)="openAddNewPersonModal()"
                       (filtersChanged)="onNewFilters($event)"
                       (rowClicked)="openPersonDetail($event)"
                       (pageChange)="onPageChange($event)"></et-filterable-table>
</div>
