<div class="departments">
  <et-filterable-table [tableData]="departmentsTableConfig"
                       [tableTitle]="('departments.title' | translate)"
                       [filters]="filtersConfig"
                       [paginationData]="paginationData"
                       [hasPagination]="true"
                       [isRowClickable]="true"
                       [dataLoading]="tableLoading"
                       [hasStickyHeader]="true"
                       [filtersQueryParamMode]="true"
                       (filtersChanged)="onNewFilters($event)"
                       (rowClicked)="openDepartmentDetail($event)"
                       (pageChange)="onPageChange($event)"></et-filterable-table>
</div>
