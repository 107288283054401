<div class="statistics">
  <div class="statistics__header">
    <h1>{{'statistics.title' | translate}}</h1>
  </div>
  <mat-tab-group dynamicHeight
                 (selectedIndexChange)="onTabChange($event)">
    <mat-tab [label]="('statistics.tab-label-by-sector' | translate)">
      <!--      <et-loading *ngIf="busy" class="spacing-top-40"></et-loading>-->
      <div class="statistics__content">
        <et-filters [filters]="filtersConfiguration['institutionsPerSector']"
                    (filtersChanged)="onFiltersChanged($event, 'institutionsPerSector')"></et-filters>
        <div class="statistics__charts-container">
          <div *ngIf="charts['institutionsPerSector'].datasets[0].data?.length">
            <canvas baseChart
                    [datasets]="charts['institutionsPerSector'].datasets"
                    [labels]="charts['institutionsPerSector'].labels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="false"
                    [chartType]="'bar'">
            </canvas>
          </div>
          <p class="no-chart-data"
             *ngIf="!charts['institutionsPerSector'].datasets[0].data?.length">{{'statistics.chart-no-data' | translate}}</p>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="('statistics.tab-label-by-department' | translate)">
      <div class="statistics__content">
        <et-filters [filters]="filtersConfiguration['institutionsPerDepartment']"
                    (filtersChanged)="onFiltersChanged($event, 'institutionsPerDepartment')"></et-filters>
        <div class="statistics__charts-container">
          <div *ngIf="charts['institutionsPerDepartment'].datasets[0].data?.length">
            <canvas baseChart
                    [datasets]="charts['institutionsPerDepartment'].datasets"
                    [labels]="charts['institutionsPerDepartment'].labels"
                    [options]="barChartInstitutionsPerDepartmentsOptions"
                    [plugins]="barChartPlugins"
                    [legend]="false"
                    [chartType]="'bar'">
            </canvas>
          </div>
          <p class="no-chart-data"
             *ngIf="!charts['institutionsPerDepartment'].datasets[0].data?.length">{{'statistics.chart-no-data' | translate}}</p>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="('statistics.tab-label-by-recognition-year' | translate)">
      <div class="statistics__content">
        <et-filters [filters]="filtersConfiguration['institutionsPerRecognitionYear']"
                    (filtersChanged)="onFiltersChanged($event, 'institutionsPerRecognitionYear')"></et-filters>
        <div class="statistics__charts-container">
          <div *ngIf="charts['institutionsPerRecognitionYear'].datasets[0].data?.length">
            <canvas baseChart
                    [datasets]="charts['institutionsPerRecognitionYear'].datasets"
                    [labels]="charts['institutionsPerRecognitionYear'].labels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="false"
                    [chartType]="'bar'">
            </canvas>
          </div>
          <p class="no-chart-data"
             *ngIf="!charts['institutionsPerRecognitionYear'].datasets[0].data?.length">{{'statistics.chart-no-data' | translate}}</p>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="('statistics.tab-label-by-constitution-year' | translate)">
      <div class="statistics__content">
        <et-filters [filters]="filtersConfiguration['institutionsPerConstitutionYear']"
                    (filtersChanged)="onFiltersChanged($event, 'institutionsPerConstitutionYear')"></et-filters>
        <div class="statistics__charts-container">
          <div *ngIf="charts['institutionsPerConstitutionYear'].datasets[0].data?.length">
            <canvas baseChart
                    [datasets]="charts['institutionsPerConstitutionYear'].datasets"
                    [labels]="charts['institutionsPerConstitutionYear'].labels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="false"
                    [chartType]="'bar'">
            </canvas>
          </div>
          <p class="no-chart-data"
             *ngIf="!charts['institutionsPerConstitutionYear'].datasets[0].data?.length">{{'statistics.chart-no-data' | translate}}</p>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="('statistics.tab-label-by-year-balance' | translate)">
      <div class="statistics__content statistics__content--balance-by-year">
        <div>
          <div class="chart-title">
            <h2>Valore della produzione complessivo</h2>
            <et-filters [filters]="filtersConfiguration['productionValuesPerYear']"
                        (filtersChanged)="onFiltersChanged($event, 'productionValuesPerYear')"></et-filters>
          </div>
          <div class="statistics__charts-container">
            <div *ngIf="charts['productionValuesPerYear'].datasets[0].data?.length">
              <canvas baseChart
                      [datasets]="charts['productionValuesPerYear'].datasets"
                      [labels]="charts['productionValuesPerYear'].labels"
                      [options]="barChartWithCurrenciesOptions"
                      [plugins]="barChartPlugins"
                      [legend]="false"
                      [chartType]="'bar'">
              </canvas>
            </div>
            <p class="no-chart-data"
               *ngIf="!charts['productionValuesPerYear'].datasets[0].data?.length">{{'statistics.chart-no-data' | translate}}</p>
          </div>
        </div>
        <div>
          <div class="chart-title">
            <h2>Risultato d'esercizio complessivo</h2>
            <et-filters [filters]="filtersConfiguration['exerciseResultsPerYear']"
                        (filtersChanged)="onFiltersChanged($event, 'exerciseResultsPerYear')"></et-filters>
          </div>
          <div class="statistics__charts-container">
            <div *ngIf="charts['exerciseResultsPerYear'].datasets[0].data?.length">
              <canvas baseChart
                      [datasets]="charts['exerciseResultsPerYear'].datasets"
                      [labels]="charts['exerciseResultsPerYear'].labels"
                      [options]="barChartWithCurrenciesOptions"
                      [plugins]="barChartPlugins"
                      [legend]="false"
                      [chartType]="'bar'">
              </canvas>
            </div>
            <p class="no-chart-data"
               *ngIf="!charts['exerciseResultsPerYear'].datasets[0].data?.length">{{'statistics.chart-no-data' | translate}}</p>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

