import { Component, OnInit }                                                            from '@angular/core';
import { MatSnackBar }                                                                  from '@angular/material/snack-bar';
import { TranslatePipe }                                                                from '@ngx-translate/core';
import { ActivatedRoute, Router }                                                       from '@angular/router';
import { LegalBodyTypeEnum, PersonDeleteGQL, PersonGQL, PersonUpdateGQL, UserRoleEnum } from '../../generated/graphql';
import { getErrorMessageFromData, getFixedSmallModalSettings }                          from '../shared/utils/helpers';
import {
  FieldConfiguration,
  FieldTypes
}                                                                                       from '../institution-details/institution-details.component';
import { FormControl, Validators }                                                      from '@angular/forms';
import moment                                                                           from 'moment';
import { BaseModalComponent, ModalField }                                               from '../shared/base-modal/base-modal.component';
import { MatDialog }                                                                    from '@angular/material/dialog';
import { UserService }                                                                  from '../core/user/user.service';

@Component({
  selector: 'et-person-details',
  templateUrl: './person-details.component.html',
  styleUrls: ['./person-details.component.scss'],
  providers: [TranslatePipe]
})
export class PersonDetailsComponent implements OnInit {

  personData: any = {};
  roleDetailsData: any;
  busy = true;
  generalInfoFields: FieldConfiguration[];
  roleDetailsFields: FieldConfiguration[];
  fieldsModel: { [key: string]: any } = {};
  editGeneralInfo = false;
  fieldTypesEnum = FieldTypes;
  legalBodyTypes = LegalBodyTypeEnum;
  showRoleDetailsSidePanel = false;
  currentRolesCount = 0;
  archivedRolesCount = 0;
  isAdmin: boolean;
  isViewer: boolean;

  constructor(private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private activatedRoute: ActivatedRoute,
              private dialog: MatDialog,
              private userService: UserService,
              private router: Router,
              private personUpdateGQL: PersonUpdateGQL,
              private personDeleteGQL: PersonDeleteGQL,
              private personGQL: PersonGQL) {
    this.isAdmin = this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.InstitutionAdmin;
    this.isViewer = this.userService.user?.role === UserRoleEnum.Viewer;
    this.generalInfoFields = ['name', 'fiscalCode', 'uniEmployee', 'document:personalDocument'].map(key => {
      let type;
      if (key === 'uniEmployee') {
        type = FieldTypes.BOOLEAN;
      } else if (key.includes('document')) {
        type = FieldTypes.DOCUMENT;
      }
      const conf: FieldConfiguration = {
        label: this.translatePipe.transform('person-details.' + key),
        key,
        type
      };
      const validators = [];
      if (key === 'fiscalCode') {
        validators.push(Validators.required);
      }
      conf.fControl = new FormControl(this.fieldsModel[key], {validators});
      return conf;
    });
    this.roleDetailsFields = ['startDate', 'expectedEndDate', 'endDate', 'uniNominee', 'document:roleDocument', 'note'].map(key => {
      let type = FieldTypes.DATE;
      if (key.includes('document')) {
        type = FieldTypes.DOCUMENT;
      } else if (key === 'note') {
        type = FieldTypes.TEXTAREA;
      } else if (key === 'uniNominee') {
        type = FieldTypes.BOOLEAN;
      }
      const conf: FieldConfiguration = {
        label: this.translatePipe.transform('role-details.' + key),
        key,
        type
      };
      return conf;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.busy = true;
      this.personGQL.fetch({id: routeParams.id}, {fetchPolicy: 'no-cache'}).subscribe(res => {
        this.personData = res.data.person;
        if (res.data.person?.roles) {
          this.currentRolesCount = res.data.person.roles.filter(r => {
            // old check
            // return !r.endDate || (r.expectedEndDate && !this.isDateBeforeToday(r.expectedEndDate)) || (r.endDate && !this.isDateBeforeToday(r.endDate));
            return !(r.endDate && this.isDateBeforeToday(r.endDate));
          }).length;
          this.archivedRolesCount = res.data.person.roles.filter(r => {
            return r.endDate && this.isDateBeforeToday(r.endDate);
          }).length;
        }
        this.busy = false;
      }, error => {
        this.busy = false;
        this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      });
    });
  }

  onOpenDocumentClick(urlToOpen?: string | undefined): void {
    window.open(urlToOpen || 'no-url', '_blank');
  }

  getDocumentUrlByKeyAndData(key: string, data: any): string | undefined {
    const split = key.split(':');
    if (split.length === 2) {
      return data[split[1]]?.url;
    }
    return undefined;
  }

  isDateBeforeToday(isoDate: string): boolean {
    return moment(isoDate).isBefore(moment(), 'day');
  }

  viewRoleDetails(roleItem: any): void {
    this.roleDetailsData = {...roleItem};
    this.showRoleDetailsSidePanel = true;
  }

  openUpdatePersonModal(): void {

    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'fiscalCode',
        type: 'text',
        label: this.translatePipe.transform('person-modal.field--fiscalCode'),
        required: false,
        value: this.personData.fiscalCode
      },
      {
        fieldKey: 'name',
        type: 'text',
        label: this.translatePipe.transform('person-modal.field--name'),
        required: true,
        value: this.personData.name
      },
      {
        fieldKey: 'uniEmployee',
        type: 'select',
        label: this.translatePipe.transform('person-modal.field--uniEmployee'),
        width: 6,
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ],
        required: true,
        value: this.personData.uniEmployee
      },
      {
        fieldKey: 'personalDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('person-modal.field--personalDocument'),
        required: false,
        value: this.personData.personalDocument?.url ? {url: this.personData.personalDocument.url} : undefined,
        // fileCustomUploadedLabel: 'some translated string'
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('person-details.edit-person'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.busy = true;
        this.personUpdateGQL.mutate({
          id: this.personData.id,
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.personData = res.data?.personUpdate;
          this.busy = false;
          this.snackBar.open(this.translatePipe.transform('person-details.update-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.busy = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  openDeletePersonModal(): void {
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('person-details.delete-confirmation-modal-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('person-details.delete-confirmation-modal-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.personDeleteGQL.mutate({
          personId: this.personData.id,
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.snackBar.open(this.translatePipe.transform('person-details.delete-confirmation-modal-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
          this.router.navigate(['/persone']);
        }, (error: any) => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }
}
