import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { InstitutionDetailsComponent }      from './institution-details.component';
import { MatTabsModule }                    from '@angular/material/tabs';
import { MatIconModule }                    from '@angular/material/icon';
import { TranslateModule }                  from '@ngx-translate/core';
import { MatButtonModule }                  from '@angular/material/button';
import { MatDatepickerModule }              from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule }                   from '@angular/material/input';
import { MatTooltipModule }                 from '@angular/material/tooltip';
import { LoadingModule }                    from '../shared/loading/loading.module';
import { OverlayModule }                    from '@angular/cdk/overlay';
import { RouterModule }                     from '@angular/router';
import { FilterableTableModule }            from '../shared/filterable-table/filterable-table.module';
import { EnhancedCurrencyModule }           from '../shared/enhanced-currency/enhanced-currency.module';
import { ExpirationRemindsTableModule }     from '../shared/expiration-reminds-table/expiration-reminds-table.module';
import { ReferentsAndRolesComponent }       from './referents-and-roles/referents-and-roles.component';
import { QuotePaymentsComponent }           from './quote-payments/quote-payments.component';
import { RoleCompensationsComponent }       from './role-compensations/role-compensations.component';
import { FilePickerModule }                 from '../shared/file-picker/file-picker.module';
import { MatSelectModule }                  from '@angular/material/select';
import { VersionedFieldModalComponent }     from './versioned-field-modal/versioned-field-modal.component';
import { MatDialogModule }                  from '@angular/material/dialog';
import { AdherentStructuresModalComponent } from './adherent-structures-modal/adherent-structures-modal.component';
import { MatAutocompleteModule }            from '@angular/material/autocomplete';
import { AtecoActivitiesModalComponent }    from './ateco-activities-modal/ateco-activities-modal.component';
import { AdminUnapproveFieldModule }        from '../shared/admin-unapprove-field/admin-unapprove-field.module';


@NgModule({
    declarations: [
        InstitutionDetailsComponent,
        ReferentsAndRolesComponent,
        QuotePaymentsComponent,
        RoleCompensationsComponent,
        VersionedFieldModalComponent,
        AdherentStructuresModalComponent,
        AtecoActivitiesModalComponent
    ],
    imports: [
        CommonModule,
        MatTabsModule,
        MatIconModule,
        TranslateModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        FormsModule,
        MatInputModule,
        MatTooltipModule,
        LoadingModule,
        OverlayModule,
        ReactiveFormsModule,
        RouterModule,
        FilterableTableModule,
        EnhancedCurrencyModule,
        ExpirationRemindsTableModule,
        FilePickerModule,
        MatSelectModule,
        MatDialogModule,
        MatAutocompleteModule,
        AdminUnapproveFieldModule
    ]
})
export class InstitutionDetailsModule {
}
