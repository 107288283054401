import { Component, Inject, OnInit }     from '@angular/core';
import { TranslatePipe }                 from '@ngx-translate/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AtecoActivity,
  AtecoActivityCreateGQL,
  AtecoActivityDeleteGQL,
  AtecoActivityUpdateGQL,
  AtecoSectorSearchGQL,
  OperationReturnTypeEnum,
  PersonSearchGQL
}                                        from '../../../generated/graphql';
import { FormControl, Validators }       from '@angular/forms';
import { forkJoin, of }                  from 'rxjs';

@Component({
  selector: 'et-ateco-activities-modal',
  templateUrl: './ateco-activities-modal.component.html',
  styleUrls: ['./ateco-activities-modal.component.scss'],
  providers: [TranslatePipe]
})
export class AtecoActivitiesModalComponent implements OnInit {

  private readonly atecoActivitiesRepeaterData: any[];
  private atecoActivitiesToRemove: string[] = [];

  // adherent repeater
  // tslint:disable-next-line:max-line-length
  atecoActivitiesRepeaterList: { index: number, isAddNew?: boolean, isUpdate?: boolean, repeaterItem: { atecoActivityId?: string, sectorId: FormControl, sectorOptions: { id: any; text: any; }[] | [], percentage: FormControl } }[] = [];

  constructor(public dialogRef: MatDialogRef<AtecoActivitiesModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { fieldKey: string, atecoActivities: any[], institutionId: string },
              private personSearchGQL: PersonSearchGQL,
              private atecoActivityCreateGQL: AtecoActivityCreateGQL,
              private atecoActivityUpdatedGQL: AtecoActivityUpdateGQL,
              private atecoActivityDeleteGQL: AtecoActivityDeleteGQL,
              private atecoSectorSearchGQL: AtecoSectorSearchGQL) {
    this.atecoActivitiesRepeaterData = this.data.atecoActivities ? [...this.data.atecoActivities] : [];
    this.atecoActivitiesRepeaterList = this.atecoActivitiesRepeaterData.map((item: AtecoActivity, index) => {

      const atecoActivityText = item.atecoSector.atecoCode + ' - ' + item.atecoSector.name;
      const sectorOptions = atecoActivityText ? [{id: item.atecoSector.id, text: atecoActivityText}] : [];

      return {
        index,
        isUpdate: true,
        repeaterItem: {
          atecoActivityId: item.id,
          sectorId: new FormControl({id: item.atecoSector.id, text: atecoActivityText}, {
            validators: [Validators.required]
          }),
          sectorOptions,
          percentage: new FormControl(item.percentage, {})
        }
      };
    });
  }

  ngOnInit(): void {
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  isConfirmDisabled(): boolean {
    return this.atecoActivitiesRepeaterList.some(ri => {
      return !ri.repeaterItem.sectorId.value;
    });
  }

  onConfirmClick(): void {
    forkJoin(
      this.atecoActivitiesToRemove.length > 0 ? this.atecoActivitiesToRemove.map(id => {
        return this.atecoActivityDeleteGQL.mutate({atecoActivityId: id});
      }) : of([])
    ).subscribe(doneDeletion => {
      forkJoin(
        this.atecoActivitiesRepeaterList.length > 0 ? this.atecoActivitiesRepeaterList.map(item => {
          if (item.isAddNew && item.repeaterItem.sectorId) {
            return this.atecoActivityCreateGQL.mutate({
              input: {
                institutionId: this.data.institutionId,
                atecoSectorId: item.repeaterItem.sectorId.value.id,
                percentage: item.repeaterItem.percentage.value
              }
            });
          } else if (item.repeaterItem.atecoActivityId && item.repeaterItem.sectorId) {
            // isUpdate - default
            return this.atecoActivityUpdatedGQL.mutate({
              atecoActivityId: item.repeaterItem.atecoActivityId,
              input: {
                atecoSectorId: item.repeaterItem.sectorId.value.id,
                institutionId: this.data.institutionId,
                percentage: item.repeaterItem.percentage.value
              }
            });
          }
          return of([]);
        }) : of([])
      ).subscribe((doneCreateUpdate) => {
        this.dialogRef.close(OperationReturnTypeEnum.Ok);
      }, error => {
        this.dialogRef.close(OperationReturnTypeEnum.Ko);
      });
    }, error => {
      this.dialogRef.close(OperationReturnTypeEnum.Ko);
    });
  }

  autocompleteDisplayFn(option: any): string {
    return option && option.hasOwnProperty('text') ? option.text : '';
  }

  // @ts-ignore
  onAutocompleteOptionSelected({option}, itemIndex: number, fieldKey: 'sectorId'): void {
    const {value} = option;
  }

  // @ts-ignore
  onAutocompleteTextChange({target}, itemIndex: number, fieldKey: 'sectorId'): void {
    const {value} = target;
    if (value) {
      // perform search
      if (fieldKey === 'sectorId') {
        this.atecoSectorSearchGQL.fetch({keyword: value}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.atecoActivitiesRepeaterList[itemIndex].repeaterItem.sectorOptions = res.data.atecoSectorSearch?.nodes ? res.data.atecoSectorSearch.nodes.map((a) => {
            return {
              id: a ? a.id : 'null',
              text: a ? (a.atecoCode + ' - ' + a.name) : 'codice non definito'
            };
          }) : [];
        });
      }
    } else {
      if (fieldKey === 'sectorId') {
        this.atecoActivitiesRepeaterList[itemIndex].repeaterItem.sectorOptions = [];
      }
    }
  }

  addNewAtecoActivityRepeaterItem(): void {
    const newIndex = this.atecoActivitiesRepeaterList.length;
    this.atecoActivitiesRepeaterList = [...this.atecoActivitiesRepeaterList, {
      index: newIndex,
      isAddNew: true,
      repeaterItem: {
        sectorId: new FormControl(undefined, {
          validators: [Validators.required]
        }),
        sectorOptions: [],
        percentage: new FormControl(undefined, {})
      }
    }];
  }

  markItemForRemoval(itemIndex: number): void {
    const foundIndexToDelete = this.atecoActivitiesRepeaterList.findIndex(o => o.index === itemIndex);
    if (foundIndexToDelete !== -1 && this.atecoActivitiesRepeaterList[itemIndex].isUpdate) {
      // @ts-ignore
      this.atecoActivitiesToRemove.push(this.atecoActivitiesRepeaterList[itemIndex].repeaterItem.atecoActivityId);
    }
    if (foundIndexToDelete !== -1) {
      this.atecoActivitiesRepeaterList.splice(foundIndexToDelete, 1);
    }
  }

  onPercentageChange(value: any, itemIndex: number): void {
    if (value > 100) {
      this.atecoActivitiesRepeaterList[itemIndex].repeaterItem.percentage.setValue(100);
    } else if (value < 0) {
      this.atecoActivitiesRepeaterList[itemIndex].repeaterItem.percentage.setValue(0);
    }
  }
}
