import { NgModule }            from '@angular/core';
import { CommonModule }        from '@angular/common';
import { MatIconModule }       from '@angular/material/icon';
import { NgxUploaderModule }   from 'ngx-uploader';
import { FilePickerComponent } from './file-picker.component';
import { TranslateModule }     from '@ngx-translate/core';
import { MatButtonModule }     from '@angular/material/button';


@NgModule({
  declarations: [FilePickerComponent],
  exports: [FilePickerComponent],
  imports: [
    CommonModule,
    NgxUploaderModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule
  ]
})
export class FilePickerModule {
}
