import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Remind, RemindUpdateGQL }                        from '../../../generated/graphql';
import moment                                             from 'moment';
import { MatSnackBar }                                    from '@angular/material/snack-bar';
import { getErrorMessageFromData }                        from '../utils/helpers';
import { TranslatePipe }                                  from '@ngx-translate/core';

@Component({
  selector: 'et-expiration-reminds-table',
  templateUrl: './expiration-reminds-table.component.html',
  styleUrls: ['./expiration-reminds-table.component.scss'],
  providers: [TranslatePipe]
})
export class ExpirationRemindsTableComponent implements OnInit {

  @Input() title?: string;
  @Input() hasActions?: boolean;
  @Input() showArchiveCheckbox!: boolean;
  @Input() reminds!: Remind[];
  @Input() showInstitution?: boolean;
  @Output() expirationRemindArchived = new EventEmitter();
  @Output() editActionClicked = new EventEmitter<any>();
  @Output() createActionClicked = new EventEmitter();

  archiveCheckables: boolean[] = [];
  sortDirection: { institution: undefined | 'asc' | 'desc', date: undefined | 'asc' | 'desc' } = {
    institution: undefined,
    date: 'asc'
  };

  constructor(private remindUpdateGQL: RemindUpdateGQL,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.sortListBy('date', (this.sortDirection.date) as 'asc' | 'desc');
  }

  onArchiveChecked(item: Remind): void {
    const checkArchive = !item.archived;
    this.remindUpdateGQL.mutate({
      remindId: item.id,
      input: {archived: checkArchive, institutionId: item.institution?.id || ''}
    }).subscribe(res => {
      if (res.data?.remindUpdate) {
        item.archived = res.data.remindUpdate.archived;
      } else {
        item.archived = checkArchive;
      }
      if (this.expirationRemindArchived.observers.length > 0) {
        this.archiveCheckables = [];
        this.expirationRemindArchived.emit(item);
      }
    }, error => {
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }

  isExpiredItem(date: any): boolean {
    const itemData = moment(date);
    return itemData.isBefore(moment(), 'day');
  }

  hasNoData(): boolean {
    // when table has no reminds data or all shown reminds items has been checked (so they moved to archived state)
    return !this.reminds.length || this.archiveCheckables.filter(Boolean).length === this.reminds.length;
  }

  sortListBy(colName: string, direction: 'asc' | 'desc'): void {
    if (colName === 'date') {
      this.reminds.sort((a, b) => {
        const aDate = moment(a.date);
        const bDate = moment(b.date);
        if (aDate.isSame(bDate)) {
          return 0;
        }
        return aDate.isAfter(bDate) ? (direction === 'desc' ? -1 : 1) : (direction === 'desc' ? 1 : -1);
      });
      this.sortDirection.date = direction;
      this.sortDirection.institution = undefined;
    } else if (colName === 'institution') {
      this.reminds.sort((a, b) => {
        if (a.institution?.vName?.genericValue === b.institution?.vName?.genericValue) {
          return 0;
        }
        // @ts-ignore
        return a.institution?.vName?.genericValue > b.institution?.vName?.genericValue ? (direction === 'asc' ? -1 : 1) : (direction === 'asc' ? 1 : -1);
      });
      this.sortDirection.institution = direction;
      this.sortDirection.date = undefined;
    }
  }

  onEditExpirationRemindClick(item: Remind): void {
    this.editActionClicked.emit(item);
  }

  onAddNewExpirationRemindClick(): void {
    this.createActionClicked.emit();
  }
}
