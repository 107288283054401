import { Component, OnInit }                                   from '@angular/core';
import {
  ExpirationsRemindsAllGQL,
  InstitutionSearchGQL,
  Remind,
  RemindCreateGQL,
  RemindUpdateGQL,
  SortKey,
  SortKeyDirection,
  UserRoleEnum
}                                                              from '../../generated/graphql';
import { MatSnackBar }                                         from '@angular/material/snack-bar';
import { getErrorMessageFromData, getFixedSmallModalSettings } from '../shared/utils/helpers';
import { TranslatePipe }                                       from '@ngx-translate/core';
import { BaseModalComponent, ModalField }                      from '../shared/base-modal/base-modal.component';
import { MatDialog }                                           from '@angular/material/dialog';
import { UserService }                                         from '../core/user/user.service';

@Component({
  selector: 'et-expirations-all',
  templateUrl: './expirations-all.component.html',
  styleUrls: ['./expirations-all.component.scss'],
  providers: [TranslatePipe]
})
export class ExpirationsAllComponent implements OnInit {

  busy = true;
  currentTabindex = 0;
  expiringRemindsData: any;
  allRemindsData: any;
  archivedRemindsData: any;
  isAdmin: boolean;
  isViewer: Boolean;

  constructor(private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private dialog: MatDialog,
              private userService: UserService,
              private remindCreateGQL: RemindCreateGQL,
              private remindUpdateGQL: RemindUpdateGQL,
              private institutionsSearchGQL: InstitutionSearchGQL,
              private expirationsRemindsAllGQL: ExpirationsRemindsAllGQL) {
    this.expiringRemindsData = [];
    this.allRemindsData = [];
    this.archivedRemindsData = [];
    this.isAdmin = this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.InstitutionAdmin;
    this.isViewer = this.userService.user?.role === UserRoleEnum.Viewer;
  }

  private fetchExpirationsRemindsByTab(tabIndex: number): void {
    // return new Promise((resolveP, rejectP) => {
    switch (tabIndex) {
      case 0:
        this.expirationsRemindsAllGQL.fetch({expiringWithinDays: 30, archived: false}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.expiringRemindsData = res.data?.reminds && res.data.reminds.length > 0 ? res.data.reminds?.map(r => {
            return {
              ...r,
              copied: true
            };
          }) : [];
          this.busy = false;
          // resolveP();
        }, error => {
          this.busy = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
        break;
      case 1:
        this.expirationsRemindsAllGQL.fetch({archived: false}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.allRemindsData = res.data?.reminds && res.data.reminds.length > 0 ? res.data.reminds?.map(r => {
            return {
              ...r,
              copied: true
            };
          }) : [];
          this.busy = false;
          // resolveP();
        }, error => {
          this.busy = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
        break;
      case 2:
        this.expirationsRemindsAllGQL.fetch({archived: true}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          // tslint:disable-next-line:no-non-null-assertion
          this.archivedRemindsData = [...res.data.reminds!];
          this.busy = false;
          // resolveP();
        }, error => {
          this.busy = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
        break;
      default:
        this.busy = false;
        // rejectP();
        break;
    }
    // });
  }

  ngOnInit(): void {
    this.fetchExpirationsRemindsByTab(0);
  }

  onTabChange(tabIndex: number): void {
    this.busy = true;
    this.currentTabindex = tabIndex;
    setTimeout(() => {
      this.fetchExpirationsRemindsByTab(tabIndex);
    }, 1000);
  }

  openAddNewExpirationModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'description',
        type: 'text',
        label: this.translatePipe.transform('expiration-modal.field--description'),
        required: true
      },
      {
        fieldKey: 'date',
        type: 'date',
        label: this.translatePipe.transform('expiration-modal.field--date'),
        required: true
      },
      {
        fieldKey: 'institutionId',
        type: 'autocomplete',
        label: this.translatePipe.transform('expiration-modal.field--institutionId'),
        required: true,
        hasRemoteSearch: true
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('expirations-all.add-expiration'),
        fields: fieldsConf
      }
    });

    const compInstance = matDialogRef.componentInstance;
    compInstance.onAutocompleteChangedRemoteSearch.subscribe((data: { fieldKey: string, searchedText: string, hasRemoteSearch: boolean }) => {
      this.doInstitutionsSearch(compInstance, data);
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.busy = true;
        this.remindCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.fetchExpirationsRemindsByTab(this.currentTabindex);
          this.busy = false;
          this.snackBar.open(this.translatePipe.transform('expiration-all.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.busy = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  openExpirationEditModal(item: Remind): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'description',
        type: 'text',
        label: this.translatePipe.transform('expiration-modal.field--description'),
        required: true,
        value: item.description
      },
      {
        fieldKey: 'date',
        type: 'date',
        label: this.translatePipe.transform('expiration-modal.field--date'),
        required: true,
        value: item.date
      },
      {
        fieldKey: 'notificationDate',
        type: 'date',
        label: this.translatePipe.transform('expiration-modal.field--notificationDate'),
        required: true,
        value: item.notificationDate
      },
      {
        fieldKey: 'institutionId',
        type: 'autocomplete',
        label: this.translatePipe.transform('expiration-modal.field--institutionId'),
        required: true,
        hasRemoteSearch: true,
        options: [
          {id: item.institution?.id, text: item.institution?.vName?.genericValue}
        ],
        value: item.institution?.id
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('expirations-all.edit-expiration'),
        fields: fieldsConf
      }
    });

    const compInstance = matDialogRef.componentInstance;
    compInstance.onAutocompleteChangedRemoteSearch.subscribe((data: { fieldKey: string, searchedText: string, hasRemoteSearch: boolean }) => {
      this.doInstitutionsSearch(compInstance, data);
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.busy = true;
        this.remindUpdateGQL.mutate({
          remindId: item.id,
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.fetchExpirationsRemindsByTab(this.currentTabindex);
          this.busy = false;
          this.snackBar.open(this.translatePipe.transform('expiration-all.edit-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.busy = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private doInstitutionsSearch(compInstance: BaseModalComponent, data: { fieldKey: string; searchedText: string; hasRemoteSearch: boolean }): void {
    this.institutionsSearchGQL.fetch({
        first: undefined,
        last: undefined,
        after: undefined,
        before: undefined,
        institutionType: undefined,
        sortFilter: {
          key: SortKey.Name,
          direction: SortKeyDirection.Asc
        },
        keyword: data.searchedText,
      },
      {fetchPolicy: 'no-cache'}
    ).subscribe(res => {
      compInstance.updateAutocompleteOptionsWithRemoteData(data.fieldKey, res.data.institutionSearch?.edges ? res.data.institutionSearch.edges.map((e) => {
        return {
          id: e?.node?.institution?.id,
          text: e?.node?.institution?.vName?.attributeValue
        };
      }) : []);
    });
  }
}
