import { NgModule }               from '@angular/core';
import { CommonModule }           from '@angular/common';
import { PersonDetailsComponent } from './person-details.component';
import { LoadingModule }          from '../shared/loading/loading.module';
import { TranslateModule }        from '@ngx-translate/core';
import { RouterModule }           from '@angular/router';
import { MatIconModule }          from '@angular/material/icon';
import { EnhancedCurrencyModule } from '../shared/enhanced-currency/enhanced-currency.module';
import { MatButtonModule }        from '@angular/material/button';



@NgModule({
  declarations: [PersonDetailsComponent],
  imports: [
    CommonModule,
    LoadingModule,
    TranslateModule,
    RouterModule,
    MatIconModule,
    EnhancedCurrencyModule,
    MatButtonModule
  ]
})
export class PersonDetailsModule { }
