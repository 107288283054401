import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe }        from '@angular/common';

@Pipe({
  name: 'enhancedCurrency'
})
export class EnhancedCurrencyPipe implements PipeTransform {

  constructor(private currencyPipe: CurrencyPipe) {
  }

  transform(value: any, currency: string): string {
    if (value || value === 0) {
      if (typeof value === 'string') {
        return value || '—';
      }
      return '€ ' + this.currencyPipe.transform(value, currency, '');
    }
    return '—';
  }

}
