import { Component, OnInit } from '@angular/core';
import { UserService }       from '../user/user.service';
import { UserRoleEnum }      from '../../../generated/graphql';

@Component({
  selector: 'et-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuLinks: Array<any> = [];
  userEmail: string | null | undefined;

  constructor(public userService: UserService) {
  }

  ngOnInit(): void {
    this.userEmail = this.userService.user?.email;
    this.menuLinks = [
      {
        id: 'institutions',
        routerLink: '/enti-spinoff',
        labelToTranslate: (this.userService.user?.role === UserRoleEnum.SpinoffOffice || this.userService.user?.role === UserRoleEnum.SpinoffAdmin) ? 'header.menu-institutions--only-spinoff' : (this.userService.user?.role === UserRoleEnum.InstitutionAdmin ? 'header.menu-institutions--only-institutions' : 'header.menu-institutions')
      },
      {
        id: 'departments',
        routerLink: '/dipartimenti',
        labelToTranslate: 'header.menu-departments'
      },
      {
        id: 'persons',
        routerLink: '/persone',
        labelToTranslate: 'header.menu-persons'
      },
      {
        id: 'expirations',
        routerLink: '/scadenze',
        labelToTranslate: 'header.menu-expirations'
      }
    ];
    if(this.userService.user?.role !== UserRoleEnum.Viewer){
      this.menuLinks.push({
        id: 'statistics',
        routerLink: '/grafici',
        labelToTranslate: 'header.menu-statistics'
      })
    }
  }

  onSignOutClick(): void {
    if (localStorage.getItem('shibo-login') === 'yes') {
      this.userService.doOuterSignOut();
    } else {
      this.userService.doSignOut();
    }
  }
}
