import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { MatButtonModule }          from '@angular/material/button';
import { MatFormFieldModule }       from '@angular/material/form-field';
import { MatIconModule }            from '@angular/material/icon';
import { MatInputModule }           from '@angular/material/input';
import { MatPaginatorModule }        from '@angular/material/paginator';
import { MatSelectModule }           from '@angular/material/select';
import { MatTableModule }            from '@angular/material/table';
import { MatTooltipModule }          from '@angular/material/tooltip';
import { TranslateModule }           from '@ngx-translate/core';
import { FiltersModule }             from '../filters/filters.module';
import { FormsModule }               from '@angular/forms';
import { LoadingModule }             from '../loading/loading.module';
import { StringClampModule }         from '../string-clamp/string-clamp.module';
import { EnhancedCurrencyModule }    from '../enhanced-currency/enhanced-currency.module';
import { FilterableTableComponent }  from './filterable-table.component';
import { AdminUnapproveFieldModule } from '../admin-unapprove-field/admin-unapprove-field.module';


@NgModule({
  declarations: [FilterableTableComponent],
  exports: [
    FilterableTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FiltersModule,
    FormsModule,
    MatTooltipModule,
    LoadingModule,
    StringClampModule,
    EnhancedCurrencyModule,
    AdminUnapproveFieldModule,
  ]
})
export class FilterableTableModule {
}
