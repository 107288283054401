import { Component, Inject, OnInit }                    from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                from '@angular/material/dialog';
import { ABErrorStateMatcher, getErrorMessageFromData } from '../../shared/utils/helpers';
import { FormControl, Validators }                      from '@angular/forms';
import {
  CheckNewAddedVersionedRecordsGQL,
  InstitutionUpdateGQL,
  InstitutionVersionedRecordIntersectionsGQL,
  VersionedFloat,
  VersionedFloatInput,
  VersionedInteger,
  VersionedIntegerInput,
  VersionedRecordDeleteGQL,
  VersionedRecordUpdateGQL,
  VersionedString,
  VersionedStringInput,
  VersionedUniParticipation
}                                                       from '../../../generated/graphql';
import { TranslatePipe }                                from '@ngx-translate/core';
import moment                                           from 'moment';
import { MatSnackBar }                                  from '@angular/material/snack-bar';

@Component({
  selector: 'et-versioned-field-modal',
  templateUrl: './versioned-field-modal.component.html',
  styleUrls: ['./versioned-field-modal.component.scss'],
  providers: [TranslatePipe]
})
export class VersionedFieldModalComponent implements OnInit {

  viewStepIndex = 0;
  validFromMaxConstraint: Date;
  fieldsModel: VersionedStringInput | VersionedFloatInput | VersionedIntegerInput | VersionedUniParticipation = {
    attributeValue: ''
  };
  errorStateMatcher = new ABErrorStateMatcher();
  attributeValueControl: FormControl;
  validFromControl: FormControl;
  validToControl: FormControl;
  attributeValueLabel: string;
  intersectionsData: { partially: any[], totally: any[] } = {
    partially: [],
    totally: []
  };

  constructor(
    private translatePipe: TranslatePipe,
    private snackBar: MatSnackBar,
    private institutionUpdateGQL: InstitutionUpdateGQL,
    private versionedRecordUpdateGQL: VersionedRecordUpdateGQL,
    private versionedRecordDeleteGQL: VersionedRecordDeleteGQL,
    private institutionVersionedRecordIntersectionsGQL: InstitutionVersionedRecordIntersectionsGQL,
    private checkNewAddedVersionedRecords: CheckNewAddedVersionedRecordsGQL,
    public dialogRef: MatDialogRef<VersionedFieldModalComponent>,
    // tslint:disable-next-line:max-line-length
    @Inject(MAT_DIALOG_DATA) public data: { operationType: 'add-new' | 'edit' | 'delete', institutionData: { id: string, isSpinoff: boolean, versionableOptions: any }, attributeName: string, previousVersionedRecord: any, versionedData?: VersionedString | VersionedFloat | VersionedInteger | VersionedUniParticipation, attibuteValueSelectOptions?: any[] }) {
    this.attributeValueControl = new FormControl(this.fieldsModel.attributeValue, {validators: [Validators.required]});
    this.validFromControl = new FormControl(this.fieldsModel?.validFrom);
    this.validToControl = new FormControl(this.fieldsModel?.validTo);
    this.attributeValueLabel = this.translatePipe.transform('institution-details.' + this.data.attributeName);
    this.validFromMaxConstraint = new Date();
    // this.validFromMaxConstraint = this.data.institutionData.versionableOptions ? new Date(this.data.institutionData.versionableOptions.onDate) : new Date();
  }

  ngOnInit(): void {
    if (this.data.operationType === 'edit' && this.data.versionedData) {
      this.fieldsModel.attributeValue = this.data.versionedData.attributeValue;
      this.fieldsModel.validFrom = this.data.versionedData.validFrom;
      this.fieldsModel.validTo = this.data.versionedData.validTo;
      this.attributeValueControl.setValue(this.fieldsModel.attributeValue);
      this.validFromControl.setValue(this.fieldsModel.validFrom);
      this.validToControl.setValue(this.fieldsModel.validTo);
    }
    // if (this.data.institutionData.id && this.data.attributeName) {
    // }
  }

  onFieldChange(value: any, fieldName: 'attributeValue' | 'validFrom' | 'validTo'): void {
    this.fieldsModel[fieldName] = value;
  }

  onSelectChange(option: any, fieldName: 'attributeValue'): void {
    this.fieldsModel[fieldName] = option.value;
  }

  isConfirmDisabled(): boolean {
    return this.data.operationType !== 'delete' && this.viewStepIndex === 0 && !this.fieldsModel.attributeValue;
  }

  private performCall(): void {
    const payload = {...this.fieldsModel};
    payload.validFrom = payload.validFrom ? moment(payload.validFrom).toISOString(true) : null;
    payload.validTo = payload.validTo ? moment(payload.validTo).toISOString(true) : null;
    if (this.data.operationType === 'add-new') {
      // tslint:disable-next-line:one-variable-per-declaration
      let spinoffInput, institutionInput;
      if (this.data.institutionData.isSpinoff) {
        spinoffInput = {};
        // @ts-ignore
        spinoffInput[this.data.attributeName] = payload;
      } else {
        institutionInput = {};
        // @ts-ignore
        institutionInput[this.data.attributeName] = payload;
      }
      this.institutionUpdateGQL.mutate({institutionId: this.data.institutionData.id, spinoffInput, institutionInput}).subscribe(res => {
        this.snackBar.open(this.translatePipe.transform('versioned-field-modal.record-created'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
        this.checkNewAddedVersionedRecords.fetch({
          id: this.data.institutionData.id,
          versionableOptions: this.data.institutionData.versionableOptions
        }).subscribe(updatedRes => {
          // @ts-ignore
          this.dialogRef.close(updatedRes.data?.institution[this.data.attributeName]);
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }, error => {
        this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      });
    } else if (this.data.operationType === 'edit' && this.data.versionedData) {
      // tslint:disable-next-line:one-variable-per-declaration
      let versionedStringInput: any, versionedIntegerInput: any, versionedFloatInput: any, versionedUniParticipationEnumInput: any;
      if (this.data.versionedData.__typename === 'VersionedFloat') {
        versionedFloatInput = payload;
      } else if (this.data.versionedData.__typename === 'VersionedInteger') {
        versionedIntegerInput = payload;
      } else if (this.data.versionedData.__typename === 'VersionedString') {
        versionedStringInput = payload;
      } else if (this.data.versionedData.__typename === 'VersionedUniParticipation') {
        versionedUniParticipationEnumInput = payload;
      }
      this.versionedRecordUpdateGQL.mutate({
        versionedRecordId: this.data.versionedData.id,
        versionedStringInput,
        versionedFloatInput,
        versionedIntegerInput,
        versionedUniParticipationEnumInput
      }).subscribe(res => {
        this.snackBar.open(this.translatePipe.transform('versioned-field-modal.record-updated'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
        this.dialogRef.close(res.data?.versionedRecordUpdate);
      }, error => {
        this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      });

    } else if (this.data.operationType === 'delete' && this.data.versionedData) {
      this.versionedRecordDeleteGQL.mutate({versionedRecordId: this.data.versionedData.id}).subscribe(res => {
        this.snackBar.open(this.translatePipe.transform('versioned-field-modal.record-deleted'), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
        this.dialogRef.close(true);
      }, error => {
        this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
          duration: 4000
        });
      });
    }
  }

  onConfirmClick(): void {
    if (this.viewStepIndex === 0 && this.data.operationType !== 'delete') {
      this.institutionVersionedRecordIntersectionsGQL.fetch({
        id: this.data.institutionData.id,
        versionableOptions: this.data.institutionData.versionableOptions,
        newRecord: {
          recordId: (this.data.operationType === 'add-new') ? null : (this.data.versionedData ? this.data.versionedData.id : ''),
          attributeName: this.data.attributeName,
          validFrom: this.fieldsModel.validFrom ? moment(this.fieldsModel.validFrom).toISOString(true) : null,
          validTo: this.fieldsModel.validTo ? moment(this.fieldsModel.validTo).toISOString(true) : null,
        }
      }, {fetchPolicy: 'no-cache'}).subscribe(res => {
        if (res.data.institution?.versionedRecordIntersections) {
          // @ts-ignore
          // tslint:disable-next-line:max-line-length
          this.intersectionsData.partially = [...res.data.institution.versionedRecordIntersections.innerIntersections, ...res.data.institution.versionedRecordIntersections.partialIntersections];
          // @ts-ignore
          this.intersectionsData.totally = [...res.data.institution.versionedRecordIntersections.outerIntersections];
          if (this.intersectionsData.partially.length > 0 || this.intersectionsData.totally.length > 0) {
            this.viewStepIndex = 1;
          } else {
            this.performCall();
          }
        }
      });
    } else {
      // submit stuff
      this.performCall();
    }
  }

  onCancelClick(): void {
    if (this.viewStepIndex === 0) {
      this.dialogRef.close(false);
    } else if (this.viewStepIndex === 1) {
      this.viewStepIndex = 0;
    }
  }
}
