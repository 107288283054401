import { ErrorHandler } from '@angular/core';
import { environment }  from '../../../environments/environment';
import { Notifier }     from '@airbrake/browser';

export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;

  constructor() {
    this.airbrake = new Notifier({
      host: environment.errorsUrl,
      projectId: 1,
      projectKey: environment.airbrakeProjectKey,
    });
    this.airbrake.addFilter(notice => {
      notice.context.environment = location.href.includes('staging') ? 'staging' : 'production';
      return notice;
    });
  }

  handleError(error: any): void {
    console.error(error);
    if (environment.production) {
      this.airbrake.notify(error);
    }
  }
}
