import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'et-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() mode: 'primary' | 'secondary' = 'primary';

  constructor() {
  }

  ngOnInit(): void {
  }

}
