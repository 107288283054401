import { BrowserModule }                     from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule }                  from './app-routing.module';
import { AppComponent }                      from './app.component';
import { BrowserAnimationsModule }           from '@angular/platform-browser/animations';
import { CoreModule }                        from './core/core.module';
import { registerLocaleData }                from '@angular/common';
import localeIt                              from '@angular/common/locales/it';
import { TranslateLoader, TranslateModule }  from '@ngx-translate/core';
import { HttpClient }                        from '@angular/common/http';
import { TranslateHttpLoader }               from '@ngx-translate/http-loader';
import { CdkScrollableModule }               from '@angular/cdk/scrolling';
import { MatMomentDateModule }               from '@angular/material-moment-adapter';
import { SignInModule }                      from './sign-in/sign-in.module';
import { AirbrakeErrorHandler }              from './core/airbrake/airbrake-error-handler';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS }    from '@angular/material/form-field';
import { DashboardModule }                   from './dashboard/dashboard.module';
import { InstitutionsModule }                from './institutions/institutions.module';
import { DepartmentsModule }                 from './departments/departments.module';
import { PersonsModule }                     from './persons/persons.module';
import { InstitutionDetailsModule }          from './institution-details/institution-details.module';
import { ExpirationsAllModule }              from './expirations-all/expirations-all.module';
import { PersonDetailsModule }               from './person-details/person-details.module';
import { DepartmentDetailsModule }           from './department-details/department-details.module';
import { StatisticsModule }                  from './statistics/statistics.module';


registerLocaleData(localeIt, 'it');

// AoT requires an exported function for factories
// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    CdkScrollableModule,
    MatMomentDateModule,
    CoreModule,
    SignInModule,
    DashboardModule,
    InstitutionsModule,
    DepartmentsModule,
    PersonsModule,
    ExpirationsAllModule,
    InstitutionDetailsModule,
    PersonDetailsModule,
    DepartmentDetailsModule,
    StatisticsModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'it-IT'},
    {provide: ErrorHandler, useClass: AirbrakeErrorHandler},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'never'}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
