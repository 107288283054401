<div class="filterable-table" [class.filterable-table--with-padding]="tableTitle">
  <div class="filterable-table__header" *ngIf="tableTitle && filters">
    <h1>{{tableTitle}}</h1>
    <ng-container *ngIf="hasPagination || filters">
      <et-filters *ngIf="filters && filters.length > 0"
                  [filters]="filters" (filtersChanged)="onFiltersChanged($event)"
                  [queryParamMode]="filtersQueryParamMode"></et-filters>
      <div *ngIf="hasPagination && paginationData" class="filterable-table__pagination">
        <mat-paginator [length]="paginationData.totalCount"
                       [pageSize]="paginationData.pageSize"
                       [disabled]="dataLoading"
                       [pageSizeOptions]="[paginationData.pageSize]"
                       [hidePageSize]="true"
                       (page)="pageEvent($event)" #paginatorRef>
        </mat-paginator>
      </div>
    </ng-container>
  </div>
  <div class="filterable-table__content"
       [class.filterable-table__content--less-spacing-top]="!tableTitle"
       [class.filterable-table__content--more-spacing-bottom]="tableTitle">
    <div class="filterable-table__add-new" *ngIf="hasAddNewButton && addNewButtonText">
      <button mat-stroked-button
              color="primary"
              (click)="onAddNewClick()"
              class="mat-elevation-z1">
        <mat-icon *ngIf="addNewButtonIcon" aria-hidden="false"
                  [attr.aria-label]="addNewButtonText">{{addNewButtonIcon}}</mat-icon>
        {{addNewButtonText}}
      </button>
    </div>
    <div class="filterable-table__table-container">
      <table *ngIf="tableData" mat-table [dataSource]="tableData.list">

        <ng-container *ngFor="let column of displayedColumns; let i = index" [matColumnDef]="displayedColumns[i]">
          <th mat-header-cell *matHeaderCellDef>
            <span [class.has-sort-key]="!!tableData.columnsConfig[i].sortKey"
                  [class.sort-selected]="!!tableData.columnsConfig[i].sortKey && sortDirection['' + tableData.columnsConfig[i].sortKey + '']">
              <mat-icon
                *ngIf="!!tableData.columnsConfig[i].sortKey && sortDirection['' + tableData.columnsConfig[i].sortKey + ''] === SortKeyDirection.Desc"
                (click)="sortListBy(tableData.columnsConfig[i].sortKey, SortKeyDirection.Asc)" aria-hidden="false"
                aria-label="ordina">arrow_drop_down
            </mat-icon>
            <mat-icon
              *ngIf="!!tableData.columnsConfig[i].sortKey && (!sortDirection['' + tableData.columnsConfig[i].sortKey + ''] || sortDirection['' + tableData.columnsConfig[i].sortKey + ''] === SortKeyDirection.Asc)"
              (click)="sortListBy(tableData.columnsConfig[i].sortKey, SortKeyDirection.Desc)"
              aria-hidden="false" aria-label="ordina">arrow_drop_up
            </mat-icon>
              {{tableData.columnsConfig[i].headerTitleKey | translate}}
            </span>
          </th>
          <td mat-cell *matCellDef="let listRow; let cellIndex = index"
              [class.filterable-table__cell-mark]="enableCellMark && tableData.columnsConfig[i].hasCellMarking && listRow.markCell">
            <!-- no type - default -->
            <span
              *ngIf="!tableData.columnsConfig[i].type || tableData.columnsConfig[i].type === TableColumnTypes.DEFAULT">
            {{!listRow[column] ? '—' : (tableData.columnsConfig[i].clampString ? (listRow[column] | stringClamp:25) : listRow[column])}}
          </span>
            <div class="two-text-cell" *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.TWO_TEXT">
              <span>{{!listRow[column] ? '—' : (tableData.columnsConfig[i].clampString ? (listRow[column] | stringClamp:25) : listRow[column])}}</span>
              <span
                *ngIf="tableData.columnsConfig[i]?.secondaryDataToShowKey && listRow.hasOwnProperty(tableData.columnsConfig[i]?.secondaryDataToShowKey)">
              {{getSecondaryDataValue(listRow, this.tableData.columnsConfig[i])}}
            </span>
            </div>
            <!-- link with icon -->
            <div *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.LINK"
                 class="link-cell">
              <ng-container *ngIf="!listRow.hideLink">
                <ng-container *ngIf="listRow[column]">
                  <mat-icon class="link-cell-icon"
                            *ngIf="tableData.columnsConfig[i].linkIcon && tableData.columnsConfig[i].linkIcon !== 'pdf'"
                            aria-hidden="false"
                            (click)="onLinkClick(listRow, tableData.columnsConfig[i]?.linkTarget)"
                            aria-label="link">{{tableData.columnsConfig[i].linkIcon}}</mat-icon>
                  <mat-icon *ngIf="tableData.columnsConfig[i].linkIcon && tableData.columnsConfig[i].linkIcon === 'pdf'"
                            svgIcon="pdf"></mat-icon>
                </ng-container>
                <a
                  (click)="onLinkClick(listRow, tableData.columnsConfig[i]?.linkTarget)">{{listRow[column] > 0 ? (listRow[column] + ' ' + ((tableData.columnsConfig[i].headerTitleKey + '--' + column) | translate)) : (listRow[column] ? listRow[column] : '')}}</a>
                <p *ngIf="(!listRow[column] || listRow[column] === 0)">—</p>
              </ng-container>
              <p *ngIf="listRow.hideLink">{{listRow[column]}}</p>
            </div>
            <!-- document, file type rectangle + file name -->
            <div class="file-type-cell"
                 *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.DOCUMENT">
              <div class="file-type-rectangle">{{tableData.list[i].fileType}}</div>
              {{listRow[column]}}</div>
            <!-- date, formatted -->
            <span
              *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.DATE">{{listRow[column] ? (listRow[column] | date: 'dd/MM/yyyy') : '—'}}</span>
            <!-- currency, formatted number -->
            <span
              *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.CURRENCY">{{listRow[column] | enhancedCurrency: 'EUR'}}</span>
            <!-- boolean, a yes or no text -->
            <span
              *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.BOOLEAN">{{!!listRow[column] ? ('filterable-table.boolean-value-yes' | translate) : ('filterable-table.boolean-value-no' | translate)}}</span>
            <!-- status, a label status -->
            <div *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.STATUS">
              <label *ngIf="!listRow[column]?.array"
                     class="status-cell status-cell--{{listRow[column]}}">{{('filterable-table.status-' + listRow[column]) | translate}}</label>
              <ng-container *ngIf="listRow[column]?.array">
                <label *ngFor="let statusItem of listRow[column]?.array"
                       class="status-cell status-cell--custom">{{('filterable-table.status-custom-' + statusItem) | translate}}</label>
              </ng-container>
            </div>
            <!-- image, a small thumb -->
            <div class="image-type-cell"
                 *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.IMAGE">
              <img [src]="listRow[column].url" alt="cella con immagine"/>
            </div>
            <!-- editable text or file name (unused)-->
            <!--          <div-->
            <!--            *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.TEXT_EDITABLE || tableData.columnsConfig[i].type === TableColumnTypes.DOCUMENT_EDITABLE"-->
            <!--            class="editable-cell"-->
            <!--            [class.table__row&#45;&#45;has-file-type]="tableData.columnsConfig[i].type === TableColumnTypes.DOCUMENT_EDITABLE">-->
            <!--            &lt;!&ndash; this is tricky. think something else &ndash;&gt;-->
            <!--            <div *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.DOCUMENT_EDITABLE"-->
            <!--                 class="file-type-rectangle">{{tableData.list[i].fileType}}</div>-->
            <!--            <mat-form-field appearance="outline" *ngIf="showInput(cellIndex)">-->
            <!--              <input matInput-->
            <!--                     type="text"-->
            <!--                     #editableInputRef-->
            <!--                     [tabIndex]="editableIndex === cellIndex ? 0 : -1"-->
            <!--                     [value]="listRow[column]"-->
            <!--                     (blur)="onEditableFieldBlur($event, listRow, column)"-->
            <!--                     (change)="onEditableFieldChange($event, listRow, column)"/>-->
            <!--            </mat-form-field>-->
            <!--            <ng-container *ngIf="showValue(cellIndex)">{{listRow[column] | stringClamp:35}}</ng-container>-->
            <!--          </div>-->
            <!-- actions, table actions -->
            <div *ngIf="tableData.columnsConfig[i].type === TableColumnTypes.ACTIONS"
                 class="actions-container-cell" [class.info-overlay-parent]="cellIndex > 1">
              <div class="validate-single-field" *ngIf="hasRowMarking && listRow.markCell">
                <button mat-stroked-button
                        color="primary"
                        (click)="validateItem(listRow)"
                        class="mat-elevation-z1 only-icon">
                  <mat-icon aria-hidden="false" [attr.aria-label]="'validate action'">done_all</mat-icon>
                </button>
              </div>
              <et-admin-unapprove-field
                *ngIf="hasRowMarking && !listRow.markCell"
                [fieldData]="{id: listRow.id, approvableType: listRow.approvableType}"
                (unapproveFieldSucceeded)="onUnapproveItemSuccess($event)"></et-admin-unapprove-field>
              <!--            {{cellIndex}}-->
              <button mat-icon-button color="primary" aria-label="azioni" (click)="infoOverlayIndexToShow = cellIndex">
                <mat-icon>more_horiz</mat-icon>
              </button>
              <div class="info-backdrop-overlay" *ngIf="infoOverlayIndexToShow !== -1"
                   (click)="infoOverlayIndexToShow = -1"></div>
              <div class="info-overlay" *ngIf="infoOverlayIndexToShow === cellIndex">
                <ng-container *ngFor="let action of listRow[column]">
                  <p *ngIf="action === TableRowActions.VIEW_DETAILS"
                     (click)="onActionButtonClick(TableRowActions.VIEW_DETAILS, listRow)">
                    {{'info-overlay.action-view-details' | translate}}
                  </p>
                  <p *ngIf="action === TableRowActions.UPDATE"
                     (click)="onActionButtonClick(TableRowActions.UPDATE, listRow)">
                    {{'info-overlay.action-edit' | translate}}
                  </p>
                  <p class="delete-text" *ngIf="action === TableRowActions.DELETE"
                     (click)="onActionButtonClick(TableRowActions.DELETE, listRow)">
                    {{'info-overlay.action-delete' | translate}}
                  </p>
                  <!--                <button *ngSwitchCase="TableRowActions.RENAME_CELL"-->
                  <!--                        mat-stroked-button-->
                  <!--                        [matTooltip]="('filterable-table.action-hint-rename' | translate)"-->
                  <!--                        matTooltipPosition="above"-->
                  <!--                        matTooltipClass="ab-mat-tooltip"-->
                  <!--                        (click)="makeCellEditable(cellIndex)">-->
                  <!--                  <mat-icon aria-hidden="false" aria-label="elimina">edit</mat-icon>-->
                  <!--                </button>-->
                </ng-container>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: hasStickyHeader"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="onRowClick(row)"
            [class.filterable-table__row-admin]="hasRowMarking"
            [class.filterable-table__row-mark]="hasRowMarking && row.markCell"
            [class.cursor-pointer]="isRowClickable"></tr>
      </table>
      <ng-container *ngIf="dataLoading && (!tableData?.list?.length || tableData?.list?.length === 0)">
        <et-loading class="filterable-table__no-results"></et-loading>
      </ng-container>
      <ng-container *ngIf="!tableData?.list?.length && !dataLoading">
        <p class="filterable-table__no-results" *ngIf="!showDoSearchHint">
          {{customEmptyDataLabel ? customEmptyDataLabel : ('filterable-table.no-results' | translate)}}
        </p>
        <p class="filterable-table__no-results"
           *ngIf="showDoSearchHint">{{'filterable-table.do-search-hint' | translate}}</p>
      </ng-container>
    </div>
  </div>
</div>
