<!-- readonly side panel, only compensations can be created/modified -->
<ng-template #roleDetailsSidePanel>
  <div class="details-side-panel"
       [class.details-side-panel--visible]="showRoleDetailsSidePanel">
    <div class="details-side-panel__header">
      <button mat-icon-button color="primary" aria-label="chiudi" (click)="showRoleDetailsSidePanel = false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="details-side-panel__content" *ngIf="roleDetailsData">
      <h2>
        {{'role-details.side-panel-details-title' | translate}}
      </h2>
      <div class="form-box">
        <div *ngFor="let detailsField of roleDetailsFields">
          <label>{{detailsField.label}}</label>
          <p
            *ngIf="!detailsField.type || detailsField.type === fieldTypesEnum.NUMBER || detailsField.type === fieldTypesEnum.TEXTAREA">
            {{ roleDetailsData[detailsField.key] || '—'}}
          </p>
          <p
            *ngIf="detailsField.type === fieldTypesEnum.BOOLEAN">
            {{roleDetailsData[detailsField.key] ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}
          </p>
          <p *ngIf="detailsField.type === fieldTypesEnum.DATE">
            {{ roleDetailsData[detailsField.key] ? (roleDetailsData[detailsField.key] | date: 'dd/MM/yyyy') : '—'}}
          </p>
          <ng-container *ngIf="detailsField.type === fieldTypesEnum.DOCUMENT">
            <p
              style="margin-right: 10px">{{getDocumentUrlByKeyAndData(detailsField.key, roleDetailsData) ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}</p>
            <div class="file-view"
                 *ngIf="getDocumentUrlByKeyAndData(detailsField.key, roleDetailsData)">
              <span
                (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(detailsField.key, roleDetailsData))">PDF</span>
              <a
                (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(detailsField.key, roleDetailsData))">{{'form-fields.document-download' | translate}}</a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="temporal-list-section">
        <h2>{{'role-details.side-panel-details-compensations-title' | translate}}</h2>
        <div class="list-box" *ngIf="roleDetailsData.compensations?.length > 0">
          <div class="list-box__row" *ngFor="let item of roleDetailsData.compensations">
            <p>{{item.year}}</p>
            <p>{{item.compensation | enhancedCurrency: 'EUR'}}</p>
            <p>{{item.compensationNature || '—'}}</p>
          </div>
        </div>
        <p
          *ngIf="!roleDetailsData.compensations?.length">{{'role-details.side-panel-details-compensations-no-data' | translate}}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #personRoleItem let-item="item" let-isArchived="isArchived">
  <div class="institution-column">
    <a *ngIf="item.institution?.isVisible"
       (click)="$event.stopPropagation(); $event.preventDefault();"
       [routerLink]="['/enti-spinoff/', item.institution?.id]">{{ item.institution?.vName?.genericValue || '-'}}</a>
    <p *ngIf="!item.institution?.isVisible">{{ item.institution?.vName?.genericValue || '-'}}</p>
  </div>
  <div>
    <h4>{{'person-details.referent-type' | translate}}</h4>
    <p>{{item.legalBody?.bodyType !== legalBodyTypes.OtherBody ? ('referents-and-roles.body-type--' + item.legalBody?.bodyType | translate) : (('referents-and-roles.body-type--'+ legalBodyTypes.OtherBody) | translate)}}</p>
  </div>
  <div>
    <h4>{{'person-details.referent-subtype' | translate}}</h4>
    <p *ngIf="item.legalBody?.bodyType !== legalBodyTypes.LegalRepresentative">{{item.legalBody?.bodyType !== legalBodyTypes.OtherBody ? ('referents-and-roles.body-subtype--' + (item.legalBody?.administrativeBodyType || item.legalBody?.advisoryBodyType || item.legalBody?.controlBodyType || item.legalBody?.steeringBodyType) | translate) : item.legalBody?.name}}</p>
    <p *ngIf="item.legalBody?.bodyType === legalBodyTypes.LegalRepresentative">—</p>
  </div>
  <div>
    <h4>{{'person-details.role-type' | translate}}</h4>
    <p>{{'institution-details.roleType--' + item.roleType | translate}}</p>
  </div>
  <!--  <div>-->
  <!--    <ng-container *ngIf="!isArchived">-->
  <!--      <h4>{{'person-details.expected-end-date' | translate}}</h4>-->
  <!--      <p>{{item.expectedEndDate ? (item.expectedEndDate | date: 'dd/MM/yyyy') : '—'}}</p>-->
  <!--    </ng-container>-->
  <!--    <ng-container *ngIf="isArchived">-->
  <!--      <h4>{{'person-details.end-date' | translate}}</h4>-->
  <!--      <p>{{item.endDate ? (item.endDate | date: 'dd/MM/yyyy') : '—'}}</p>-->
  <!--    </ng-container>-->
  <!--  </div>-->
  <div>
    <mat-icon aria-hidden="false" [attr.aria-label]="'apri ruolo persona'">
      keyboard_arrow_right
    </mat-icon>
  </div>
</ng-template>

<div class="person-details">
  <div class="person-details__header">
    <h1>{{personData.name}}</h1>
  </div>
  <et-loading class="spacing-top-40" *ngIf="busy"></et-loading>
  <div class="person-details__content" *ngIf="!busy">
    <h2>
      {{'person-details.section-form' | translate}}
      <div>
        <button mat-stroked-button
                *ngIf="!isAdmin && !isViewer"
                color="primary"
                (click)="openUpdatePersonModal()"
                class="mat-elevation-z1">
          <mat-icon aria-hidden="false"
                    [attr.aria-label]="('person-details.edit-button' | translate)">edit</mat-icon>
          {{'person-details.edit-button' | translate}}
        </button>
        <button mat-stroked-button
                *ngIf="!isAdmin && !isViewer"
                color="primary"
                (click)="openDeletePersonModal()"
                class="mat-elevation-z1">
          <mat-icon aria-hidden="false"
                    [attr.aria-label]="('person-details.delete-button' | translate)">delete</mat-icon>
          {{'person-details.delete-button' | translate}}
        </button>
      </div>
    </h2>
    <div class="form-box">
      <div *ngFor="let field of generalInfoFields">
        <label>{{field.label}}</label>
        <ng-container *ngIf="!editGeneralInfo">
          <p
            *ngIf="!field.type">{{ personData[field.key] || '—'}}</p>
          <p
            *ngIf="field.type === fieldTypesEnum.BOOLEAN">
            {{personData[field.key] ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}
          </p>
          <ng-container *ngIf="field.type === fieldTypesEnum.DOCUMENT">
            <p
              style="margin-right: 10px">{{getDocumentUrlByKeyAndData(field.key, personData) ? ('form-fields.boolean-yes' | translate) : ('form-fields.boolean-no' | translate)}}</p>
            <div class="file-view"
                 *ngIf="getDocumentUrlByKeyAndData(field.key, personData)">
              <span (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(field.key, personData))">PDF</span>
              <a
                (click)="onOpenDocumentClick(getDocumentUrlByKeyAndData(field.key, personData))">{{'form-fields.document-download' | translate}}</a>
            </div>
          </ng-container>
        </ng-container>
        <!-- todo implement edit mode in the sooner future -->
        <!-- ng-container *ngIf="generalMainEditable"> -->
        <!-- Use it only when simple field (which has input form control) is required -->
        <!-- <mat-error -->
        <!-- *ngIf="mainField[mainField.key].fControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error> -->
        <!-- </ng-container> -->
      </div>
    </div>
    <h2 *ngIf="currentRolesCount > 0">{{ 'person-details.current-role' | translate}}</h2>
    <div class="person-details__roles-container">
      <ng-container *ngFor="let roleItem of personData.roles; index as i">
        <div class="person-details__role-item"
             (click)="viewRoleDetails(roleItem)"
             *ngIf="!roleItem.endDate || (roleItem.expectedEndDate && !isDateBeforeToday(roleItem.expectedEndDate)) || (roleItem.endDate && !isDateBeforeToday(roleItem.endDate))">
          <ng-template [ngTemplateOutlet]="personRoleItem"
                       [ngTemplateOutletContext]="{item: roleItem , isArchived: false}"></ng-template>
        </div>
      </ng-container>
    </div>
    <h2 *ngIf="archivedRolesCount > 0">{{ 'person-details.archived-roles' | translate}}</h2>
    <div class="person-details__roles-container">
      <ng-container *ngFor="let roleItem of personData.roles; index as i">
        <div class="person-details__role-item"
             *ngIf="roleItem.endDate && isDateBeforeToday(roleItem.endDate)"
             (click)="viewRoleDetails(roleItem)">
          <ng-template [ngTemplateOutlet]="personRoleItem"
                       [ngTemplateOutletContext]="{item: roleItem , isArchived: true}"></ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="app-backdrop-overlay" *ngIf="showRoleDetailsSidePanel"
     (click)="showRoleDetailsSidePanel = false"></div>
<ng-template [ngTemplateOutlet]="roleDetailsSidePanel"></ng-template>
