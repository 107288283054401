import { Component, OnInit }                                   from '@angular/core';
import { TranslatePipe }                                       from '@ngx-translate/core';
import { Router }                                              from '@angular/router';
import { UserService }                                         from '../core/user/user.service';
import { FilterInternal, FilterSetting, FilterTypes }          from '../shared/filters/filters.component';
import {
  DepartmentsDropdownListGQL,
  InstitutionCreateGQL,
  InstitutionSearchGQL,
  InstitutionSearchResult,
  InstitutionTypeEnum,
  PartnershipPersonKind,
  SearchShowOnly,
  SortFilterInput,
  SortKey,
  SortKeyDirection,
  UniParticipationEnum,
  UserRoleEnum
}                                                              from '../../generated/graphql';
import { PaginationData, TableColumnTypes, TableData }         from '../shared/filterable-table/filterable-table.component';
import { SectionNames }                                        from '../institution-details/institution-details.component';
import { MatSnackBar }                                         from '@angular/material/snack-bar';
import { getErrorMessageFromData, getFixedSmallModalSettings } from '../shared/utils/helpers';
import { BaseModalComponent, ModalField }                      from '../shared/base-modal/base-modal.component';
import { MatDialog }                                           from '@angular/material/dialog';
import { MatDialogRef }                                        from '@angular/material/dialog/dialog-ref';
import moment                                                  from 'moment';

export interface InstitutionRow {
  id: string;
  name: string | null | undefined;
  previousName: string | null | undefined;
  vatNumber: string | null | undefined;
  fiscalCode: string | null | undefined;
  expirations: string | null | undefined;
}

export interface InstitutionFilters {
  keyword?: string;
  departmentId?: string;
  active?: boolean;
  showOnly?: SearchShowOnly;
  institutionType?: InstitutionTypeEnum;
  spinoffParticipation?: boolean | null;
  withPartnershipPersonKind?: PartnershipPersonKind | null;
  recognitionDateFrom?: string;
  sortFilter?: SortFilterInput;
}

@Component({
  selector: 'et-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss'],
  providers: [TranslatePipe]
})
export class InstitutionsComponent implements OnInit {

  tableTitle = '';
  addNewButtonText = '';
  showAddNewButton = false;
  tableLoading = true;
  institutionsTableConfig: TableData;
  paginationData: PaginationData = {
    pageSize: 30
  };
  filtersData: InstitutionFilters = {};
  filtersConfig: FilterSetting[] | undefined;

  constructor(private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private router: Router,
              private dialog: MatDialog,
              private institutionsSearchGQL: InstitutionSearchGQL,
              private institutionCreateGQL: InstitutionCreateGQL,
              private departmentsDropdownListGQL: DepartmentsDropdownListGQL,
              public userService: UserService) {
    this.institutionsTableConfig = {
      list: [],
      columnsConfig: [
        {
          dataToShowKey: 'name',
          headerTitleKey: 'institutions.name',
          type: TableColumnTypes.TWO_TEXT,
          secondaryDataToShowKey: 'previousName',
          sortKey: SortKey.Name,
          sortDefaultValue: SortKeyDirection.Asc
        },
        {dataToShowKey: 'fiscalCode', headerTitleKey: 'institutions.fiscal-code'},
        {dataToShowKey: 'vatNumber', headerTitleKey: 'institutions.vat-number'},
        {
          dataToShowKey: 'expirationsCount',
          type: TableColumnTypes.LINK,
          linkIcon: 'notifications_active',
          headerTitleKey: 'institutions.expirations',
          linkTarget: SectionNames.EXPIRATIONS,
          sortKey: SortKey.Reminds
        }
      ]
    };
  }

  ngOnInit(): void {

    if (this.userService.user?.role === UserRoleEnum.SpinoffOffice || this.userService.user?.role === UserRoleEnum.SpinoffAdmin) {
      this.tableTitle = this.translatePipe.transform('institutions.title--only-spinoff');
    } else if (this.userService.user?.role === UserRoleEnum.InstitutionAdmin) {
      this.tableTitle = this.translatePipe.transform('institutions.title--only-institutions');
    } else {
      this.tableTitle = this.translatePipe.transform('institutions.title');
    }

    if (this.userService.user?.role === UserRoleEnum.InstitutionOffice || this.userService.user?.role === UserRoleEnum.SpinoffOffice) {
      this.showAddNewButton = true;
      // tslint:disable-next-line:max-line-length
      this.addNewButtonText = this.userService.user?.role === UserRoleEnum.InstitutionOffice ? this.translatePipe.transform('institutions.add-institution-or-spinoff') : this.translatePipe.transform('institutions.add-spinoff');
    }

    this.filtersData.sortFilter = {
      key: SortKey.Name,
      direction: SortKeyDirection.Asc
    };

    this.departmentsDropdownListGQL.fetch({}, {fetchPolicy: 'network-only'}).subscribe((res: any) => {
      this.filtersConfig = [
        {
          fieldKey: 'departmentId',
          type: FilterTypes.DROPDOWN,
          values: [{
            id: '',
            text: this.translatePipe.transform('institutions.filter-all-departments')
          }, ...res.data.departments],
          defaultValue: ''
        },
        {
          fieldKey: 'active',
          type: FilterTypes.DROPDOWN,
          values: [
            {id: 'yes', text: this.translatePipe.transform('institutions.filter-active-yes')},
            {id: 'no', text: this.translatePipe.transform('institutions.filter-active-no')}
          ],
          defaultValue: 'yes'
        }
      ];
      // tslint:disable-next-line:max-line-length
      if (this.userService.user?.role === UserRoleEnum.InstitutionOffice || this.userService.user?.role === UserRoleEnum.InstitutionAdmin || this.userService.user?.role === UserRoleEnum.Viewer) {
        this.filtersConfig.push({
          fieldKey: 'institutionType',
          type: FilterTypes.DROPDOWN,
          values: [
            {
              id: undefined,
              text: '-'
            }, ...this.getInstitutionTypesForOptions()
          ],
          placeholder: this.translatePipe.transform('institutions.filter-institution-type-placeholder'),
          defaultValue: undefined
        });
        this.filtersConfig.unshift({
          fieldKey: 'showOnly',
          type: FilterTypes.DROPDOWN,
          values: [
            {
              id: SearchShowOnly.All,
              text: this.translatePipe.transform('institutions.filter-showOnly--' + SearchShowOnly.All)
            },
            {
              id: SearchShowOnly.Institutions,
              text: this.translatePipe.transform('institutions.filter-showOnly--' + SearchShowOnly.Institutions)
            },
            {
              id: SearchShowOnly.ParticipateSpinoffs,
              text: this.translatePipe.transform('institutions.filter-showOnly--' + SearchShowOnly.ParticipateSpinoffs)
            },
          ],
          defaultValue: SearchShowOnly.All
        });
      }
      if (this.userService.user?.role === UserRoleEnum.SpinoffOffice || this.userService.user?.role === UserRoleEnum.SpinoffAdmin) {
        this.filtersConfig.push({
          fieldKey: 'spinoffParticipation',
          type: FilterTypes.DROPDOWN,
          values: [
            {id: '', text: this.translatePipe.transform('institutions.filter-spinoff-participation-all')},
            {id: 'yes', text: this.translatePipe.transform('institutions.filter-spinoff-participation-yes')},
            {id: 'no', text: this.translatePipe.transform('institutions.filter-spinoff-participation-no')}
          ],
          placeholder: this.translatePipe.transform('institutions.filter-spinoff-participation-placeholder'),
          defaultValue: ''
        });
      }
      this.filtersConfig = [
        ...this.filtersConfig,
        {
          fieldKey: 'recognitionDateFrom',
          type: FilterTypes.DATE,
          placeholder: this.translatePipe.transform('institutions.filter-recognition-date-from-placeholder'),
        },
        {
          fieldKey: 'withPartnershipPersonKind',
          type: FilterTypes.DROPDOWN,
          values: [
            {
              id: '',
              text: this.translatePipe.transform('institutions.filter-partnership-kind-all')
            }, ...this.getPartnershipPersonKindsForOptions()
          ],
          placeholder: this.translatePipe.transform('institutions.filter-with-partnership-person-kind-placeholder'),
          defaultValue: ''
        },
        {
          fieldKey: 'keyword',
          placeholder: (this.userService.user?.role === UserRoleEnum.SpinoffOffice || this.userService.user?.role === UserRoleEnum.SpinoffAdmin) ? this.translatePipe.transform('institutions.filter-keyword-placeholder--only-spinoff') : (this.userService.user?.role === UserRoleEnum.InstitutionAdmin ? this.translatePipe.transform('institutions.filter-keyword-placeholder--only-institutions') : this.translatePipe.transform('institutions.filter-keyword-placeholder')),
          type: FilterTypes.KEYWORD
        }
      ];
    });
  }

  private formatArrayToMatchTableData(arrayToFormat: any): Array<InstitutionRow> {
    const todayMidnightDatetime = new Date().setHours(0, 0, 0, 0);
    return arrayToFormat.map((el: InstitutionSearchResult) => {
      return {
        id: el.institution?.id,
        name: el.institution?.vName?.attributeValue,
        // tslint:disable-next-line:max-line-length
        previousName: (el.foundVersionedRecord?.attributeName === 'name' && el.foundVersionedRecord.genericValue !== el.institution?.vName?.attributeValue) ? (this.translatePipe.transform('institutions.previously-named', {text: el.foundVersionedRecord.genericValue})) : null,
        fiscalCode: el.institution?.fiscalCode,
        vatNumber: el.institution?.vatNumber,
        expirationsCount: el.institution?.expiringReminds?.length
        // expirationsCount: el.institution?.reminds?.filter(r => {
        //   // not archived, within 30 days or in the past.
        //   const expirationDatetime = new Date(r.date).setHours(0, 0, 0, 0);
        //   return !r.archived && ((expirationDatetime < todayMidnightDatetime) || (expirationDatetime <= new Date().setTime(todayMidnightDatetime + (30 * 24 * 60 * 60 * 1000))));
        // }).length
      };
    }, []);
  }

  // tslint:disable-next-line:max-line-length
  private fetchInstitutions(first?: number, last?: number, after?: string, before?: string, keyword?: string, departmentId?: string, active?: boolean, showOnly?: SearchShowOnly, institutionType?: InstitutionTypeEnum, recognitionDateFrom?: string, spinoffParticipation?: boolean | null, withPartnershipPersonKind?: PartnershipPersonKind | null): void {
    this.tableLoading = true;
    this.institutionsSearchGQL.fetch({
        first,
        last,
        after,
        before,
        keyword,
        departmentId,
        active,
        showOnly,
        institutionType,
        recognitionDateFrom,
        spinoffParticipation,
        withPartnershipPersonKind,
        sortFilter: this.filtersData.sortFilter
      },
      {fetchPolicy: 'no-cache'}
    ).subscribe(res => {
      this.institutionsTableConfig.list = this.formatArrayToMatchTableData(res.data.institutionSearch?.edges?.map((e) => e?.node));
      this.paginationData.totalCount = res.data.institutionSearch?.totalCount;
      this.paginationData.pageInfo = res.data.institutionSearch?.pageInfo;
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }


  openInstitutionDetails(rowData: any): void {
    this.router.navigate(['/enti-spinoff/' + rowData.id]);
  }

  openInstitutionDetailsWithTarget(rowDataAndTarget: any): void {
    this.router.navigate(['/enti-spinoff/' + rowDataAndTarget.data.id], {queryParams: {sectionToOpen: rowDataAndTarget.linkTarget}});
  }

  onNewFilters(newFilters: FilterInternal[]): void {
    let filtersChanged = false;
    const keywordFilter = newFilters.find(filter => filter.fieldKey === 'keyword');
    let keywordValue;
    if (keywordFilter) {
      keywordValue = keywordFilter.newValue;
      filtersChanged ||= keywordValue !== this.filtersData.keyword;
    }
    const departmentIdFilter = newFilters.find(filter => filter.fieldKey === 'departmentId');
    let departmentIdValue;
    if (departmentIdFilter) {
      if (typeof departmentIdFilter.newValue === 'string') {
        departmentIdValue = departmentIdFilter.newValue;
      } else if (departmentIdFilter.newValue?.id) {
        departmentIdValue = departmentIdFilter.newValue.id;
      }
      filtersChanged ||= departmentIdValue.toString() !== this.filtersData.departmentId;
    }
    const activeFilter = newFilters.find(filter => filter.fieldKey === 'active');
    let activeValue;
    if (activeFilter) {
      activeValue = activeFilter.newValue === 'yes';
      filtersChanged ||= activeValue !== this.filtersData.active;

    }
    let withPartnershipPersonKindValue;
    const withPartnershipPersonKind = newFilters.find(filter => filter.fieldKey === 'withPartnershipPersonKind');
    if (withPartnershipPersonKind) {
      if (typeof withPartnershipPersonKind.newValue === 'string') {
        withPartnershipPersonKindValue = withPartnershipPersonKind.newValue;
      } else if (withPartnershipPersonKind.newValue?.id) {
        withPartnershipPersonKindValue = withPartnershipPersonKind.newValue.id;
      }
      filtersChanged ||= withPartnershipPersonKindValue !== this.filtersData.withPartnershipPersonKind;
    }
    let recognitionDateFromValue;
    const recognitionDateFromFilter = newFilters.find(filter => filter.fieldKey === 'recognitionDateFrom');
    if (recognitionDateFromFilter) {
      recognitionDateFromValue = recognitionDateFromFilter.newValue ? moment(recognitionDateFromFilter.newValue).toISOString(true) : undefined;
      filtersChanged ||= recognitionDateFromValue !== this.filtersData.recognitionDateFrom;
    }
    let showOnlyValue;
    // tslint:disable-next-line:max-line-length
    if (this.userService.user?.role === UserRoleEnum.InstitutionOffice || this.userService.user?.role === UserRoleEnum.InstitutionAdmin || this.userService.user?.role === UserRoleEnum.Viewer) {
      const showOnlyFilter = newFilters.find(filter => filter.fieldKey === 'showOnly');
      if (showOnlyFilter) {
        if (typeof showOnlyFilter.newValue === 'string') {
          showOnlyValue = showOnlyFilter.newValue;
        } else if (showOnlyFilter.newValue?.id) {
          showOnlyValue = showOnlyFilter.newValue.id;
        }
        filtersChanged ||= showOnlyValue.toString() !== this.filtersData.showOnly;
      }
    }
    let institutionTypeValue;
    // tslint:disable-next-line:max-line-length
    if (this.userService.user?.role === UserRoleEnum.InstitutionOffice || this.userService.user?.role === UserRoleEnum.InstitutionAdmin || this.userService.user?.role === UserRoleEnum.Viewer) {
      const institutionTypeFilter = newFilters.find(filter => filter.fieldKey === 'institutionType');
      if (institutionTypeFilter) {
        if (typeof institutionTypeFilter.newValue === 'string') {
          institutionTypeValue = institutionTypeFilter.newValue;
        } else if (institutionTypeFilter.newValue?.id) {
          institutionTypeValue = institutionTypeFilter.newValue.id;
        }
        filtersChanged ||= institutionTypeValue !== this.filtersData.institutionType;
      }
    }
    let spinoffParticipationValue;
    if (this.userService.user?.role === UserRoleEnum.SpinoffOffice || this.userService.user?.role === UserRoleEnum.SpinoffAdmin) {
      const spinoffParticipationFilter = newFilters.find(filter => filter.fieldKey === 'spinoffParticipation');
      if (spinoffParticipationFilter) {
        spinoffParticipationValue = !spinoffParticipationFilter.newValue ? null : spinoffParticipationFilter.newValue === 'yes';
        filtersChanged ||= spinoffParticipationValue !== this.filtersData.spinoffParticipation;
      }
    }
    if (filtersChanged) {
      if (this.institutionsTableConfig.list?.length > 0) {
        this.institutionsTableConfig.list = [];
      }
      this.filtersData.keyword = keywordValue;
      this.filtersData.departmentId = departmentIdValue;
      this.filtersData.active = activeValue;
      this.filtersData.showOnly = showOnlyValue;
      this.filtersData.institutionType = institutionTypeValue;
      this.filtersData.withPartnershipPersonKind = withPartnershipPersonKindValue === '' ? null : withPartnershipPersonKindValue;
      this.filtersData.spinoffParticipation = spinoffParticipationValue;
      this.filtersData.recognitionDateFrom = recognitionDateFromValue;
      // tslint:disable-next-line:max-line-length
      this.fetchInstitutions(this.paginationData.pageSize, undefined, undefined, undefined, this.filtersData.keyword, this.filtersData.departmentId, this.filtersData.active, this.filtersData.showOnly, this.filtersData.institutionType, this.filtersData.recognitionDateFrom, this.filtersData.spinoffParticipation, this.filtersData.withPartnershipPersonKind);
    }
  }

  onPageChange(event: { pageSize: number; after: any; before?: any }): void {
    if (event.after && !event.before) {
      // go forward
      this.fetchInstitutions(this.paginationData.pageSize, undefined, event.after, undefined,
        // tslint:disable-next-line:max-line-length
        this.filtersData.keyword, this.filtersData.departmentId, this.filtersData.active, this.filtersData.showOnly, this.filtersData.institutionType, this.filtersData.recognitionDateFrom, this.filtersData.spinoffParticipation, this.filtersData.withPartnershipPersonKind);
    } else if (event.before) {
      // go backward
      this.fetchInstitutions(undefined, this.paginationData.pageSize, undefined,
        // tslint:disable-next-line:max-line-length
        event.before, this.filtersData.keyword, this.filtersData.departmentId, this.filtersData.active, this.filtersData.showOnly, this.filtersData.institutionType, this.filtersData.recognitionDateFrom, this.filtersData.spinoffParticipation, this.filtersData.withPartnershipPersonKind);
    }
  }

  private getInstitutionTypesForOptions(): { id: string, text: string }[] {
    return Object.values(InstitutionTypeEnum).map(value => ({
      id: value,
      text: this.translatePipe.transform('institution-details.institutionType--' + value)
    }));
  }

  private getUniParticipationForOptions(): { id: string, text: string }[] {
    return Object.values(UniParticipationEnum).map(value => ({
      id: value,
      text: this.translatePipe.transform('institution-details.uniparticipation--' + value)
    }));
  }

  private getPartnershipPersonKindsForOptions(): { id: string, text: string }[] {
    return Object.values(PartnershipPersonKind).map(value => ({
      id: value,
      text: this.translatePipe.transform('institutions.filter-partnership-kind--' + value)
    }));
  }

  private handleModalFieldsVisibility(matDialogRef: MatDialogRef<BaseModalComponent, any>): void {
    const compInstance = matDialogRef.componentInstance;
    compInstance.onFieldsChange.subscribe(data => {
      if (data.fieldName === 'institutionOrSpinoff') {
        if (data.currentFieldsModel[data.fieldName] === 'institutionInput') {
          compInstance.setSelectFieldOptions('institutionType', this.getInstitutionTypesForOptions(), null);
          compInstance.setSelectFieldOptions('uniParticipation', this.getUniParticipationForOptions(), null);
        } else if (data.currentFieldsModel[data.fieldName] === 'spinoffInput') {
          compInstance.setSelectFieldOptions('institutionType', [{
            id: InstitutionTypeEnum.Company,
            text: this.translatePipe.transform('institution-details.institutionType--company')
          }], InstitutionTypeEnum.Company);
        }

        if (this.userService.user?.role === UserRoleEnum.InstitutionOffice && data.currentFieldsModel.institutionType === InstitutionTypeEnum.Company) {
          compInstance.setSelectFieldOptions('uniParticipation', [{
            id: UniParticipationEnum.ConstitutionShare,
            text: this.translatePipe.transform('institution-details.uniparticipation--constitutionShare')
          }, {
            id: UniParticipationEnum.PostConstitutionShare,
            text: this.translatePipe.transform('institution-details.uniparticipation--postConstitutionShare')
          }]);
        }
      }
    });
  }


  openAddNewInstitutionModal(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'institutionType',
        type: 'select',
        label: this.translatePipe.transform('institution-modal.field--institutionType'),
        width: 12,
        options: this.getInstitutionTypesForOptions(),
        required: true,
      },
      {
        fieldKey: 'uniParticipation',
        type: 'select',
        label: this.translatePipe.transform('institution-modal.field--uniParticipation'),
        width: 12,
        options: this.getUniParticipationForOptions(),
        required: true,
      },
      {
        fieldKey: 'vName',
        type: 'text',
        label: this.translatePipe.transform('institution-modal.field--vName'),
        required: true
      },
      {
        fieldKey: 'fiscalCode',
        type: 'text',
        label: this.translatePipe.transform('institution-modal.field--fiscalCode'),
        required: false
      },
      {
        fieldKey: 'vatNumber',
        type: 'text',
        label: this.translatePipe.transform('institution-modal.field--vatNumber'),
      },
      {
        fieldKey: 'constitutionDate',
        type: 'date',
        label: this.translatePipe.transform('institution-modal.field--constitutionDate'),
        required: true
      },
      {
        fieldKey: 'vLegalResidence',
        type: 'text',
        label: this.translatePipe.transform('institution-modal.field--vLegalResidence'),
        required: true
      }
    ];

    if (this.userService.user?.role === UserRoleEnum.InstitutionOffice) {
      fieldsConf.unshift({
        fieldKey: 'institutionOrSpinoff',
        type: 'select',
        label: this.translatePipe.transform('institution-modal.field--institutionOrSpinoff'),
        width: 12,
        options: [
          {id: 'institutionInput', text: this.translatePipe.transform('institution-modal.input-type-institution')},
          {id: 'spinoffInput', text: this.translatePipe.transform('institution-modal.input-type-spinoff')},
        ],
        required: true,
      });
    }
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        stickyFooter: true,
        hasClose: true,
        title: this.userService.user?.role === UserRoleEnum.InstitutionOffice ?
          this.translatePipe.transform('institutions.add-institution-or-spinoff') :
          this.translatePipe.transform('institutions.add-spinoff'),
        fields: fieldsConf
      }
    });

    this.handleModalFieldsVisibility(matDialogRef);

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.tableLoading = true;
        let rightInput = {};
        fieldsModel.vName = {
          attributeValue: fieldsModel.vName,
          validFrom: null,
          validTo: null
        };
        fieldsModel.vLegalResidence = {
          attributeValue: fieldsModel.vLegalResidence,
          validFrom: null,
          validTo: null
        };
        if (fieldsModel.institutionOrSpinoff) {
          const institutionOrSpinoffValue = fieldsModel.institutionOrSpinoff;
          delete fieldsModel.institutionOrSpinoff;
          rightInput = {
            [institutionOrSpinoffValue]: fieldsModel
          };
        } else {
          rightInput = {
            spinoffInput: fieldsModel
          };
        }

        this.institutionCreateGQL.mutate({...rightInput}, {fetchPolicy: 'no-cache'})
          .subscribe(res => {
            this.filtersData.keyword = res.data?.institutionCreate?.vName?.genericValue || '';
            this.fetchInstitutions(this.paginationData.pageSize, undefined, undefined, undefined, this.filtersData.keyword);
            this.snackBar.open(this.translatePipe.transform('institution-modal.add-new-success'), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          }, error => {
            this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
              duration: 4000
            });
          });
      }
    });
  }

  onSortChanged(event: { key: SortKey; direction: SortKeyDirection }): void {
    this.filtersData.sortFilter = {...event};
    // tslint:disable-next-line:max-line-length
    this.fetchInstitutions(this.paginationData.pageSize, undefined, undefined, undefined, this.filtersData.keyword, this.filtersData.departmentId, this.filtersData.active, this.filtersData.showOnly, this.filtersData.institutionType, this.filtersData.recognitionDateFrom, this.filtersData.spinoffParticipation, this.filtersData.withPartnershipPersonKind);
  }
}
