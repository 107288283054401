import { NgModule }                         from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { BaseModalComponent }               from './base-modal.component';
import { TranslateModule }                  from '@ngx-translate/core';
import { FilePickerModule }                 from '../file-picker/file-picker.module';
import { MatDialogModule }                  from '@angular/material/dialog';
import { MatIconModule }                    from '@angular/material/icon';
import { MatInputModule }                   from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule }              from '@angular/material/datepicker';
import { MatSelectModule }                  from '@angular/material/select';
import { MatButtonModule }                  from '@angular/material/button';
import { MatAutocompleteModule }            from '@angular/material/autocomplete';
import { DatetimeRangePickerModule }        from '../datetime-range-picker/datetime-range-picker.module';
import { MatSlideToggleModule }             from '@angular/material/slide-toggle';
import { MatCheckboxModule }                from '@angular/material/checkbox';

@NgModule({
    declarations: [BaseModalComponent],
    exports: [BaseModalComponent],
    imports: [
        CommonModule,
        TranslateModule,
        FilePickerModule,
        MatDialogModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatSelectModule,
        MatButtonModule,
        MatAutocompleteModule,
        DatetimeRangePickerModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        FormsModule
    ]
})
export class BaseModalModule {
}
