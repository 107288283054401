import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { getTimeOptions }                                            from '../utils/helpers';
import moment, { Moment }                                            from 'moment';

@Component({
  selector: 'et-datetime-range-picker',
  templateUrl: './datetime-range-picker.component.html',
  styleUrls: ['./datetime-range-picker.component.scss']
})
export class DatetimeRangePickerComponent implements OnInit, OnChanges {

  @Input() hideTime = false;
  @Input() buttonMode = false;
  @Input() beginsAt?: any;
  @Input() endsAt?: any;
  @Input() disabled = false;
  @Input() minDate = moment();
  @Output() beginsAtChange = new EventEmitter();
  @Output() endsAtChange = new EventEmitter();

  startDate: Moment | undefined;
  startTime = '';
  endDate: Moment | undefined;
  endTime = '';
  startTimeOptions: string[] = [];
  endTimeOptions: string[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.initTimeOptions();
  }

  ngOnChanges(): void {
    /*if (!this.beginsAt || !this.endsAt) {
      return;
    }*/
    this.startDate = this.beginsAt ? moment(this.beginsAt) : undefined;
    this.endDate = this.endsAt ? moment(this.endsAt) : undefined;
    if (this.startDate?.isAfter(this.endDate) && this.endDate) {
      this.endDate = moment(this.startDate);
    }
    this.emitValues();
    // set start time model
    /*let startHours = this.startDate.getHours();
    if (startHours < 10) {
      startHours = `0${startHours}`;
    }
    let startMinutes = this.startDate.getMinutes();
    if (startMinutes < 10) {
      startMinutes = `0${startMinutes}`;
    }
    this.startTime = `${startHours}:${startMinutes}`;
    // set end time model
    let endHours = this.endDate.getHours();
    if (endHours < 10) {
      endHours = `0${endHours}`;
    }
    let endMinutes = this.endDate.getMinutes();
    if (endMinutes < 10) {
      endMinutes = `0${endMinutes}`;
    }
    this.endTime = `${endHours}:${endMinutes}`;*/
  }

  initTimeOptions(): void {
    getTimeOptions().forEach((options: string) => {
      this.startTimeOptions.push(options);
      this.endTimeOptions.push(options);
    });
  }

  onDateTimeChange(): void {
    this.emitValues();
  }

  emitValues(): void {
    // const todayAtMidnight = new Date().setHours(0, 0, 0);
    // now startDate and endDate are moment date
    const beginsAt = this.startDate && moment(this.startDate.valueOf());
    const endsAt = this.endDate && moment(this.endDate.valueOf());
    let emit = true;
    if (!this.hideTime) {
      if (beginsAt) {
        if (this.startTime.length === 5) {
          const splitTime = this.startTime.split(':');
          beginsAt.set('hours', Number(splitTime[0]) < 24 ? Number(splitTime[0]) : 0);
          beginsAt.set('minutes', Number(splitTime[1]) < 60 ? Number(splitTime[1]) : 0);
        } else if (this.startTime.length > 5) {
          beginsAt.set('hours', 0);
          beginsAt.set('minutes', 0);
        } else {
          emit = false;
        }
      }
      if (endsAt) {
        if (this.endTime.length === 5) {
          const splitTime = this.endTime.split(':');
          endsAt.set('hours', Number(splitTime[0]) < 24 ? Number(splitTime[0]) : 0);
          endsAt.set('minutes', Number(splitTime[1]) < 60 ? Number(splitTime[1]) : 0);
        } else if (this.endTime.length > 5) {
          endsAt.set('hours', 23);
          endsAt.set('minutes', 59);
        } else {
          emit = false;
        }
      }
    } else if (!this.startDate && !this.endDate) {
      emit = false; // I can emit empty values
      this.beginsAtChange.emit('');
      this.endsAtChange.emit('');
    }
    if (emit) {
      this.beginsAtChange.emit(beginsAt);
      this.endsAtChange.emit(endsAt);
    }
  }

  clearSearch(): void {
    this.startDate = undefined;
    this.endDate = undefined;
    this.startTime = '';
    this.endTime = '';
    this.beginsAtChange.emit(undefined);
    this.endsAtChange.emit(undefined);
  }

  onInputFocusOut(which: 'start' | 'end'): void {
    if (which === 'start') {
      const splitTime = this.startTime.split(':');
      const hours = splitTime[0] && Number(splitTime[0]) < 24 ? splitTime[0].slice(0, 2) : '00';
      const minutes = Number(splitTime[1]) < 60 ? splitTime[1].slice(0, 2) : '00';
      this.startTime = `${hours}:${minutes}`;
    }
    if (which === 'end') {
      const splitTime = this.endTime.split(':');
      const hours = splitTime[0] && Number(splitTime[0]) < 24 ? splitTime[0].slice(0, 2) : '00';
      const minutes = Number(splitTime[1]) < 60 ? splitTime[1].slice(0, 2) : '00';
      this.endTime = `${hours}:${minutes}`;
    }
  }

}
