import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslatePipe }                                                         from '@ngx-translate/core';
import { UploadOutput }                                                          from 'ngx-uploader';
import { MatSnackBar }                                                           from '@angular/material/snack-bar';

@Component({
  selector: 'et-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
  providers: [TranslatePipe]
})
export class FilePickerComponent{

  @Input() files: any[] = [];
  @Input() file: any;
  @Input() mode: 'image' | 'file' | 'all' = 'file';
  @Input() multiple = false;
  @Input() readOnly = false;
  @Input() smallerDrop = false;
  @Input() withInputAndLabel?: string;
  @Input() customUploadedLabel?: string;
  @Output() upload = new EventEmitter<UploadOutput>();
  @Output() delete = new EventEmitter<number | null>();
  dragging = false;
  fileChanged = false;

  // @ts-ignore
  @ViewChild('inputRef') inputRef: ElementRef;

  constructor(private snackbar: MatSnackBar,
              private translatePipe: TranslatePipe) {
  }

  onUploadOutput(event: UploadOutput): void {
    this.dragging = event.type === 'dragOver';
    if (event.type === 'addedToQueue' && event.file) {
      // @ts-ignore
      event.file.nativeFile = new File([event.file.nativeFile], `${new Date().getTime()}${Math.random() * 1000}`, {type: event.file.type});
      if (!event.file.name) {
        event.file.name = event.file.nativeFile.name;
      }
      if (this.mode !== 'all') {
        if (this.mode === 'file' && event.file.type !== 'application/pdf' && event.file.type !== 'image/jpg' &&
          event.file.type !== 'image/jpeg' && event.file.type !== 'image/png') {
          this.snackbar.open(this.translatePipe.transform('file-picker.file-not-supported'), this.translatePipe.transform('close'));
          return;
        }
        if (this.mode === 'image' && event.file.type !== 'image/jpg' && event.file.type !== 'image/jpeg' && event.file.type !== 'image/png') {
          this.snackbar.open(this.translatePipe.transform('file-picker.file-not-supported'),
            this.translatePipe.transform('close')); // this may seem duplicated code but they are gonna ask for different messages for sure
          return;
        }
      }
      if (event.file.size > 1000000 * 10) {
        this.snackbar.open(this.translatePipe.transform('file-picker.file-too-big'), this.translatePipe.transform('close'));
        return;
      }
      this.fileChanged = true;
      this.upload.emit(event);
      this.inputRef.nativeElement.value = '';
    }
  }

  onDelete(index: number | null): void {
    // null if single file, number is more than one file ([files] is used), so we need to delete the selected one.
    this.delete.emit(index);
  }

  openFileUrl(url: any): void {
    window.open(url, '_blank');
  }
}

