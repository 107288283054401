import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  OperationReturnTypeEnum,
  PaymentCreateGQL,
  PaymentDeleteGQL,
  PaymentUpdateGQL,
  QuotePaymentsGQL
}                                                             from '../../../generated/graphql';
import {
  BaseModalComponent,
  ModalField
}                                                             from '../../shared/base-modal/base-modal.component';
import {
  getErrorMessageFromData,
  getFixedSmallModalSettings
}                                                             from '../../shared/utils/helpers';
import { MatDialog }                                          from '@angular/material/dialog';
import { TranslatePipe }                                      from '@ngx-translate/core';
import { MatSnackBar }                                        from '@angular/material/snack-bar';

@Component({
  selector: 'et-quote-payments',
  templateUrl: './quote-payments.component.html',
  styleUrls: ['./quote-payments.component.scss'],
  providers: [TranslatePipe]
})
export class QuotePaymentsComponent implements OnInit, OnChanges {

  @Input() quoteId!: string;
  @Input() hasActions?: boolean;

  quotePayments: any[];
  infoOverlayIndexToShow = -1;

  constructor(private dialog: MatDialog,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar,
              private quotePaymentsGQL: QuotePaymentsGQL,
              private paymentCreateGQL: PaymentCreateGQL,
              private paymentUpdateGQL: PaymentUpdateGQL,
              private paymentDeleteGQL: PaymentDeleteGQL) {
    this.quotePayments = [];
  }

  ngOnInit(): void {
  }

  onOpenDocumentClick(urlToOpen?: string | undefined): void {
    window.open(urlToOpen || 'no-url', '_blank');
  }

  private fetchQuotePayments(): void {
    this.quotePaymentsGQL.fetch({id: this.quoteId}, {fetchPolicy: 'no-cache'}).subscribe(res => {
      this.quotePayments = res.data?.quote?.payments ? res.data.quote.payments : [];
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.quoteId) {
      this.fetchQuotePayments();
    }
  }

  onAddNewPaymentClick(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'paymentDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('payment-modal.field--paymentDate'),
        required: true
      },
      {
        fieldKey: 'paymentDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('payment-modal.field--paymentDocument'),
        required: true
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('payment-modal.field--note'),
      },
      {
        fieldKey: 'quoteId',
        type: 'text-readonly',
        hidden: true,
        value: this.quoteId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('payment-modal.create-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.paymentCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.paymentCreate) {
            this.quotePayments = [...this.quotePayments, res.data?.paymentCreate];
          }
          this.snackBar.open(this.translatePipe.transform('payment-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openEditPaymentModal(item: any): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'paymentDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('payment-modal.field--paymentDate'),
        required: true,
        value: item.paymentDate
      },
      {
        fieldKey: 'paymentDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('payment-modal.field--paymentDocument'),
        required: true,
        value: item?.paymentDocument?.url ? {url: item?.paymentDocument.url} : undefined,
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('payment-modal.field--note'),
        value: item.note
      },
      {
        fieldKey: 'quoteId',
        type: 'text-readonly',
        hidden: true,
        value: this.quoteId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('payment-modal.edit-title'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.paymentUpdateGQL.mutate({
          paymentId: item.id,
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.paymentUpdate) {
            const foundIndex = this.quotePayments.findIndex((d: any) => d.id === item.id);
            if (foundIndex !== -1) {
              this.quotePayments[foundIndex] = {...res.data.paymentUpdate};
              this.quotePayments = [...this.quotePayments]; // needed that to trigger angular update.
            }
          }
          this.snackBar.open(this.translatePipe.transform('payment-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  private openDeletePaymentModal(paymentId: string): void {
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.paymentDeleteGQL.mutate({
          id: paymentId,
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.paymentDelete.success === OperationReturnTypeEnum.Ok) {
            this.quotePayments = this.quotePayments.filter(p => p.id !== paymentId);
          }
          this.snackBar.open(this.translatePipe.transform('delete-item-confirmation-modal.delete-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, (error: any) => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  onActionButtonClick(actionName: 'update' | 'delete', item: any): void {
    if (actionName === 'update') {
      this.openEditPaymentModal(item);
    } else if (actionName === 'delete') {
      this.openDeletePaymentModal(item.id);
    }
    this.infoOverlayIndexToShow = -1;
  }
}
