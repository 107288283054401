<div class="filters">
  <div *ngFor="let filter of internalFilters"
       [class.filter--hidden]="filter.type === FilterTypes.HIDDEN">
    <!-- Default - text input -->
    <div *ngIf="!filter.type || filter.type === FilterTypes.TEXT">
      <mat-form-field appearance="outline" color="primary">
        <input matInput
               type="text"
               [placeholder]="filter?.placeholder || ''"
               [(ngModel)]="filter.newValue"
               (ngModelChange)="onSubjectChange()"/>
      </mat-form-field>
    </div>
    <!-- Dropdown single select-->
    <div class="filter--dropdown" *ngIf="filter.type === FilterTypes.DROPDOWN">
      <mat-form-field appearance="outline" color="primary">
        <mat-select [placeholder]="filter?.placeholder || ''"
                    [(ngModel)]="filter.newValue"
                    (ngModelChange)="onSubjectChange()">
          <mat-option *ngFor="let value of filter.values" [value]="value.id" [title]="value.text">
            {{value.text}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Date input -->
    <div *ngIf="filter.type === FilterTypes.DATE">
      <mat-form-field appearance="outline" class="full-width-field mat-form-field--datepicker-dark">
        <input matInput
               [placeholder]="filter?.placeholder || ''"
               [matDatepicker]="pickerDate"
               [(ngModel)]="filter.newValue"
               (ngModelChange)="onSubjectChange()">
        <mat-datepicker-toggle [disableRipple]="true" matPrefix [for]="pickerDate">
          <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="apri calendario">calendar_today</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="filter--keyword" *ngIf="!filter.type || filter.type === FilterTypes.KEYWORD">
      <mat-form-field appearance="outline" color="primary">
        <input matInput
               type="text"
               [placeholder]="filter?.placeholder || ''"
               (keydown.enter)="onKeywordSearchClicked($event, filter)"
               [(ngModel)]="filter.newValue"/>
        <span *ngIf="filter.newValue" class="cursor-pointer" matSuffix
              (click)="filter.newValue='';onKeywordSearchClicked($event)">
              <mat-icon aria-hidden="false" aria-label="cancella">close</mat-icon>
            </span>
      </mat-form-field>
      <button mat-flat-button
              color="primary"
              class="mat-elevation-z1"
              (click)="onKeywordSearchClicked($event)">
        <mat-icon aria-hidden="false" aria-label="cerca">search</mat-icon>
        {{'filters.search' | translate}}</button>
    </div>
  </div>
</div>
