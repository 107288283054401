import { NgModule }        from '@angular/core';
import { CommonModule }    from '@angular/common';
import { HeaderComponent } from './header.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule }    from '@angular/router';
import { MatMenuModule }   from '@angular/material/menu';


@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    MatMenuModule,
  ],
  exports: [HeaderComponent]
})
export class HeaderModule {
}
