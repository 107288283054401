import { Directive, OnDestroy } from '@angular/core';
import { Unsubscribable }       from 'rxjs';

@Directive()
export class SubscriptionsRegisterDirective implements OnDestroy {

  subscriptions: Unsubscribable[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
