<div class="base-modal versioned-field-modal">
  <div class="base-modal__header base-modal__header--sticky">
    <h3
      class="base-modal__header__title">{{('versioned-field-modal.title-operation--' + this.data?.operationType) | translate}}</h3>
    <button [mat-dialog-close]="false" mat-icon-button class="mat-icon-button--small">
      <mat-icon aria-hidden="false" aria-label="chiudi">close</mat-icon>
    </button>
  </div>
  <div class="base-modal__body base-modal__body--with-sticky-header">
    <ng-container *ngIf="viewStepIndex === 0 && data.operationType !== 'delete'">
      <p
        class="base-modal__body-hint">{{data.operationType === 'edit' ? ('versioned-field-modal.versioning-update' | translate) : ''}}</p>
      <div class="base-modal__fields-container">
        <div class="form-field-container">
          <label>{{attributeValueLabel}}</label>
          <mat-form-field *ngIf="!data.attibuteValueSelectOptions" appearance="outline">
            <input matInput
                   type="text"
                   [name]="attributeValueLabel"
                   [maxLength]="1000"
                   placeholder=""
                   [formControl]="attributeValueControl"
                   [errorStateMatcher]="errorStateMatcher"
                   (ngModelChange)="onFieldChange($event, 'attributeValue')"/>
            <mat-error
              *ngIf="attributeValueControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="data.attibuteValueSelectOptions"
                          class="full-width-field"
                          appearance="outline">
            <mat-select placeholder=""
                        [value]="attributeValueControl.value"
                        [errorStateMatcher]="errorStateMatcher"
                        (selectionChange)="onSelectChange($event, 'attributeValue')">
              <mat-option *ngFor="let item of data.attibuteValueSelectOptions"
                          [value]="item.id">{{item.text}}</mat-option>
            </mat-select>
            <mat-error
              *ngIf="attributeValueControl.hasError('required')">{{'form-validation.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="form-field-container" [ngStyle]="{gridColumn: 'span 6'}">
          <label>{{'versioned-field-modal.valid-from' | translate}}</label>
          <mat-form-field appearance="outline" class="mat-form-field--datepicker-dark">
            <input matInput
                   [name]="'validFrom'"
                   placeholder=""
                   [max]="validFromMaxConstraint"
                   [matDatepicker]="validFromPicker"
                   [errorStateMatcher]="errorStateMatcher"
                   [formControl]="validFromControl"
                   (ngModelChange)="onFieldChange($event, 'validFrom')">
            <mat-datepicker-toggle [disableRipple]="true" matPrefix [for]="validFromPicker">
              <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="apri calendario">calendar_today
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #validFromPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-field-container" [ngStyle]="{gridColumn: 'span 6'}">
          <label>{{'versioned-field-modal.valid-to' | translate}}</label>
          <mat-form-field appearance="outline" class="mat-form-field--datepicker-dark">
            <input matInput
                   [name]="'validTo'"
                   placeholder=""
                   [min]="fieldsModel.validFrom ? fieldsModel.validFrom : undefined"
                   [matDatepicker]="validToPicker"
                   [errorStateMatcher]="errorStateMatcher"
                   [formControl]="validToControl"
                   (ngModelChange)="onFieldChange($event, 'validTo')">
            <mat-datepicker-toggle [disableRipple]="true" matPrefix [for]="validToPicker">
              <mat-icon matDatepickerToggleIcon aria-hidden="false" aria-label="apri calendario">calendar_today
              </mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #validToPicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="data.operationType === 'add-new' && data?.versionedData">
        <p>{{'versioned-field-modal.current-version' | translate}}</p>
        <p><strong>{{data.versionedData?.__typename === 'VersionedUniParticipation' ? (('institution-details.uniparticipation--'+ data.versionedData?.attributeValue) | translate) : (data.versionedData?.attributeValue || '\n' +
          '—')}}</strong>&nbsp;({{'versioned-field-modal.valid-from' | translate}}:&nbsp;{{data.versionedData?.validFrom ? (data.versionedData?.validFrom | date: 'dd/MM/yyyy') : '—'}},&nbsp;{{'versioned-field-modal.valid-to' | translate}}:&nbsp;{{data.versionedData?.validTo ? (data.versionedData?.validTo | date: 'dd/MM/yyyy') : '—' }})</p>
      </div>
      <div *ngIf="data.previousVersionedRecord">
        <p>{{'versioned-field-modal.previous-version' | translate}}</p>
        <p><strong>{{data.previousVersionedRecord?._versionedType === 'VersionedUniParticipation' ? (('institution-details.uniparticipation--'+ data.previousVersionedRecord?.genericValue) | translate) : (data.previousVersionedRecord?.genericValue || '\n' +
          '—')}}</strong>&nbsp;({{'versioned-field-modal.valid-from' | translate}}:&nbsp;{{ data.previousVersionedRecord?.validFrom ? (data.previousVersionedRecord?.validFrom | date: 'dd/MM/yyyy') : '—'}},&nbsp;{{'versioned-field-modal.valid-to' | translate}}:&nbsp;{{data.previousVersionedRecord?.validTo ? (data.previousVersionedRecord?.validTo | date: 'dd/MM/yyyy') : '—'}})</p>
      </div>
    </ng-container>
    <ng-container *ngIf="viewStepIndex === 0 && data.operationType === 'delete'">
      <p>Vuoi procedere con l'eliminazione del dato versionato <strong>{{attributeValueLabel}}</strong>?</p>
    </ng-container>
    <ng-container *ngIf="viewStepIndex === 1">
      <!--      partially = partial_intersections + inner_intersections-->
      <h3>Attenzione</h3>
      <p
        *ngIf="intersectionsData.partially.length > 0">{{ 'versioned-field-modal.warning-intersection-overwriting' | translate}}</p>
      <div class="intersection-item" *ngFor="let item of intersectionsData.partially">
        <span>• {{item.genericValue}}</span>
        <span>{{item.validFrom ? (item.validFrom | date: 'dd/MM/yyyy') : ('versioned-field-modal.date-not-defined' | translate)}}</span>
        <span>{{item.validTo ? (item.validTo | date: 'dd/MM/yyyy') : ('versioned-field-modal.date-not-defined' | translate)}}</span>
      </div>
      <!--      info@moku.io              2020                2022-->
      <!--      totally = outer_intersections-->
      <p
        *ngIf="intersectionsData.totally.length > 0">{{ 'versioned-field-modal.warning-intersection-deletion' | translate }}</p>
      <div class="intersection-item" *ngFor="let item of intersectionsData.totally">
        <span>• {{item.genericValue}}</span>
        <span>{{item.validFrom ? (item.validFrom | date: 'dd/MM/yyyy') : ('versioned-field-modal.date-not-defined' | translate)}}</span>
        <span>{{item.validTo ? (item.validTo | date: 'dd/MM/yyyy') : ('versioned-field-modal.date-not-defined' | translate)}}</span>
      </div>
      <!--      info3@moku.io           1990                 2003-->
      <p class="warning-confirm-text">{{'versioned-field-modal.warning-intersection-confirm-action' | translate}}</p>
    </ng-container>
  </div>
  <div class="base-modal__footer">
    <button mat-button
            color="primary"
            (click)="onCancelClick()"
    >{{('base-modal.cancel' | translate)}}</button>
    <button mat-stroked-button
            color="primary"
            class="mat-elevation-z1"
            [disabled]="isConfirmDisabled()"
            (click)="onConfirmClick()">
      <mat-icon aria-hidden="false" aria-label="conferma">done</mat-icon>
      {{('base-modal.confirm' | translate)}}
    </button>
  </div>
</div>
