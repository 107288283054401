import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDatepickerModule }                                                                             from '@angular/material/datepicker';
import {
  MatPaginator,
  PageEvent
}                                                                                                          from '@angular/material/paginator';
import { ActivatedRoute, Router }                                                                          from '@angular/router';
import { FilterSetting }                                                                                   from '../filters/filters.component';
import { StringClampPipe }                                                                                 from '../string-clamp/string-clamp.pipe';
import {
  SortKey,
  SortKeyDirection
}                                                                                                          from '../../../generated/graphql';

export enum TableColumnTypes {
  DEFAULT = 'default',
  TWO_TEXT = 'two-text',
  LINK = 'link',
  BOOLEAN = 'boolean',
  DATE = 'date',
  STATUS = 'status',
  IMAGE = 'image',
  CURRENCY = 'currency',
  DOCUMENT = 'document',
  TEXT_EDITABLE = 'text-editable',
  DOCUMENT_EDITABLE = 'doc-editable',
  ACTIONS = 'actions'
}

export enum TableRowActions {
  VIEW_DETAILS = 'view-details',
  UPDATE = 'update',
  DELETE = 'delete'
}

export interface PaginationData {
  pageSize: number;
  pageInfo?: any;
  totalCount?: number;
}

export interface TableData {
  list: any[];
  columnsConfig: {
    dataToShowKey: string,
    headerTitleKey: string,
    type?: TableColumnTypes,
    secondaryDataToShowKey?: string,
    clampString?: boolean,
    linkIcon?: string,
    linkTarget?: string,
    hasCellMarking?: boolean,
    sortKey?: SortKey,
    sortDefaultValue?: SortKeyDirection
  }[];
}

@Component({
  selector: 'et-filterable-table',
  templateUrl: './filterable-table.component.html',
  styleUrls: ['./filterable-table.component.scss'],
  providers: [MatDatepickerModule, StringClampPipe]
})
export class FilterableTableComponent implements OnInit, OnChanges {

  @Input() tableData: TableData | undefined;
  @Input() tableTitle?: string;
  @Input() hasPagination?: boolean;
  @Input() paginationData?: PaginationData;
  @Input() filters?: Array<FilterSetting>;
  @Input() filtersQueryParamMode?: boolean;
  @Input() hasStickyHeader?: boolean;
  @Input() dataLoading?: boolean;
  @Input() isRowClickable?: boolean;
  @Input() hasRowMarking?: boolean;
  @Input() enableCellMark?: boolean;
  @Input() customEmptyDataLabel?: string;
  @Input() showDoSearchHint?: boolean;
  @Input() hasAddNewButton?: boolean;
  @Input() addNewButtonText?: string;
  @Input() addNewButtonIcon?: string;

  @Output() pageChange = new EventEmitter<{ pageSize: number, after: any, before?: any }>();
  @Output() filtersChanged = new EventEmitter();
  @Output() sortChanged = new EventEmitter<{ key: SortKey, direction: SortKeyDirection }>();
  @Output() addNewClicked = new EventEmitter();
  @Output() validateItemClicked = new EventEmitter<any>();
  @Output() unapproveItemSucceeded = new EventEmitter<any>();
  @Output() rowClicked = new EventEmitter<any>();
  @Output() linkCellClicked = new EventEmitter<{ data: any, linkTarget: string | undefined }>();
  @Output() actionButtonClicked = new EventEmitter<{ actionType: string, object: any, valueKey?: string, newValue?: any }>();
  // inside object sometimes we have id we need to update the entry... sometimes inside object there is other precious data like an url to open

  // columns to be displayed with its related data. String of backend object keys needed
  displayedColumns: string[] = [];
  // Editable field row index among the rows
  editableIndex: number | undefined;
  TableRowActions = TableRowActions;
  TableColumnTypes = TableColumnTypes;
  SortKeyDirection = SortKeyDirection;
  infoOverlayIndexToShow = -1;
  sortDirection: { [key: string]: any } = {};

  // just so he does not initialize the columns two times
  firstColConfigDone = false;

  // @ts-ignore
  @ViewChild('paginatorRef') paginator: MatPaginator;
  // @ts-ignore
  @ViewChild('editableInputRef') editableInput: ElementRef;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private stringClampPipe: StringClampPipe) {
  }

  ngOnInit(): void {
    if (!this.firstColConfigDone) {
      // I added this because in one case this.configColumns() is called two times in a row,
      // first on ngOnChanges then on ngOnInit and the status labels were like 'table.status-admin-admin-bought'
      // instead of 'table.status-admin-bought'
      this.configColumns();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData) {
      this.tableData = changes.tableData.currentValue;
      this.configColumns();
      this.firstColConfigDone = true;
    }
  }

  configColumns(): void {
    if (this.tableData) {
      if (!this.tableData.columnsConfig || this.tableData.columnsConfig.length === 0) {
        throw new Error('Wrong columns config');
      }
      this.displayedColumns = Object.values(this.tableData.columnsConfig.reduce((seeYouLaterAccumulator, o, index) => {
        if (o.dataToShowKey) {
          seeYouLaterAccumulator[index] = o.dataToShowKey;
        }
        return seeYouLaterAccumulator;
      }, this.displayedColumns));

      const activeSortColumn = this.tableData.columnsConfig.find(c => c.sortKey && c.sortDefaultValue);
      if (activeSortColumn) {
        this.sortDirection[(activeSortColumn.sortKey as string)] = activeSortColumn.sortDefaultValue;
      }

      if (!!this.tableData.list && this.tableData.list.some(el => el.status)) {
        const statusProcessedList = this.tableData.list.map(el => {
          if (Array.isArray(el.status)) {
            return {
              ...el,
              status: {
                array: el.status.filter(Boolean) // clean array of possible invalid values
              }
            };
          } else {
            return {
              ...el,
              status: el.status
            };
          }
        });
        this.tableData.list = [...statusProcessedList];
      }
    }
    // first time array is empty, after ngChanges of parent component it will get the array of data. think about it
    // else {
    // 	throw new Error('Wrong input data for Table');
    // }
  }

  pageEvent(event: PageEvent): void {
    if (this.tableData) {
      this.tableData.list = [];
    }
    // If the page size changed
    if (event.pageSize !== this.paginationData?.pageSize) {
      return;
    }

    if (!event.previousPageIndex || event.previousPageIndex < event.pageIndex) {
      // Next page
      this.pageChange.emit({pageSize: event.pageSize, after: this.paginationData.pageInfo.endCursor});
    } else if (event.previousPageIndex > event.pageIndex) {
      // Previous page
      this.pageChange.emit({pageSize: event.pageSize, after: undefined, before: this.paginationData.pageInfo.startCursor});
    }
    /*const queryParams: Params = {pageIndex: event.pageIndex, previousPageIndex: event.previousPageIndex};
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });*/
  }

  onFiltersChanged(newFilters: any): void {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.filtersChanged.emit(newFilters);
  }

  onRowClick(row: any): void {
    if (this.isRowClickable) {
      this.rowClicked.emit(row);
    }
  }

  // onEditableFieldChange(event: any, row: any, column?: any): void {
  //   const newValue = event.target.value;
  //   if (row && newValue !== '') {
  //     this.actionButtonClicked.emit({actionType: TableRowActions.RENAME_CELL, object: row, newValue});
  //     this.editableIndex = undefined;
  //   }
  // }
  //
  // onEditableFieldBlur(event: any, row: any, column?: any): void {
  //   const newValue = event.target.value;
  //   if (newValue !== this.editableInput.nativeElement.value) {
  //     this.actionButtonClicked.emit({actionType: TableRowActions.RENAME_CELL, object: row, newValue});
  //   }
  //   this.editableIndex = undefined;
  // }

  onActionButtonClick(actionType: any, row: any): void {
    if (actionType === TableRowActions.VIEW_DETAILS || actionType === TableRowActions.DELETE || actionType === TableRowActions.UPDATE) {
      this.actionButtonClicked.emit({actionType, object: row});
      this.infoOverlayIndexToShow = -1;
    }
  }

  // makeCellEditable(index: number): void {
  //   this.editableIndex = index;
  //   setTimeout(() => {
  //     this.editableInput.nativeElement.focus();
  //   }, 0);
  // }
  //
  // showInput(index: number): boolean {
  //   return this.editableIndex === index;
  // }

  showValue(index: number): boolean {
    return this.editableIndex !== index;
  }

  onLinkClick(listRow: any, linkTarget: string | undefined): void {
    this.linkCellClicked.emit({data: listRow, linkTarget});
  }

  // tslint:disable-next-line:max-line-length
  getSecondaryDataValue(listRow: any, columnsConfigElement: { dataToShowKey: string; headerTitleKey: string; type?: TableColumnTypes; secondaryDataToShowKey?: string; clampString?: boolean; linkIcon?: string; linkTarget?: string }): string {
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    return columnsConfigElement.clampString ? this.stringClampPipe.transform(listRow[columnsConfigElement.secondaryDataToShowKey], 25) : listRow[columnsConfigElement.secondaryDataToShowKey];
  }

  onAddNewClick(): void {
    this.addNewClicked.emit();
  }

  validateItem(rowItem: any): void {
    this.validateItemClicked.emit(rowItem);
  }

  onUnapproveItemSuccess(unapprovedResult: any): void {
    this.unapproveItemSucceeded.emit(unapprovedResult);
  }

  sortListBy(key: SortKey | undefined, direction: SortKeyDirection): void {
    this.sortDirection = {};
    if (key) {
      this.sortDirection[key] = direction;
      this.sortChanged.emit({key, direction});
    }
  }
}
