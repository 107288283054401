import { Component, OnInit }                                      from '@angular/core';
import { PaginationData, TableData }                              from '../shared/filterable-table/filterable-table.component';
import { FilterInternal, FilterSetting, FilterTypes }             from '../shared/filters/filters.component';
import { Person, PersonCreateGQL, PersonSearchGQL, UserRoleEnum } from '../../generated/graphql';
import { TranslatePipe }                                          from '@ngx-translate/core';
import { Router }                                                 from '@angular/router';
import { getErrorMessageFromData, getFixedSmallModalSettings }    from '../shared/utils/helpers';
import { MatSnackBar }                                            from '@angular/material/snack-bar';
import { MatDialog }                                              from '@angular/material/dialog';
import { BaseModalComponent, ModalField }                         from '../shared/base-modal/base-modal.component';
import { UserService }                                            from '../core/user/user.service';


export interface PersonRow {
  id: string;
  nominative: string | undefined;
  fiscalCode: string | undefined;
}

export interface PersonFilters {
  keyword?: string | undefined;
}

@Component({
  selector: 'et-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss'],
  providers: [TranslatePipe]
})
export class PersonsComponent implements OnInit {

  tableLoading = false;
  personsTableConfig: TableData;
  paginationData: PaginationData = {
    pageSize: 10
  };
  filtersData: PersonFilters = {};
  filtersConfig: FilterSetting[] | undefined;
  isAdmin: boolean;
  isViewer: Boolean;

  private static formatArrayToMatchTableData(arrayToFormat: any): Array<PersonRow> {
    return arrayToFormat.map((el: Person) => {
      return {
        id: el.id,
        nominative: el.name,
        fiscalCode: el.fiscalCode
      };
    }, []);
  }

  constructor(private snackBar: MatSnackBar,
              private translatePipe: TranslatePipe,
              private router: Router,
              private dialog: MatDialog,
              private userService: UserService,
              private personSearchGQL: PersonSearchGQL,
              private personCreateGQL: PersonCreateGQL) {
    this.personsTableConfig = {
      list: [],
      columnsConfig: [
        {dataToShowKey: 'nominative', headerTitleKey: 'persons.nominative'},
        {dataToShowKey: 'fiscalCode', headerTitleKey: 'persons.fiscal-code'}
      ]
    };
    this.isAdmin = this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.InstitutionAdmin;
    this.isViewer = this.userService.user?.role === UserRoleEnum.Viewer;
  }

  ngOnInit(): void {

    this.filtersConfig = [
      {
        fieldKey: 'keyword',
        placeholder: this.translatePipe.transform('persons.filter-keyword-placeholder'),
        type: FilterTypes.KEYWORD
      }
    ];
  }

  private fetchPersons(first?: number, last?: number, after?: string, before?: string, keyword?: string): void {
    this.tableLoading = true;
    this.personSearchGQL.fetch({
        first,
        last,
        after,
        before,
        keyword: keyword || ''
      },
      {fetchPolicy: 'no-cache'}
    ).subscribe(res => {
      this.personsTableConfig.list = PersonsComponent.formatArrayToMatchTableData(res.data.personSearch?.edges?.map((e) => e?.node));
      this.paginationData.totalCount = res.data.personSearch?.totalCount;
      this.paginationData.pageInfo = res.data.personSearch?.pageInfo;
      this.tableLoading = false;
    }, error => {
      this.tableLoading = false;
      this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
        duration: 4000
      });
    });
  }


  openPersonDetail(rowData: any): void {
    this.router.navigate([`/persone/${rowData.id}`]);
  }

  onNewFilters(newFilters: FilterInternal[]): void {
    const keywordFilter = newFilters.find(filter => filter.fieldKey === 'keyword');
    let keywordValue;
    if (keywordFilter) {
      keywordValue = keywordFilter.newValue;
    }
    if (keywordValue !== this.filtersData.keyword) {
      if (this.personsTableConfig.list?.length > 0) {
        this.personsTableConfig.list = [];
      }
      if (!keywordValue) {
        this.filtersData.keyword = undefined;
        this.paginationData.totalCount = 0;
        this.tableLoading = false;
      } else {
        this.filtersData.keyword = keywordValue;
        this.fetchPersons(this.paginationData.pageSize, undefined, undefined, undefined, this.filtersData.keyword);
      }
    }
  }

  onPageChange(event: { pageSize: number; after: any; before?: any }): void {
    if (event.after && !event.before) {
      // go forward
      this.fetchPersons(this.paginationData.pageSize, undefined, event.after, undefined,
        this.filtersData.keyword);
    } else if (event.before) {
      // go backward
      this.fetchPersons(undefined, this.paginationData.pageSize, undefined,
        event.before, this.filtersData.keyword);
    }
  }

  openAddNewPersonModal(): void {

    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'fiscalCode',
        type: 'text',
        label: this.translatePipe.transform('person-modal.field--fiscalCode'),
        required: false
      },
      {
        fieldKey: 'name',
        type: 'text',
        label: this.translatePipe.transform('person-modal.field--name'),
        required: true
      },
      {
        fieldKey: 'uniEmployee',
        type: 'select',
        label: this.translatePipe.transform('person-modal.field--uniEmployee'),
        width: 6,
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ],
        required: true
      },
      {
        fieldKey: 'personalDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('person-modal.field--personalDocument'),
        required: false
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('persons.add-person'),
        fields: fieldsConf
      }
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        // where needed use BaseModalComponent.filterConfirmedModelByDisabledAndExcludedKeys(fieldsModel)
        this.tableLoading = true;
        this.personCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          this.snackBar.open(this.translatePipe.transform('person-modal.add-new-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
          this.filtersData.keyword = res.data?.personCreate?.name;
          this.fetchPersons(this.paginationData.pageSize, undefined, undefined, undefined, this.filtersData.keyword);
        }, error => {
          this.tableLoading = false;
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }
}
