import { Component, EventEmitter, Input, OnInit, Output }      from '@angular/core';
import {
  AdministrativeBodyTypeEnum,
  AdvisoryBodyTypeEnum,
  ApprovableTypeEnum,
  ControlBodyTypeEnum,
  LegalBodyCreateGQL,
  LegalBodyDeleteGQL,
  LegalBodyTypeEnum,
  LegalBodyUpdateGQL,
  OperationReturnTypeEnum,
  PersonSearchGQL,
  Role,
  RoleCreateGQL,
  RoleDeleteGQL,
  RoleTypeEnum,
  RoleUpdateGQL,
  SteeringBodyTypeEnum,
  UserRoleEnum
}                                                              from '../../../generated/graphql';
import { UserService }                                         from '../../core/user/user.service';
import { BaseModalComponent, ModalField }                      from '../../shared/base-modal/base-modal.component';
import { getErrorMessageFromData, getFixedSmallModalSettings } from '../../shared/utils/helpers';
import { TranslatePipe }                                       from '@ngx-translate/core';
import { MatSnackBar }                                         from '@angular/material/snack-bar';
import { MatDialog }                                           from '@angular/material/dialog';
import { MatDialogRef }                                        from '@angular/material/dialog/dialog-ref';
import { ReferentsAndRolesService }                            from './referents-and-roles.service';
import { SubscriptionsRegisterDirective }                      from '../../shared/subscriptions-register/subscriptions-register.directive';

@Component({
  selector: 'et-referents-and-roles',
  templateUrl: './referents-and-roles.component.html',
  styleUrls: ['./referents-and-roles.component.scss'],
  providers: [TranslatePipe]
})
export class ReferentsAndRolesComponent extends SubscriptionsRegisterDirective implements OnInit {

  roleOverlayIndexToShow = -1;
  referentOverlayIndexToShow = -1;
  isAdmin: boolean;
  isViewer: boolean;
  legalBodyTypes = LegalBodyTypeEnum;
  roleApprovableType = ApprovableTypeEnum.Role;

  @Input() listData!: any[];
  @Input() institutionId!: string;
  @Output() viewDetailsClicked = new EventEmitter<{ referentId: string, roleId: string }>();
  @Output() validateRoleItemClicked = new EventEmitter<{ id: string, approvableType: ApprovableTypeEnum }>();
  @Output() refreshRoleApprovable = new EventEmitter<any>();
  @Output() listDataUpdated = new EventEmitter<{ listData: any, updateUnapprovables?: boolean }>();

  // tslint:disable-next-line:max-line-length
  private static getReferentPayloadObjFromFieldsModel(fieldsModel: any): { administrativeBodyInput: any, advisoryBodyInput: any, controlBodyInput: any, legalRepresentativeInput: any, otherBodyInput: any, steeringBodyInput: any } {
    const commonFields = {
      expectedEmployeeNumber: fieldsModel.expectedEmployeeNumber,
      duration: fieldsModel.duration,
      expiryDate: fieldsModel.expiryDate,
      description: fieldsModel.description,
      note: fieldsModel.note,
      institutionId: fieldsModel.institutionId
    };
    // tslint:disable-next-line:one-variable-per-declaration
    let administrativeBodyInput, advisoryBodyInput, controlBodyInput, legalRepresentativeInput, otherBodyInput, steeringBodyInput;
    switch (fieldsModel._referentType) {
      case LegalBodyTypeEnum.AdministrativeBody:
        administrativeBodyInput = {
          ...commonFields,
          administrativeBodyType: fieldsModel._referentSubtype
        };
        break;
      case LegalBodyTypeEnum.AdvisoryBody:
        advisoryBodyInput = {
          ...commonFields,
          advisoryBodyType: fieldsModel._referentSubtype
        };
        break;
      case LegalBodyTypeEnum.ControlBody:
        controlBodyInput = {
          ...commonFields,
          controlBodyType: fieldsModel._referentSubtype
        };
        break;
      case LegalBodyTypeEnum.SteeringBody:
        steeringBodyInput = {
          ...commonFields,
          steeringBodyType: fieldsModel._referentSubtype
        };
        break;
      case LegalBodyTypeEnum.LegalRepresentative:
        legalRepresentativeInput = {
          ...commonFields
        };
        break;
      case LegalBodyTypeEnum.OtherBody:
        otherBodyInput = {
          ...commonFields,
          name: fieldsModel.name
        };
        break;
    }

    return {
      administrativeBodyInput,
      advisoryBodyInput,
      controlBodyInput,
      legalRepresentativeInput,
      otherBodyInput,
      steeringBodyInput
    };
  }

  constructor(private userService: UserService,
              private translatePipe: TranslatePipe,
              private snackBar: MatSnackBar,
              private dialog: MatDialog,
              private referentAndRolesService: ReferentsAndRolesService,
              private legalBodyCreateGQL: LegalBodyCreateGQL,
              private legalBodyUpdateGQL: LegalBodyUpdateGQL,
              private legalBodyDeleteGQL: LegalBodyDeleteGQL,
              private personSearchGQL: PersonSearchGQL,
              private roleCreateGQL: RoleCreateGQL,
              private roleUpdateGQL: RoleUpdateGQL,
              private roleDeleteGQL: RoleDeleteGQL) {
    super();
    this.isAdmin = this.userService.user?.role === UserRoleEnum.SpinoffAdmin || this.userService.user?.role === UserRoleEnum.InstitutionAdmin;
    this.isViewer = this.userService.user?.role === UserRoleEnum.Viewer;
  }

  private getReferentSubtypeOptionByType(which: LegalBodyTypeEnum): any[] | null {
    switch (which) {
      case LegalBodyTypeEnum.AdministrativeBody:
        return [
          {
            id: AdministrativeBodyTypeEnum.SoleDirector,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.SoleDirector)
          },
          {
            id: AdministrativeBodyTypeEnum.AdministrativeBoard,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.AdministrativeBoard)
          },
          {
            id: AdministrativeBodyTypeEnum.BoardOfDirectors,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.BoardOfDirectors)
          },
          {
            id: AdministrativeBodyTypeEnum.ManagementBoard,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.ManagementBoard)
          },
          {
            id: AdministrativeBodyTypeEnum.ScientificTechnicalCommittee,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.ScientificTechnicalCommittee)
          },
          {
            id: AdministrativeBodyTypeEnum.Director,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.Director)
          },
          {
            id: AdministrativeBodyTypeEnum.ConsortiumCouncil,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.ConsortiumCouncil)
          },
          {
            id: AdministrativeBodyTypeEnum.OtherAdministrativeBody,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.OtherAdministrativeBody)
          },
          {
            id: AdministrativeBodyTypeEnum.AdministrativeBoard,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdministrativeBodyTypeEnum.AdministrativeBoard)
          }
        ];
      case LegalBodyTypeEnum.AdvisoryBody:
        return [
          {
            id: AdvisoryBodyTypeEnum.ScientificCommittee,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdvisoryBodyTypeEnum.ScientificCommittee)
          },
          {
            id: AdvisoryBodyTypeEnum.ConsortiumConsult,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdvisoryBodyTypeEnum.ConsortiumConsult)
          },
          {
            id: AdvisoryBodyTypeEnum.ScientificConsult,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdvisoryBodyTypeEnum.ScientificConsult)
          },
          {
            id: AdvisoryBodyTypeEnum.OtherAdvisoryBody,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + AdvisoryBodyTypeEnum.OtherAdvisoryBody)
          }
        ];
      case LegalBodyTypeEnum.ControlBody:
        return [
          {
            id: ControlBodyTypeEnum.SupervisoryBoard,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + ControlBodyTypeEnum.SupervisoryBoard)
          },
          {
            id: ControlBodyTypeEnum.BoardOfAuditors,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + ControlBodyTypeEnum.BoardOfAuditors)
          },
          {
            id: ControlBodyTypeEnum.OtherControlBody,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + ControlBodyTypeEnum.OtherControlBody)
          }
        ];
      case LegalBodyTypeEnum.SteeringBody:
        return [
          {
            id: SteeringBodyTypeEnum.Assembly,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + SteeringBodyTypeEnum.Assembly)
          },
          {
            id: SteeringBodyTypeEnum.BoardOfDirectors,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + SteeringBodyTypeEnum.BoardOfDirectors)
          },
          {
            id: SteeringBodyTypeEnum.AddressBoard,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + SteeringBodyTypeEnum.AddressBoard)
          },
          {
            id: SteeringBodyTypeEnum.ScientificTechnicalCommittee,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + SteeringBodyTypeEnum.ScientificTechnicalCommittee)
          },
          {
            id: SteeringBodyTypeEnum.OtherSteeringBody,
            text: this.translatePipe.transform('referents-and-roles.body-subtype--' + SteeringBodyTypeEnum.OtherSteeringBody)
          }
        ];
      default:
        return null;
    }
  }

  private getReferentTypeOptions(): any[] {
    return [
      {
        id: LegalBodyTypeEnum.AdministrativeBody,
        text: this.translatePipe.transform('referents-and-roles.body-type--' + LegalBodyTypeEnum.AdministrativeBody)
      },
      {
        id: LegalBodyTypeEnum.AdvisoryBody,
        text: this.translatePipe.transform('referents-and-roles.body-type--' + LegalBodyTypeEnum.AdvisoryBody)
      },
      {
        id: LegalBodyTypeEnum.ControlBody,
        text: this.translatePipe.transform('referents-and-roles.body-type--' + LegalBodyTypeEnum.ControlBody)
      },
      {
        id: LegalBodyTypeEnum.LegalRepresentative,
        text: this.translatePipe.transform('referents-and-roles.body-type--' + LegalBodyTypeEnum.LegalRepresentative)
      },
      {
        id: LegalBodyTypeEnum.SteeringBody,
        text: this.translatePipe.transform('referents-and-roles.body-type--' + LegalBodyTypeEnum.SteeringBody)
      },
      {
        id: LegalBodyTypeEnum.OtherBody,
        text: this.translatePipe.transform('referents-and-roles.body-type--' + LegalBodyTypeEnum.OtherBody)
      }
    ];
  }

  private setReferentModalHandler(matDialogRef: MatDialogRef<BaseModalComponent, any>): void {
    const compInstance = matDialogRef.componentInstance;
    compInstance.onFieldsChange.subscribe(data => {
      if (data.fieldName === '_referentType') {
        if (data.currentFieldsModel[data.fieldName] === LegalBodyTypeEnum.OtherBody) {
          // show name field
          compInstance.setFieldVisibility('_referentSubtype', false);
          compInstance.setFieldVisibility('name', true);
          compInstance.setFieldRequired('_referentSubtype', false);
          compInstance.setFieldRequired('name', true);
        } else if (data.currentFieldsModel[data.fieldName] !== LegalBodyTypeEnum.LegalRepresentative) {
          // show and activate referent subtype field
          compInstance.setFieldVisibility('_referentSubtype', true);
          compInstance.setFieldVisibility('name', false);
          compInstance.setFieldRequired('_referentSubtype', true);
          const options = this.getReferentSubtypeOptionByType(data.currentFieldsModel._referentType);
          if (options) {
            compInstance.setSelectFieldOptions('_referentSubtype', options);
          }
        } else {
          compInstance.setFieldVisibility('name', false);
          compInstance.setFieldVisibility('_referentSubtype', false);
          compInstance.setFieldRequired('_referentSubtype', false);
        }
      }
    });
  }

  private getRoleTypeSelectOptions(): any[] {
    return [
      {id: RoleTypeEnum.Director, text: this.translatePipe.transform('institution-details.roleType--' + RoleTypeEnum.Director)},
      {id: RoleTypeEnum.Member, text: this.translatePipe.transform('institution-details.roleType--' + RoleTypeEnum.Member)},
      {id: RoleTypeEnum.President, text: this.translatePipe.transform('institution-details.roleType--' + RoleTypeEnum.President)},
      {id: RoleTypeEnum.ViceDirector, text: this.translatePipe.transform('institution-details.roleType--' + RoleTypeEnum.ViceDirector)},
      {id: RoleTypeEnum.VicePresident, text: this.translatePipe.transform('institution-details.roleType--' + RoleTypeEnum.VicePresident)},
      {id: RoleTypeEnum.OtherType, text: this.translatePipe.transform('institution-details.roleType--' + RoleTypeEnum.OtherType)}
    ];
  }

  private doPersonSearch(compInstance: BaseModalComponent, data: { fieldKey: string; searchedText: string; hasRemoteSearch: boolean }): void {
    this.personSearchGQL.fetch({
        first: undefined,
        last: undefined,
        after: undefined,
        before: undefined,
        keyword: data.searchedText
      },
      {fetchPolicy: 'no-cache'}
    ).subscribe(res => {
      compInstance.updateAutocompleteOptionsWithRemoteData(data.fieldKey, res.data.personSearch?.edges ? res.data.personSearch.edges.map((e) => {
        return {
          id: e?.node?.id,
          text: e?.node?.name
        };
      }) : []);
    });
  }

  private deleteItem(deleteQueryName: 'legalBodyDelete' | 'roleDelete', idToDelete: string, referentId?: string): void {
    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: true,
      data: {
        stickyHeader: false,
        hasClose: false,
        title: this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-title'),
        bodyHtml: '<p>' + this.translatePipe.transform('delete-item-confirmation-modal.confirm-deletion-text') + '</p>',
        isConfirmModal: true
      }
    });

    matDialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        // @ts-ignore
        this[`${deleteQueryName}GQL`].mutate({id: idToDelete}, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.[deleteQueryName].success === OperationReturnTypeEnum.Ok) {
            if (deleteQueryName === 'legalBodyDelete') {
              const foundIndexToDelete = this.listData.findIndex(o => o.id === idToDelete);
              if (foundIndexToDelete !== -1) {
                this.listData.splice(foundIndexToDelete, 1);
                this.listDataUpdated.emit({listData: this.listData});
              }
            } else if (deleteQueryName === 'roleDelete') {
              const foundIndex = this.listData.findIndex(o => o.id === referentId);
              if (foundIndex !== -1) {
                this.listData[foundIndex].groupedRoles = this.listData[foundIndex].groupedRoles.filter((r: Role) => r.id !== idToDelete);
                this.listDataUpdated.emit({listData: this.listData, updateUnapprovables: true});
              }
            }
          }
          this.snackBar.open(this.translatePipe.transform('delete-item-confirmation-modal.delete-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, (error: any) => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
      this.referentOverlayIndexToShow = -1;
      this.roleOverlayIndexToShow = -1;
    });
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.referentAndRolesService.roleDetailsObservable.subscribe(data => {
        this.openEditRoleModal(data.referentId, data.item);
      })
    );
  }

  onViewDetailsClick(referentId: string, roleId: string): void {
    this.roleOverlayIndexToShow = -1;
    this.viewDetailsClicked.emit({referentId, roleId});
  }

  onAddNewReferentClick(): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: '_referentType', // aux for legal body type
        type: 'select',
        label: this.translatePipe.transform('referent-modal.field--_referentType'),
        required: true,
        options: this.getReferentTypeOptions()
      },
      {
        fieldKey: '_referentSubtype', // aux for legal body subtype where needed
        type: 'select',
        label: this.translatePipe.transform('referent-modal.field--_referentSubtype'),
        required: true,
        hidden: true,
        options: []
      },
      {
        fieldKey: 'expectedEmployeeNumber',
        type: 'number',
        minNumber: 0,
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--expectedEmployeeNumber')
      },
      {
        fieldKey: 'duration',
        type: 'number',
        minNumber: 0,
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--duration')
      },
      {
        fieldKey: 'expiryDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--expiryDate')
      },
      {
        fieldKey: 'name',
        type: 'text',
        hidden: true,
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--name')
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('referent-modal.create-title'),
        fields: fieldsConf
      }
    });

    this.setReferentModalHandler(matDialogRef);

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.legalBodyCreateGQL.mutate({
          ...ReferentsAndRolesComponent.getReferentPayloadObjFromFieldsModel(fieldsModel)
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.legalBodyCreate) {
            this.listData.unshift(res.data.legalBodyCreate);
            this.listDataUpdated.emit({listData: this.listData});
          }
          this.snackBar.open(this.translatePipe.transform('referent-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  openEditReferentModal(item: any): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: '_referentType', // aux for legal body type
        type: 'select',
        label: this.translatePipe.transform('referent-modal.field--_referentType'),
        required: true,
        options: this.getReferentTypeOptions(),
        value: item.bodyType
      },
      {
        fieldKey: '_referentSubtype', // aux for legal body subtype where needed
        type: 'select',
        label: this.translatePipe.transform('referent-modal.field--_referentSubtype'),
        required: true,
        hidden: true,
        options: [],
        value: item.administrativeBodyType || item.advisoryBodyType || item.controlBodyType || item.steeringBodyType
      },
      {
        fieldKey: 'expectedEmployeeNumber',
        type: 'number',
        minNumber: 0,
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--expectedEmployeeNumber'),
        value: item.expectedEmployeeNumber
      },
      {
        fieldKey: 'duration',
        type: 'number',
        minNumber: 0,
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--duration'),
        value: item.duration
      },
      {
        fieldKey: 'expiryDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--expiryDate'),
        value: item.expiryDate
      },
      {
        fieldKey: 'name',
        type: 'text',
        hidden: true,
        width: 6,
        label: this.translatePipe.transform('referent-modal.field--name'),
        value: item.name
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        hasClose: true,
        title: this.translatePipe.transform('referent-modal.edit-title'),
        fields: fieldsConf
      }
    });

    this.setReferentModalHandler(matDialogRef);
    const compInstance = matDialogRef.componentInstance;
    // needed to to that way, maybe refactor in the future
    if (item.bodyType) {
      if (item.bodyType === LegalBodyTypeEnum.OtherBody) {
        // show name field
        compInstance.setFieldVisibility('_referentSubtype', false);
        compInstance.setFieldVisibility('name', true);
        compInstance.setFieldRequired('_referentSubtype', false);
        compInstance.setFieldRequired('name', true);
      } else if (item.bodyType !== LegalBodyTypeEnum.LegalRepresentative) {
        // show and activate referent subtype field
        compInstance.setFieldVisibility('_referentSubtype', true);
        compInstance.setFieldVisibility('name', false);
        compInstance.setFieldRequired('_referentSubtype', true);
        const options = this.getReferentSubtypeOptionByType(item.bodyType);
        if (options) {
          compInstance.setSelectFieldOptions('_referentSubtype', options);
        }
      } else {
        compInstance.setFieldVisibility('name', false);
        compInstance.setFieldVisibility('_referentSubtype', false);
        compInstance.setFieldRequired('_referentSubtype', false);
      }
    }

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.legalBodyUpdateGQL.mutate({
          legalBodyId: item.id,
          ...ReferentsAndRolesComponent.getReferentPayloadObjFromFieldsModel(fieldsModel)
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.legalBodyUpdate) {
            const foundIndex = this.listData.findIndex(o => o.id === item.id);
            if (foundIndex !== -1) {
              this.listData[foundIndex] = {...res.data.legalBodyUpdate};
              this.listDataUpdated.emit({listData: this.listData});
            }
          }
          this.snackBar.open(this.translatePipe.transform('referent-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
      this.referentOverlayIndexToShow = -1;
    });
  }

  openDeleteReferentModal(referentId: string): void {
    this.deleteItem('legalBodyDelete', referentId);
  }


  onAddNewRoleClick(referentId: string): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'roleType',
        type: 'select',
        label: this.translatePipe.transform('role-modal.field--roleType'),
        required: true,
        options: this.getRoleTypeSelectOptions()
      },
      {
        fieldKey: 'personId',
        type: 'autocomplete',
        label: this.translatePipe.transform('role-modal.field--personId'),
        required: true,
        hasRemoteSearch: true
      },
      {
        fieldKey: 'uniNominee',
        type: 'select',
        width: 6,
        label: this.translatePipe.transform('role-modal.field--uniNominee'),
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ]
      },
      {
        fieldKey: 'startDate',
        type: 'date',
        maxDateConstraint: new Date(),
        width: 6,
        label: this.translatePipe.transform('role-modal.field--startDate')
      },
      {
        fieldKey: 'endDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('role-modal.field--endDate')
      },
      {
        fieldKey: 'expectedEndDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('role-modal.field--expectedEndDate')
      },
      {
        fieldKey: 'roleDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('role-modal.field--roleDocument')
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('role-modal.field--note')
      },
      {
        fieldKey: 'legalBodyId',
        type: 'text-readonly',
        hidden: true,
        value: referentId
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        stickyFooter: true,
        hasClose: true,
        title: this.translatePipe.transform('role-modal.create-title'),
        fields: fieldsConf
      }
    });

    const compInstance = matDialogRef.componentInstance;
    compInstance.onAutocompleteChangedRemoteSearch.subscribe((data: { fieldKey: string, searchedText: string, hasRemoteSearch: boolean }) => {
      this.doPersonSearch(compInstance, data);
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        this.roleCreateGQL.mutate({
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.roleCreate) {
            const foundIndex = this.listData.findIndex(o => o.id === referentId);
            if (foundIndex !== -1) {
              this.listData[foundIndex].groupedRoles = [res.data.roleCreate, ...this.listData[foundIndex].groupedRoles];
              this.listDataUpdated.emit({listData: this.listData, updateUnapprovables: true});
            }
          }
          this.snackBar.open(this.translatePipe.transform('role-modal.create-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
    });
  }

  openEditRoleModal(referentId: string, item: any): void {
    const fieldsConf: ModalField[] = [
      {
        fieldKey: 'roleType',
        type: 'select',
        label: this.translatePipe.transform('role-modal.field--roleType'),
        required: true,
        options: this.getRoleTypeSelectOptions(),
        value: item.roleType
      },
      {
        fieldKey: 'personId',
        type: 'autocomplete',
        label: this.translatePipe.transform('role-modal.field--personId'),
        required: true,
        hasRemoteSearch: true,
        options: [
          {id: item.person.id, text: item.person.name}
        ],
        value: item.person.id
      },
      {
        fieldKey: 'uniNominee',
        type: 'select',
        width: 6,
        label: this.translatePipe.transform('role-modal.field--uniNominee'),
        options: [
          {id: true, text: this.translatePipe.transform('form-fields.boolean-yes')},
          {id: false, text: this.translatePipe.transform('form-fields.boolean-no')}
        ],
        value: item.uniNominee
      },
      {
        fieldKey: 'startDate',
        type: 'date',
        width: 6,
        maxDateConstraint: new Date(),
        label: this.translatePipe.transform('role-modal.field--startDate'),
        value: item.startDate
      },
      {
        fieldKey: 'endDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('role-modal.field--endDate'),
        value: item.endDate
      },
      {
        fieldKey: 'expectedEndDate',
        type: 'date',
        width: 6,
        label: this.translatePipe.transform('role-modal.field--expectedEndDate'),
        value: item.expectedEndDate
      },
      {
        fieldKey: 'roleDocument',
        type: 'file-picker-pdf',
        label: this.translatePipe.transform('role-modal.field--roleDocument'),
        value: item.roleDocument?.url ? {url: item.roleDocument.url} : undefined,
      },
      {
        fieldKey: 'note',
        type: 'textarea',
        label: this.translatePipe.transform('role-modal.field--note'),
        value: item.note
      },
      {
        fieldKey: 'legalBodyId',
        type: 'text-readonly',
        hidden: true,
        value: referentId
      },
      {
        fieldKey: 'institutionId',
        type: 'text-readonly',
        hidden: true,
        value: this.institutionId
      }
    ];

    const matDialogRef = this.dialog.open(BaseModalComponent, {
      ...getFixedSmallModalSettings(),
      disableClose: false,
      outputOnCancel: true,
      data: {
        stickyHeader: true,
        stickyFooter: true,
        hasClose: true,
        title: this.translatePipe.transform('role-modal.edit-title'),
        fields: fieldsConf
      }
    });

    const compInstance = matDialogRef.componentInstance;
    compInstance.onAutocompleteChangedRemoteSearch.subscribe((data: { fieldKey: string, searchedText: string, hasRemoteSearch: boolean }) => {
      this.doPersonSearch(compInstance, data);
    });

    matDialogRef.afterClosed().subscribe(fieldsModel => {
      if (fieldsModel) {
        fieldsModel.startDate = !fieldsModel.startDate ? null : fieldsModel.startDate;
        fieldsModel.endDate = !fieldsModel.endDate ? null : fieldsModel.endDate;
        this.roleUpdateGQL.mutate({
          roleId: item.id,
          input: fieldsModel
        }, {fetchPolicy: 'no-cache'}).subscribe(res => {
          if (res.data?.roleUpdate) {
            const foundReferentIndex = this.listData.findIndex(o => o.id === referentId);
            if (foundReferentIndex !== -1) {
              const foundRoleIndex = this.listData[foundReferentIndex].groupedRoles.findIndex((r: Role) => r.id === item.id);
              if (foundRoleIndex !== -1) {
                this.listData[foundReferentIndex].groupedRoles[foundRoleIndex] = {...res.data.roleUpdate};
                this.listDataUpdated.emit({listData: this.listData, updateUnapprovables: true});
              }
            }
          }
          this.snackBar.open(this.translatePipe.transform('role-modal.edit-success'), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        }, error => {
          this.snackBar.open(getErrorMessageFromData(error), this.translatePipe.transform('notification-box.close'), {
            duration: 4000
          });
        });
      }
      this.roleOverlayIndexToShow = -1;
    });
  }

  openDeleteRoleModal(referentId: string, roleId: string): void {
    this.deleteItem('roleDelete', roleId, referentId);
  }

  validateRoleItem(id: string): void {
    this.validateRoleItemClicked.emit({id, approvableType: ApprovableTypeEnum.Role});
  }

  refreshRoleApprovableField(data: any): void {
    this.refreshRoleApprovable.emit(data);
  }
}
